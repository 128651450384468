import string from 'util/string';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import Map from 'component/Map2';
import { useEffect } from 'react';
import sendRequest, { sendPost } from 'util/request';
import Urls from 'util/urls';
import { useState } from 'react';
import {
  adjustDate,
  downloadFile,
  formatDate,
  getString,
  nNU,
  round,
  setHours,
  setSearchRangeDate,
  truncTime,
  DD_LOCATION_FORMAT_REGEX,
} from 'util/utils';
import FormControls from 'component/FormControls';
import Signal from 'component/Signal';
import DeviceMarker2 from 'component/DeviceMarker2';
import { FaFileDownload } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { selectSearchRangeDate } from 'redux/app/app.selector';

const DeviceDetailJourney = ({ deviceId }) => {
  const [data, setData] = useState({});
  const [advancedData, setAdvancedData] = useState([]);
  const [total, setTotal] = useState(0);
  const [selectedItem, setSelectedItem] = useState(-1);
	const [assetName, setAssetName] = useState(null);
	const [sigfoxId, setSigfoxId] = useState(null);

  const searchRangeDate = useSelector(selectSearchRangeDate);
  const [searchData, setSearchData] = useState({
    fromDate: searchRangeDate?.[0] || truncTime(adjustDate(new Date(), -7)),
    toDate: searchRangeDate?.[1] || truncTime(),
  });

  const formControls = [
    {
      type: 'rangeDate',
      onChange: (e) =>
        setSearchData({
          ...searchData,
          fromDate: e[0],
          toDate: e[1],
        }),
      options: {
        defaultDate: [searchData.fromDate, searchData.toDate],
      },
    },
  ];

  const doSearch = async (getAll = false) => {
    setHours(searchData.fromDate);
    setHours(searchData.toDate, 23, 59, 59, 999);
    let body = {
      ...(!searchData?.fromDate
        ? null
        : {
            fromDate: searchData.fromDate.toISOString(),
          }),
      ...(!searchData?.toDate
        ? null
        : {
            toDate: searchData.toDate.toISOString(),
          }),
      deviceId,
      offset: 0,
      size: 10000000,
    };

    if (!body?.fromDate || !body?.toDate) {
      return;
    }

    setSearchRangeDate([searchData.fromDate, searchData.toDate]);
    let response = await sendPost({
      // url: `${Urls.device.advancedLog}/${deviceId}`,
      url: Urls.device.journey,
      body,
    });
    const { result } = response;
    let data = result
      .map((e) => {
        let computedLocation;
        let location;
        try {
          computedLocation = JSON.parse(e.computedLocation);
          location = [
            computedLocation?.Lat || computedLocation?.lat,
            computedLocation?.Lng || computedLocation?.lng,
          ];
        } catch (ex) {}

        if (!location || !location[0] || !location[1]) {
          return null;
        }
        if (!DD_LOCATION_FORMAT_REGEX.test(`${location[0]},${location[1]}`)) {
          return null;
        }

        return {
          ...data,
          ...e,
          location,
        };
      })
      .filter((e) => !!e);
    // if (!!data?.length) {
    //   setSelectedItem(0);
    // } else {
    //   setSelectedItem(-1);
    // }
		setSelectedItem(-1);
    if (!getAll) {
      setTotal(data?.length || 0);
      setAdvancedData(data);
			// console.log(data);
    }
    return data;
  };

  useEffect(() => {
    if (!data?.deviceId) {
      return;
    }
    doSearch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, searchData]);

  useEffect(() => {
    if (!deviceId) {
      return;
    }
    sendRequest({
      url: `${Urls.device.info}/${deviceId}`,
    }).then((response) => {
      const { result } = response;
      setData(result);
      // doSearch();
			// console.log(result);
			if(nNU(result?.assetName)){
				setAssetName(result.assetName);
			}
			else{
				setAssetName(null);
			}
			if(nNU(result?.sigfoxId)){
				setSigfoxId(result.sigfoxId);
			}
			else{
				setSigfoxId(null);
			}
    });
  }, [deviceId]);


  const [loadForm, setLoadForm] = useState(false);
  const [geoList, setGeoList] = useState([]);
  //const [addGeoData, setAddGeoData] = useState(null);
  //const [selectedLocation, setSelectedLocation] = useState(-1);
  const getAllGPSSetting = () => {
    setTimeout(() => {
      setLoadForm(false);
    }, 0);
    sendRequest({
      method: 'get',
      url: `${Urls.gpsNotifyConfiguration.getAllGPSSetting}/${deviceId}`,
      background: true,
    }).then((res) => {
      const { result } = res;

      // if (!!result?.length) {
      //   setSelectedLocation(0);
      // } else {
      //   setSelectedLocation(-1);
      // }
      //setTotal(result?.length || 0);
      let geoValue = [];
      result.forEach((item, index) => {
        geoValue[index] = { ...item };
        geoValue[index].location = [item?.latitude, item?.longitude];
      });
      setGeoList(geoValue);
			// console.log(geoValue);
    });
  };
  // console.log(geoList);

  useEffect(() => {
    getAllGPSSetting();
		// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId]);

  const [zoom, setZoom] = useState();
  useEffect(() => {
    if (!nNU(localStorage.getItem('Map'))) return;
    let data = JSON.parse(localStorage.getItem('Map'));
    if (!nNU(data)) return;
    setZoom(data);
  }, []);

  return (
    <>
      <Container className="device-detail-map" id="device-detail-journey">
        <Row>
          <Col
						xl={8}
					>
            <Card>
              <Card.Header>{string.DEVICE_LOCATION}</Card.Header>
              <Card.Body className="detail-map-container">
                {nNU(zoom) && (
                  <Map
									  assetName={assetName}
										sigfoxId={sigfoxId}
                    data={
                      !!advancedData?.length
                        ? advancedData
                        : [{ computedLocation: data.deviceLocation }]
                    }
                    geoData={geoList}
                    showRoutes={true}
                    selectedMarker={selectedItem}
                    MarkerContent={DeviceMarker2}
                    isJourney={true}
                    zoom={zoom}
										maxZoom={22}
                    isGeoChangedIcon={true}
                    isCircle={true}
										isGPSDevice={true}
										isDetailMap = {false}
                  />
                )}
              </Card.Body>
            </Card>
          </Col>
          <Col
						xl={4}
					>
            <Card>
              <Card.Header >{string.DEVICE_DETAIL_JOURNEY}</Card.Header>
              <Card.Body className="detail-map-container">
                <Row>
                  <Col>
                    <FormControls controls={formControls} />
                  </Col>
                  <Col>
                    <span>{getString(string.ROWS, total)}</span>
                  </Col>
                  {/* <Col className="journey-nav-btns">
                    <Button
                      className="journey-btn"
                      onClick={() => {
                        let idx = (selectedItem - 1 + total) % total;

                        if (!isNaN(idx)) {
                          setSelectedItem(idx);

                          document
                            .getElementsByClassName('journey-item')
                            [idx].scrollIntoView();
                        }
                      }}
                    >
                      ↑
                    </Button>
                    <Button
                      className="journey-btn"
                      onClick={() => {
                        let idx = (selectedItem + 1) % total;

                        if (!isNaN(idx)) {
                          setSelectedItem(idx);
                          document
                            .getElementsByClassName('journey-item')
                            [idx].scrollIntoView();
                        }
                      }}
                    >
                      ↓
                    </Button>
                  </Col>
                  <Col className="journey-dl-btn">
                    <Button
                      onClick={async () => {
                        let journeyData = await doSearch(true);
                        let csvData = [
                          ...[
                            [
                              string.DEVICE_ID,
                              string.DEVICE_LOG_TIME,
                              string.LOCATION_LATITUDE,
                              string.LOCATION_LONGITUDE,
                            ].join(','),
                          ],
                          ...journeyData.map((e) =>
                            [
                              data.sigfoxId,
                              formatDate(e.dataLogTime, 'YYYY-MM-DD HH:mm:ss'),
                              round(e.location?.[0], 6),
                              round(e.location?.[1], 5),
                            ].join(',')
                          ),
                        ];
                        // console.log(csvData);
                        downloadFile({
                          data: csvData.join('\n'),
                          fileName: `DeviceJourney_${data.sigfoxId}`,
                          fileType: 'csv',
                        });
                      }}
                      className="journey-btn"
                    >
                      <FaFileDownload />
                    </Button>
                  </Col> */}
                </Row>
                <Row>
                  {/* <Col>
                    <FormControls controls={formControls} />
                  </Col>
                  <Col>
                    <span>{getString(string.ROWS, total)}</span>
                  </Col> */}
                  <Col className="journey-nav-btns">
                    <Button
                      className="journey-btn"
                      onClick={() => {
                        let idx = (selectedItem - 1 + total) % total;

                        if (!isNaN(idx)) {
                          setSelectedItem(idx);

                          document
                            .getElementsByClassName('journey-item')
                            [idx].scrollIntoView();
                        }
                      }}
                    >
                      ↑
                    </Button>
                    <Button
                      className="journey-btn"
                      onClick={() => {
                        let idx = (selectedItem + 1) % total;

                        if (!isNaN(idx)) {
                          setSelectedItem(idx);
                          document
                            .getElementsByClassName('journey-item')
                            [idx].scrollIntoView();
                        }
                      }}
                    >
                      ↓
                    </Button>
                  </Col>
                  <Col className="journey-dl-btn">
                    <Button
                      onClick={async () => {
                        let journeyData = await doSearch(true);
                        let csvData = [
                          ...[
                            [
                              string.DEVICE_ID,
                              string.DEVICE_LOG_TIME,
                              string.LOCATION_LATITUDE,
                              string.LOCATION_LONGITUDE,
                            ].join(','),
                          ],
                          ...journeyData.map((e) =>
                            [
                              data.sigfoxId,
                              formatDate(e.dataLogTime, 'YYYY-MM-DD HH:mm:ss'),
                              round(e.location?.[0], 6),
                              round(e.location?.[1], 5),
                            ].join(',')
                          ),
                        ];
                        // console.log(csvData);
                        downloadFile({
                          data: csvData.join('\n'),
                          fileName: `DeviceJourney_${data.sigfoxId}`,
                          fileType: 'csv',
                        });
                      }}
                      className="journey-btn"
                    >
                      <FaFileDownload />
                    </Button>
                  </Col>
                </Row>
                <Row id="journeyList">
                  {advancedData.map((e, index) => (
                    <Card
                      className={`journey-item ${
                        index === selectedItem ? 'journey-item-selected' : ''
                      }`}
                      key={index}
                      onClick={() => setSelectedItem(index)}
                    >
                      <Card.Body>
                        <div>
                          {formatDate(e.dataLogTime, 'YYYY-MM-DD HH:mm:ss')}
                        </div>
                        <div>
                          <Signal data={e.lqi} />
                        </div>
                        {/* <div className="journey-location">
                          {e.location.map((e, i) => round(e, 6 - i)).join(' ')}
                        </div> */}
                      </Card.Body>
                    </Card>
                  ))}
                </Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default DeviceDetailJourney;
