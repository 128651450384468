import Page from 'component/Page';
import Table from 'component/Table';
import { accountRoleNameMap, accountStatusNameMap } from 'page/user/UserList';
import { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import { ACCOUNT_ROLE_ADMIN } from 'util/permission';
import { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';

const UserSelect = ({
	onItemSelected = () => {},
	deviceId,
	onUpdated,
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const title = string.ACCOUNT_SELECT;
  const headers = [
    string.LOGIN_ID,
    string.ACCOUNT_NAME,
    string.ACCOUNT_ROLE,
    string.ACCOUNT_MAIL,
    string.ACCOUNT_STATUS,
  ];
  const [data, setData] = useState([]);
  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key,
        data: [
          raw.loginId,
          raw.accountName,
          accountRoleNameMap[raw.accountRole] || '',
          raw.email,
          accountStatusNameMap[raw.valid] || '',
        ],
        raw: { ...raw },
      })),
    []
  );
  const actions = [{ render: 'userSelect', onClick: onItemSelected }];

  const doSearch = useCallback(
    (page, pageSize) => {
      return sendPost({
        url:
          currentUser.accountRole === ACCOUNT_ROLE_ADMIN
            ? Urls.user.list
            : `${Urls.user.childList2}/${currentUser.accountId}/${deviceId}`,
        body: {
          offset: (page - 1) * pageSize,
          size: pageSize,
        },
      }).then((data) => {
        const { total, result } = data;
        setData(result);
        setTotal(total);
      });
    },
		/* eslint-disable react-hooks/exhaustive-deps */
    [currentUser,onUpdated]
  );

  useEffect(() => {
    doSearch(page, pageSize);
  }, [page, pageSize, doSearch]);

  return (
    <>
      <Page
        title={title}
        body={
          <>
            <Container>
              <Table
                className="nowrap"
                page={page}
                pageSize={pageSize}
                total={total}
                onPageChanged={setPage}
                onPageSizeChanged={setPageSize}
                headers={headers}
                data={dataTable(data)}
                onRowClick={onItemSelected}
                actions={actions}
              />
            </Container>
          </>
        }
      />
    </>
  );
};

export default UserSelect;
