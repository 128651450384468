import Page from 'component/Page';
import string from 'util/string';
import Table from 'component/Table';
import Modal from 'component/Modal';
import { useState } from 'react';
import { useEffect } from 'react';
import { useCallback } from 'react';
import sendRequest, { sendPost } from 'util/request';
import { ACCOUNT_ROLE_ADMIN } from 'util/permission';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import Urls from 'util/urls';
import { formatDate, getString, showConfirm, showToast } from 'util/utils';
import ContractMaintenance from './ContractMaintenance';
import { useMemo } from 'react';
// var hash = require('object-hash');

const ContractList = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = useMemo(
    () => currentUser.accountRole === ACCOUNT_ROLE_ADMIN,
    [currentUser.accountRole]
  );
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const title = string.CONTRACT_MANAGEMENT;

  const headers = isAdmin
    ? [
        string.CONTRACT_NUMBER,
        string.CONTRACT_CODE,
        string.ACCOUNT_NAME,
        string.CONTRACT_ACTIVATION_DATE,
        string.CONTRACT_EXPIRATION_DATE,
      ]
    : [
        string.CONTRACT_NUMBER,
        string.ACCOUNT_NAME,
        string.CONTRACT_ACTIVATION_DATE,
        string.CONTRACT_EXPIRATION_DATE,
      ];
  const [data, setData] = useState([]);
  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key,
        data: isAdmin
          ? [
              raw.contractNumber,
              raw.contractId,
              raw.accountName,
              formatDate(raw.validationDate, 'YYYY/MM/DD'),
              formatDate(raw.expirationDate, 'YYYY/MM/DD'),
            ]
          : [
              raw.contractNumber,
              // raw.contractId,
              raw.accountName,
              formatDate(raw.validationDate, 'YYYY/MM/DD'),
              formatDate(raw.expirationDate, 'YYYY/MM/DD'),
            ],
        raw,
      })),
    []
  );

  const [selectedItem, setSelectedItem] = useState(null);
  const [addMode, setAddMode] = useState(false);
  const editAction = ({ raw }) => {
    setAddMode(false);
    setSelectedItem(raw);
  };
  const onModalHide = () => setSelectedItem(null);
  const onModalUpdated = (res, editMode) => {
    onModalHide();
    doSearch(page, pageSize);
    // if (editMode) {
    //   return;
    // }
    setSelectedItem(null);
  };
  const modalBody = (
    <>
      <ContractMaintenance
        onUpdated={onModalUpdated}
        data={selectedItem}
        addMode={addMode}
      />
    </>
  );
  const mainActions = [
    {
      icon: 'add',
      title: isAdmin ? string.NEW_CONTRACT : string.ADD_CONTRACT,
      onClick: () => {
        setAddMode(true);
        setSelectedItem({
          accountId: currentUser.accountId,
          accountName: currentUser.accountName,
          validationDate: new Date(),
          expirationDate: new Date(),
        });
      },
    },
  ];
  const actions = [
    {
      render: currentUser.accountRole === ACCOUNT_ROLE_ADMIN ? 'edit' : 'info',
      onClick: editAction,
    },
    ({ raw }) => {
      if (!isAdmin) {
        return null;
      }
      if (
        !raw?.accountId ||
        raw?.accountId === '00000000-0000-0000-0000-000000000000'
      ) {
        return {
          render: 'minus',
          alt: 'Delete',
          className: 'color-danger',
          onClick: ({ raw }) => {
            showConfirm({
              body: getString(string.CONTRACT_DELETE_CONFIRM, [
                raw.contractNumber,
              ]),
              defaultAction: () => {
                sendRequest({
                  url: `${Urls.contract.update}/${raw.contractId}`,
                  method: 'delete',
                }).then(() => {
                  showToast(string.CONTRACT_DELETE_SUCCESS, 'success');
                  doSearch(page, pageSize);
                });
              },
            });
          },
        };
      }
      return {
        render: 'unassign',
        className: 'color-warning',
        onClick: ({ raw }) => {
          showConfirm({
            body: getString(string.ACCOUNT_UNASSIGN_CONTRACT_CONFIRM, [
              raw.accountName,
              raw.contractNumber,
            ]),
            defaultAction: () => {
              sendPost({
                url: `${Urls.contract.unlink}/${raw.contractId}`,
              }).then(() => {
                showToast(string.ACCOUNT_UNASSIGN_CONTRACT_SUCCESS, 'success');
                doSearch(page, pageSize);
              });
            },
          });
        },
      };
    },
  ];

  const doSearch = useCallback(
    async (page, pageSize, getAll = false) => {
      let data = await sendPost({
        url:
          currentUser.accountRole === ACCOUNT_ROLE_ADMIN
            ? Urls.contract.list
            : `${Urls.contract.listOfAccount}/${currentUser.accountId}`,
        body: {
          offset: (page - 1) * pageSize,
          size: pageSize,
        },
      });
      const { total, result } = data;
      if (!getAll) {
        setData(result);
        setTotal(total);
      }
      return result;
    },
    [currentUser]
  );

  useEffect(() => {
    doSearch(page, pageSize);
  }, [page, pageSize, doSearch]);

  return (
    <>
      <Page
        title={title}
        body={
          <>
            <Table
              className="nowrap"
              mainActions={mainActions}
              page={page}
              pageSize={pageSize}
              total={total}
              onPageChanged={setPage}
              onPageSizeChanged={setPageSize}
              headers={headers}
              data={dataTable(data)}
              actions={actions}
              getCSVData={async () => {
                return dataTable(await doSearch(1, total, true));
              }}
              csvFileName="ContractList"
            />
          </>
        }
      />
      <Modal show={!!selectedItem} onHide={onModalHide} body={modalBody} />
    </>
  );
};

export default ContractList;
