import * as React from "react"

const DkSf03wt2FloodHalf = (props) => {
  return (
    <svg
      id="\u30EC\u30A4\u30E4\u30FC_23_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <g id="\u30EC\u30A4\u30E4\u30FC_23_1_00000114758273829322634720000013394272586514149813_">
        <path
          d="M5 48.58c-1.98 0-3.58-1.61-3.58-3.58V5c0-1.98 1.61-3.58 3.58-3.58h40c1.98 0 3.58 1.61 3.58 3.58v40c0 1.98-1.61 3.58-3.58 3.58H5z"
          fill="#fff"
        />
        <path d="M45 2.83c1.19 0 2.17.97 2.17 2.17v40c0 1.19-.97 2.17-2.17 2.17H5c-1.19 0-2.17-.97-2.17-2.17V5c0-1.19.97-2.17 2.17-2.17h40M45 0H5C2.24 0 0 2.24 0 5v40c0 2.76 2.24 5 5 5h40c2.76 0 5-2.24 5-5V5c0-2.76-2.24-5-5-5z" />
        <defs>
          <path
            id="SVGID_23_1_"
            d="M45 50H5c-2.76 0-5-2.24-5-5V5c0-2.76 2.24-5 5-5h40c2.76 0 5 2.24 5 5v40c0 2.76-2.24 5-5 5z"
          />
        </defs>
        <clipPath id="SVGID_23_00000023976911326778800770000009429769090947511213_">
          <use xlinkHref="#SVGID_23_1_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_23_00000023976911326778800770000009429769090947511213_)"
          fill="#01a0c6"
          d="M50 -0.09L-0.01 49.91 50 49.91z"
        />
        <g>
          <path d="M45 2.83c1.19 0 2.17.97 2.17 2.17v40c0 1.19-.97 2.17-2.17 2.17H5c-1.19 0-2.17-.97-2.17-2.17V5c0-1.19.97-2.17 2.17-2.17h40M45 0H5C2.24 0 0 2.24 0 5v40c0 2.76 2.24 5 5 5h40c2.76 0 5-2.24 5-5V5c0-2.76-2.24-5-5-5z" />
        </g>
      </g>
      <g id="\u30EC\u30A4\u30E4\u30FC_23_3">
        <g id="LINE_23_00000016763261895863963070000005104790132133934472_">
          <path
            d="M8.55 15.44a.425.425 0 10-.85 0v5.14c0 .24.19.42.43.42.23 0 .42-.19.42-.43v-5.13zm8.25 2.98a.425.425 0 000-.85c-.23 0-.42.19-.42.43s.19.42.42.42zm1.58-3.57V30.1H6.54V14.85c0-.59.4-1.1.96-1.25l1.75-.47h1.6V9.02l.58-7.06h2.06l.59 7.06v4.11h1.6l1.75.47c.56.16.95.67.95 1.25z"
            id="XMLID_23_00000123416120862017429810000001006585774977919380_"
          />
        </g>
        <g id="LINE_23_00000039115784625065600390000002187852024674256530_">
          <path
            d="M32.82 28.07a.425.425 0 10-.85 0v5.15a.425.425 0 00.85 0v-5.15zm7.13-2.29l1.75.47c.56.15.95.66.95 1.24v15.25H30.81V27.49c0-.58.39-1.09.96-1.24l1.74-.47h1.61v-4.12l.58-7.05h2.06l.59 7.05v4.12h1.6zm1.12 5.29a.425.425 0 10-.01-.85.425.425 0 00.01.85z"
            id="XMLID_23_00000158741975959943413870000006154806592794291902_"
          />
        </g>
        <path
          d="M18.15.29v5.06c0 1.1.9 2 2 2h14.58c1.1 0 2 .9 2 2v5.25"
          fill="none"
          stroke="#000"
          strokeWidth={1.4173}
          strokeMiterlimit={10}
        />
      </g>
    </svg>
  )
}

export default DkSf03wt2FloodHalf;
