// import GeoMarker from 'component/GeoMarker';
import Chart from 'component/Chart';
import FormControls from 'component/FormControls';
import Map from 'component/Map2';
import { notifyState } from 'component/State';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, Redirect } from 'react-router-dom';
import { appHideConfirm } from 'redux/app/app.action';
import { selectSearchRangeDate } from 'redux/app/app.selector';
import { selectCurrentUser } from 'redux/user/user.selector';
import { ACCOUNT_ROLE_CHILD } from 'util/permission';
import sendRequest, { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import { FaFileDownload, FaFileUpload } from 'react-icons/fa';
import {
  adjustDate,
  averageArray,
  DD_LOCATION_FORMAT_REGEX,
  downloadFile,
  formatDate,
  getFormData,
  getString,
  nNU,
  setHours,
  setSearchRangeDate,
  showConfirm,
  showToast,
  truncTime,
  dataURIToBlob,
	getLocalTime,
} from 'util/utils';
import Paths from 'util/paths';
import Slideshow from 'component/Slideshow';
// import { forEach } from 'lodash';
import Resizer from 'react-image-file-resizer';
import DeviceMarker2 from 'component/DeviceMarker2';
const GET_LOG_INTERVAL = 5 * 1000;
// const LOCATION_UPDATE = 1;
// const DEVICE_NAME_UPDATE = 0;

const DkSf03wt2FloodState = [
	string.DKSF03WT2_FLOOD_OK,
	string.DKSF03WT2_FLOOD_NG,
];

const DkSf03wt2FloodState2 = [
	string.DKSF03WT2_FLOOD_AB_OK,
	string.DKSF03WT2_FLOOD_A_NG,
	string.DKSF03WT2_FLOOD_B_NG,
	string.DKSF03WT2_FLOOD_AB_NG,
];

const DkSf03wt2TiltState = [
	string.DKSF03WT2_TILT_OK,
	string.DKSF03WT2_TILT_NG,
];

const DkSf03wt2BatteryState = [
	string.DKSF03WT2_BATTERY_OK,
	string.DKSF03WT2_BATTERY_NG,
];

const DkSf03wt2Timeout = [
	string.DKSF03WT2_TIMEOUT,
];

const DkSf07TiltState = [
	string.DKSF07_TILT_OK,
	string.DKSF07_TILT_NG,
];

const DeviceDetailStatus = ({ deviceId }) => {
  const [editLocation, setEditLocation] = useState(false);
  const [deviceLocation, setDeviceLocation] = useState(null);
  const [data, setData] = useState({});
  const [chartData, setChartData] = useState({
    dataHum: [],
    dataHumCenter: 0,
    dataTemp: [],
    dataTempCenter: 0,
    dataLightTemp: [],
    dataLightTempCenter: 0,
    dataWBGT: [],
    dataWBGTCenter: 0,
    time: [],
  });
	const [exportDataSf03wt2, setExportDataSf03wt2] = useState({
		floodState: [],
		tiltState: [],
		batteryLow: [],
		lqi: [],
		logTime: [],
		lat: [],
		lng: []
	});
	const [exportDataSf07, setExportDataSf07] = useState({
		tiltState: [],
		batteryLow: [],
		lqi: [],
		logTime: [],
		lat: [],
		lng: []
	});
  const history = useHistory();
  const [advancedData, setAdvancedData] = useState({});
  const [loadForm, setLoadForm] = useState(false);
  const searchRangeDate = useSelector(selectSearchRangeDate);
  const [isGraphText, setGraphText] = useState(false);
  const [isGraphData, setGraphData] = useState(true);
  const [searchData, setSearchData] = useState({
    fromDate: searchRangeDate?.[0] || truncTime(adjustDate(new Date(), -7)),
    toDate: searchRangeDate?.[1] || truncTime(),
  });
  const wrapperRef = useRef(null);
  const onDragEnter = () => wrapperRef.current.classList.add('dragover');

  const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

  const onDrop = () => wrapperRef.current.classList.remove('dragover');
  const currentUser = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  // console.log(currentUser.accountRole === ACCOUNT_ROLE_CHILD);

  const formControls = [
    {
      type: 'rangeDate',
      onChange: (e) => {
        setSearchData({
          ...searchData,
          fromDate: e[0],
          toDate: e[1],
        });
        setSearchRangeDate([e[0], e[1]]);
      },

      options: {
        defaultDate: [searchData.fromDate, searchData.toDate],
      },
    },
  ];

  // console.log(searchData);
  const getDeviceData = () => {
    setTimeout(() => {
      setLoadForm(false);
    }, 0);
    sendRequest({
      url: `${Urls.device.info}/${deviceId}`,
      background: true,
    }).then((response) => {
      const { result } = response;
      let deviceLocation = result?.deviceLocation;
      try {
        if (!!deviceLocation) {
          deviceLocation = JSON.parse(deviceLocation);
        }
      } catch (e) {}
      setData({ ...result, deviceLocation });
      setTimeout(() => {
        setLoadForm(true);
      }, 0);
    }).catch((e) => {
			//console.log(e);
			const message = e;
			if(message === `使用中のアカウントから端末（ID: ${deviceId}）をアクセスできません。`){
				//console.log(message);
				setRedirect(true);
			}
		});
  };

  // useEffect(() => {
  //   if (!deviceId) {
  //     return;
  //   }
  //   sendPost({
  //     url: `${Urls.device.advancedLog}/${deviceId}`,
  //     body: { offset: 0, size: 1 },
  //   }).then((response) => {
  //     const { result } = response;
  //     console.log(result);
  //     setAdvancedData(result?.[0]);
  //     getDeviceData();
  //   });
  //   /* eslint-disable react-hooks/exhaustive-deps */
  // }, [deviceId]);

  const [geoList, setGeoList] = useState([]);
  //const [selectedItem, setSelectedItem] = useState(null);
  const [total, setTotal] = useState(0);
  //const [addGeoData, setAddGeoData] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(-1);
  const getAllGPSSetting = () => {
    setTimeout(() => {
      setLoadForm(false);
    }, 0);
    sendRequest({
      method: 'get',
      url: `${Urls.gpsNotifyConfiguration.getAllGPSSetting}/${deviceId}`,
      background: true,
    }).then((res) => {
      const { result } = res;

      if (!!result?.length) {
        setSelectedLocation(0);
      } else {
        setSelectedLocation(-1);
      }
      setTotal(result?.length || 0);
      let geoValue = [];
      result.forEach((item, index) => {
        geoValue[index] = { ...item };
        geoValue[index].location = [item?.latitude, item?.longitude];
      });
      setGeoList(geoValue);
    });
  };
  //console.log(geoList);

  useEffect(() => {
    getAllGPSSetting();
		/* eslint-disable react-hooks/exhaustive-deps */
  }, [deviceId]);


  const [state, setState] = useState([]);
  const [currentLocation, setCurrentLocation] = useState();
  const [currentLocationDisable, setCurrentLocationDisable] = useState();
  const [showPicture, setShowPicture] = useState(false);
	const [redirect, setRedirect] = useState(false);

  const getDevicesLogData = useCallback(() => {
    if (window?.['gettingDevicesLog'] === true) {
      return;
    }
    window['gettingDevicesLog'] = true;
		getDeviceData();
		//console.log(loadForm);
    sendRequest({
      url: `${Urls.device.latestDeviceData}/${deviceId}`,

      background: true,
    })
      .then((response) => {
        const { result } = response;

        setAdvancedData(result);
        //getDeviceData();
      })
      .finally(() => {
        window['gettingDevicesLog'] = false;
      });
			/* eslint-disable react-hooks/exhaustive-deps */
  }, [deviceId]);

  useEffect(() => {
    let newState = state;
    newState[data?.deviceId] = notifyState(advancedData?.wbgt);
    setState(newState);

  }, [data, advancedData]);

  useEffect(() => {
    let getLogInterval3 = setInterval(getDevicesLogData, GET_LOG_INTERVAL);
    window['gettingDevicesLog'] = false;
    getDevicesLogData();
    return () => {
      clearInterval(getLogInterval3);
    };
  }, [deviceId]);

  useEffect(() => {
    if(!nNU(searchData.fromDate) || !nNU(searchData.toDate)){
			return;
		}
    setHours(searchData.fromDate);
    setHours(searchData.toDate, 23, 59, 59, 999);
    let body = {
      ...(!searchData?.fromDate
        ? null
        : {
            fromDate: searchData.fromDate.toISOString(),
          }),
      ...(!searchData?.toDate
        ? null
        : {
            toDate: searchData.toDate.toISOString(),
          }),
      deviceId,
      ...{
        offset: 0,
        size: 10000000,
      },
    };
    sendPost({
      url: Urls.device.journey,
      body,
    }).then((response) => {
      const { result } = response;

			if(result.length > 0){
				if(nNU(result[0]?.wbgt)){
					// console.log('Result:', result[0]);
					let dataHum = [];
					let dataTemp = [];
					let dataLightTemp = [];
					let dataWBGT = [];
					let timeC = [];
					let date = new Date();
					let lat = [];
					let lng = [];

					result.forEach((e) => {
						dataHum.push(e.humidity);
						dataTemp.push(e.airTemperature);
						dataLightTemp.push(e.globeTemperature);
						dataWBGT.push(e.wbgt);
						date = new Date(`${e.dataLogTime}Z`);
						timeC.push(formatDate(date, 'YYYY-MM-DD HH:mm'));
						let location = JSON.parse(e.computedLocation);
						// console.log(location);
						nNU(location) ? lat.push(location.Lat) : lat.push('');
						nNU(location) ? lng.push(location.Lng) : lng.push('');
					});
					const dataHumAver = averageArray(dataHum).toFixed(0);
					const dataTempAver = averageArray(dataTemp).toFixed(0);
					const dataLightTempAver = averageArray(dataLightTemp).toFixed(0);
					const dataWBGTAver = averageArray(dataWBGT).toFixed(0);
					let date1 = new Date(searchData.fromDate);
					let date2 = new Date(timeC[timeC.length - 1]);
					date2.setDate(date2.getDate() - 1);
					date2.setHours(23);
					date2.setMinutes(59);
					setGraphText(date2 - date1 >= 0);
					setChartData({
						time: timeC.reverse(),
						dataTemp: dataTemp.reverse(), //subArray(dataTemp, dataTempAver),
						dataTempCenter: dataTempAver,
						dataHum: dataHum.reverse(), //subArray(dataHum, dataHumAver),
						dataHumCenter: dataHumAver,
						dataLightTemp: dataLightTemp.reverse(), //subArray(dataLightTemp, dataLightTempAver),
						dataLightTempCenter: dataLightTempAver,
						dataWBGT: dataWBGT.reverse(), // subArray(dataWBGT, dataWBGTAver),
						dataWBGTCenter: dataWBGTAver,
						lat: lat.reverse(),
						lng: lng.reverse(),
					});
				}
				else if(nNU(result[0]?.dataType1)){

					// console.log('Result:', result[0]);
					let floodState = [];
					let tiltState = [];
					let batteryLow = [];
					let lqi = [];
					let logTime = [];
					let lat = [];
					let lng = [];

					result.forEach((raw) => {
						let dataValid =
							nNU(raw?.notifyType) && nNU(raw?.dataType1) ? true:false;
						if(dataValid){
							let dipSwitch =
								nNU(raw?.dipSwitch) ? parseInt(`0x${raw?.dipSwitch}`):0;
							let singleSensor =
								(dipSwitch & parseInt('0x0010')) > 0 ? 1:0;
							let flood =
								nNU(raw?.notifyType) && (raw?.notifyType === '17') ? 0 :
									nNU(raw?.dataType1) ?
										singleSensor > 0 ? parseInt(raw?.dataType1) % 2: parseInt(raw?.dataType1) % 4:0;
							let floodStateVal =
								singleSensor > 0 ? DkSf03wt2FloodState[flood] : DkSf03wt2FloodState2[flood];
							let tilt =
								nNU(raw?.dataType3) && (raw?.dataType3 === '1') ? 1:0;
							let tiltStateVal = DkSf03wt2TiltState[tilt];
							let battery =
								nNU(raw?.batteryVoltage) && parseFloat(raw?.batteryVoltage) > 2.4 ? 0:1;
							let batteryLowVal = DkSf03wt2BatteryState[battery];
							let lqiState =
							 nNU(raw?.lqi) ? raw?.lqi === 'Excellent' ? '強い':
							 	raw?.lqi === 'Good' ? '良い':
									raw?.lqi === 'Average' ? '弱い' :
										'非常に弱い' :'-';
							let date = new Date(`${raw?.dataLogTime}Z`);
							let location = JSON.parse(raw?.computedLocation);

							floodState.push(floodStateVal);
							tiltState.push(tiltStateVal);
							batteryLow.push(batteryLowVal);
							lqi.push(lqiState);
							logTime.push(formatDate(date, 'YYYY-MM-DD HH:mm'));
							nNU(location) ? lat.push(location.Lat) : lat.push('');
							nNU(location) ? lng.push(location.Lng) : lng.push('');
						}
					});

					setExportDataSf03wt2({
						floodState: floodState.reverse(),
						tiltState: tiltState.reverse(),
						batteryLow: batteryLow.reverse(),
						lqi: lqi.reverse(),
						logTime: logTime.reverse(),
						lat: lat.reverse(),
						lng: lng.reverse(),
					});
				}
			}
			// console.log('WBGT');
    });

    setCurrentLocationDisable(null);
    setCurrentLocation(null);
    setEditLocation(false);
  }, [searchData, deviceId]);

  useEffect(() => {
    let newLocation = null;
    let computedLocation = advancedData?.computedLocation;

    try {
      if (!!computedLocation) {
        computedLocation = JSON.parse(computedLocation);
        // if (nNU(computedLocation?.Lat) && nNU(computedLocation?.Lng)) {
        if (nNU(computedLocation)) {
          const lat = computedLocation?.Lat || computedLocation?.lat;
          const lng = computedLocation?.Lng || computedLocation?.lng;
          newLocation = DD_LOCATION_FORMAT_REGEX.test(`${lat},${lng}`)
            ? [lat, lng]
            : null;
        }
      }

      let dataDeviceLocation = JSON.parse(data?.deviceLocation);
      if (!newLocation) {
        if (nNU(dataDeviceLocation)) {
          const lat = dataDeviceLocation?.Lat || dataDeviceLocation?.lat;
          const lng = dataDeviceLocation?.Lng || dataDeviceLocation?.lng;
          newLocation = DD_LOCATION_FORMAT_REGEX.test(`${lat},${lng}`)
            ? [lat, lng]
            : null;
        }
      }
    } catch (e) {

		}

    setDeviceLocation(newLocation);
  }, [data, advancedData]);

  const handleDownload = () => {
    let arrCSVData = [];
    // console.log(chartData);
    arrCSVData.push(`時間,湿度(%),気温(℃),黒球温度(℃),WBGT(℃),緯度,経度`);
    for (let i = 0; i < chartData.time.length; i++) {
      let exportData = `${chartData.time[i]},${chartData.dataHum[i]},${chartData.dataTemp[i]},${chartData.dataLightTemp[i]},${chartData.dataWBGT[i]},${chartData.lat[i]},${chartData.lng[i]}`;

      arrCSVData.push(exportData);
    }
    downloadFile({
      data: arrCSVData.join('\n'),
      fileName: `DeviceDetail_${data.sigfoxId}`,
      fileType: 'csv',
    });
  };

	const handleDownloadSf03wt2 = () => {
		// console.log('Data: ',data);
		// console.log('DKSF03WT2 Log data: ',exportDataSf03wt2);
		if(exportDataSf03wt2.logTime.length > 0){
			let arrCSVData = [];
			arrCSVData.push(`番号,装置名称,Sigfox ID,水位情報,傾斜状態,電源情報,通信品質,記録時間,緯度,経度`);
			for(let i = 0; i < exportDataSf03wt2.logTime.length; i++){
				let exportData = `${i+1},`;	// 番号
				exportData = `${exportData}${data.assetName},`;
				exportData = `${exportData}${data.sigfoxId},`;
				exportData = `${exportData}${exportDataSf03wt2.floodState[i]},`;
				exportData = `${exportData}${exportDataSf03wt2.tiltState[i]},`;
				exportData = `${exportData}${exportDataSf03wt2.batteryLow[i]},`;
				exportData = `${exportData}${exportDataSf03wt2.lqi[i]},`;
				exportData = `${exportData}${exportDataSf03wt2.logTime[i]},`;
				exportData = `${exportData}${exportDataSf03wt2.lat[i]},`;
				exportData = `${exportData}${exportDataSf03wt2.lng[i]}`;
				arrCSVData.push(exportData);
			}
			let exportTime = getLocalTime();
			// console.log('exportTime: ', exportTime);
			downloadFile({
				data: arrCSVData.join('\n'),
				fileName: `Log_${data.sigfoxId}_${exportTime}`,
				fileType: 'csv',
			});
		}
	};

  const updateDevice = (formData, isLocationUpdate) => {
    // console.log(data);
    showConfirm({
      body: isLocationUpdate
        ? string.LOCATION_UPDATE_CONFIRM
        : string.DEVICE_UPDATE_CONFIRM,
      defaultAction: () => {
        sendRequest({
          url: `${Urls.device.update}/${deviceId}`,
          method: 'PUT',
          body: formData,
        }).then((response) => {
          showToast(
            isLocationUpdate
              ? string.LOCATION_UPDATE_SUCCESS
              : string.DEVICE_UPDATE_SUCCESS,
            'success'
          );
          getDeviceData();
          window.location.href = `${Paths.device.detail}/?id=${deviceId}`;
          // history.push(`${Paths.device.detail}/?id=${deviceId}`);
        });
      },
      cancelAction: () => {
        getDeviceData();

        dispatch(appHideConfirm());
        // window.location.reload();
        window.location.href = `${Paths.device.detail}/?id=${deviceId}`;
        // history.push(`${Paths.device.detail}/?id=${deviceId}`);
      },
    });
  };

  const onLocationUpdate = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let location = JSON.stringify(getFormData(e.target));
    if (
      DD_LOCATION_FORMAT_REGEX.test(
        `${JSON.parse(location)?.Lat},${JSON.parse(location)?.Lng}`
      )
    ) {
      let formData = {
        ...data,
        deviceLocation: location,
        serialNumbber: data.serialNumber,
      };
      delete formData['profileId'];
      updateDevice(formData, true);
    } else {
      showToast(string.LOCATION_IS_NOT_FORMAT);
    }
  };

  const onDragEnd = (index, newLocation) => {
    console.log(newLocation);
    // updateLocation(newLocation);
  };

  const [imageList, setImageList] = useState([]);

  const onDeviceChange = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let form = getFormData(e.target, { assetName: true });
    let formData = {
      ...data,
      assetName: form.assetName,
      deviceLocation: JSON.stringify(data.deviceLocation),
      serialNumbber: data.serialNumber,
    };
    delete formData['profileId'];
    updateDevice(formData, false);
  };
  const [zoom, setZoom] = useState();
  useEffect(() => {
    if (!nNU(localStorage.getItem('Map'))) return;
    let data = JSON.parse(localStorage.getItem('Map'));
    if (!nNU(data)) return;
    setZoom(data);
  }, [advancedData]);

  const locationUpdate = (e) => {
    showConfirm({
      body: string.GET_CURRENT_LOCATION_CONFIRM,
      defaultAction: () => {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            let location = {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            };
            setCurrentLocationDisable(null);
            setCurrentLocation(location);
            setEditLocation(true);
          },
          () => {
            setCurrentLocationDisable(string.CURRENT_LOCATION_DISABLE);
            setCurrentLocation(null);
            setEditLocation(true);
          }
        );
      },
      cancelAction: () => {
        setEditLocation(true);
        setCurrentLocationDisable(null);
        setCurrentLocation(null);
        dispatch(appHideConfirm());
      },
    });
  };

  const resizeImage = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        360,
        'JPEG',
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        'base64'
      );
  });

  const inputRef0 = useRef(null);
  const [image, setImage] = useState();
  const [picture, setPicture] = useState({});
  const handleChange = async (e) => {
    if (e.target.files.length) {
      getDeviceData();
      const formData = new FormData();
      for (let i = 0; i < e.target.files.length; i++) {
        const file = e.target.files[i];
        // console.log(file);
        const imageData = await resizeImage(file);
        const newFile = dataURIToBlob(imageData);
        formData.append('file', newFile);
      }

      showConfirm({
        body: string.UPLOAD_IMAGE_CONFIRM,
        defaultAction: () => {
          sendPost({
            url: `${Urls.image.uploadImage}/${deviceId}`,
            headers: {
              'content-type': 'multipart/form-data',
            },
            body: formData,
          }).then(() => {
            showToast(string.UPLOAD_IMAGE_SUCCESS, 'success');
            setImage(null);
            handleUpdateDeviceImage();
          });
        },
      });
    }
  };
  const handleUpload = (e) => {
    inputRef0.current?.click();
  };

  const handleUpdateDeviceImage = () => {
    sendRequest({
      url: `${Urls.image.getImagesDevice}/${deviceId}`,
      background: true,
    }).then((res) => {
      const { result } = res;
      setImageList(result);
    });
  };
  const handleImageDelete = (e) => {
    showConfirm({
      body: '',
      defaultAction: () => {
        sendRequest({
          method: 'delete',
          url: `${Urls.image.deleteImage}/${imageList[e].imageId}`,
        }).then(() => {
          getDeviceData();
          handleUpdateDeviceImage();
          showToast('Success', 'success');
        });
      },
    });
  };
  // console.log(imageList);

  useEffect(() => {
    imageList.length < 1 && setShowPicture(false);
  }, [imageList]);

  return (
    <>
      <div
        ref={wrapperRef}
        style={{ display: 'flex', gap: '5px', marginLeft: '13px' }}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDrop={onDrop}
      >
        { isGraphData && data.model === string.DEVICE_WBGT ?
          (
            <span
              style={{
                border: '1px solid black',
                padding: '1px 10px',
                height: '27px',
                userSelect: 'none',
                color: '#fff',
                backgroundColor: '#1C63B7',
              }}
            >
              {string.TIME_GRAPH}
            </span>
          ) : ('')
        }
        { isGraphData && data.model === string.DEVICE_WBGT ?
          (
            <FormControls controls={formControls} />
          )
					: data.model === string.DEVICE_SF03WT2 ?
          (
            <FormControls controls={formControls} />
          )
					:('')
        }
        { isGraphData && data.model === string.DEVICE_WBGT ?
          (
            <Button
              onClick={handleDownload}
              style={{ padding: '0rem 0.7rem', height: '100%' }}
            >
              <FaFileDownload />
              <span>{string.CSV_DOWNLOAD}</span>
            </Button>
          )
					: data.model === string.DEVICE_SF03WT2 ?
          (
            <Button
              onClick={handleDownloadSf03wt2}
              style={{ padding: '0rem 0.7rem', height: '100%' }}
            >
              <FaFileDownload />
              <span>{string.CSV_DOWNLOAD}</span>
            </Button>
          )
					:('')
        }
        {data.model === string.DEVICE_XE1151 &&
					(<div>
						<input
							ref={inputRef0}
							type="file"
							id="upload-button"
							accept="image/*"
							multiple={true}
							// onSelect={handleChange}
							onChange={handleChange}
							style={{ display: 'none' }}
						/>
        	</div>)
				}

        {/*data.model === string.DEVICE_XE1151 && (<Button
          onClick={handleUpload}
          style={{ padding: '0rem 0.7rem', height: '100%' }}
        >
          <FaFileUpload />
          {string.UPLOAD_IMAGE}
        </Button>)*/}

        <div style={{ marginTop: '3px', color: 'red' }}>
          {isGraphData && data.model === string.DEVICE_WBGT
            ? isGraphText &&
              getString(string.GRAPH_TEXT, [
                formatDate(searchData.fromDate, 'YYYY-MM-DD'),
                formatDate(
                  new Date(chartData.time[0]).setDate(
                    new Date(chartData.time[0]).getDate()
                  ),
                  'YYYY-MM-DD'
                ),
              ])
            : ('')}
        </div>
      </div>

      <Container id="device-detail-status">
        <Row>
          <Col xl={8}>
            <Card>
              <Card.Header
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>{string.DEVICE_LOCATION}</div>
                {data.model === string.DEVICE_XE1151 && data.imageValid && (
                  <Button
                    style={{ padding: '0px 10px' }}
                    onClick={() => {
                      !showPicture && handleUpdateDeviceImage();
                      setShowPicture(!showPicture);
                    }}
                  >
                    {showPicture ? string.HIDE_PICTURE : string.SHOW_PICTURE}
                  </Button>
                )}
                {data.model === string.DEVICE_XE1151 && (
                  <Button
                    style={{ padding: '0px 10px' }}
                    onClick={handleUpload}
                  >
                    <FaFileUpload />
                    {string.UPLOAD_IMAGE}
                  </Button>
                )}
              </Card.Header>
              <Card.Body className="detail-map-container map-status">
                {showPicture ? (
                    <div className="photo">
                      <Slideshow
                        data={imageList}
                        deleteImage={handleImageDelete}
                      />
                    </div>
                    )
                    : data.model === string.DEVICE_SFGPS ? (nNU(zoom) && (<Map
                      //data={geoList}
                      onDragEnd={onDragEnd}
                      //draggable={true}
                      data={[
                        {
                          ...data,
                          ...advancedData,
                          location: deviceLocation,
                        },
                      ]}
                      geoData={geoList}
                      zoom={zoom}
											maxZoom={22}
                      isCircle={true}
                      isGeoChangedIcon={true}
                      MarkerContent={DeviceMarker2}
                      //isAnimatedIcon = {true}
											isGPSDevice = {true}
											isDetailMap = {true}
                    />))
                    : data.model === string.DEVICE_SF03WT2 ? (nNU(zoom) && (<Map
                      onDragEnd={onDragEnd}
                      isHazardMap={true}
                      data={[
                        {
                          ...data,
                          ...advancedData,
                          location: deviceLocation,
                        },
                      ]}
                      state={state}
                      zoom={zoom}
                      MarkerContent={DeviceMarker2}
                    />))
                    : data.model === string.DEVICE_SF07 ? (nNU(zoom) && (<Map
                      onDragEnd={onDragEnd}
                      isHazardMap={true}
                      data={[
                        {
                          ...data,
                          ...advancedData,
                          location: deviceLocation,
                        },
                      ]}
                      state={state}
                      zoom={zoom}
                      MarkerContent={DeviceMarker2}
                    />))
                    : nNU(data?.model) ? (nNU(zoom) && (<Map
                      onDragEnd={onDragEnd}
                      //draggable={true}
                      data={[
                        {
                          ...data,
                          ...advancedData,
                          location: deviceLocation,
                        },
                      ]}
                      state={state}
                      zoom={zoom}
											// maxZoom={data.model === string.DEVICE_SFGPS ? 22:19}
                      MarkerContent={DeviceMarker2}
                    />))
										:('')}
              </Card.Body>
            </Card>
          </Col>
          <Col xl={4}>
            <Card className="_device-info-card">
              <Card.Header>{string.DEVICE_INFO}</Card.Header>
              <Card.Body>
                <Form onSubmit={onDeviceChange}>
                  <FormControls
                    data={data}
                    controls={[
                      {
                        id: 'sigfoxId',
                        title: string.DEVICE_SIGFOX_ID,
                        readOnly: true,
                        minWidth: '100px',
                      },
                      {
                        id: 'model',
                        title: string.DEVICE_MODEL,
                        readOnly: true,
                        minWidth: '100px',
                        // titleXs: 4,
                      },
                      {
                        id: 'assetName',
                        title: string.DEVICE_NAME,
                        // titleXs: 4,
                        minWidth: '100px',
                        readOnly:
                          currentUser.accountRole === ACCOUNT_ROLE_CHILD,
                      },
                    ]}
                  />
                  {currentUser.accountRole !== ACCOUNT_ROLE_CHILD && (
                    <Button type="submit">{string.SAVE}</Button>
                  )}
                </Form>
              </Card.Body>
            </Card>
            {(nNU(data.model) && (data.model !== string.DEVICE_SFGPS) && (currentUser.accountRole !== ACCOUNT_ROLE_CHILD)) ? (
              <Card>
              <Card.Header
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <div>{string.DEVICE_LOCATION_UPDATE}</div>
                {editLocation ? (<Button
                  style={{ padding: '0px 10px' }}
                  onClick={() => {
                    setCurrentLocationDisable(null);
                    setCurrentLocation(null);
                    setEditLocation(false);
                  }}
                >
                  x
                </Button>) : (<></>)}
              </Card.Header>
              <Card.Body>
                {editLocation ? (
                  <>
                    {
                      <Form onSubmit={onLocationUpdate}>
                        <FormControls
                          data={
                            !!currentLocation
                              ? {
                                  Lat: currentLocation.lat,
                                  Lng: currentLocation.lng,
                                }
                              : {}
                          }
                          controls={[
                            {
                              id: 'Lat',
                              title: string.LOCATION_LATITUDE,
                              minWidth: '50px',
                              placeHolder: currentLocationDisable || '',
                              fontSize: !!currentLocationDisable
                                ? '0.8rem'
                                : '1rem',
                            },
                            {
                              id: 'Lng',
                              title: string.LOCATION_LONGITUDE,
                              minWidth: '50px',
                              placeHolder: currentLocationDisable || '',
                              fontSize: !!currentLocationDisable
                                ? '0.8rem'
                                : '1rem',
                            },
                          ]}
                        />

                        <Button type="submit">{string.SAVE}</Button>
                      </Form>
                    }
                  </>
                ) : (
                  <>
                    <Button onClick={locationUpdate}>
                      {string.DEVICE_LOCATION_UPDATE}
                    </Button>
                  </>
                )}
              </Card.Body>
            </Card>
            ) : (
              <></>
            )}
          </Col>
        </Row>
        {isGraphData && data.model === string.DEVICE_WBGT && (
          <>
            <Row>
              <Col xl={6}>
                <Card>
                  {/* <Card.Header>{string.HUMIDITY}</Card.Header> */}
                  <Card.Body>
                    <Chart
                      title={string.HUMIDITY}
                      color="#686de0"
                      data={chartData.dataHum}
                      dataCenter={chartData.dataHumCenter}
                      xAxis={chartData.time}
                      temp={false}
                      // min={Math.floor(arrayMin(chartData.dataHum)) - 2}
                      // max={Math.round(arrayMax(chartData.dataHum)) + 2}
                      // tickAmount={
                      //   Math.round(arrayMax(chartData.dataHum)) +
                      //   2 -
                      //   (Math.floor(arrayMin(chartData.dataHum)) - 2)
                      // }
                      min={0}
                      max={100}
                      tickAmount={100}
                      dateStartSearch={formatDate(
                        searchData.fromDate,
                        'YYYY-MM-DD HH:mm'
                      )}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={6}>
                <Card>
                  {/* <Card.Header>{string.TEMPERATURE}</Card.Header> */}
                  <Card.Body>
                    <Chart
                      title={string.TEMPERATURE}
                      color="#6ab04c"
                      data={chartData.dataTemp}
                      dataCenter={chartData.dataTempCenter}
                      xAxis={chartData.time}
                      // min={Math.floor(arrayMin(chartData.dataTemp)) - 2}
                      // max={Math.round(arrayMax(chartData.dataTemp)) + 2}
                      // tickAmount={
                      //   Math.round(arrayMax(chartData.dataTemp)) +
                      //   2 -
                      //   (Math.floor(arrayMin(chartData.dataTemp)) - 2)
                      // }
                      min={0}
                      max={50}
                      tickAmount={50}
                      dateStartSearch={formatDate(
                        searchData.fromDate,
                        'YYYY-MM-DD HH:mm'
                      )}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col xl={6}>
                <Card>
                  {/* <Card.Header>{string.LIGHT_TEMPERATURE}</Card.Header> */}
                  <Card.Body>
                    <Chart
                      title={string.LIGHT_TEMPERATURE}
                      color="#f0932b"
                      data={chartData.dataLightTemp}
                      dataCenter={chartData.dataLightTempCenter}
                      xAxis={chartData.time}
                      // min={Math.floor(arrayMin(chartData.dataLightTemp)) - 2}
                      // max={Math.round(arrayMax(chartData.dataLightTemp)) + 2}
                      // tickAmount={
                      //   Math.round(arrayMax(chartData.dataLightTemp)) +
                      //   2 -
                      //   (Math.floor(arrayMin(chartData.dataLightTemp)) - 2)
                      // }
                      min={0}
                      max={50}
                      tickAmount={50}
                      dateStartSearch={formatDate(
                        searchData.fromDate,
                        'YYYY-MM-DD HH:mm'
                      )}
                      showPopOver={true}
                    />
                  </Card.Body>
                </Card>
              </Col>
              <Col xl={6}>
                <Card>
                  {/* <Card.Header>{string.WBGT_TEMPERATURE}</Card.Header> */}
                  <Card.Body>
                    <Chart
                      title={string.WBGT_TEMPERATURE}
                      color="#eb4d4b"
                      data={chartData.dataWBGT}
                      dataCenter={chartData.dataWBGTCenter}
                      xAxis={chartData.time}
                      // min={Math.floor(arrayMin(chartData.dataWBGT)) - 2}
                      // max={Math.round(arrayMax(chartData.dataWBGT)) + 2}
                      // tickAmount={
                      //   Math.round(arrayMax(chartData.dataWBGT)) +
                      //   2 -
                      //   (Math.floor(arrayMin(chartData.dataWBGT)) - 2)
                      // }
                      min={0}
                      max={50}
                      tickAmount={50}
                      dateStartSearch={formatDate(
                        searchData.fromDate,
                        'YYYY-MM-DD HH:mm'
                      )}
                      showPopOver={true}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>

			{redirect && (<Redirect
				to={Paths.device.status}
			>
			</Redirect>)}
    </>
  );
};

export default DeviceDetailStatus;
