import GeoMarker from 'component/GeoMarker';
import GeoSelect from 'component/GeoSelect';
import GeoUpdate from 'component/GeoUpdate';
import DeviceMarker2 from 'component/DeviceMarker2';
import Map from 'component/Map2';
import Modal from 'component/Modal';
import 'flatpickr/dist/themes/material_blue.css';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import sendRequest, { sendPost } from 'util/request';
import string from 'util/string';

import Urls from 'util/urls';
import { FaAlignJustify, FaFileDownload, FaFileUpload, FaTrash, FaEdit } from 'react-icons/fa';
import { mailTemplate, gpsMailTemplate } from 'util/mailTemplate';
import {
  emailValidate,
  focusElement,
  showToast,
  nNU,
  getString,
  DD_LOCATION_FORMAT_REGEX,
  showConfirm,
} from 'util/utils';
// import { set } from 'lodash';
// import { Prev } from 'react-bootstrap/esm/PageItem';

const mailTitle = ['進入通知', '退出通知', '長期滞在通知', '位置通知'];
const mailTitleIndex = ['0', '1', '2', '3'];
const mailTemplateHeaderSample = [
  '',
  'DK-SFGPS（Sigfox ID）',
  '3.3V',
  '',
	'11月7日 12時30分',
];

const GPSMailSetting = ({ deviceId }) => {
  const [geoList, setGeoList] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);
	const [updatedItem, setUpdatedItem] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [total, setTotal] = useState(0);
  const [valueInputMail, setValueInputMail] = useState();
  const [mailList, setMailList] = useState([]);
  const [addGeoData, setAddGeoData] = useState(null);
  const inputRef0 = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const [dataMailTemplate, setDataMailTemplate] = useState([]);
  const [showTemplate, setShowTemplate] = useState(0);
  const [loadForm, setLoadForm] = useState(false);
  const [deviceLocation, setDeviceLocation] = useState(null);
  const [deviceData, setDeviceData] = useState({});
	const [deviceAdvancedData, setDeviceAdvancedData] = useState({});
	const [gpsTrigger, setGpsTrigger] = useState(false);

  const modalBodyShow = (index) => {
    let mailShow = mailTemplate;
    mailShow.title =
      dataMailTemplate[index]?.title || gpsMailTemplate[index].title;
    mailShow.content =
      dataMailTemplate[index]?.content || gpsMailTemplate[index].content;
    mailShow.header = gpsMailTemplate[index].header;
		mailShow.header = mailShow.header.replace("xx月xx日　xx時xx分", mailTemplateHeaderSample[4]);
    return (
      <>
        <h3>{mailShow.title}</h3>
        <p>
          {mailShow.header.split(/(?:\r\n|\r|\n)/g).map((key, e) => (
            <span key={e + 1}>
              {`${mailShow.header.split(/(?:\r\n|\r|\n)/g)[e]} ${
                mailTemplateHeaderSample[e]
              }`}
              <br />
            </span>
          ))}
        </p>
        <p>
          {mailShow.content.split(/(?:\r\n|\r|\n)/g).map((key, e) => (
            <span key={e + 20}>
              {mailShow.content.split(/(?:\r\n|\r|\n)/g)[e]}{' '}
              {mailShow.content.split(/(?:\r\n|\r|\n)/g)[e].length > 0 ? (
                <br></br>
              ) : (
                ''
              )}
            </span>
          ))}
        </p>
        <p>
          {mailShow.footer.split(/(?:\r\n|\r|\n)/g).map((key, e) => (
            <span key={e + 100}>
              {mailShow.footer.split(/(?:\r\n|\r|\n)/g)[e]} <br />
            </span>
          ))}
        </p>
      </>
    );
  };

  const [selectedLocation, setSelectedLocation] = useState(-1);

  const getDeviceData = () => {
    setTimeout(() => {
      setLoadForm(false);
    }, 0);
    sendRequest({
      url: `${Urls.device.info}/${deviceId}`,
      background: true,
    }).then((response) => {
      const { result } = response;
      let deviceLocation = result?.deviceLocation;
      try {
        if (!!deviceLocation) {
          deviceLocation = JSON.parse(deviceLocation);
        }
      } catch (e) {}
      setDeviceData({ ...result, deviceLocation });
      setTimeout(() => {
        setLoadForm(true);
      }, 0);
    });
  };

  useEffect(() => {
    if (window?.['gettingDevicesLog'] === true) {
      return;
    }
    window['gettingDevicesLog'] = true;
    sendRequest({
      url: `${Urls.device.latestDeviceData}/${deviceId}`,

      background: true,
    })
      .then((response) => {
        const { result } = response;

        setDeviceAdvancedData(result);
        getDeviceData();
      })
      .finally(() => {
        window['gettingDevicesLog'] = false;
      });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [deviceId]);

	useEffect(() => {
    let newLocation = null;
    let computedLocation = deviceAdvancedData?.computedLocation;

    try {
      if (!!computedLocation) {
        computedLocation = JSON.parse(computedLocation);
        // if (nNU(computedLocation?.Lat) && nNU(computedLocation?.Lng)) {
        if (nNU(computedLocation)) {
          const lat = computedLocation?.Lat || computedLocation?.lat;
          const lng = computedLocation?.Lng || computedLocation?.lng;
          newLocation = DD_LOCATION_FORMAT_REGEX.test(`${lat},${lng}`)
            ? [lat, lng]
            : null;
        }
      }

      let dataDeviceLocation = JSON.parse(deviceData?.deviceLocation);
      if (!newLocation) {
        if (nNU(dataDeviceLocation)) {
          const lat = dataDeviceLocation?.Lat || dataDeviceLocation?.lat;
          const lng = dataDeviceLocation?.Lng || dataDeviceLocation?.lng;
          newLocation = DD_LOCATION_FORMAT_REGEX.test(`${lat},${lng}`)
            ? [lat, lng]
            : null;
        }
      }
    } catch (e) {}

    setDeviceLocation(newLocation);
  }, [deviceData, deviceAdvancedData]);


  useEffect(() => {
    if (!deviceId) {
      return;
    }
    sendRequest({
      url: `${Urls.gpsNotifyConfiguration.getGPSMailSetting}/${deviceId}`,
    }).then((res) => {
      const { result } = res;

      let newEmailAddresses = [...mailList];
      if (nNU(result.emailAddresses)) newEmailAddresses = result.emailAddresses;

      setMailList(newEmailAddresses);
      setDataMailTemplate(result.emailTemplate);
			if(nNU(result.gpsTrigger)){
				setGpsTrigger(result.gpsTrigger);
			}
			else{
				setGpsTrigger(false);
			}
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [deviceId]);

  const onModalHide = () => {
    setSelectedItem(null);

    setAddGeoData(null);
  };
  const onModalUpdated = () => {
    onModalHide();
    getAllGPSSetting(true);
	};
  const modalBody = (
    <GeoSelect
      deviceId={deviceId}
      onUpdated={onModalUpdated}
      data={selectedItem}
    />
  );

  const onUpdateModalHide = () => {
    setUpdatedItem(null);
  };
  const onUpdateFinished = () => {
    onUpdateModalHide();
    getAllGPSSetting();
  };
  const modalUpdateBody = (
    (nNU(updatedItem) && <GeoUpdate
      deviceId={deviceId}
			settingId={updatedItem?.settingId}
      onUpdated={onUpdateFinished}
      data={updatedItem}
    />)
  );

  const handleRemoveMailList = (mailItem) => {
    let newMailList = [...mailList];
    newMailList.splice(mailItem, 1);
    setMailList(newMailList);
  };
  const handleSetMail = () => {
    focusElement('.input');
    setValueInputMail('');
    let isMatchingMail = false;
    if (mailList.length > 50) {
      showToast(string.MAIL_LENGTH_ERROR, 'warning');
      return;
    }
    mailList.forEach((mail) => {
      if (valueInputMail === mail) {
        isMatchingMail = true;
        return;
      }
    });
    if (isMatchingMail) return;
    if (!valueInputMail) return;
    if (!emailValidate(valueInputMail)) return;
    setMailList([...mailList, valueInputMail]);
  };
  const handleSelectedData = (e) => {
    setSelectedItem(e);
    setAddGeoData(e);
  };

  const getAllGPSSetting = useCallback((selected = false) => {
    sendRequest({
      method: 'get',
      url: `${Urls.gpsNotifyConfiguration.getAllGPSSetting}/${deviceId}`,
      background: true,
    }).then((res) => {
      const { result } = res;

      setTotal(result?.length || 0);
      let geoValue = [];
      result.forEach((item, index) => {
        geoValue[index] = { ...item };
        geoValue[index].location = [item?.latitude, item?.longitude];
      });
      setGeoList(geoValue);
      // if (!!result?.length) {
      //   //setSelectedLocation(result?.length - 1);
			// 	setSelectedLocation(0);
      // }
			// else {
      //   setSelectedLocation(-1);
      // }
			if (selected === true){
				if (!!result?.length) {
					setSelectedLocation(result?.length - 1);
					// setSelectedLocation(0);
				}
				else {
					setSelectedLocation(-1);
				}
			}
			else{
				// setSelectedLocation(-1);
			}
    });
  });
  // console.log(geoList);
	// console.log(selectedLocation);

  useEffect(() => {
    getAllGPSSetting();
  }, [deviceId]);

  const handleDeleteGpsSetting = (e) => {
    showConfirm({
      body: getString(string.GEO_DELETE,[e.regionName]),
      defaultAction: () => {
        sendRequest({
          url: `${Urls.gpsNotifyConfiguration.deleteGPSSetting}/${e.settingId}`,
          method: 'delete',
        }).then((res) => {
          showToast(getString(string.GEO_DELETED,[e.regionName]), 'success');
          getAllGPSSetting();
        });
      },
    });
  };

	const handleUpdateGpsSetting = (e) => {
		//console.log(e);
    setUpdatedItem(e);

	};

  const handleUpload = (index) => {
    //console.log(index);
    switch (index) {
      case 0:
        inputRef0.current?.click();
        break;
      case 1:
        inputRef1.current?.click();
        break;
      case 2:
        inputRef2.current?.click();
        break;
      case 3:
        inputRef3.current?.click();
        break;
      default:
        inputRef0.current?.click();
        break;
    }
  };

  const downloadTxtFile = (index) => {
    const element = document.createElement('a');
    const dataTemplate = dataMailTemplate[index];
    const file = new Blob(
      ['件名：\n', dataTemplate?.title, '\n本文：', dataTemplate?.content],
      {
        type: 'text/plain;charset=utf-8',
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = `MailTemplate${index}.txt`;
    document.body.appendChild(element);
    element.click();
  };
  const handleDisplayFileDetails = (index) => {
    //console.log(index);
    const reader = new FileReader();
    reader.onload = (e) => {
      const title = reader.result.split(/(?:\r\n|\r|\n)/g)[1] + '\n';
      let content = '\n';
      for (let i = 4; i < reader.result.split(/(?:\r\n|\r|\n)/g).length; i++) {
        content = content + reader.result.split(/(?:\r\n|\r|\n)/g)[i];
        content = content + '\n';
      }
      let newMailTemplate = [...dataMailTemplate];
      newMailTemplate[index].title = title;
      newMailTemplate[index].content = content;
      setDataMailTemplate(newMailTemplate);
    };
    switch (index) {
      case 0:
        reader?.readAsText(inputRef0.current.files[0]);
        break;
      case 1:
        reader?.readAsText(inputRef1.current.files[0]);
        break;
      case 2:
        reader?.readAsText(inputRef2.current.files[0]);
        break;
      case 3:
        reader?.readAsText(inputRef3.current.files[0]);
        break;

      default:
        reader?.readAsText(inputRef0.current.files[0]);
        break;
    }
  };

  const handleShowMailTemplate = (inx) => {
    setShowTemplate(inx);
    setModalShow(true);
  };

  const handleSetFromData = () => {
		let newMailTemplate = [...dataMailTemplate];
		for(let i=0; i<dataMailTemplate.length; i++){
			newMailTemplate[i].title = dataMailTemplate[i]?.title || gpsMailTemplate[i].title;
			newMailTemplate[i].content = dataMailTemplate[i]?.content || gpsMailTemplate[i].content;
		}
    const gpsSettingData = {
      deviceId,
			gpsTrigger: gpsTrigger,
      emailAddresses: mailList,
      emailTemplate: newMailTemplate,
    };

    sendPost({
      url: Urls.gpsNotifyConfiguration.storeGPSMailSetting,
      body: gpsSettingData,
    }).then((res) => {});
  };

  const onDragEnd = (index, newLocation) => {
    console.log(newLocation);
    // updateLocation(newLocation);
  };

  const [zoom, setZoom] = useState();
  useEffect(() => {
    if (!nNU(localStorage.getItem('Map'))) return;
    let data = JSON.parse(localStorage.getItem('Map'));
    if (!nNU(data)) return;
    setZoom(data);
  }, []);

  return (
    <>
      <Container className="gps-setting" fluid>
        <Card>
          <Card.Body
						//className="detail-map-container map-status"
					>
            <Row className="map-box">
              <Col>
                {nNU(zoom) && (
                  <Map
										onDragEnd={onDragEnd}
										data={[
											{
												...deviceData,
												...deviceAdvancedData,
												location: deviceLocation,
											},
										]}
                    geoData={geoList}
                    zoom={zoom}
										maxZoom={22}
                    geoSelectedMarker={selectedLocation}
                    //selectedMarker={deviceLocation}
                    GeoMarkerContent={GeoMarker}
										MarkerContent={DeviceMarker2}
                    isCircle={true}
                    isClick={true}
                    onSelectedData={handleSelectedData}
                    addGeoData={addGeoData}
                    isGeoChangedIcon={true}
										isGPSDevice={true}
										isDetailMap={true}
                  />
                )}
              </Col>
              <Col xs={2}>
                <Row style={{ marginBottom: '7px',textAlign: 'center' }}>
                  <div>{string.GEO_LIST_TITLE}</div>
                </Row>
                <Row style={{ marginBottom: '7px' }}>
                  <Col>
                    <span>{getString(string.ROWS, total)}</span>
                  </Col>
                  <Col className="geo-nav-btns">
                    <Button
                      className="geo-btn"
                      onClick={() => {
                        let idx = (selectedLocation - 1 + total) % total;

                        if (!isNaN(idx)) {
                          setSelectedLocation(idx);

                          document
                            .getElementsByClassName('geo-item')
                            [idx].scrollIntoView();
                        }
                      }}
                    >
                      ↑
                    </Button>
                    <Button
                      className="geo-btn"
                      onClick={() => {
                        let idx = (selectedLocation + 1) % total;

                        if (!isNaN(idx)) {
                          setSelectedLocation(idx);
                          document
                            .getElementsByClassName('geo-item')
                            [idx].scrollIntoView();
                        }
                      }}
                    >
                      ↓
                    </Button>
                  </Col>
                </Row>
                <Row id="geoList">
                  {geoList.map((e, index) => (
                    <Card
                      className={`geo-item ${
												e.enable === false ?
													index === selectedLocation ?
														'geo-item-selected2':'geo-item-disabled'
													:index === selectedLocation ?
														'geo-item-selected':''
                      }`}
                      key={index}
                      onClick={() => setSelectedLocation(index)}
                    >
                      <Card.Body>
												<Col
													sm={8}
													xs={4}
												>
	                        <div>{e.regionName}</div>
												</Col>
												<Col
													sm={1}
													//xs={1}
												>
													{<Button
														style={{
															padding: '0px 4px',
														}}
														//handleSelectedData
														//onClick={() => handleSelectedData(e)}
														onClick={() => handleUpdateGpsSetting(e)}
														variant="outline-primary"
														size="sm"
													>
														<FaEdit />
													</Button>}
												</Col>
												<Col
													sm={1}
													//xs={1}
												>
													{<Button
														style={{
															padding: '0px 4px',
														}}
														onClick={() => handleDeleteGpsSetting(e)}
														variant="outline-danger"
														size="sm"
													>
														<FaTrash />
													</Button>}
												</Col>
                      </Card.Body>
                    </Card>
                  ))}
                </Row>
              </Col>
            </Row>
            <Row xs={1} sm={4}>
              <Col sm={2} id="label-title">
                <span style={{ fontSize: 24 }}>{`①　`}</span>
                <span>{`${string.ACCOUNT_MAIL}`}</span>
              </Col>
              <Col sm={6}>
                <div className="input-group">
                  <input
                    placeholder="メールアドレスを入力してください"
                    type="text"
                    className="input form-control"
                    value={valueInputMail}
                    onChange={(e) => setValueInputMail(e.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleSetMail();
                      }
                    }}
                  />
                  <Button size="sm" onClick={handleSetMail}>
                    {string.SAVE}
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="mail-list">
              <Row xs={1} sm={4}>
                {mailList.map((mail, index) => (
                  <Col key={index}>
                    <span>{mail}</span>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => handleRemoveMailList(index)}
                    >
                      x
                    </Button>
                  </Col>
                ))}
              </Row>
            </div>
            <Row xs={1} sm={4} style={{ marginTop: '10px' }}>
              <Col sm={12} id="label-title">
                <span style={{ fontSize: 24 }}>{`②　`}</span>
                <span>{`${string.MAIL_TEMPLATE}`}</span>
              </Col>
            </Row>
            {/* <Row className="border-responsive" style={{ marginTop: '5px' }}>
              <Col xs={2} style={{ paddingLeft: '5%' }}>
                {mailTitle[0]}
              </Col>
              <Col xs={10} className="preview-button" key={0}>
                <div className="button-up-download">
                  <div className="sm">
                    <input
                      ref={inputRef0}
                      onChange={() => handleDisplayFileDetails(0)}
                      className="d-none"
                      type="file"
                    />
                    <button
                      onClick={() => handleUpload(0)}
                      className={`btn btn-primary`}
                    >
                      <FaFileUpload />
                    </button>
                  </div>
                  <Button size="sm" onClick={() => downloadTxtFile(0)}>
                    <FaFileDownload />
                  </Button>
                  <Button size="sm" onClick={() => handleShowMailTemplate(0)}>
                    <FaAlignJustify />
                  </Button>
                </div>
              </Col>
            </Row> */}
            {/* <Row className="border-responsive" style={{ marginTop: '5px' }}>
              <Col xs={2} style={{ paddingLeft: '5%' }}>
                {mailTitle[1]}
              </Col>
              <Col xs={10} className="preview-button" key={1}>
                <div className="button-up-download">
                  <div className="sm">
                    <input
                      ref={inputRef1}
                      onChange={() => handleDisplayFileDetails(1)}
                      className="d-none"
                      type="file"
                    />
                    <button
                      onClick={() => handleUpload(1)}
                      className={`btn btn-primary`}
                    >
                      <FaFileUpload />
                    </button>
                  </div>
                  <Button size="sm" onClick={() => downloadTxtFile(1)}>
                    <FaFileDownload />
                  </Button>
                  <Button size="sm" onClick={() => handleShowMailTemplate(1)}>
                    <FaAlignJustify />
                  </Button>
                </div>
              </Col>
            </Row> */}
            {/* <Row className="border-responsive" style={{ marginTop: '5px' }}>
              <Col xs={2} style={{ paddingLeft: '5%' }}>
                {mailTitle[2]}
              </Col>
              <Col xs={10} className="preview-button" key={2}>
                <div className="button-up-download">
                  <div className="sm">
                    <input
                      ref={inputRef2}
                      onChange={() => handleDisplayFileDetails(2)}
                      className="d-none"
                      type="file"
                    />
                    <button
                      onClick={() => handleUpload(2)}
                      className={`btn btn-primary`}
                    >
                      <FaFileUpload />
                    </button>
                  </div>
                  <Button size="sm" onClick={() => downloadTxtFile(2)}>
                    <FaFileDownload />
                  </Button>
                  <Button size="sm" onClick={() => handleShowMailTemplate(2)}>
                    <FaAlignJustify />
                  </Button>
                </div>
              </Col>
            </Row> */}
            {/* <Row className="border-responsive" style={{ marginTop: '5px' }}>
              <Col xs={2} style={{ paddingLeft: '5%' }}>
                {mailTitle[3]}
              </Col>
              <Col xs={10} className="preview-button" key={2}>
                <div className="button-up-download">
                  <div className="sm">
                    <input
                      ref={inputRef3}
                      onChange={() => handleDisplayFileDetails(2)}
                      className="d-none"
                      type="file"
                    />
                    <button
                      onClick={() => handleUpload(2)}
                      className={`btn btn-primary`}
                    >
                      <FaFileUpload />
                    </button>
                  </div>
                  <Button size="sm" onClick={() => downloadTxtFile(2)}>
                    <FaFileDownload />
                  </Button>
                  <Button size="sm" onClick={() => handleShowMailTemplate(2)}>
                    <FaAlignJustify />
                  </Button>
                </div>
              </Col>
							<Col xs={2} className="preview-button">
								{<Form.Check
									type='checkbox'
								></Form.Check>}
							</Col>
            </Row> */}
						{mailTitleIndex.map((index) => (
							<React.Fragment key = {parseInt(index, 10)}>
								<Row
									className="border-responsive"
									style={{ marginTop: '5px' }}
								>
									<Col
										xs={4}
										style={{ paddingLeft: '5%' }}
									>
										{mailTitle[index]}
									</Col>
									<Col
										className="enable-check"
										xs={2}
									>
										{(parseInt(index,10) > 2) && (<Form.Check
											type="switch"
											checked={gpsTrigger}
											onChange={() => setGpsTrigger(!gpsTrigger)}
											// label={gpsTrigger ? "有効":"無効"}
											label="有"
										></Form.Check>)}
									</Col>
									<Col
										className="enable-check"
										xs={4}
									>
										<Row>
											<div
												className = "button-up-download"
												key = {index}
											>
												<div
													className="sm"
												>
													<input
														ref={
															index === '3'
																? inputRef3
																: index === '2'
																? inputRef2
																: index === '1'
																? inputRef1
																: inputRef0
														}
														onChange={() => handleDisplayFileDetails(+index)}
														className="d-none"
														type="file"
													/>
												</div>
												<button
													size="sm"
													style={{
														margin:'2px',
													}}
													onClick={() => handleUpload(+index)}
													className={`btn btn-primary`}
												>
													<FaFileUpload />
												</button>
												<button
													size="sm"
													style={{
														margin:'2px',
													}}
													onClick={() => downloadTxtFile(+index)}
													className={`btn btn-primary`}
												>
													<FaFileDownload />
												</button>
												<button
													size="sm"
													style={{
														margin:'2px',
													}}
													onClick={() => handleShowMailTemplate(+index)}
													className={`btn btn-primary`}
												>
													<FaAlignJustify />
												</button>

											</div>
										</Row>
									</Col>
								</Row>
							</React.Fragment>
						))}

            <div className="button-set">
              {/* <Button onClick={() => setIsReload(!isReload)}>Reload</Button> */}
              <Button onClick={handleSetFromData}>{'保存'}</Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
      <Modal
				show={!!selectedItem}
				//onHide={true}
				onHide={onModalHide}
				body={modalBody}
			/>
      <Modal
				show={!!updatedItem}
				//onHide={true}
				onHide={onUpdateModalHide}
				body={modalUpdateBody}
			/>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        body={modalBodyShow(showTemplate)}
      />
    </>
  );
};

export default GPSMailSetting;
