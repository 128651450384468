import { setMapValue } from 'util/utils';

const AppAcitonTypes = {
  SHOW_TOAST: '',
  HIDE_TOAST: '',
  SHOW_CONFIRM: '',
  HIDE_CONFIRM: '',
  SET_SEARCH_RANGE_DATE: '',
};

setMapValue(AppAcitonTypes);

export default AppAcitonTypes;
