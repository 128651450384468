import logoImg from 'asset/img/logo.png';
import { Navbar, NavDropdown } from 'react-bootstrap';
import { FaUserAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectCurrentUser } from 'redux/user/user.selector';
import Paths from 'util/paths';
import string from 'util/string';
import { signOut } from 'util/utils';
import './Header.scss';
import Menu from './Menu';
import { ACCOUNT_ROLE_CHILD} from 'util/permission';

const Header = () => {
  const history = useHistory();
  const currentUser = useSelector(selectCurrentUser);
  const logOutLinkClick = () => {
    signOut();
  };

  const userInfoClick = () => {
    history.push(`${Paths.user.info}`);
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" className="header-navs">
      <div>
        <Navbar.Toggle aria-controls="web-nav" />
        <Navbar.Brand href="/">
          <img alt="Alinco" src={logoImg} />
          ALINCO Sensor FOX
        </Navbar.Brand>
      </div>
      <div className="grow"></div>
      <div id="header-user">
        <NavDropdown title={<FaUserAlt />} id="web-nav-user">
          <NavDropdown.ItemText className="current-user">
            {currentUser.accountName}
          </NavDropdown.ItemText>
					{(currentUser.accountRole !== ACCOUNT_ROLE_CHILD) && (
						<NavDropdown.Item onClick={userInfoClick}>
							{string.MY_ACCOUNT}
						</NavDropdown.Item>
					)}
          <NavDropdown.Item onClick={logOutLinkClick}>
            {string.LOG_OUT}
          </NavDropdown.Item>
        </NavDropdown>
      </div>
      <Navbar.Collapse id="web-nav">
        <Menu />
      </Navbar.Collapse>
    </Navbar>
  );
};
export default Header;
