/* eslint-disable react-hooks/exhaustive-deps */
import Page from 'component/Page';
import { useCallback, useEffect, useState } from 'react';
import { Button, Tab, Tabs } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectCurrentUser } from 'redux/user/user.selector';
import Paths from 'util/paths';
import sendRequest, { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import { getParams, GUIDChecker, nNU, showToast } from 'util/utils';
import './DeviceDetail.scss';
import DeviceDetailJourney from './DeviceDetailJourney';
import DeviceDetailPayload from './DeviceDetailPayload';
import DeviceDetailStatus from './DeviceDetailStatus';
import DeviceDetailThreshold from './DeviceDetailThreshold';
import GPSMailSetting from './GPSMailSetting2';
import DeviceDkSf03Wt2MailSetting from './DeviceDkSf03Wt2MailSetting';
import DeviceDkSf07MailSetting from './DeviceDkSf07MailSetting';
import {
  ACCOUNT_ROLE_ADMIN,
  ACCOUNT_ROLE_CHILD,
  ACCOUNT_ROLE_PARENT,
} from 'util/permission';

const DEVICE_DETAIL_TAB = {
  STATUS: 'status',
  JOURNEY: 'journey',
  PAYLOAD: 'payload',
  THRESHOLD: 'threshold',
  GPSSETTING: 'gpsSetting',
};

const DeviceDetailTabs = {
  status: {
    title: string.DEVICE_DETAIL_STATUS,
    component: DeviceDetailStatus,
  },

  // payload: {
  //   title: string.DEVICE_DETAIL_PAYLOAD,
  //   component: DeviceDetailPayload,
  // },
};

const DeviceDetail = ({ data = { deviceId: null} }) => {
  const params = getParams();
  const [currentTab, setCurrentTab] = useState(
     params?.tab || DEVICE_DETAIL_TAB.STATUS
    // params?.tab || DEVICE_DETAIL_TAB.JOURNEY
  );
  const deviceId = data.deviceId || params?.deviceId || params?.id;
	// const deviceModel = data.deviceModel || string.DEVICE_WBGT;
  const title = string.DEVICE_DETAIL;
  const [dataD, setDataD] = useState({});
  const currentUser = useSelector(selectCurrentUser);
  const [deviceLists, setDeviceLists] = useState([]);
  const [deviceSelected, setDeviceSelected] = useState(deviceId);
	const [retry, setRetry] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (!deviceSelected) {
      return;
    }
    sendRequest({
      url: `${Urls.device.info}/${deviceSelected}`,
    }).then((response) => {
      const { result } = response;
      setDataD(result);
    });
  }, [deviceSelected]);


  if (dataD.model === string.DEVICE_WBGT &&
    currentUser.accountRole !== ACCOUNT_ROLE_CHILD
  ) {
    DeviceDetailTabs.threshold = {
      title: string.DEVICE_DETAIL_THRESH,
      component: DeviceDetailThreshold,
    };
  } else {
    if (DeviceDetailTabs.threshold) {
      delete DeviceDetailTabs.threshold;
    }
  }

  if (dataD.model === string.DEVICE_SFGPS &&
    currentUser.accountRole !== ACCOUNT_ROLE_CHILD
  ) {
    DeviceDetailTabs.gpsSetting = {
      title: string.GPS_MAIL_SETTING,
      component: GPSMailSetting,
    };
    DeviceDetailTabs.journey = {
      title: string.DEVICE_DETAIL_JOURNEY,
      component: DeviceDetailJourney,
    };
  } else {
    if (DeviceDetailTabs.gpsSetting) {
      delete DeviceDetailTabs.gpsSetting;
    }
    if (DeviceDetailTabs.journey) {
      delete DeviceDetailTabs.journey;
    }
  }

  if (dataD.model === string.DEVICE_SF03WT2 &&
    currentUser.accountRole !== ACCOUNT_ROLE_CHILD
  ) {
    DeviceDetailTabs.sf03wt2MailSetting = {
      title: string.DKSF03WT2_MAIL_SETTING,
      component: DeviceDkSf03Wt2MailSetting,
    };
  } else {
    if (DeviceDetailTabs.sf03wt2MailSetting) {
      delete DeviceDetailTabs.sf03wt2MailSetting;
    }
  }

  if (dataD.model === string.DEVICE_SF07 &&
    currentUser.accountRole !== ACCOUNT_ROLE_CHILD
  ) {
    DeviceDetailTabs.sf07MailSetting = {
      title: string.DKSF07_MAIL_SETTING,
      component: DeviceDkSf07MailSetting,
    };
  } else {
    if (DeviceDetailTabs.sf07MailSetting) {
      delete DeviceDetailTabs.sf07MailSetting;
    }
  }

  if (currentUser.accountRole === ACCOUNT_ROLE_ADMIN) {
    DeviceDetailTabs.payload = {
      title: string.DEVICE_DETAIL_PAYLOAD,
      component: DeviceDetailPayload,
    };
    // DeviceDetailTabs.journey = {
    //   title: string.DEVICE_DETAIL_JOURNEY,
    //   component: DeviceDetailJourney,
    // };
  } else {
    if (DeviceDetailTabs.payload) {
      delete DeviceDetailTabs.payload;
    }
    // if (DeviceDetailTabs.journey) {
    //   delete DeviceDetailTabs.journey;
    // }
  }

  // if (dataD.model === string.DEVICE_SFGPS) {
  //   DeviceDetailTabs.journey = {
  //     title: string.DEVICE_DETAIL_JOURNEY,
  //     component: DeviceDetailJourney,
  //   };
  // } else {
  //   if (DeviceDetailTabs.journey) {
  //     delete DeviceDetailTabs.journey;
  //   }
  // }


	// const [searchId, setSearchId] = useState('');
  const doSearch = useCallback(
    (page, pageSize) => {
			// console.log('doSearch');
			if(!nNU(dataD?.model)){
				return;
			}
			// console.log('Model: ', dataD.model);
      if (currentUser.accountRole !== ACCOUNT_ROLE_CHILD) {
        sendPost({
          url:
            currentUser.accountRole === ACCOUNT_ROLE_ADMIN
							? `${Urls.device.listAdvancedLog}`
							: `${Urls.device.listOfDevicesContract}/${currentUser.accountId}`,
							// ? `${Urls.device.list}`
              // : `${Urls.device.listOfAccountContract}/${currentUser.accountId}`,
          body: {
            offset: (page - 1) * pageSize,
            size: pageSize,
						keyword: '',
						// deviceModel: string.DEVICE_SF03WT2,
						deviceModel: dataD.model,
						// deviceModel: deviceModel,
          },
        }).then((response) => {
          const { result } = response;
          let deviceIDs = [];
          result.forEach((id) => deviceIDs.push(id?.deviceId));
          setDeviceLists(deviceIDs);
          let isCurrentTabExist = false;
          Object.keys(DeviceDetailTabs).forEach((t, index) => {
            let tab = DeviceDetailTabs[t];
            if (tab === currentTab) isCurrentTabExist = true;
          });
          // if (isCurrentTabExist === false) setCurrentTab('status');
          if (isCurrentTabExist === false) {
						if (dataD.model === string.DEVICE_SFGPS){
							setCurrentTab('journey');
						}
						else{
							setCurrentTab('status');
						}
					}
					setRetry(false);
        }).catch((e) => {
					setRetry(true);
				});
      }
    },
    [currentUser, dataD]
  );

  useEffect(() => {
		if(!dataD){
			return;
		}
    doSearch(1, 100000);
  }, [doSearch, deviceId, dataD]);

	useEffect(() => {
		if (retry === true){
			doSearch(1, 100000);
			setRetry(false);
		}
	},[retry]);

	// Handle UP/DOWN button click (Select next device)
	const HandleUpButtonClicked = () => {
		let idx = deviceLists.findIndex(
			(id) => id === deviceSelected
		);
		idx = (idx - 1 + deviceLists.length) % deviceLists.length;
		history.push(
			`${Paths.device.detail}/?id=${deviceLists[idx]}`
		);
		setDeviceSelected(deviceLists[idx]);
		window.location.reload();
	};
	const HandleDownButtonClicked = () => {
		let idx = deviceLists.findIndex(
			(id) => id === deviceSelected
		);
		idx = (idx + 1) % deviceLists.length;
		history.push(
			`${Paths.device.detail}/?id=${deviceLists[idx]}`
		);
		setDeviceSelected(deviceLists[idx]);
		window.location.reload();
	};

  return (
    <>
      <Page
        className="screen-device-detail"
        title={
          <>
            {title}{' '}
            {deviceLists.length > 1 && (
              <>
                <Button
                  className="device-select"
                  onClick={HandleUpButtonClicked}
                >
                  ↑
                </Button>
                <Button
                  className="device-select"
                  onClick={HandleDownButtonClicked}
                >
                  ↓
                </Button>
              </>
            )}
          </>
        }
        body={
          currentUser.accountRole === ACCOUNT_ROLE_CHILD ? (
            <>
              <DeviceDetailStatus deviceId={deviceSelected} />
            </>
          ) : (
            <>
              <Tabs activeKey={currentTab} onSelect={(t) => setCurrentTab(t)}>
                {Object.keys(DeviceDetailTabs).map((t, index) => {
                  let tab = DeviceDetailTabs[t];
                  let TabComponent = tab.component;

                  if (!TabComponent) {
                    return null;
                  }

                  return (
                    <Tab key={index} eventKey={t} title={tab.title}>
                      {currentTab === t && (
                        <TabComponent deviceId={deviceSelected} />
                      )}
                    </Tab>
                  );
                })}
              </Tabs>
            </>
          )
        }
      />
    </>
  );
};

export default DeviceDetail;
