import * as React from "react"

function DkSf07Normal(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
      <defs>
        <style>
          {
            ".cls-13,.cls-14{stroke-linecap:round;stroke:#4d4d4d;stroke-miterlimit:10;stroke-width:.99px}.cls-13{fill:#999}.cls-14{fill:none}"
          }
        </style>
      </defs>
      <g
        id="_\u901A\u5E38\u767D\u80CC\u666F"
        data-name="\u901A\u5E38\u767D\u80CC\u666F"
      >
        <rect
          x={1.42}
          y={1.42}
          width={47.17}
          height={47.17}
          rx={3.58}
          ry={3.58}
          fill="#fff"
        />
        <path d="M45 2.83c1.19 0 2.17.97 2.17 2.17v40c0 1.19-.97 2.17-2.17 2.17H5c-1.19 0-2.17-.97-2.17-2.17V5c0-1.19.97-2.17 2.17-2.17h40M45 0H5C2.24 0 0 2.24 0 5v40c0 2.76 2.24 5 5 5h40c2.76 0 5-2.24 5-5V5c0-2.76-2.24-5-5-5z" />
      </g>
      <g
        id="_\u30EC\u30A4\u30E4\u30FC_5"
        data-name="\u30EC\u30A4\u30E4\u30FC 5"
      >
        <path
          stroke="#2b2b2b"
          strokeWidth=".71px"
          fill="#999"
          strokeLinejoin="round"
          strokeLinecap="round"
          d="M11.4 31.69L35.8 45.76 38.93 45.71 41.22 44.77 41.22 19.56 14.04 4.07 8.77 11.49 8.88 16.62 11.4 21.12 11.4 31.69z"
        />
        <path
          strokeLinejoin="round"
          strokeLinecap="round"
          stroke="#000"
          strokeWidth=".68px"
          d="M15.05 7.33L10.03 14.21 32.19 27.04 37.3 20.21 15.05 7.33z"
        />
        <path className="cls-14" d="M10.85 17.88L32.33 30.23 35.46 29.5" />
        <path className="cls-13" d="M40.1 21.01L35.9 26.03" />
        <path className="cls-14" d="M35.66 27.44L35.66 29.05" />
        <path className="cls-14" d="M35.51 36.38L35.51 44.55" />
        <path className="cls-14" d="M38.96 36.68L38.96 44.34" />
        <path
          className="cls-14"
          d="M35.9 29.78c.58.44 1.54 1.81 2.31 3.59.23.54.65 1.83.63 2.48M32.72 30.56c.63.47 1.87 1.54 2.45 3.33.19.58.27 1.11.29 1.57M12.18 19.02c.77.66 1.66 1.56 2.49 2.74.46.65.83 1.28 1.12 1.86"
        />
        <path className="cls-14" d="M32.38 27.89L32.38 29.49" />
        <path
          className="cls-13"
          d="M30.47 29.63c.11.4.22.92.24 1.52.02.46 0 .88-.05 1.22"
        />
      </g>
    </svg>
  )
}

export default DkSf07Normal;
