import React from "react";
import { useState, useEffect } from "react";
import DkSf07Normal from "dksf07icon/DkSf07Normal";
import DkSf07Tilt from "dksf07icon/DkSf07Tilt";

const IconList = [
	<DkSf07Normal/>,                  // 0
	<DkSf07Tilt/>,                   // 1
];

const DkSf07Icon = ({
	tilt=0,
	blinkInterval=0,
	blinkOff=false,
}) => {
	const [countdown, setCountdown] = useState(blinkInterval);

	useEffect(() => {
		const timerId = setTimeout(() => {
			if(countdown > 0){
				setCountdown(countdown - 1);
			}
		},500);
		// console.log('countdown: ', countdown);
		return () => clearTimeout(timerId);
	},[countdown]);

	let iconNo = (tilt === 1) ? 1:0;
	return (
		((blinkOff === false) && (countdown % 2) === 0) ?
			(<React.Fragment>
				{IconList[iconNo]}
			</React.Fragment>)
			:(<></>)
	)
}

export default DkSf07Icon;

