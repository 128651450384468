import './Signal.scss';

const dataTable = {
  NA: 'ss_0',
  LIMIT: 'ss_2',
  AVERAGE: 'ss_3',
  GOOD: 'ss_4',
  EXCELLENT: 'ss_5',
};

const Signal = ({ className, data = '' }) => {
  return (
    <>
      <div
        className={`signal ${
          className || dataTable?.[data?.toUpperCase()] || 'ss_0'
        }`}
      >
        <div className="signal-bar"></div>
        <div className="signal-bar"></div>
        <div className="signal-bar"></div>
        <div className="signal-bar"></div>
        <div className="signal-bar"></div>
        <i className="warning-icon" />
      </div>
    </>
  );
};

export default Signal;
