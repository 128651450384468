import { setMapValue } from 'util/utils';

const UserAcitonTypes = {
  SIGN_IN_START: 'SIGN_IN_START',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAILURE: 'SIGN_IN_FAILURE',
  SIGN_OUT: 'SIGN_OUT',
};

setMapValue(UserAcitonTypes);

export default UserAcitonTypes;
