import ContractList from 'page/contract/ContractList';
import DeviceActiveAlert from 'page/device/DeviceActiveAlert';
import DeviceActivity from 'page/device/DeviceActivity';
import DeviceAlertHistory from 'page/device/DeviceAlertHistory';
import DeviceDetail from 'page/device/DeviceDetail';
import DeviceList from 'page/device/DeviceList';
// import DeviceStatus from 'page/device/DeviceStatus';
import DeviceStatus2 from 'page/device/DeviceStatus2';
import Home from 'page/Home';
import AuthLog from 'page/user/AuthLog';
import UserInfo from 'page/user/UserInfo';
import UserList from 'page/user/UserList';
import UserMaintenance from 'page/user/UserMaintenance';
import Paths from './paths';
import { ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT } from './permission';

const Routes = [
  { path: Paths.root, component: Home },
  {
    path: Paths.user.info,
    component: UserInfo,
    permissions: [ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT],
  },
  {
    path: Paths.user.list,
    component: UserList,
    permissions: [ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT],
  },
  {
    path: Paths.user.log,
    component: AuthLog,
    permissions: [ACCOUNT_ROLE_ADMIN],
  },
  {
    path: Paths.user.update,
    component: UserMaintenance,
    permissions: [ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT],
  },
  {
    path: Paths.contract.list,
    component: ContractList,
    permissions: [ACCOUNT_ROLE_ADMIN],
  },
  {
    path: Paths.device.list,
    component: DeviceList,
    permissions: [ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT],
  },
  { path: Paths.device.detail,
		component: DeviceDetail,
	},
  // {
  //   path: Paths.device.status,
  //   component: DeviceStatus,
  //   permissions: [ACCOUNT_ROLE_PARENT, ACCOUNT_ROLE_CHILD],
  // },
  {
    path: Paths.device.status,
    component: DeviceStatus2,
  },
  {
    path: Paths.device.statusSF07,
    component: DeviceStatus2,
  },
  {
    path: Paths.device.statusWBGT,
    component: DeviceStatus2,
  },
  {
    path: Paths.device.statusGPS,
    component: DeviceStatus2,
  },
  {
    path: Paths.device.statusSF03WT,
    component: DeviceStatus2,
  },
  {
    path: Paths.device.statusSF03WT2,
    component: DeviceStatus2,
  },
  {
    path: Paths.device.statusXE1151,
    component: DeviceStatus2,
  },
  {
    path: Paths.device.activity,
    component: DeviceActivity,
    permissions: [ACCOUNT_ROLE_ADMIN],
  },
  {
    path: Paths.device.activeAlert,
    component: DeviceActiveAlert,
    permissions: [ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT],
  },
  {
    path: Paths.device.alertHistory,
    component: DeviceAlertHistory,
    permissions: [ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT],
  },
];

export const RouteMap = Routes.reduce((curResult, curElm) => {
  curResult[curElm.path] = curElm;
  return curResult;
}, {});

export default Routes;
