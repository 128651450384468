import { ToastContainer as BSToastContainer } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectToast } from 'redux/app/app.selector';
import Toast from './Toast';
import './ToastContainer.scss';

const ToastContainer = () => {
  const toast = useSelector(selectToast);

  return (
    <BSToastContainer>
      {Object.keys(toast).map((id) => (
        <Toast key={id} {...{ ...toast[id], id }} />
      ))}
    </BSToastContainer>
  );
};

export default ToastContainer;
