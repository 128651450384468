import { Card } from 'react-bootstrap';
import PageTitle from './PageTitle';
import './Page.scss';

const Page = ({ title, body, className }) => {
  return (
    <div className={`_page ${className || ''}`}>
      {title && <PageTitle title={title} />}
      <Card>
        <Card.Body>{body}</Card.Body>
      </Card>
    </div>
  );
};

export default Page;
