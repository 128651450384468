import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect } from "react";
import L from "leaflet";
// import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
// import * as React from 'react';
// import { nNU } from 'util/utils';

// const position = [51.505, -0.09];

const MapButton = ({
	active = 0,
	handleOnClick,
}) => {
  const map = useMap();
  useEffect(() => {
    if (!map) return;

    const buttonControl = L.control({
      position: "topleft"
    });

		buttonControl.onAdd = function (map) {
			// console.log("rerender: ", active);
			this._div = L.DomUtil.create("div", "");
			const buttonElement =
				`<div class="btnWrapper">
					<button id="map-button" class="btn btn-primary">
						ハザードマップ
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="button-right" class="bi bi-chevron-right" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
						</svg>
					</button>
				</div>`;
			const buttonElement2 =
				`<div class="btnWrapper">
					<button id="map-button" class="btn btn-primary">
						ハザードマップ
						<svg xmlns="http://www.w3.org/2000/svg"width="16" height="16" fill="currentColor" id="button-down" class="bi bi-chevron-down" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
						</svg>
					</button>
				</div>`;
			this._div.innerHTML = active === 0 ? buttonElement : buttonElement2;
			this._div.addEventListener("click", () => {
				let nowHtml = this._div.innerHTML;
				handleOnClick();
				if (nowHtml.includes("button-right")) {
					this._div.innerHTML = buttonElement2;
				}
				else{
					this._div.innerHTML = buttonElement;
				}
			});
			return this._div;
    };

    buttonControl.addTo(map);
		// map.remove(buttonControl);

    // return () => {
    //   map.remove(buttonControl);
    // };
  }, [map]);

  return null;
};

export default MapButton;
