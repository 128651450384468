import FormControls from 'component/FormControls';
import Page from 'component/Page';
import { Button, Form, Row } from 'react-bootstrap';
//import { useSelector } from 'react-redux';
//import { selectCurrentUser } from 'redux/user/user.selector';
import { sendPut } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import { getFormData, showConfirm, showToast, focusElement } from 'util/utils';

const repeatTimeMap = [
  5, 10, 15, 30, 60, 120,
];
const holdTimeMap = [
  10, 30, 60, 120, 180, 300,
];
const geoRadiusMap = [
  100, 200, 300, 500, 1000, 1500, 2000, 3000, 5000, 10000, 20000,
];

const GeoUpdate = ({ deviceId, settingId, data, onUpdated }) => {
	// if(!nNU(settingId)){
	// 	return;
	// }
  //const currentUser = useSelector(selectCurrentUser);
  const title = string.UPDATE_GPS_SETTING;
  //const editMode = !!data?.deviceId;
  const controls = [
    {
      id: 'enable',
      title: string.ACTIVE,
      type: 'checkbox',
      md: 12,
      options: [{ value: 'true' }],
			defaultValue: data?.enable,
    },
    {
      id: 'latitude',
      title: string.LOCATION_LATITUDE,
      readOnly: true,
      defaultValue: data?.latitude,
      md: 6,
    },
    {
      id: 'longitude',
      title: string.LOCATION_LONGITUDE,
      readOnly: true,
      defaultValue: data?.longitude,
      md: 6,
    },
    {
      id: 'radius',
      title: string.GEO_RADIUS,
      md: 6,
      type: 'selection',
      options: geoRadiusMap.map((value) => ({ select: value })),
			//defaultValue: data?.radius,
    },
    {
      id: 'regionName',
      title: string.GEO_REGION_NAME,
      md: 6,
    },

    {
      id: 'checkin',
      title: string.GEO_CHECK_IN,
      type: 'checkbox',
      md: 6,
      options: [{ label: string.ACTIVE, value: 'true' }],
      defaultValue: data?.checkIn,
    },
    {
      id: 'checkout',
      title: string.GEO_CHECK_OUT,
      type: 'checkbox',
      md: 6,
      options: [{ label: string.ACTIVE, value: 'true' }],
      defaultValue: data?.checkOut,
    },
    {
      id: 'holdTime',
      title: string.GEO_HOLD_TIME,
      md: 6,
      type: 'selection',
      options: holdTimeMap.map((value) => ({ select: value })),
			defaultValue: data?.holdTimeMinute,
    },
    {
      id: 'holdTimeEnable',
      type: 'checkbox',
      md: 6,
      options: [{ label: string.ACTIVE, value: 'true' }],
      defaultValue: data?.holdTimeEnable,
    },
    {
      id: 'holdTimeRepeat',
      title: string.GEO_HOLD_TIME_REPEAT,
      md: 6,
      type: 'selection',
      options: repeatTimeMap.map((value) => ({ select: value })),
			defaultValue: data?.repeatAlertCycle,
    },
    {
      id: 'holdTimeRepeatEnable',
      type: 'checkbox',
      md: 6,
      options: [{ label: string.ACTIVE, value: 'true' }],
      defaultValue: data?.repeatAlertHoldTime,
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = getFormData(e.target, {
      rawCol: {
        holdTimeRepeatEnable: true,
        holdTimeRepeat: true,
        holdTimeEnable: true,
        holdTime: true,
        checkout: true,
        checkin: true,
        radius: true,
        latitude: true,
        longitude: true,
        regionName: true,
        enable: true,
      },
    });
    if(!formData?.regionName){
      showToast(string.GEO_NAME_EMPTY_ERR);
      focusElement('#regionName');
      return;
    }
    showConfirm({
      body: string.GEO_UPDATE_CONFIRM,
      defaultAction: () => {
        let body = {
          settingId: settingId,
					enable: formData.enable,
					regionName: formData.regionName,
					latitude: formData.latitude,
					longitude: formData.longitude,
					radius: formData.radius,
					holdTimeMinute: formData.holdTime,
					holdTimeEnable: formData.holdTimeEnable,
					checkIn: formData.checkin,
					checkOut: formData.checkout,
					repeatAlertHoldTime: formData.holdTimeRepeatEnable,
					repeatAlertCycle: formData.holdTimeRepeat,
				};
        sendPut({
          url: `${Urls.gpsNotifyConfiguration.editGPSSetting}/${deviceId}`,
          body: body,
        }).then((res) => {
          showToast(string.GEO_UPDATE_SUCCESS, 'success');
          onUpdated(res);
        });
      },
    });
  };

  return (
    <>
      <Page
        title={title}
        body={
          <>
            <Form onSubmit={onSubmit}>
              <FormControls data={data} controls={controls} />
              <Row className="form-button-row">
                <Button type="submit">{string.SAVE}</Button>
              </Row>
            </Form>
          </>
        }
      />
    </>
  );
};
export default GeoUpdate;
