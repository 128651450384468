import * as React from "react"

function DkSf07Tilt(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
      <defs>
        <style>
          {
            ".cls-23,.cls-24{stroke-linecap:round;stroke:#4d4d4d;stroke-width:.99px;stroke-miterlimit:10}.cls-23{fill:#999}.cls-24{fill:none}"
          }
        </style>
      </defs>
      <path
        fill="#fff"
        id="_\u50BE\u659C\u767D\u80CC\u666F"
        data-name="\u50BE\u659C\u767D\u80CC\u666F"
        d="M32.35 4.07L38.46 7.25 39.54 7.82 40.67 7.39 43.78 6.22 42.61 9.32 42.18 10.46 42.75 11.54 45.93 17.63 42.75 23.74 42.09 25 42.75 26.25 45.94 32.37 42.74 38.64 42.19 39.72 42.62 40.85 43.74 43.76 40.68 42.61 39.54 42.18 38.46 42.75 32.37 45.93 26.26 42.75 25 42.09 23.75 42.75 17.65 45.93 11.54 42.75 10.46 42.18 9.33 42.61 6.22 43.78 7.39 40.68 7.82 39.54 7.25 38.46 4.07 32.37 7.25 26.26 7.91 25 7.25 23.75 4.07 17.65 7.25 11.54 7.82 10.46 7.39 9.33 6.22 6.22 9.32 7.39 10.46 7.82 11.54 7.25 17.63 4.07 23.74 7.25 25 7.91 26.25 7.25 32.35 4.07z"
      />
      <g
        id="_\u30EC\u30A4\u30E4\u30FC_5"
        data-name="\u30EC\u30A4\u30E4\u30FC 5"
      >
        <path
          stroke="#2b2b2b"
          strokeWidth=".71px"
          fill="#999"
          strokeLinejoin="round"
          strokeLinecap="round"
          d="M11.4 31.69L35.8 45.76 38.93 45.71 41.22 44.77 41.22 19.56 14.04 4.07 8.77 11.49 8.88 16.62 11.4 21.12 11.4 31.69z"
        />
        <path
          stroke="#000"
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth=".68px"
          d="M15.05 7.33L10.03 14.21 32.19 27.04 37.3 20.21 15.05 7.33z"
        />
        <path className="cls-24" d="M10.85 17.88L32.33 30.23 35.46 29.5" />
        <path className="cls-23" d="M40.1 21.01L35.9 26.03" />
        <path className="cls-24" d="M35.66 27.44L35.66 29.05" />
        <path className="cls-24" d="M35.51 36.38L35.51 44.55" />
        <path className="cls-24" d="M38.96 36.68L38.96 44.34" />
        <path
          className="cls-24"
          d="M35.9 29.78c.58.44 1.54 1.81 2.31 3.59.23.54.65 1.83.63 2.48M32.72 30.56c.63.47 1.87 1.54 2.45 3.33.19.58.27 1.11.29 1.57M12.18 19.02c.77.66 1.66 1.56 2.49 2.74.46.65.83 1.28 1.12 1.86"
        />
        <path className="cls-24" d="M32.38 27.89L32.38 29.49" />
        <path
          className="cls-23"
          d="M30.47 29.63c.11.4.22.92.24 1.52.02.46 0 .88-.05 1.22"
        />
      </g>
      <path
        d="M32.35 4.07l6.1 3.18 1.08.56 1.14-.43 3.1-1.17-1.16 3.1-.43 1.14.56 1.08 3.18 6.1-3.18 6.1-.66 1.26.66 1.26 3.19 6.11-3.2 6.27-.55 1.08.43 1.13 1.11 2.91-3.06-1.15-1.14-.43-1.08.56-6.1 3.18-6.1-3.18-1.26-.66-1.26.66-6.1 3.18-6.1-3.18-1.08-.56-1.14.43-3.1 1.17 1.16-3.1.43-1.14-.56-1.08-3.18-6.1 3.18-6.1.66-1.26-.66-1.26-3.18-6.1 3.18-6.1.56-1.08-.43-1.14-1.17-3.1 3.1 1.16 1.14.43 1.08-.56 6.1-3.18 6.1 3.18 1.26.66 1.26-.66 6.1-3.18zm0-3.07l-7.36 3.84L17.63 1l-7.36 3.84-8.71-3.28 3.28 8.72L1 17.64 4.84 25 1 32.36l3.84 7.36-3.28 8.71 8.72-3.28 7.36 3.84L25 45.15l7.36 3.84 7.36-3.84 8.71 3.28-3.27-8.56 3.83-7.52-3.84-7.36 3.84-7.36-3.84-7.36 3.28-8.71-8.72 3.28L32.35 1h0z"
        fill="#f1000b"
        strokeMiterlimit={10}
        stroke="#000"
        id="_\u50BE\u659C\u67A0"
        data-name="\u50BE\u659C\u67A0"
      />
    </svg>
  )
}

export default DkSf07Tilt;
