import * as React from "react"

const DkSf03wt2LowBattery = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      {...props}
    >
      <style />
      <g id="\u30EC\u30A4\u30E4\u30FC_25_2">
        <path d="M43.52 28.44H48c1.1 0 2-.9 2-2v-4c0-1.1-.9-2-2-2h-4.48c-1.1 0-2 .9-2 2v4a2 2 0 002 2z" />
        <path
          d="M41.13 36.45h-37c-1.66 0-3-1.34-3-3v-16.9c0-1.66 1.34-3 3-3h37c1.66 0 3 1.34 3 3v16.9c0 1.66-1.34 3-3 3z"
          fill="#fff"
          stroke="#000"
          strokeWidth={2.126}
          strokeMiterlimit={10}
        />
      </g>
      <g id="\u30EC\u30A4\u30E4\u30FC_25_3">
        <defs>
          <path id="SVGID_25_1_" d="M0 9.68H9.29V40.16H0z" />
        </defs>
        <clipPath id="SVGID_25_00000140702802820616918910000004904757353466672029_">
          <use xlinkHref="#SVGID_25_1_" overflow="visible" />
        </clipPath>
        <path
          d="M41.13 36.45h-37c-1.66 0-3-1.34-3-3v-16.9c0-1.66 1.34-3 3-3h37c1.66 0 3 1.34 3 3v16.9c0 1.66-1.34 3-3 3z"
          clipPath="url(#SVGID_25_00000140702802820616918910000004904757353466672029_)"
          fill="#fd1a16"
          stroke="#000"
          strokeWidth={2.126}
          strokeMiterlimit={10}
        />
      </g>
      <g id="\u30EC\u30A4\u30E4\u30FC_25_4">
        <path
          d="M25.21 3.43L11.94 26.41c-.38.67.1 1.5.87 1.5h26.54c.77 0 1.25-.83.87-1.5L26.94 3.43a.996.996 0 00-1.73 0z"
          fill="#ffff01"
          stroke="#000"
          strokeWidth={1.4224}
          strokeMiterlimit={10}
        />
        <path d="M24.64 24.93c0-.91.61-1.57 1.45-1.57.86 0 1.43.66 1.43 1.57 0 .86-.56 1.52-1.45 1.52-.92 0-1.43-.71-1.43-1.52zm2.23-3.98h-1.62l-.34-13.5h2.31l-.35 13.5z" />
      </g>
    </svg>
  )
}

export default DkSf03wt2LowBattery;
