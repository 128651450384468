import string from 'util/string';
import Table from 'component/Table';
import { useState } from 'react';
import { useCallback } from 'react';
import sendRequest, { sendPost } from 'util/request';
import Urls from 'util/urls';
import { useEffect } from 'react';
import {
  adjustDate,
  downloadFile,
  formatDate,
  getFormData,
  setHours,
  setSearchRangeDate,
  showConfirm,
  showToast,
  truncTime,
} from 'util/utils';
import FormControls from 'component/FormControls';
import { useSelector } from 'react-redux';
import { selectSearchRangeDate } from 'redux/app/app.selector';
import { Button, Form, Row } from 'react-bootstrap';
import { ImBin } from 'react-icons/im';
import Modal from 'component/Modal';
import FormContext from 'rc-field-form/es/FormContext';
import Page from 'component/Page';

const DeviceDetailPayload = ({ deviceId }) => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(50);

  const searchRangeDate = useSelector(selectSearchRangeDate);
  const [searchData, setSearchData] = useState({
    fromDate: searchRangeDate?.[0] || truncTime(adjustDate(new Date(), -7)),
    toDate: searchRangeDate?.[1] || truncTime(),
  });

  const formControls = [
    {
      type: 'rangeDate',
      onChange: (e) =>
        setSearchData({
          ...searchData,
          fromDate: e[0],
          toDate: e[1],
        }),
      options: {
        defaultDate: [searchData.fromDate, searchData.toDate],
      },
    },
  ];

  const headers = [
    string.DEVICE_LOG_TIME,
    string.DEVICE_LOG_DATA,
    string.DEVICE_LOG_SEQUENCENO,
  ];
  const [data, setData] = useState([]);
  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key,
        id: raw.deviceId,
        data: [
          formatDate(raw.dataLogTime, 'YYYY/MM/DD HH:mm:ss'),
          raw.uplinkData,
          raw.sequenceNumber,
        ],
        raw,
      })),
    []
  );

  const mainActions = [
    {
      icon: 'download',
      title: string.CSV_DOWNLOAD,
      onClick: async () => {
        let csvData = await doSearch(1, total, true);

        let arrCSVData = csvData.split('\r\n');
        // Format Datetime from 2022-04-25 07:39:19.330285 => 2022-04-25 14:39:19
        for (let i = 1; i < arrCSVData.length - 1; i++) {
          //2022-04-25 07:39:19.330285,6398791684de7219cF000000,26,
          let timeFormatted = formatDate(
            arrCSVData[i].split(',')[0],
            'YYYY-MM-DD HH:mm:ss'
          );
          let stringArrayCSVData = `${timeFormatted},${
            arrCSVData[i].split(',')[1]
          },${arrCSVData[i].split(',')[2]}`;
          arrCSVData[i] = stringArrayCSVData;
        }
        downloadFile({
          data: arrCSVData.join('\n'),
          fileName: `DevicePayload_${data[0].sigfoxId}`,
          fileType: 'csv',
        });
      },
    },
  ];
  const actions = [];

  const doSearch = useCallback(
    async (page, pageSize, getAll = false) => {
      setHours(searchData.fromDate);
      setHours(searchData.toDate, 23, 59, 59, 999);
      let body = {
        ...(!searchData?.fromDate
          ? null
          : {
              fromDate: searchData.fromDate.toISOString(),
            }),
        ...(!searchData?.toDate
          ? null
          : {
              toDate: searchData.toDate.toISOString(),
            }),
        deviceId,
        ...(!getAll
          ? {
              offset: (page - 1) * pageSize,
              size: pageSize,
            }
          : null),
      };
      if (!body?.fromDate || !body?.toDate) {
        return;
      }
      setSearchRangeDate([searchData.fromDate, searchData.toDate]);
      let response = await sendPost({
        url: !getAll ? Urls.device.payload : Urls.device.payloadExport,
        body,
        ...(!getAll ? null : { isRaw: true }),
      });
      if (!getAll) {
        const { total, result } = response;
        // console.log(result);
        setData(result);
        setTotal(total);
      }
      return response;
    },
    [deviceId, searchData]
  );

  const deleteOptions = { all: '全部', date: '日選択' };

  useEffect(() => {
    doSearch(page, pageSize);
  }, [page, pageSize, doSearch]);

  const controls = [
    {
      id: 'select',
      type: 'radio',
      defaultValue: 'all',
      options: Object.keys(deleteOptions).map((value) => {
        return {
          label: deleteOptions[value],
          value,
        };
      }),
    },
    {
      id: 'dateSelect',
      type: 'dateSelect',
      options: { defaultDate: truncTime() },
    },
  ];

  const onSubmitForm = (e) => {
    e.preventDefault();
    e.stopPropagation();
    const formData = getFormData(e.target);
    const { select, dateSelect } = formData;
    const toDate = setHours(dateSelect, 23, 59, 59, 999).toISOString();

    let body =
      select === 'date'
        ? { deviceId: deviceId, toDate: toDate }
        : { deviceId: deviceId };
    showConfirm({
      body: string.DELETE_DATA_CONFIRM,
      defaultAction: () => {
        sendRequest({
          url: `${Urls.device.deleteDeviceData}`,
          method: 'delete',
          body: body,
        }).then(() => {
          doSearch(page, pageSize);
          setShow(false);
        });
      },
    });
  };

  const modalBody = (
    <Page
      title={string.CONFIRM}
      className="xs"
      body={
        <Form onSubmit={onSubmitForm}>
          <FormControls controls={controls} />
          <div style={{ display: 'flex', justifyContent: 'right', gap: '5px' }}>
            <Button variant="secondary" onClick={() => setShow(false)}>
              Cancel
            </Button>
            <Button type="submit">OK</Button>
          </div>
        </Form>
      }
    />
  );
  const [isShow, setShow] = useState(false);
  const handleDeleteDeviceDataConfirm = () => {
    setShow(true);
  };

  return (
    <>
      <div style={{ display: 'flex', gap: '5px', marginLeft: '5px' }}>
        <span
          style={{
            border: '1px solid black',
            padding: '1px 10px',
            height: '27px',
            userSelect: 'none',
            color: '#fff',
            backgroundColor: '#1C63B7',
          }}
        >
          {string.TIME_PAYLOAD}
        </span>
        <FormControls controls={formControls} />
        <Button
          size="sm"
          variant="danger"
          style={{ padding: '0.2rem 0.3rem', height: '100%' }}
          onClick={handleDeleteDeviceDataConfirm}
        >
          <ImBin />
          <span style={{ marginTop: '10px' }}> {string.DELETE_DATA}</span>
        </Button>
      </div>

      <Table
        className="nowrap"
        mainActions={mainActions}
        page={page}
        pageSize={pageSize}
        total={total}
        onPageChanged={setPage}
        onPageSizeChanged={setPageSize}
        headers={headers}
        data={dataTable(data)}
        actions={actions}
      />
      <Modal
        show={isShow}
        onHide={() => setShow(false)}
        body={modalBody}
        size="xs"
      ></Modal>
    </>
  );
};

export default DeviceDetailPayload;
