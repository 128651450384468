import * as React from "react"

const DkSf03wt2TiltFloodHalf = (props) => {
  return (
    <svg
      id="\u30EC\u30A4\u30E4\u30FC_29_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <path
        fill="#fff"
        id="\u50BE\u659C\u767D\u80CC\u666F_29"
        d="M32.36 4.16L38.47 7.34 39.54 7.9 40.68 7.47 43.78 6.31 42.62 9.41 42.19 10.54 42.75 11.62 45.94 17.72 42.75 23.82 42.1 25.08 42.75 26.34 45.95 32.46 42.75 38.73 42.2 39.81 42.63 40.94 43.75 43.85 40.69 42.7 39.55 42.27 38.47 42.83 32.37 46.02 26.27 42.83 25.01 42.18 23.75 42.83 17.65 46.02 11.55 42.83 10.47 42.27 9.33 42.7 6.23 43.87 7.39 40.77 7.82 39.63 7.26 38.55 4.08 32.45 7.26 26.35 7.92 25.09 7.26 23.83 4.08 17.73 7.26 11.63 7.82 10.55 7.39 9.41 6.23 6.31 9.33 7.47 10.46 7.9 11.54 7.34 17.64 4.16 23.74 7.34 25 8 26.26 7.34z"
      />
      <g id="\u30EC\u30A4\u30E4\u30FC_29_5">
        <defs>
          <path
            id="SVGID_29_1_"
            d="M32.36 1.09L25 4.93 17.64 1.09 10.28 4.93 1.57 1.65 4.85 10.37 1.01 17.73 4.85 25.09 1.01 32.45 4.85 39.81 1.57 48.53 10.29 45.25 17.65 49.09 25.01 45.25 32.37 49.09 39.73 45.25 48.45 48.52 45.17 39.97 49.01 32.44 45.17 25.08 49.01 17.72 45.17 10.36 48.44 1.65 39.72 4.93z"
          />
        </defs>
        <clipPath id="SVGID_29_00000177455313147529063970000011862898778591152049_">
          <use xlinkHref="#SVGID_29_1_" overflow="visible" />
        </clipPath>
        <path
          clipPath="url(#SVGID_29_00000177455313147529063970000011862898778591152049_)"
          fill="#01a0c6"
          d="M49.01 1.09L1.01 49.09 49 49z"
        />
      </g>
      <g id="\u30EC\u30A4\u30E4\u30FC_29_6">
        <g id="LINE_29_00000075135062577891469680000000960248461345069455_">
          <path
            d="M8.13 15.01c-.24 0-.43.19-.43.43v5.14c0 .24.19.42.43.42.23 0 .42-.19.42-.43v-5.14c0-.24-.19-.42-.42-.42zM6.54 30.1V14.85c0-.59.4-1.1.96-1.25l1.75-.47h1.6V9.02L11.26 4h2.41l.41 5.02v4.11h1.6l1.75.47c.56.15.95.66.95 1.25V30.1H6.54zM16.8 17.58a.425.425 0 000 .85c.23 0 .42-.19.42-.42 0-.25-.19-.43-.42-.43z"
            id="XMLID_29_00000038380629026748013400000007808212985581502094_"
          />
        </g>
        <g id="LINE_29_00000120520203022829816840000002137709568111438009_">
          <path
            d="M32.82 28.07a.425.425 0 10-.85 0v5.15a.425.425 0 00.85 0v-5.15zm7.13-2.29l1.75.47c.56.15.95.66.95 1.24v15.25H30.81V27.49c0-.58.39-1.09.96-1.24l1.74-.47h1.61v-4.12l.58-7.05h2.06l.59 7.05v4.12h1.6zm1.12 5.29a.425.425 0 10-.01-.85.425.425 0 00.01.85z"
            id="XMLID_29_00000066484372780800085570000003181514813875946897_"
          />
        </g>
        <path
          d="M18.15 2.27v3.08c0 1.1.9 2 2 2h14.58c1.1 0 2 .9 2 2v5.25"
          fill="none"
          stroke="#000"
          strokeWidth={1.4173}
          strokeMiterlimit={10}
        />
      </g>
      <path
        d="M32.36 4.16l6.1 3.18 1.08.56 1.14-.43 3.1-1.17-1.16 3.1-.43 1.14.56 1.08 3.18 6.1-3.18 6.1-.66 1.26.66 1.26 3.19 6.11-3.2 6.27-.55 1.08.43 1.13 1.11 2.91-3.06-1.15-1.14-.43-1.08.56-6.1 3.18-6.1-3.18-1.26-.66-1.26.66-6.1 3.18-6.1-3.18-1.08-.56-1.12.44-3.1 1.17 1.16-3.1.43-1.14-.56-1.08-3.18-6.1 3.18-6.1.66-1.26-.66-1.26-3.18-6.1 3.18-6.1.56-1.08-.43-1.14-1.17-3.1 3.1 1.16 1.14.43 1.08-.56 6.1-3.18 6.1 3.18L25 8l1.26-.66 6.1-3.18zm0-3.07L25 4.93l-7.36-3.84-7.36 3.84-8.71-3.28 3.28 8.72-3.84 7.36 3.84 7.36-3.84 7.36 3.84 7.36-3.28 8.71 8.72-3.28 7.36 3.84 7.36-3.84 7.36 3.84 7.36-3.84 8.71 3.28-3.27-8.56L49 32.44l-3.84-7.36L49 17.72l-3.84-7.36 3.28-8.71-8.72 3.28-7.36-3.84h0z"
        fill="#f1000b"
        stroke="#000"
        strokeMiterlimit={10}
        id="\u50BE\u659C\u67A0_29"
      />
    </svg>
  )
}

export default DkSf03wt2TiltFloodHalf;
