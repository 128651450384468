import FormControls from 'component/FormControls';
import Modal from 'component/Modal';
import Page from 'component/Page';
import Table, { TablePaggingOption } from 'component/Table';
import DeviceSelect from 'page/device/DeviceSelect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import { ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT } from 'util/permission';
import sendRequest, { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import {
  getFormData,
  getString,
  GUIDChecker,
  showConfirm,
  showToast,
} from 'util/utils';
import ContractSelect from './ContractSelect';

const deviceNamesMap = [
  string.DEVICE_SF03,
  string.DEVICE_SFGPS,
  string.DEVICE_WBGT,
  string.DEVICE_SF03WT2,
];
const ContractMaintenance = ({ data, onUpdated, addMode = false }) => {
  const currentUser = useSelector(selectCurrentUser);
  const editMode = useMemo(
    () => !addMode && currentUser.accountRole === ACCOUNT_ROLE_ADMIN,
    [currentUser.accountRole]
  );
  const infoMode = useMemo(() => !addMode && !editMode, [editMode]);

  const newContractMode = useMemo(
    () => !editMode && !infoMode && !addMode,
    [editMode, infoMode, addMode]
  );
  const title = useMemo(
    () =>
      addMode
        ? string.NEW_CONTRACT
        : !infoMode
        ? string.CONTRACT_EDIT
        : string.CONTRACT_INFO,
    [infoMode, addMode]
  );
  const controls = [
    (addMode && currentUser.accountRole === ACCOUNT_ROLE_ADMIN) ||
    editMode ||
    infoMode
      ? {
          id: 'contractNumber',
          title: string.CONTRACT_NUMBER,
          readOnly: infoMode,
          md: 6,
        }
      : {
          id: 'contractId',
          title: string.CONTRACT_CODE,
          readOnly: infoMode,
          md: 6,
        },
    {
      id: 'accountName',
      title: string.ACCOUNT_NAME,
      readOnly: editMode || infoMode,
      md: 6,
      type: editMode || infoMode ? 'text' : 'hidden',
      // options: deviceNamesMap.map((value) => ({ select: value })),
    },
    {
      id: 'validationDate',
      title: string.CONTRACT_ACTIVATION_DATE,
      readOnly: infoMode,
      type:
        (addMode && currentUser.accountRole === ACCOUNT_ROLE_ADMIN) ||
        editMode ||
        infoMode
          ? 'date'
          : 'hidden',
      md: 6,
    },
    {
      id: 'expirationDate',
      title: string.CONTRACT_EXPIRATION_DATE,
      readOnly: infoMode,
      type:
        (addMode && currentUser.accountRole === ACCOUNT_ROLE_ADMIN) ||
        editMode ||
        infoMode
          ? 'date'
          : 'hidden',
      md: 6,
    },
    { id: 'accountId', type: 'hidden' },
  ];
  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = getFormData(e.target, {
      rawCol: { contractNumber: true, contractId: true },
    });
    let { contractNumber, contractId } = formData || {};

    showConfirm({
      body: editMode
        ? string.CONTRACT_UPDATE_CONFIRM
        : string.CONTRACT_NEW_CONFIRM,

      defaultAction: () => {
        let submitedAction = (res) => {
          showToast(string.CONTRACT_UPDATE_SUCCESS, 'success');
          onUpdated(res, editMode);
        };

        if (
          contractNumber?.length > 0 &&
          (addMode || editMode) &&
          currentUser.accountRole === ACCOUNT_ROLE_ADMIN
        ) {
          sendRequest({
            method: editMode ? 'PUT' : 'POST',
            url:
              (addMode || editMode) &&
              currentUser.accountRole === ACCOUNT_ROLE_ADMIN
                ? editMode
                  ? `${Urls.contract.update}/${data?.contractId}`
                  : `${Urls.contract.update}`
                : `${Urls.contract.link}`,
            body:
              (addMode || editMode) &&
              currentUser.accountRole === ACCOUNT_ROLE_ADMIN
                ? { ...formData, foreignAccountId: currentUser.accountId }
                : {
                    accountId: currentUser?.accountId,
                    contractId: contractId,
                  },
          }).then((res) => {
            setModalShow(false);
            submitedAction(res);
          });
        } else {
          showToast(
            newContractMode
              ? string.CONTRACT_NUMBER_EMPTY_ERR
              : getString(
                  string.ADD_CONTRACT_ERROR,
                  infoMode ? contractNumber : editMode ? data.contractId : ''
                )
          );
        }
      },
    });
  };

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [modalShow, setModalShow] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [isAssignContract, setAssignContract] = useState(false);

  const headers = [
    string.DEVICE_NAME,
    string.DEVICE_SIGFOX_ID,
    string.DEVICE_MODEL,
    string.DEVICE_SERIALNO,
  ];
  const [listData, setListData] = useState([]);
  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key,
        data: [raw.assetName, raw.sigfoxId, raw.model, raw.serialNumber],
        raw,
      })),
    []
  );

  const doSearch = useCallback(
    (page, pageSize) => {
      if (!data?.contractId) {
        return;
      }
      sendPost({
        url: `${Urls.device.listOfContract}/${data?.contractId}`,
        body: {
          offset: (page - 1) * pageSize,
          size: pageSize,
          // offset: 0,
          // size: 9999999,
        },
      }).then((data) => {
        const { total, result } = data;
        setListData(result);
        setTotal(total);
      });
    },
    [data]
  );

  useEffect(() => {
    doSearch(page, pageSize);
  }, [page, pageSize, doSearch]);

  const mainActions = infoMode
    ? []
    : !data?.accountId ||
      data?.accountId === '00000000-0000-0000-0000-000000000000'
    ? listData.length > 0
      ? [
          {
            icon: 'add',
            title: string.ADD_DEVICE,
            onClick: () => {
              setAssignContract(false);
              setModalShow(true);
            },
          },
          {
            icon: 'assign',
            title: string.ADD_CONTRACT,
            onClick: () => {
              setAssignContract(true);
              setModalShow(true);
            },
          },
        ]
      : [
          {
            icon: 'add',
            title: string.ADD_DEVICE,
            onClick: () => {
              setAssignContract(false);
              setModalShow(true);
            },
          },
        ]
    : [];
  const actions = infoMode
    ? []
    : !data?.accountId ||
      data?.accountId === '00000000-0000-0000-0000-000000000000'
    ? [
        {
          render: 'assignminus',
          onClick: ({ raw }) => {
            showConfirm({
              body: getString(string.CONTRACT_UNASSIGN_DEVICE_CONFIRM, [
                data?.contractNumber,
              ]),
              defaultAction: () => {
                sendPost({
                  url: `${Urls.device.listOfContract}/${data?.contractId}`,
                  body: {
                    offset: 0,
                    size: total,
                  },
                }).then((response) => {
                  const { result } = response;
                  // console.log(result);
                  sendPost({
                    url: Urls.device.assignToContract,
                    body: {
                      contractId: data.contractId,
                      assignDevices: result.filter(
                        (e) => e.deviceId !== raw.deviceId
                      ),
                    },
                  }).then(() => {
                    showToast(
                      getString(string.CONTRACT_UNASSIGN_DEVICE_SUCCESS, [
                        data?.contractNumber,
                      ]),
                      'success'
                    );
                    doSearch(page, pageSize);
                  });
                });
              },
            });
          },
        },
      ]
    : [];

  const onDeviceSelect = (items) => {
    showConfirm({
      body: getString(string.CONTRACT_ASSIGN_DEVICE_CONFIRM, [
        data?.contractNumber,
      ]),
      defaultAction: () => {
        sendPost({
          url: `${Urls.device.listOfContract}/${data?.contractId}`,
          body: {
            offset: 0,
            size: total,
          },
        }).then((response) => {
          const { result } = response;
          sendPost({
            url: Urls.device.assignToContract,
            body: {
              contractId: data.contractId,
              assignDevices: [...result, ...items.map((e) => e)],
            },
          }).then((res) => {
            showToast(string.CONTRACT_ASSIGN_DEVICE_SUCCESS, 'success');
            setModalShow(false);
            doSearch(1, pageSize);
          });
        });
      },
    });
  };
  const onContractSelect = (items) => {
    showConfirm({
      body: getString(string.CONTRACT_ASSIGN_ACCOUNT_CONFIRM, [
        items?.accountName,
        data?.contractNumber,
      ]),
      defaultAction: () => {
        sendPost({
          url: `${Urls.contract.link}`,
          body: {
            accountId: items?.accountId,
            contractId: data?.contractId,
          },
        }).then((response) => {
          onUpdated();
          showToast(string.CONTRACT_ASSIGN_ACCOUNT_SUCCESS, 'success');
          setModalShow(false);
          doSearch(1, pageSize);
        });
      },
    });
  };

  const modalBody = isAssignContract ? (
    <ContractSelect
      onItemSelected={onContractSelect}
      multiple={false}
    ></ContractSelect>
  ) : (
    <DeviceSelect onItemSelected={onDeviceSelect} multiple={true} />
  );

  return (
    <>
      <Page
        title={title}
        body={
          <>
            <Form onSubmit={onSubmit}>
              <FormControls data={data} controls={controls} />
              <Row className="form-button-row">
                <Button type="submit">{string.SAVE}</Button>
              </Row>
            </Form>

            {!!editMode && (
              <>
                <Table
                  className="nowrap"
                  mainActions={mainActions}
                  page={page}
                  pageSize={pageSize}
                  onPageChanged={setPage}
                  onPageSizeChanged={setPageSize}
                  total={total}
                  headers={headers}
                  data={dataTable(listData)}
                  actions={actions}
                  pagging={TablePaggingOption.TOP}
                />
                <Modal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  body={modalShow && modalBody}
                />
              </>
            )}
          </>
        }
      />
    </>
  );
};
export default ContractMaintenance;
