import React from 'react';
import string from 'util/string';
import DkSf03wt2Icon from './DkSf03wt2Icon';
import DkSf03wtIcon from './DkSf03wtIcon';
import DkSf07Icon from './DkSf07Icon';
import './Icon.scss';

const GetIcon = ({
	deviceType,
	timeout=0,
	flood=0,
	tilt=0,
	batteryLow=0,
	blinkInterval=0,
	blinkOff=false,
}) => {
    if(deviceType === string.DEVICE_SF03WT){
        return (
            <div
                className='dksf03wt2-icon'
            >
                {
                    <DkSf03wtIcon
                        timeout={timeout}
                        flood={flood}
                        tilt={tilt}
                        batteryLow={batteryLow}
												blinkInterval={blinkInterval}
												blinkOff={blinkOff}
                    />
                }
            </div>
        )
    }
    else if(deviceType === string.DEVICE_SF03WT2){
        return (
            <div
                className='dksf03wt2-icon'
            >
                {
                    <DkSf03wt2Icon
                        timeout={timeout}
                        flood={flood}
                        tilt={tilt}
                        batteryLow={batteryLow}
												blinkInterval={blinkInterval}
												blinkOff={blinkOff}
                    />
                }
            </div>
        )
    }
		else {
			return (
				<div
						className='dksf03wt2-icon'
				>
						{
								<DkSf07Icon
										tilt={tilt}
										blinkInterval={blinkInterval}
										blinkOff={blinkOff}
								/>
						}
				</div>
		)
		}
}

export default GetIcon;
