import { Badge } from 'react-bootstrap';
import string from 'util/string';
import './Sensor.scss';

const notifyTypeMapSF04 = {
  '04': string.NOTI_FLOODING_SENSOR,
  '05': string.NOTI_DROUGHT_SENSOR,
  '08': string.NOTI_TILT_SENSOR,
};

const notifyBgMapSF04 = {
  '04': 'danger',
  '05': 'success',
  '08': 'warning',
};

const notifyTypeMapSF03WT2 = {
  '04': string.NOTI_HIGH_FLOOD_LEVEL,
  '05': string.NOTI_LOW_FLOOD_LEVEL,
  '08': string.NOTI_TILT_SENSOR,
  '09': string.NOTI_LOW_FLOOD_LEVEL,
};

const notifyBgMapSF03WT2 = {
  '04': 'danger',
  '05': 'warning',
  '08': 'purple',
  '09': 'warning',
};
const Sensor = ({ data, model }) => {
  return (
    <div
      className={`sensor sensor-${
        model === string.DEVICE_SF03WT2
          ? notifyBgMapSF03WT2[data] || 'success'
          : notifyBgMapSF04[data] || 'success'
      }`}
    >
      <Badge
        bg={
          model === string.DEVICE_SF03WT2
            ? notifyBgMapSF03WT2[data] || 'success'
            : notifyBgMapSF04[data] || 'success'
        }
      >
        {model === string.DEVICE_SF03WT2
          ? notifyTypeMapSF03WT2[data] || string.NOTI_NORMAL
          : notifyTypeMapSF04[data] || string.NOTI_NORMAL}
      </Badge>
    </div>
  );
};

export default Sensor;
