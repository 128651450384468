import './App.scss';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useLocation,
  useHistory,
  Link,
	//useSearchParams,
} from 'react-router-dom';
// import Home from 'page/Home';
import { Container, Navbar } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import Paths from 'util/paths';
import Menu from 'component/Menu';
import Header from 'component/Header';
import Footer from 'component/Footer';
import Routes from 'util/routes';
import Login from 'page/user/Login';
import Loading from 'component/Loading';
import ToastContainer from 'component/ToastContainer';
import Confirm from 'component/Confirm';
import { useEffect, useState } from 'react';
import { nNU, reSignIn, signOut } from 'util/utils';
import string from 'util/string';
import ForgetPassword from 'page/user/ForgetPassword';
import history from 'util/history';
import { ACCOUNT_ROLE_ADMIN } from 'util/permission';

const App = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isLoggedIn = !!currentUser;
  const queryParams = new URLSearchParams(window.location.search);
  const [deviceId, setDeviceId] = useState();

	useEffect(()=> {
		let id = queryParams.get("id");
		setDeviceId(id);
		/* eslint-disable react-hooks/exhaustive-deps */
	},[]);

  // console.log(currentUser);
  const url = useHistory();
  useEffect(() => {
    // Set first zoom: 17
    if (!nNU(localStorage.getItem('Map'))) localStorage.setItem('Map', 17);
    let data = { min: 0, max: 100 };
    // Set range Humidity: 0-100%
    if (!nNU(localStorage.getItem(string.HUMIDITY)))
      localStorage.setItem(string.HUMIDITY, JSON.stringify(data));
    data = { min: 0, max: 50 };
    // Set range Temperate: 0-100
    if (!nNU(localStorage.getItem(string.TEMPERATURE)))
      localStorage.setItem(string.TEMPERATURE, JSON.stringify(data));
    if (!nNU(localStorage.getItem(string.LIGHT_TEMPERATURE)))
      localStorage.setItem(string.LIGHT_TEMPERATURE, JSON.stringify(data));
    if (!nNU(localStorage.getItem(string.WBGT_TEMPERATURE)))
      localStorage.setItem(string.WBGT_TEMPERATURE, JSON.stringify(data));
  }, []);
  const onStorageUpdate = (e) => {
    const { key, newValue } = e;
    if (key === 'persist:root') {
      const newValue1 = JSON.parse(newValue);
      const user = JSON.parse(newValue1.user);
      if(nNU(user.currentUser)){
				reSignIn(user.currentUser);
			}
			else{
				signOut();
				//setDeviceId(null);
			}
      // setName(newValue);
    }
  };
  useEffect(() => {
    // setName(localStorage.getItem('name') || '');
    window.addEventListener('storage', onStorageUpdate);
    return () => {
      window.removeEventListener('storage', onStorageUpdate);
    };
  }, []);
  return (
    <BrowserRouter>
      <Loading />
      <Confirm />
      <Switch>
        <Route exact path={Paths.user.forgetPassword}>
          <ToastContainer />
          <ForgetPassword />
        </Route>
        <Route exact path={Paths.user.login}>
          {isLoggedIn ? <Redirect to={Paths.device.status} /> : <Login />}
        </Route>
        <Route path="/">
          <Container id="App" className="container-fluid">
            <ToastContainer />
            {!isLoggedIn ? (
              <Login />
            ) : (
              <>
                <Header />
                <Container id="body" className="container-fluid">
                  <Navbar className="vertical-navs">
                    <Menu />
                  </Navbar>
                  <div id="main">
                    <Switch>
                      {currentUser.accountRole === ACCOUNT_ROLE_ADMIN ? (
												!nNU(deviceId) ? (
													<Route exact path="/">
														<Redirect to={Paths.device.statusWBGT} />
													</Route>
												) : (
													<Route exact path="/">
														<Redirect to={`${Paths.device.detail}/?id=${deviceId}`} />
													</Route>
												)
                      ) : (
												!nNU(deviceId) ? (
													<Route exact path="/">
														<Redirect to={Paths.device.status} />
													</Route>
												) : (
													<Route exact path="/">
														<Redirect to={`${Paths.device.detail}/?id=${deviceId}`} />
													</Route>
												)
                        // <Route exact path="/">
                        //   <Redirect to={Paths.device.status} />
                        // </Route>
                      )}

                      {Routes.map((props, index) => (
                        <Route key={index} exact {...props} />
                      ))}
                    </Switch>
                  </div>
                </Container>
                <Footer />
              </>
            )}
          </Container>
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
