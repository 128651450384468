import * as React from "react"

const DkSf03wtFlood = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      {...props}
    >
      <path
        d="M45.01 50.09h-40c-2.76 0-5-2.24-5-5v-40c0-2.76 2.24-5 5-5h40c2.76 0 5 2.24 5 5v40c0 2.76-2.24 5-5 5z"
        fill="#01a0c6"
      />
      <path d="M45.01 2.92c1.19 0 2.17.97 2.17 2.17v40c0 1.19-.97 2.17-2.17 2.17h-40c-1.19 0-2.17-.97-2.17-2.17v-40c0-1.19.97-2.17 2.17-2.17h40m0-2.83h-40c-2.76 0-5 2.24-5 5v40c0 2.76 2.24 5 5 5h40c2.76 0 5-2.24 5-5v-40c0-2.76-2.24-5-5-5z" />
      <path d="M37.53 24.64a2.78 2.78 0 010 5.56h-1.67v12.9c0 .5-.41.9-.9.9h-19.9c-.5 0-.9-.41-.9-.9V30.2h-1.67c-1.54 0-2.78-1.24-2.78-2.78s1.24-2.78 2.78-2.78h1.67v-2.35c0-.5.4-.9.9-.9h7.46V16.4L23.19 6h3.62l.68 10.4v4.98h7.46c.5 0 .9.41.9.9v2.35h1.68v.01zm.68 2.78c0-.38-.3-.68-.68-.68s-.68.3-.68.68c0 .38.3.68.68.68s.68-.3.68-.68zm-25.06 0c0-.38-.3-.68-.68-.68s-.68.3-.68.68c0 .38.3.68.68.68.38 0 .68-.3.68-.68z" />
      <g>
        <path
          fill="none"
          stroke="#000"
          strokeWidth={2.126}
          strokeMiterlimit={10}
          d="M25.01 6L25.01 1.54"
        />
      </g>
    </svg>
  )
}

export default DkSf03wtFlood;
