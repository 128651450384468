import { useEffect } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { MdFace, MdVpnKey } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { signInStart } from 'redux/user/user.action';
import Paths from 'util/paths';
import string from 'util/string';
import { focusElement, getFormData, showToast } from 'util/utils';
import './Login.scss';

const LoginText = ({ type = 'text', id, placeholder, Icon }) => {
  const props = { id, name: id, type };
  if (placeholder) {
    props['placeholder'] = placeholder;
  }

  return (
    <InputGroup>
      {!!Icon && (
        <InputGroup.Text>
          <Icon />
        </InputGroup.Text>
      )}
      <Form.Control {...props} />
    </InputGroup>
  );
};

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    // history.push(Urls.user.login);
    let loginTimeout = setTimeout(() => {
      history.push(Paths.root);
    }, 100);
    return () => {
      if (loginTimeout) {
        clearTimeout(loginTimeout);
      }
    };
  }, []);

  const onSubmit = async (e) => {
    // console.log(e);
    e.preventDefault();
    e.stopPropagation();
    // console.log(getFormData(e.target));
    let formData = getFormData(e.target, {
      rawCol: {
        loginId: true,
        password: true,
      },
    });
    if (!formData?.username) {
      showToast(string.LOGIN_ID_EMPTY_ERR);
      focusElement('#username');
      return;
    }
    if (!formData?.password) {
      showToast(string.PASSWORD_EMPTY_ERR);
      focusElement('#password');
      return;
    }
    await dispatch(signInStart(formData));
    history.push(Paths.root);
    return false;
  };

  return (
    <>
      <div className="screen-login">
        <div className="wrapper fadeInDown">
          <div id="formContent">
            <h2 className="active">{string.TITLE}</h2>

            <div className="card-body">
              <Form onSubmit={onSubmit}>
                <LoginText
                  id="username"
                  Icon={MdFace}
                  placeholder={string.LOGIN_ID}
                />
                <LoginText
                  id="password"
                  Icon={MdVpnKey}
                  type="password"
                  placeholder={string.PASSWORD}
                />
                <Button type="submit">{string.LOG_IN}</Button>{' '}
                <div id="formFooter">
                  <a
                    className="underlineHover"
                    href={Paths.user.forgetPassword}
                  >
                    {string.FORGET_PASSWORD}
                  </a>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
