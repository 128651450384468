const hazardTiles = [
	{
			name: '洪水浸水想定区域（想定最大規模）',
			url: 'https://disaportaldata.gsi.go.jp/raster/01_flood_l2_shinsuishin_data/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '洪水浸水想定区域（計画規模（現在の凡例））',
			url: 'https://disaportaldata.gsi.go.jp/raster/01_flood_l1_shinsuishin_newlegend_data/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '浸水継続時間（想定最大規模）',
			url: 'https://disaportaldata.gsi.go.jp/raster/01_flood_l2_keizoku_data/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '家屋倒壊等氾濫想定区域（氾濫流）',
			url: 'https://disaportaldata.gsi.go.jp/raster/01_flood_l2_kaokutoukai_hanran_data/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '家屋倒壊等氾濫想定区域（河岸侵食）',
			url: 'https://disaportaldata.gsi.go.jp/raster/01_flood_l2_kaokutoukai_kagan_data/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '高潮浸水想定区域',
			url: 'https://disaportaldata.gsi.go.jp/raster/03_hightide_l2_shinsuishin_data/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '津波浸水想定',
			url: 'https://disaportaldata.gsi.go.jp/raster/04_tsunami_newlegend_data/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '土砂災害警戒区域（土石流）',
			url: 'https://disaportaldata.gsi.go.jp/raster/05_dosekiryukeikaikuiki/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '土砂災害警戒区域（急傾斜地の崩壊）',
			url: 'https://disaportaldata.gsi.go.jp/raster/05_kyukeishakeikaikuiki/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '土砂災害警戒区域（地すべり）',
			url: 'https://disaportaldata.gsi.go.jp/raster/05_jisuberikeikaikuiki/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '土石流危険渓流',
			url: 'https://disaportaldata.gsi.go.jp/raster/05_dosekiryukikenkeiryu/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '急傾斜地崩壊危険箇所',
			url: 'https://disaportaldata.gsi.go.jp/raster/05_kyukeisyachihoukai/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '地すべり危険箇所',
			url: 'https://disaportaldata.gsi.go.jp/raster/05_jisuberikikenkasyo/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
	{
			name: '雪崩危険箇所',
			url: 'https://disaportaldata.gsi.go.jp/raster/05_nadarekikenkasyo/{z}/{x}/{y}.png',
			attribution: '<a href="https://disaportal.gsi.go.jp/hazardmap/copyright/opendata.html">出典：ハザードマップポータルサイトへ</a>',
			checked: false,
	},
];
export default hazardTiles;