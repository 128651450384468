import * as React from "react"

const DkSf03wt2FloodFullLowBattery = (props) => {
  return (
    <svg
      id="\u30EC\u30A4\u30E4\u30FC_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <g id="\u901A\u5E38\u6D78\u6C34\u80CC\u666F_22">
        <path
          d="M45 50H5c-2.76 0-5-2.24-5-5V5c0-2.76 2.24-5 5-5h40c2.76 0 5 2.24 5 5v40c0 2.76-2.24 5-5 5z"
          fill="#01a0c6"
        />
        <path d="M45.01 2.83c1.19 0 2.17.97 2.17 2.17v40c0 1.19-.97 2.17-2.17 2.17h-40c-1.19 0-2.17-.97-2.17-2.17V5c0-1.19.97-2.17 2.17-2.17h40m0-2.83h-40c-2.76 0-5 2.24-5 5v40c0 2.76 2.24 5 5 5h40c2.76 0 5-2.24 5-5V5c0-2.76-2.24-5-5-5z" />
      </g>
      <g id="\u30EC\u30A4\u30E4\u30FC_22_12">
        <path d="M36.11 35.17h2.69c.66 0 1.2-.54 1.2-1.2v-2.4c0-.66-.54-1.2-1.2-1.2h-2.69c-.66 0-1.2.54-1.2 1.2v2.4c0 .66.54 1.2 1.2 1.2z" />
        <path
          d="M34.68 39.64h-22.2c-.99 0-1.8-.81-1.8-1.8V27.69c0-.99.81-1.8 1.8-1.8h22.2c.99 0 1.8.81 1.8 1.8v10.14c0 1-.81 1.81-1.8 1.81z"
          fill="#fff"
          stroke="#000"
          strokeWidth={1.2756}
          strokeMiterlimit={10}
        />
        <defs>
          <path id="SVGID_22_1_" d="M10 23.57H15.57V41.86H10z" />
        </defs>
        <clipPath id="SVGID_22_00000014624438645211722540000017645708374221658523_">
          <use xlinkHref="#SVGID_22_1_" overflow="visible" />
        </clipPath>
        <path
          d="M34.68 39.64h-22.2c-.99 0-1.8-.81-1.8-1.8V27.69c0-.99.81-1.8 1.8-1.8h22.2c.99 0 1.8.81 1.8 1.8v10.14c0 1-.81 1.81-1.8 1.81z"
          clipPath="url(#SVGID_22_00000014624438645211722540000017645708374221658523_)"
          fill="#fd1a16"
          stroke="#000"
          strokeWidth={1.2756}
          strokeMiterlimit={10}
        />
        <path
          d="M25.13 19.82l-7.96 13.79c-.23.4.06.9.52.9h15.92c.46 0 .75-.5.52-.9l-7.96-13.79a.6.6 0 00-1.04 0z"
          fill="#ffff01"
          stroke="#000"
          strokeWidth={0.8534}
          strokeMiterlimit={10}
        />
        <g>
          <path d="M24.78 32.72c0-.55.37-.94.87-.94.52 0 .86.4.86.94 0 .52-.34.91-.87.91-.55.01-.86-.42-.86-.91zm1.34-2.39h-.97l-.21-8.1h1.39l-.21 8.1z" />
        </g>
      </g>
    </svg>
  )
}

export default DkSf03wt2FloodFullLowBattery;
