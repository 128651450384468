import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { appHideConfirm } from 'redux/app/app.action';
import { selectConfirm } from 'redux/app/app.selector';
import string from 'util/string';
import Modal from './Modal';

const Confirm = () => {
  const confirm = useSelector(selectConfirm);

  const dispatch = useDispatch();

  const {
    title = string.CONFIRM,
    body = string.ARE_YOU_SURE,
    size = 'md',
    defaultButton = 'OK',
    defaultAction,
    cancelButton = 'Cancel',
    cancelAction = () => {
      dispatch(appHideConfirm());
    },
    customButton,
  } = confirm || {};

  return (
    <Form>
      <Modal
        size={size}
        show={!!confirm}
        onHide={cancelAction}
        header={<h5>{title}</h5>}
        closeButton={false}
        body={body}
        footer={
          <>
            {customButton}
            {!!cancelButton && (
              <Button variant="secondary" onClick={cancelAction}>
                {cancelButton}
              </Button>
            )}
            <Button
              onClick={() => {
                let rs = true;
                if (defaultAction) {
                  rs = defaultAction();
                }
                if (rs !== false) {
                  dispatch(appHideConfirm());
                }
              }}
            >
              {defaultButton}
            </Button>
          </>
        }
      />
    </Form>
  );
};

export default Confirm;
