import UserAcitonTypes from './user.types';

export const signInStart = (payload) => ({
  type: UserAcitonTypes.SIGN_IN_START,
  payload,
});

export const signInSuccess = (payload) => ({
  type: UserAcitonTypes.SIGN_IN_SUCCESS,
  payload,
});

export const signInFailure = () => ({
  type: UserAcitonTypes.SIGN_IN_FAILURE,
});

export const signOut = () => ({
  type: UserAcitonTypes.SIGN_OUT,
});
