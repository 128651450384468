import './Loading.scss';

const Loading = () => {
  return (
    <>
      <div className="_loading">
        <div className="loading-background"></div>
        <div className="loading-container">
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
          <span className="circle"></span>
        </div>
      </div>
    </>
  );
};

export default Loading;
