/* eslint-disable react-hooks/exhaustive-deps */
import Battery from 'component/Battery';
import DeviceMarker from 'component/DeviceMarker';
import Map from 'component/Map2';
import Page from 'component/Page';
import Sensor from 'component/Sensor';
import Signal from 'component/Signal';
import State from 'component/State';
import GetIcon from 'component/Icon';
import GetDkSf03Wt2State from 'component/DkSf03wt2State';
import GetDkSF07State from 'component/DkSf07State';
import Slideshow from 'component/Slideshow';
import Table, { TablePaggingOption } from 'component/Table';
// import Player from 'component/Player';
import { get } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row, Spinner, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import {
  ACCOUNT_ROLE_ADMIN,
  ACCOUNT_ROLE_CHILD,
  ACCOUNT_ROLE_PARENT,
} from 'util/permission';
import { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import {
	DD_LOCATION_FORMAT_REGEX,
	formatDate,
	durationFromNow,
	nNU,
} from 'util/utils';
import { useHistory, useLocation } from 'react-router-dom';
import Paths from 'util/paths';
import axios from 'axios';
import { useMemo } from 'react';
import useSound from 'use-sound';
import alertSound from 'sounds/alert-1-86270.mp3';
// console.log(alertSound);

//import SimpleMap from 'component/GoogleMap';
const GET_LOG_INTERVAL = 5 * 1000;
const ICON_BLINK_INTERVAL = 20;

const DeviceStatus2 = () => {
	//const platform = window.navigator.platform;
	//const userAgent = window.navigator.userAgent;
	//console.log(platform);
	//console.log(userAgent);

  const currentUser = useSelector(selectCurrentUser);
  const location = useLocation();
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchId, setSearchId] = useState('');
	const [blinkCountdown, setBlinkCountdown] = useState([]);
	// const [blinkOffTable, setBlinkOffTable] = useState([]);
	const [blinkOff, setBlinkOff] = useState(false);
	const [play] = useSound(alertSound);
  const [selectedMarker, setSelectedMarker] = useState(null);

  const deviceModel = useMemo(() => {
    if (location.pathname === Paths.device.statusSF07) {
      return string.DEVICE_SF07;
    } else if (location.pathname === Paths.device.statusWBGT) {
      return string.DEVICE_WBGT;
    } else if (location.pathname === Paths.device.statusGPS) {
      return string.DEVICE_SFGPS;
    } else if (location.pathname === Paths.device.statusSF03WT2) {
      return string.DEVICE_SF03WT2;
    } else if (location.pathname === Paths.device.statusSF03WT) {
      return string.DEVICE_SF03WT;
    } else if (location.pathname === Paths.device.statusXE1151) {
      return string.DEVICE_XE1151;
    } else {
      return string.DEVICE_WBGT;
    }
  }, [location]);
   //console.log(deviceModel);

  const title = string.DEVICE_INFO;
  const headers = [
    //DK_SF04 - DK_SF03
    [
      string.DEVICE_NAME,
      string.DEVICE_SIGFOX_ID,
      string.DEVICE_STATUS,
      string.DEVICE_TEMPERATURE,
      string.DEVICE_BATTERY,
      string.DEVICE_SIGNAL_STRENGTH,
    ],
    //DK_SFGPS
    [
      string.DEVICE_NAME,
      string.DEVICE_SIGFOX_ID,
      string.DEVICE_BATTERY,
      string.DEVICE_SIGNAL_STRENGTH,
    ],
    //DK_WBGT
    [
      string.DEVICE_STATE,
      string.DEVICE_WBGT_DATA_TITLE,
      string.DEVICE_NAME,
      string.DEVICE_SIGFOX_ID,
      string.DEVICE_BATTERY,
      string.DEVICE_SIGNAL_STRENGTH,
    ],
    //DK_SF03WT
    [
      string.DEVICE_NAME,
      string.DEVICE_SIGFOX_ID,
      string.DEVICE_STATUS,
      string.DEVICE_BATTERY,
      string.DEVICE_SIGNAL_STRENGTH,
    ],
    //DK_SF03WT2
    [
      string.DEVICE_NAME,
      string.DEVICE_SIGFOX_ID,
      string.DEVICE_STATUS,
      string.DEVICE_BATTERY,
      string.DEVICE_SIGNAL_STRENGTH,
    ],
		//XE1151
    [
      string.DEVICE_NAME,
      string.DEVICE_STATUS,
      string.DEVICE_SIGFOX_ID,
      string.DEVICE_BATTERY,
      string.DEVICE_SIGNAL_STRENGTH,
    ],
    //DK-SF03WT2 VER2
    [
      string.DEVICE_NAME,
      string.DEVICE_STATUS,
      string.DEVICE_STATUS_DETAIL,
      string.DEVICE_SIGFOX_ID,
      string.DEVICE_BATTERY,
      string.DEVICE_SIGNAL_STRENGTH,
    ],
    //DK-SF07
    [
      string.DEVICE_NAME,
      string.DEVICE_STATUS,
      string.DEVICE_STATUS_DETAIL,
      string.DEVICE_SIGFOX_ID,
      string.DEVICE_BATTERY,
      string.DEVICE_SIGNAL_STRENGTH,
    ],
  ];
  const [devicesLogData, setDevicesLogData] = useState([]);
  const [state, setState] = useState({});
  // const [threshold, setThreshold] = useState({});

  const notifyState = (data, threshold) => {
    let notifyState;
    if (data > threshold[0]) notifyState = 'danger';
    else if (data > threshold[1]) notifyState = 'caution';
    else if (data > threshold[2]) notifyState = 'vigilance';
    else if (data > threshold[3]) notifyState = 'warning';
    else notifyState = 'normal';
    return notifyState;
  };

  const getMapData = useCallback(
    (devicesLogData) =>
      devicesLogData.map((e, i) => {
        let computedLocation;
        try {
          computedLocation = JSON.parse(e.computedLocation);
          // deviceLocation = JSON.parse(e.deviceLocation);
        } catch (e) {}
        // console.log(computedLocation);
        const lat = computedLocation?.Lat || computedLocation?.lat;
        const lng = computedLocation?.Lng || computedLocation?.lng;

        let location = nNU(computedLocation)
          ? DD_LOCATION_FORMAT_REGEX.test(`${lat},${lng}`)
            ? [lat, lng]
            : null
          : // : nNU(deviceLocation)
            // ? [deviceLocation.Lat, deviceLocation.Lng]
            null;
        return {
          ...e,
          location,
        };
      }),
    []
  );
  // console.log(getMapData(devicesLogData));
	const getMarkerBlink = (logData) => {
		if(!nNU(dksf03wt2State) || !nNU(logData[0])){
			return null;
		}
		let countdown = [];
		if(logData[0]?.deviceId !== dksf03wt2State[0]?.deviceId){
			for(let i = 0; i < logData.length; i++){
				countdown = [...countdown, 0];
			}
		}
		else{
			for(let i = 0; i < logData.length; i++){
				let duration = 100;
				if(nNU(logData[i]?.dataLogTime)){
					let logDate = new Date(formatDate(logData[i]?.dataLogTime,"YYYY-MM-DDTHH:mm:ss"));
					duration = durationFromNow(logDate);
					// console.log('Duration: ', duration);
				}
				let dipSwitch =
					nNU(logData[i]?.dipSwitch) ? parseInt(`0${logData[i]?.dipSwitch}`):0;
					//nNU(logData[i]?.dipSwitch) ? parseInt(dip):0;
				let timeout = (duration > 1) && ((dipSwitch & parseInt('0x0008')) > 0) ? 1:0;
				let singleSensor =
					(dipSwitch & parseInt('0x0010')) > 0 ? 1:0;
				// let deviceType =
				// 	singleSensor > 0 ? string.DEVICE_SF03WT:string.DEVICE_SF03WT2;
				let flood =
					nNU(logData[i]?.notifyType) && (logData[i]?.notifyType === '17') ? 0 :
						nNU(logData[i]?.dataType1) ?
							singleSensor > 0 ? parseInt(logData[i]?.dataType1) % 2: parseInt(logData[i]?.dataType1) % 4:0;
				let tilt =
					nNU(logData[i]?.dataType3) && (logData[i]?.dataType3 === '1') ? 1:0;
				let batteryLow =
					nNU(logData[i]?.batteryVoltage) && parseFloat(logData[i]?.batteryVoltage) > 2.4 ? 0:1;
				let dataValid =
					nNU(logData[i]?.notifyType) && nNU(logData[i]?.dataType1) ? true:false;
				let change = false;
				if(dataValid && nNU(dksf03wt2State[i])){
					if(tilt !== dksf03wt2State[i]?.tilt){
						change = true;
					}
					else if(flood !== dksf03wt2State[i]?.flood){
						change = true;
					}
					else if(batteryLow !== dksf03wt2State[i]?.battery){
						change = true;
					}
				}
				let blink = change ? ICON_BLINK_INTERVAL:0;
				countdown = [...countdown, blink];
				if(change){
					// play();
				}
			}
		}
		return countdown;
	};

	const getMarkerBlink2 = (logData) => {
		if(!nNU(dksf07State) || !nNU(logData[0])){
			return null;
		}
		let countdown = [];
		if(logData[0]?.deviceId !== dksf07State[0]?.deviceId){
			for(let i = 0; i < logData.length; i++){
				countdown = [...countdown, 0];
			}
		}
		else{
			for(let i = 0; i < logData.length; i++){
				let tilt =
					nNU(logData[i]?.dataType7) && (logData[i]?.dataType7.toUpperCase()  === '00') ? 1:0;
				let change = false;
				if(nNU(dksf07State[i])){
					if(tilt !== dksf07State[i]?.tilt){
						change = true;
					}
				}
				let blink = change ? ICON_BLINK_INTERVAL:0;
				countdown = [...countdown, blink];
				if(change){
					// play();
				}
			}
		}
		return countdown;
	};

	const dataTable = useCallback(
    (devicesLogData) =>
      devicesLogData.map((raw, key) => {
        // console.log(raw);
				// console.log('key: ', key);
        let computedLocation;
        try {
          computedLocation = JSON.parse(raw.computedLocation);
          // deviceLocation = JSON.parse(raw.deviceLocation);
        } catch (e) {}
        let location = nNU(computedLocation)
          ? [computedLocation?.Lat, computedLocation?.Lng]
          : // : nNU(deviceLocation)
            // ? [deviceLocation.Lat, deviceLocation.Lng]
            null;
        switch (raw.model) {
          case string.DEVICE_XE1151:
            return {
              key,
              data: [
                raw.assetName,
                <Sensor data={raw?.notifyType} model={raw.model} />,
                raw?.sigfoxId,
                <Battery data={raw?.batteryVoltage} />,
                <Signal data={raw?.lqi} />,
              ],
              raw: {
                ...raw,
                location,
              },
              id: raw.deviceId,
            };
          case string.DEVICE_SF04:
            return {
              key,
              data: [
                raw.assetName,
                raw?.sigfoxId,
                <Sensor data={raw?.notifyType} model={raw.model} />,
                !raw?.temperature ? '' : `${raw?.temperature}℃`,
                <Battery data={raw?.batteryVoltage} />,
                <Signal data={raw?.lqi} />,
              ],
              raw: {
                ...raw,
                location,
              },
              id: raw.deviceId,
            };
          case string.DEVICE_SF03WT:
            return {
              key,
              data: [
                raw.assetName,
                raw?.sigfoxId,
                <Sensor data={raw?.notifyType} model={raw?.model} />,
                <Battery data={raw?.batteryVoltage} />,
                <Signal data={raw?.lqi} />,
              ],
              raw: {
                ...raw,
                location,
              },
              id: raw.deviceId,
            };
          case string.DEVICE_SF03WT2:
						let duration = 100;
						if(nNU(raw?.dataLogTime)){
							let logDate = new Date(formatDate(raw?.dataLogTime,"YYYY-MM-DDTHH:mm:ss"));
							duration = durationFromNow(logDate);
							// console.log('Duration: ', duration);
						}
						let dipSwitch =
              nNU(raw?.dipSwitch) ? parseInt(`0x${raw?.dipSwitch}`):0;
						let timeout = (duration > 1) && ((dipSwitch & parseInt('0x0008')) > 0) ? 1:0;
							let singleSensor =
              (dipSwitch & parseInt('0x0010')) > 0 ? 1:0;
            let deviceType =
              singleSensor > 0 ? string.DEVICE_SF03WT:string.DEVICE_SF03WT2;
            let flood =
							nNU(raw?.notifyType) && (raw?.notifyType === '17') ? 0 :
              	nNU(raw?.dataType1) ?
              		singleSensor > 0 ? parseInt(raw?.dataType1) % 2: parseInt(raw?.dataType1) % 4:0;
            let tilt =
              nNU(raw?.dataType3) && (raw?.dataType3 === '1') ? 1:0;
            let batteryLow =
              nNU(raw?.batteryVoltage) && parseFloat(raw?.batteryVoltage) > 2.4 ? 0:1;
						let change = false;
						let dataValid =
							nNU(raw?.notifyType) && nNU(raw?.dataType1) ? true:false;
						if(dataValid && nNU(dksf03wt2State[key])){
							if(raw?.deviceId === dksf03wt2State[key]?.deviceId){
								if(tilt !== dksf03wt2State[key]?.tilt){
									change = true;
								}
								else if(flood !== dksf03wt2State[key]?.flood){
									change = true;
								}
								else if(batteryLow !== dksf03wt2State[key]?.battery){
									change = true;
								}
							}
						}
						let iconOff = false;
						if(dataValid){
							if(nNU(blinkOff) && nNU(blinkCountdown) && nNU(blinkCountdown[key]) && (blinkCountdown[key] > 0)){
								iconOff = blinkOff;
								// console.log('Key: ', key);
								// console.log('Icon Off: ', iconOff);
							}
						}

            return {
              key,
              data: [
                raw.assetName,
                dataValid ? (<GetIcon
                  deviceType={deviceType}
                  timeout={timeout}
                  flood={flood}
                  tilt={tilt}
                  batteryLow={batteryLow}
									// blinkInterval={blink}
									blinkOff={iconOff}
                />):(<Signal />),
                //<Sensor data={raw?.notifyType} model={raw.model} />,
                dataValid ? (<GetDkSf03Wt2State
                  timeout={timeout}
                  singleSensor={singleSensor}
                  flood={flood}
                  tilt={tilt}
                  battery={batteryLow}
                />):(<Signal />),
                raw?.sigfoxId,
                dataValid ? (<Battery data={raw?.batteryVoltage} />):(<Signal />),
                dataValid ? (<Signal data={raw?.lqi} />):(<Signal />),
              ],
              raw: {
                ...raw,
                location,
              },
              id: raw.deviceId,
            };
          case string.DEVICE_SFGPS:
            return {
              key,
              data: [
                raw.assetName,
                raw?.sigfoxId,
                <Battery data={raw?.batteryVoltage} />,
                <Signal data={raw?.lqi} />,
              ],
              raw: {
                ...raw,
                location,
              },
              id: raw.deviceId,
            };
          case string.DEVICE_WBGT:
            return {
              key,
              id: raw.deviceId,
              data: [
                !raw?.wbgt ? (
                  <Signal />
                ) : (
                  <State className={state?.[raw.deviceId]} />
                ),
                !raw.wbgt ? <Signal /> : `${raw?.wbgt}℃`,
                raw.assetName,
                raw?.sigfoxId,

                !raw?.batteryVoltage ? (
                  <Signal />
                ) : (
                  <Battery data={raw?.batteryVoltage} />
                ),
                <Signal data={raw?.lqi} />,
              ],
              raw: {
                ...raw,
                location,
              },
            };
					case string.DEVICE_SF07:
            let sf07tilt =
              nNU(raw?.dataType7) && (raw?.dataType7.toUpperCase() === '00') ? 1:0;
							if(nNU(blinkOff) && nNU(blinkCountdown) && nNU(blinkCountdown[key]) && (blinkCountdown[key] > 0)){
								iconOff = blinkOff;
								// console.log('Key: ', key);
								// console.log('Icon Off: ', iconOff);
							}
						return {
							key,
							data:[
								raw.assetName,
								<GetIcon
                  deviceType={string.DEVICE_SF07}
                  tilt={sf07tilt}
                  // batteryLow={batteryLow}
									// blinkInterval={blink}
									blinkOff={iconOff}
                />,
								<GetDkSF07State
                  tilt={sf07tilt}
                />,
								raw?.sigfoxId,
								<Battery data={raw?.batteryVoltage} />,
								<Signal data={raw?.lqi} />
							],
              raw: {
                ...raw,
                location,
              },
              id: raw.deviceId,
						};
          default:
            return {
              key,
              data: [
                raw.assetName,
                raw?.sigfoxId,
                <Sensor data={raw?.notifyType} model={raw.model} />,
                !raw?.temperature ? '' : `${raw?.temperature}℃`,
                <Battery data={raw?.battery} />,
                <Signal data={raw?.lqi} />,
              ],
              raw: {
                ...raw,
                location,
              },
            };
        }
      }),
    [devicesLogData, state, blinkOff, blinkCountdown]
  );

  const history = useHistory();
  const infoAction = ({ id }) =>
    history.push(`${Paths.device.detail}/?id=${id}`);
  const mainActions = [];
  const actions = useCallback([
    ({ raw }) => {
      if (!raw?.location) {
        return null;
      }
			if(raw?.model === string.DEVICE_SF03WT2){
				if(!nNU(raw?.notifyType) || !nNU(raw?.dataType1)){
					return null;
				}
			}
			if(raw?.model === string.DEVICE_SFGPS){
				if(currentUser.accountRole !== ACCOUNT_ROLE_CHILD){
					return {
						render: 'history',
						onClick: infoAction
					}
				}
				else{
					return {
						render: 'info',
						onClick: infoAction
					}
				}
			}
      return {
        render: 'map',
        className: `_map-marker ${
          model === string.DEVICE_WBGT ? state?.[raw.deviceId] || 'normal' : ''
        }`,
        onClick: ({ rowIndex }) => {
          setSelectedMarker(rowIndex);
          // resolve reselect marker
          setTimeout(() => setSelectedMarker(null), 0);

					// console.log('Re-center');
        },
      };
    },
  ]);
  const onSelect = useCallback((e) => {
		// console.log(e);
    let { raw, rowIndex } = e;
		// console.log(raw);
		if (!raw?.location) {
			return null;
		}
		if(raw?.model === string.DEVICE_SF03WT2){
			if(!nNU(raw?.notifyType) || !nNU(raw?.dataType1)){
				return null;
			}
		}
		setSelectedMarker(rowIndex);
		// resolve reselect marker
		setTimeout(() => setSelectedMarker(null), 0);
	});

  const makeState = useCallback(() => {
    if (devicesLogData[0]?.model === string.DEVICE_WBGT) {
      let newState = state;
      devicesLogData.forEach((d, index) => {
        let newThreshold = [31, 28, 25, 21];
        newState[d.deviceId] = notifyState(d?.wbgt, newThreshold);
      });

      setState(newState);
    }
  });
  // console.log(devicesLogData);
  const [showLoading, setShowLoading] = useState(false);

  const getDevicesLogData = useCallback(() => {
    if (window?.['gettingDevicesLog'] === true) {
      return;
    }
		// if(nNU(blinkCountdown)){
		// 	let totalCount = 0;
		// 	for(let i = 0; i < blinkCountdown.length; i++){
		// 		totalCount = totalCount + blinkCountdown[i];
		// 	}
		// 	if(totalCount > 0){
		// 		console.log('Not get device log');
		// 		return;
		// 	}
		// }

    setShowLoading(true);
    window['gettingDevicesLog'] = true;
    sendPost({
      url:
        currentUser.accountRole === ACCOUNT_ROLE_ADMIN
          ? `${Urls.device.listAdvancedLog}`
          : `${Urls.device.listOfDevicesContract}/${currentUser.accountId}`,
      body: {
        offset: (page - 1) * pageSize,
        size: pageSize,
        keyword: searchId.trim(),
        deviceModel: deviceModel,
      },
      background: true,
    })
      .then((response) => {
        const { total, result } = response;
				setDevicesLogData(result);
        setTotal(total);
      })
      .finally(() => {
        window['gettingDevicesLog'] = false;
        setShowLoading(false);
      });
  }, [page, pageSize, searchId]);

	// Update status of DKSF03WT2
	const [dksf03wt2State, setDksf03wt2State] = useState([]);
	const updateDksf03wt2State = (logData) => {
		// console.log('Render');

		let newDksf03wt2State = [];
		for(let i = 0; i < logData.length; i++){
			let duration = 100;
			if(nNU(logData[i]?.dataLogTime)){
				let logDate = new Date(formatDate(logData[i]?.dataLogTime,"YYYY-MM-DDTHH:mm:ss"));
				duration = durationFromNow(logDate);
				// console.log('Duration: ', duration);
			}
			let dipSwitch =
				nNU(logData[i]?.dipSwitch) ? parseInt(`0${logData[i]?.dipSwitch}`):0;
				//nNU(logData[i]?.dipSwitch) ? parseInt(dip):0;
			let timeout = (duration > 1) && ((dipSwitch & parseInt('0x0008')) > 0) ? 1:0;
			let singleSensor =
				(dipSwitch & parseInt('0x0010')) > 0 ? 1:0;
			// let deviceType =
			// 	singleSensor > 0 ? string.DEVICE_SF03WT:string.DEVICE_SF03WT2;
			let flood =
				nNU(logData[i]?.notifyType) && (logData[i]?.notifyType === '17') ? 0 :
					nNU(logData[i]?.dataType1) ?
						singleSensor > 0 ? parseInt(logData[i]?.dataType1) % 2: parseInt(logData[i]?.dataType1) % 4:0;
			let tilt =
				nNU(logData[i]?.dataType3) && (logData[i]?.dataType3 === '1') ? 1:0;
			let batteryLow =
				nNU(logData[i]?.batteryVoltage) && parseFloat(logData[i]?.batteryVoltage) > 2.4 ? 0:1;
			let dataValid =
				nNU(logData[i]?.notifyType) && nNU(logData[i]?.dataType1) ? true:false;
			let newState = {
				deviceId: logData[i]?.deviceId,
				timeout: timeout,
				dipSwitch: dipSwitch,
				type: singleSensor,
				flood: flood,
				tilt: tilt,
				battery: batteryLow,
				valid: dataValid
			};
			newDksf03wt2State = [...newDksf03wt2State, newState];
		}
		return newDksf03wt2State;
	};

	// Update status of DKSF07
	const [dksf07State, setDksf07State] = useState([]);
	const updateDksf07State = (logData) => {
		let newDksf07State = [];
		for(let i = 0; i < logData.length; i++){
			let transmissionMode =
				(nNU(logData[i].valid) && (logData[i].valid === true)) ? logData[i].notifyMode : null;
			let tilt =
				nNU(logData[i]?.dataType7) && (logData[i]?.dataType7.toUpperCase()  === '00') ? 1:0;
			let batteryLow =
				nNU(logData[i]?.batteryVoltage) && parseFloat(logData[i]?.batteryVoltage) > 2.4 ? 0:1;
			let newState = {
				deviceId: logData[i]?.deviceId,
				transmission: transmissionMode,
				tilt: tilt,
				battery: batteryLow,
			};
			newDksf07State = [...newDksf07State, newState];
		}
		return newDksf07State;
	};

  useEffect(() => {
		let getLogInterval3 = setInterval(getDevicesLogData,GET_LOG_INTERVAL);
		window['gettingDevicesLog'] = false;
		getDevicesLogData();
		return () => {
			clearInterval(getLogInterval3);
		};
  }, [page, pageSize, searchId]);

  const [model, setModel] = useState('');
  useEffect(() => {
    if (get(devicesLogData, '[0].model')) setModel(devicesLogData[0].model);
  }, [devicesLogData]);

  useEffect(() => {
    makeState();
  }, [devicesLogData]);
  const [zoom, setZoom] = useState();
  useEffect(() => {
    let data = JSON.parse(localStorage.getItem('Map'));
    if (!nNU(data)) return;
    setZoom(data);
  }, [devicesLogData]);

	useEffect(() => {
		// Update status of DKSF03WT2
		const newDksf03wt2State = updateDksf03wt2State(devicesLogData);
		setDksf03wt2State(newDksf03wt2State);

		// Update status of DKSF07
		const newDksf07State = updateDksf07State(devicesLogData);
		setDksf07State(newDksf07State);
	}, [devicesLogData]);

	useEffect(() => {
		let countdown = [];
		if(nNU(dksf03wt2State)){
			// countdown = getMarkerBlink(devicesLogData);
		}
		else if(nNU(dksf07State)){
			// countdown = getMarkerBlink2(devicesLogData);
		}
		countdown = getMarkerBlink2(devicesLogData);
		if(nNU(blinkCountdown) && nNU(countdown) && (blinkCountdown.length === countdown.length)){
			for(let i = 0; i < countdown.length; i++){
				if(countdown[i] < blinkCountdown[i]){
					countdown[i] = blinkCountdown[i];
				}
			}
		}
		setBlinkCountdown(countdown);
	}, [devicesLogData]);

	useEffect(() => {
		const timerId = setTimeout(() => {
			setBlinkOff(!blinkOff);
			if(nNU(blinkCountdown)){
				let countdown = blinkCountdown;
				let totalCount = 0;
				for(let i = 0; i < countdown.length; i++){
					if(countdown[i] > 0){
						countdown[i] = countdown[i] - 1;
					}
					totalCount = totalCount + countdown[i];
				}
				setBlinkCountdown(countdown);

				if(totalCount > 0){
					window['gettingDevicesLog'] = true;
				}
				else{
					window['gettingDevicesLog'] = false;
				}
				// console.log('Countdown: ', countdown);
			}

			// console.log('Blink Off: ', blinkOff);
			// console.log('BlinkOffTable: ', countdownTable);
			// if(nNU(countdown[1]) && (countdown[1] > 0)){
			// 	console.log('Countdown: ', countdown[1]);
			// }
		},500);
		return () => clearTimeout(timerId);
	}, [blinkOff, blinkCountdown]);

  // const handleTest = () => {
  //   const body = {
  //     id: '4pLjHRiw9OLuIchh1T6GoqTuUKtKFlNWYFHCqhUYukvSy1wQiD3fheknTtQYmahLXxCS0uPxeLDVrxcejMrlsTltQMzMMDKjAECvsLd7vUOnB_CZeqMD7gUe1Wt-pTZGmFzBYvTYQPbGaq4M3P63ALGtqR7u8HykGjgI5XXOcJ6XoUu5KBWPKBDnFMVhwQtlbzEt_fYuln18YzxOcMVLz2DomJg8T6lp_EM1dBS5T998cZQTzfJbhrv7Hn7W1uAH5hcL8lrQEA3CTTGQxMi8kTB3smZaDPsau5L-US6cv5COAJ0GxNaRmfH3cB7Zwx4KyEZHFZ4SwPs54zD6lbQUBnzpxlQ374HQa1zw0KZrTVXqTOF2ksbLL8r4UHLu0Z5BoVLyThsNI5a-yIG_uwaSLMLDGlgYp3Pj54Sq_sSqTw-EZMD_X_4tkr-Oy-WlbobulxJZ0QVBA-JyKK6NgOMCDnkDcycY9Pz_y_6a9VTiKeAyeGOWgOapuIZ8GTMg40VjVtveljWx5nK8sCXK7M0TptQ5pnYhl5inAbj7pQ8EirCIEYsT0Ix3avF9QrSlo213iSpIR1NLwn8-eamOyLHbKKAo6eAq3pz8Xk7M60Oxugn5Dk9GbXrQM1cDkbb8WwfAyC4UI33O8Qr048S2g5-EvdXm-yacT-I7KObbcehF6DMOnxHuoj2fXcy1VJRY_UuOpfBl2pvgP2OwlY3pzvQvkaFlnNBhXK2yu0G6JIhonEsSCasVUiIy2GJvEZZWdM4OOsGK-xbjYG3KjKmufJFd4oed5BP209fc6o_-oVAus2RH1vfiOyEH5QSCjXiIv0ro0cqkHXl_vc-GqhzifPxDJm0aV7NIc0KyuFfs1qCbyxLs6YAVj4H408hRnFlW7Q5sAxlChYCMaD9QHes6rtrWHGmrmHV-zTktke-QOyQ20vU6o6gpla7JlDW-EiYyLTAKiKu7WCaAwMUaoW-1DlfWmFeh7gEHXSZcGLC8r7vMiKQWoTGzkO0dueTLMePFgMigIsa8gm9R5mBBfqi3VTwOvmCXNqYSud6rgJojF5vjxeTerUo6xSxWJcHCQNu3nuToAHUiN8BTgwb9rff-IznqZZ80zCXnGTsRSkX7KE4StlWJmr8ZgDtQIS6QWYXlE0IL43_brWaq-9pswJk2ZkXPMBG4wZPom2JanRdSceAO5nNvqpVRoG4pwIH2F0PT553mn0kbURbuuGkpQWP41Xn9aX2JpveBLnJVvVlvH82kn0GDUdOjZ0eSK7ucJzj5F-81TYiOldvcJMz0R03RuSWbugUrKrSj4oZ9b-B9Ubo1SqAtvg3xyrDCG5zPStIirQmAhUMiadoJl4GSZkzRcki0prWxdZ9aZYft3APTAVxKax_8jk9EScph4IwQ_RvmRopBDqaGTC-scRoHfbrk4BcIHV-h0NNVuSYccDCCg9tYxTCilq97wm3aoo-zOB4=',
  //   };
  //   axios
  //     .post(
  //       'https://api.mapbox.com/search/v1/retrieve?session_token=ca8bb18c-70d5-4744-88df-56d3d39cc5e4&access_token=pk.eyJ1Ijoia2lldC1uZ3V5ZW4iLCJhIjoiY2w0Y2syY2trMTBmNjNnbGQ3czJzNWwxMiJ9.dz1-izPXfqXT3XL1BvWinw',
  //       body
  //     )
  //     .then((res) => {
  //       console.log(res);
  //     });
  // };

  return (
    <>
      <Page
        title={
          <>
            {title}&nbsp;
            {showLoading && <Spinner animation="border" size="sm" />}
          </>
        }
        body={
          <>
            <Container className="container-fluid">
              <Row>
                <h1>{(model === string.DEVICE_SF07) ? string.DEVICE_SF07NAME : model}</h1>
              </Row>
              <Row>
                <Col>
                  <Table
                    className="nowrap"
                    mainActions={mainActions}
                    page={page}
                    pageSize={pageSize}
                    total={total}
                    onPageChanged={setPage}
                    onPageSizeChanged={setPageSize}
                    headers={
                      model === string.DEVICE_SFGPS
                        ? headers[1]
                        : model === string.DEVICE_WBGT
                        ? headers[2]
                        : model === string.DEVICE_SF03WT
                        ? headers[3]
                        : model === string.DEVICE_SF03WT2
                        ? headers[6]
                        : model === string.DEVICE_XE1151
                        ? headers[5]
                        : model === string.DEVICE_SF07
                        ? headers[7]
                        : headers[0]
                    }
                    data={dataTable(devicesLogData)}
                    actions={actions
											// model !== string.DEVICE_SF03WT2 ?
											// 	actions : null
										}
                    pagging={TablePaggingOption.TOP}
                    search={true}
                    onSearch={(value) => setSearchId(value)}
                    isPagging={
                      currentUser.accountRole === ACCOUNT_ROLE_ADMIN ? true
                        : model !== string.DEVICE_WBGT ? true
                        : false
                    }
										onRowClick={onSelect}
										// onRowClick={onSelect2}
                  />
                </Col>
              </Row>

							{/* <Row>
								<Col>
									<SimpleMap>

									</SimpleMap>
								</Col>
							</Row> */}

              <Row>
                <Col>
                  {(model.length > 0) && nNU(zoom) && (
                      <Map
                        //draggable={true}
                        data={getMapData(devicesLogData)}
                        selectedMarker={selectedMarker}
                        MarkerContent={DeviceMarker}
                        state={state}
                        zoom={zoom}
												maxZoom={model === string.DEVICE_SFGPS ? 22:19}
                        isHazardShowed={
                          model === string.DEVICE_SF03WT2 ? true
                            : model === string.DEVICE_SF03WT ? true
															: model === string.DEVICE_SF07 ? true
																: false
                        }
												MarkerBlink={
													model === string.DEVICE_SF03WT2 ? blinkCountdown
														: model === string.DEVICE_SF07 ? blinkCountdown
															: null
													// getBlink(blinkOff, blinkCountdown) : null
													// getMarkerBlink(devicesLogData) : null
												}
												blinkOff={blinkOff}
												isGPSDevice={model === string.DEVICE_SFGPS ? true:false}
                      />
                    )
                  }
                </Col>
              </Row>

							{/* <div>
									<Player />
							</div> */}

            </Container>
            {/* <Button onClick={handleTest}>TEST</Button> */}
          </>
        }
      />
    </>
  );
};

export default DeviceStatus2;
