import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { mailTemplate } from 'util/mailTemplate';
import string from 'util/string';
import { formatDate, formatDayOfTheWeekJa } from 'util/utils';
import './MailAlertSelect.scss';
var kanjidate = require('kanjidate');

const MailAlertSelect = ({ selectedData = [] }) => {
	// let header = selectedData?.header;
	// header = header.replace('https','\nhttps');
	// selectedData?.content = selectedData?.content.replace('https','\nhttps');
  return (
    <div className="alert-select">
      <h5 style={{ marginBottom: '20px' }}>{selectedData?.detail}</h5>
      <Row style={{ display: 'flex' }}>
        <Col xs="auto" xl={1}>
          {string.MAIL_FORM}
        </Col>
        <Col style={{ color: '#1c63b7', cursor: 'pointer' }}>
          <span className="link"> {string.MAIL_FORM_USER}</span>
        </Col>
      </Row>
      <Row style={{ display: 'flex' }}>
        <Col xs="auto" xl={1}>
          {string.MAIL_TO}
        </Col>
        <Col style={{ color: '#1c63b7', cursor: 'pointer', hover: '' }}>
          {selectedData?.sendTo?.split(';').map((mail, index) => (
            <>
              <span className="link" key={mail}>
                {mail}
              </span>
              {index === selectedData?.sendTo?.split(';').length - 1
                ? ''
                : '、'}
            </>
          ))}
        </Col>
      </Row>
      <Row style={{ display: 'flex', marginBottom: '20px' }}>
        <Col xs="auto" xl={1}>
          {string.MAIL_DATE}
        </Col>
        <Col>
          {formatDayOfTheWeekJa(
            formatDate(
              selectedData?.detectionTime,
              'YYYY年MM月DD日 (ddd) HH:mm'
            )
          )}
        </Col>
      </Row>
      {selectedData?.header?.split('\n').map((line) => (
        <Row>
          <Col>{line}</Col>
        </Row>
      ))}
      <Row style={{ visibility: 'hidden' }}>{'CONTENT'}</Row>
      {selectedData?.content?.split('\n').map((line) => (
        <Row>
          <Col>{line}</Col>
        </Row>
      ))}
      <Row style={{ visibility: 'hidden' }}>{'FOOTER'}</Row>
      {mailTemplate.footer.split('\n').map((line) => (
        <Row>
          <Col>{line}</Col>
        </Row>
      ))}
      <Row>
        <Col>{`------------------------------------------------------------`}</Col>
      </Row>
    </div>
  );
};

export default MailAlertSelect;
