import * as React from "react"

const DkSf03wt2DataTimeout = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      {...props}
    >
      <style>
        {
          ".st202,.st203{fill:#fff;stroke:#000;stroke-width:2.8308;stroke-linecap:round;stroke-miterlimit:10}.st203{stroke-width:2.7826}"
        }
      </style>
      <circle
        cx={25}
        cy={25}
        r={22.5}
        fill="#fff"
        stroke="#000"
        strokeWidth={4.252}
        strokeMiterlimit={10}
        id="\u30EC\u30A4\u30E4\u30FC_201"
      />
      <path
        fill="#fff"
        stroke="#000"
        strokeWidth={2.8346}
        strokeLinecap="round"
        strokeMiterlimit={10}
        id="\u30EC\u30A4\u30E4\u30FC_203"
        d="M37.45 16.35L25 25 15.29 18.97"
      />
      <g id="\u30EC\u30A4\u30E4\u30FC_202">
        <path className="st202" d="M25 2.51L25 8.51" />
        <path className="st202" d="M25 41.5L25 47.5" />
        <path className="st203" d="M2.5 25L8.5 25" />
        <path className="st203" d="M41.5 25L47.5 25" />
      </g>
      <path
        fill="#ff0001"
        stroke="#fd1a16"
        strokeWidth={5.6693}
        strokeMiterlimit={10}
        id="\u30EC\u30A4\u30E4\u30FC_204"
        d="M47.5 2.51L2.5 47.5"
      />
    </svg>
  )
}

export default DkSf03wt2DataTimeout;
