import Page from 'component/Page';
import string from 'util/string';
import Table from 'component/Table';
import { useState } from 'react';
import { useCallback } from 'react';
import sendRequest, { sendPost } from 'util/request';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import Urls from 'util/urls';
import { ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT } from 'util/permission';
import { useEffect } from 'react';
import Modal from 'component/Modal';
import DeviceMaintenance from './DeviceMaintenance';
import { useMemo } from 'react';
import UserSelect from 'page/user/UserSelect2';
import { getString, nNU, showConfirm, showToast } from 'util/utils';
import Paths from 'util/paths';
import { useHistory } from 'react-router-dom';

const DeviceList = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = useMemo(
    () => currentUser.accountRole === ACCOUNT_ROLE_ADMIN,
    [currentUser.accountRole]
  );
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchId, setSearchId] = useState('');

  const title = string.DEVICE_MANAGEMENT;
  const headers = isAdmin
    ? [
        string.DEVICE_NAME,
        string.DEVICE_SIGFOX_ID,
        string.DEVICE_SERIALNO,
        string.DEVICE_MODEL,
        string.CONTRACT_NUMBER,
        string.ACCOUNT_NAME,
      ]
    : [
        string.DEVICE_NAME,
        string.DEVICE_SIGFOX_ID,
        string.DEVICE_SERIALNO,
        string.DEVICE_MODEL,
      ];
  const [data, setData] = useState([]);
  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key,
        id: raw.deviceId,
        data: isAdmin
          ? [
              raw.assetName,
              raw.sigfoxId,
              raw?.serialNumber || raw?.serialNumbber,
              raw.model,
              raw.contractNumber,
              raw.accountName,
            ]
          : [
              raw.assetName,
              raw.sigfoxId,
              raw?.serialNumber || raw?.serialNumbber,
              raw.model,
            ],
        raw: { ...raw, serialNumber: raw?.serialNumber || raw?.serialNumbber },
      })),
    []
  );
  const history = useHistory();
  const infoAction = ({ id }) =>
    history.push(`${Paths.device.detail}/?id=${id}`);
  const editAction = ({ raw }) => {
    return setSelectedItem(raw);
  };
  const mainActions = isAdmin
    ? [
        {
          icon: 'add',
          title: string.NEW_DEVICE,
          onClick: () => setSelectedItem({}),
        },
      ]
    : [];
  const actions = [
    { render: 'info', onClick: infoAction },
    ...(isAdmin
      ? [
          { render: 'edit', onClick: editAction },
          {
            render: 'minus',
            alt: 'Delete',
            className: 'color-danger',
            onClick: ({ raw }) => {
              showConfirm({
                body: getString(string.DEVICE_DELETE_CONFIRM, [raw.assetName]),
                defaultAction: () => {
                  sendRequest({
                    url: `${Urls.device.update}/${raw.deviceId}`,
                    method: 'delete',
                  }).then(() => {
                    showToast(string.DEVICE_DELETE_SUCCESS, 'success');
                    doSearch(page, pageSize);
                  });
                },
              });
            },
          },
        ]
      : currentUser?.accountRole === ACCOUNT_ROLE_PARENT
      ? [
          {
            render: 'assign',
            onClick: ({ raw, id }) => {
              // console.log(id);
              setAssigningDevice(raw);
            },
          },
        ]
      : []),
  ];

  const [assigningDevice, setAssigningDevice] = useState(null);
	const [accountListUpdated, setAccountListUpdated] = useState(false);
  const onUserSelect = ({ raw }) => {
    // console.log(raw);
    // console.log(assigningDevice);
    let { accountId, accountName } = raw;
    let { assetName } = assigningDevice;
    sendPost({
      url: `${Urls.device.listOfAccount}/${accountId}`,
      body: { offset: 0, size: 999999999 },
    }).then((rs) => {
      // console.log(rs);
      if (!!rs.result.find((e) => e.deviceId === assigningDevice.deviceId)) {
        showToast(
          getString(string.ACCOUNT_DEVICE_ALREADY_ASSIGNED, [
            assetName,
            accountName,
          ])
        );
        return;
      }
      showConfirm({
        body: getString(string.ACCOUNT_ASSIGN_DEVICE_CONFIRM, [accountName]),
        defaultAction: () => {
          let deviceAssigndataTable = (e) => ({
            deviceId: e.deviceId,
            sigfoxId: e.sigfoxId,
          });
          sendPost({
            url: Urls.device.assignToAccount,
            body: {
              accountId,
              assignDevices: [
                // ...rs.result.map(deviceAssigndataTable),
                ...[deviceAssigndataTable(assigningDevice)],
              ],
            },
          }).then(() => {
            showToast(string.ACCOUNT_ASSIGN_DEVICE_SUCCESS, 'success');
						setAccountListUpdated(!accountListUpdated);
          });
        },
      });
    });
  };

	// useEffect(() => {
	// 	if(accountListUpdated === true){
	// 		setTimeout(() => {
	// 			setAccountListUpdated(false);
	// 		}, 10);
	// 	}
	// },[accountListUpdated]);

  const [selectedItem, setSelectedItem] = useState(null);
  const onModalHide = () => setSelectedItem(null);
  const onModalUpdated = () => {
    onModalHide();
    doSearch(page, pageSize);
  };
  // console.log(selectedItem);
  const modalBody = (
    <DeviceMaintenance onUpdated={onModalUpdated} data={selectedItem} />
  );

  const doSearch = useCallback(
    async (page, pageSize, searchId, getAll = false) => {
      let data = await sendPost({
        url: isAdmin
          ? Urls.device.list
          : currentUser.accountRole === ACCOUNT_ROLE_PARENT
          ? `${Urls.device.listOfAccountContract}/${currentUser.accountId}`
          : `${Urls.device.listOfMe}`,
        body: {
          offset: (page - 1) * pageSize,
          size: pageSize,
          keyword: searchId,
        },
      });
      const { total, result } = data;
      if (!getAll) {
        setData(result);
        setTotal(total);
      }
      return result;
    },
    [currentUser, isAdmin]
  );

  useEffect(() => {
    doSearch(page, pageSize, searchId);
  }, [page, pageSize, doSearch, searchId]);

  return (
    <>
      <Page
        title={title}
        body={
          <Table
            className="nowrap"
            mainActions={mainActions}
            page={page}
            pageSize={pageSize}
            total={total}
            onPageChanged={setPage}
            onPageSizeChanged={setPageSize}
            headers={headers}
            data={dataTable(data)}
            actions={actions}
            getCSVData={async () => {
              return dataTable(await doSearch(1, total, '', true));
            }}
            csvFileName="DeviceList"
            search={true}
            onSearch={(value) => setSearchId(value)}
            isPagging={
              currentUser.accountRole === ACCOUNT_ROLE_ADMIN ? true : false
            }
          />
        }
      />
      <Modal
        show={nNU(assigningDevice)}
        onHide={() => setAssigningDevice(null)}
        body={<UserSelect
								onItemSelected={onUserSelect}
								deviceId={nNU(assigningDevice) && nNU(assigningDevice.deviceId) ? assigningDevice.deviceId : ''}
								onUpdated={accountListUpdated}
							/>}
      />
      <Modal show={!!selectedItem} onHide={onModalHide} body={modalBody} />
    </>
  );
};

export default DeviceList;
