import React, { useState } from 'react';
import { Toast as BSToast } from 'react-bootstrap';

const Toast = ({ delay, content, type }) => {
  const [show, setShow] = useState(true);

  return (
    <BSToast
      onClose={() => setShow(false)}
      show={show}
      delay={delay}
      autohide
      className={type}
    >
      <BSToast.Header
        // closeVariant={
        //   ['error', 'success'].indexOf(type) > -1 ? 'white' : undefined
        // }
        className={type}
      >
        <span className="me-auto">
          {content.split(/(?:\r\n|\r|\n)/g).map((item) => (
            <React.Fragment>
              <div>{item}</div>
            </React.Fragment>
          ))}
        </span>
      </BSToast.Header>
    </BSToast>
  );
};

export default Toast;
