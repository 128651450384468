import { cloneDeep } from 'util/utils';
import UserAcitonTypes from './user.types';

const INITIAL_STATE = {
  currentUser: null,
};
const userReducer = (state = cloneDeep(INITIAL_STATE), { type, payload }) => {
  switch (type) {
    case UserAcitonTypes.SIGN_IN_SUCCESS:
      return { ...state, currentUser: { ...state.currentUser, ...payload } };
    case UserAcitonTypes.SIGN_OUT:
      return { ...state, currentUser: null };
    default:
      return state;
  }
};

export default userReducer;
