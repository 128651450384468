import { FaInfoCircle,FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Paths from 'util/paths';
import { formatDate, nNU, round } from 'util/utils';
import Battery from './Battery';
import Sensor from './Sensor';
import Signal from './Signal';
import State from './State';
import { Button } from 'react-bootstrap';
import string from 'util/string';

const GeoMarker = ({ data, isJourney = false }) => {
  //console.log(data);
  return (
    <>
      <h6>
        <div>
          {data.regionName}{'　'}
          {/* <FaEdit
            //style={{padding: '0px 0px',}}
            //onClick={handleViewGeoDetail}
          /> */}
        </div>
      </h6>
      <div>
        {nNU(data?.location) && data?.location?.length > 1 && (
          <div>{data.location.map((e, i) => round(e, 6 - i)).join(' ')}</div>
        )}
      </div>
      {/*<div>
        {nNU(data?.radius) && (
          <div>{data.radius}{'m'}</div>
        )}
      </div>*/}
    </>
  );
};

export default GeoMarker;
