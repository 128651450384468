const Urls = {
  baseUrl: 'https://entry.alsensorfox.com',
  // baseUrl: 'https://test.iotalinco.com',
  // baseUrl: 'https://192.168.1.10:5002',
  user: {
    login: '/Accounts/authenticate',
    list: '/Accounts/getall',
    update: '/Accounts',
    childList: '/Accounts/getallchildrenforparent',
    childList2: '/Accounts/getallchildrenforparentassigndevice',
    parentList: '/Accounts/getallparents',
    authLog: '/AuthenticationLogs/getauthenticationlogs',
    changePassword: '/Accounts/resetpassword',
    getMailFromLoginID: '/Accounts/getaccountemailresetpassword',
    checkEmailAndLoginID: '/Accounts/forgetpassword',
    confirmActiveCode: '/Accounts/confirmactivationcoderesetpassword',
    confirmActiveCodeAndSetNewPassword:
      '/Accounts/confirmactivationcoderesetpassword',
  },
  contract: {
    list: '/Contracts/getall',
    listOfAccount: '/Contracts/getcontractsbyaccount',
    link: '/Contracts/linkcontracttoaccount',
    unlink: '/Contracts/unlinkcontractfromaccount',
    update: '/Contracts',
  },
  device: {
    list: '/Devices/getall',
    listOfAccountContract:
      '/Devices/getassigneddevicesbelongcontractsbyaccount',
    listOfMe: '/Devices/getassigneddevicesforcurrentaccount',
    listOfAccount: '/Devices/getassigneddevicesbyaccount',
    listOfContract: '/Devices/getassigneddevicesbycontract',
    listStatusOfAccount: '/DeviceLog/getalllatestlogbyaccount',
    listOrphans: '/Devices/getallorphandevices',
    listOrphansOfAccount:
      '/Devices/getallorphandevicesforcontractbyparentaccount',
    update: '/Devices',
    info: '/Devices',
    assignToAccount: '/Devices/assigndevicestoaccount',
    unassignToAccount: '/Devices/unassigndevicesinaccount',
    assignToContract: '/Devices/assigndevicestocontract',
    advancedLog: '/DeviceLog/getadvancedlog',
    bidirLog: '/DeviceLog/getbidirlog',
    uplinkLog: '/DeviceLog/getuplinklog',
    activities: '/DeviceLog/getdeviceactivities',
    journey: '/DeviceLog/getdevicejourney',
    payload: '/DeviceLog/getdevicepayload',
    payloadExport: '/DeviceLog/exportdevicepayload',
    listOfDevicesContract:
      '/DeviceLog/getassigneddevicesbelongcontractsbyaccount',
    activeAlert: '/Alerts/getactivealerts',
    alertHistory: '/Alerts/getalerthistory',
    listOrphansOfContractByAccount:
      '/Devices/getorphandevicesbelongcontractsbyaccount',
    listAdvancedLog: '/DeviceLog/getalldeviceandlasteddata',
    latestDeviceData: '/DeviceLog/getlatestlog',
    deleteDeviceData: '/DeviceLog/deletedevicedata',
  },
  notify: {
    getNotifyConfiguration: '/NotifyConfiguration/getdevicenotifyconfiguration',
    setNotifyConfiguration:
      '/NotifyConfiguration/storedevicenotifyconfiguration',
    getThresholdValue: '/NotifyConfiguration/getdevicenotifythreshold',
  },
  gpsNotifyConfiguration: {
    getAllGPSSetting: '/GPSConfiguration/getallgpssetting',
    storeGPSSetting: '/GPSConfiguration/addgpssetting',
    editGPSSetting: '/GPSConfiguration/editgpssetting',
    deleteGPSSetting: '/GPSConfiguration/deletegpssetting',
    storeGPSMailSetting: '/GPSConfiguration/storegpsconfiguration',
    getGPSMailSetting: '/GPSConfiguration/getgpsconfiguration',
  },
  image: {
    getImagesDevice: '/Image/deviceimage',
    uploadImage: '/Image/upload',
    deleteImage: '/Image',
    deleteDeviceImage: '/Image/deleteimagesbelongdevice',
  },
  xe1151: {
    getImageCoordinatesByDevice: '/Xe1151DeviceCoordinates/getimagecoodinatesbydevice',
    getAllImageCoordinates: '/Xe1151DeviceCoordinates/getallimagecoodinates',
    setImageCoordinatesByDevice: '/Xe1151DeviceCoordinates/setdevicecoordinates',
    updateDeviceCoordinates: '/Xe1151DeviceCoordinates/updatedevicecoordinates',
    deleteDeviceCoordinates: '/Xe1151DeviceCoordinates/deletedevicecoordinates',
  },
  sf03wt2: {
    storeDeviceNotifyConfiguration:'/SF03Configuration/storedevicenotifyconfiguration',
    getDeviceNotifyConfiguration:'/SF03Configuration/getdevicenotifyconfiguration',
  },
  sf07: {
    storeDeviceNotifyConfiguration:'/DKSF07Configuration/storedevicenotifyconfiguration',
    getDeviceNotifyConfiguration:'/DKSF07Configuration/getdevicenotifyconfiguration',
  },
};
export default Urls;
