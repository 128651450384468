import FormControls from 'component/FormControls';
import Page from 'component/Page';
import { useState } from 'react';
import { Button, Card, Form } from 'react-bootstrap';
import { ImEye } from 'react-icons/im';
import { RiEyeCloseLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import {
  focusElement,
  getFormData,
  showConfirm,
  showToast,
  signOut,
} from 'util/utils';

const UserInfo = () => {
  const currentUser = useSelector(selectCurrentUser);
  const [showPassword, setShowPassword] = useState(false);

  const onChangePassword = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let formData = getFormData(e.target, {
      rawCol: {
        oldPassword: true,
        newPassword: true,
        confirmPassword: true,
      },
    });
    if (!formData?.oldPassword) {
      showToast(string.PASSWORD_EMPTY_ERR);
      focusElement('._user-info-form #oldPassword');
      return;
    }
    if (!formData?.newPassword) {
      showToast(string.PASSWORD_EMPTY_ERR);
      focusElement('._user-info-form #newPassword');
      return;
    }
    if (formData?.newPassword !== formData?.confirmPassword) {
      showToast(string.NEW_PASSWORD_NOT_MATCHED_ERR);
      focusElement('._user-info-form #newPassword');
      return;
    }
    showConfirm({
      body: string.CHANGE_PASSWORD_CONFIRM,
      defaultAction: () => {
        sendPost({
          url: Urls.user.changePassword,
          body: formData,
        }).then(() => {
          showToast(string.CHANGE_PASSWORD_SUCCESS, 'success');
          signOut();
        });
      },
    });
  };

  return (
    <>
      <Page
        title={string.MY_ACCOUNT}
        body={
          <>
            <Card.Title>{string.MY_ACCOUNT}</Card.Title>
            <FormControls
              controls={[
                {
                  title: string.LOGIN_ID,
                  readOnly: true,
                  defaultValue: currentUser.loginId,
                },
                {
                  title: string.ACCOUNT_NAME,
                  readOnly: true,
                  defaultValue: currentUser.accountName,
                },
                {
                  title: string.EMAIL_ADDRESS,
                  readOnly: true,
                  defaultValue: currentUser.email,
                },
              ]}
            />
            <Card.Title>{string.CHANGE_PASSWORD}</Card.Title>
            <Form
              className="_user-info-form"
              style={{ textAlign: 'center' }}
              onSubmit={onChangePassword}
            >
              <FormControls
                controls={[
                  {
                    id: 'oldPassword',
                    title: string.CURRENT_PASSWORD,
                    type: showPassword ? 'text' : 'password',
                    footer: showPassword ? (
                      <>
                        <ImEye
                          onClick={() => {
                            setShowPassword(false);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <RiEyeCloseLine
                          onClick={() => {
                            setShowPassword(true);
                          }}
                        />
                      </>
                    ),
                  },
                  {
                    id: 'newPassword',
                    title: string.NEW_PASSWORD,
                    type: showPassword ? 'text' : 'password',
                    footer: showPassword ? (
                      <>
                        <ImEye
                          onClick={() => {
                            setShowPassword(false);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <RiEyeCloseLine
                          onClick={() => {
                            setShowPassword(true);
                          }}
                        />
                      </>
                    ),
                  },
                  {
                    id: 'confirmPassword',
                    title: string.CONFIRM_NEW_PASSWORD,
                    type: showPassword ? 'text' : 'password',
                    footer: showPassword ? (
                      <>
                        <ImEye
                          onClick={() => {
                            setShowPassword(false);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <RiEyeCloseLine
                          onClick={() => {
                            setShowPassword(true);
                          }}
                        />
                      </>
                    ),
                  },
                ]}
              />
              <Button type="submit">{string.UPDATE}</Button>
            </Form>
          </>
        }
      />
    </>
  );
};

export default UserInfo;
