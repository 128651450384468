import { useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import { useEffect } from "react";
import L from "leaflet";
// import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";
// import * as React from 'react';
// import { nNU } from 'util/utils';

// const position = [51.505, -0.09];

const MapButton2 = ({
	active = false,
	handleOnClick,
}) => {
  const map = useMap();
  useEffect(() => {
    if (!map) return;

    const buttonControl = L.control({
      position: "topleft"
    });

		buttonControl.onAdd = function (map) {
			// console.log("rerender: ", active);
			this._div = L.DomUtil.create("div", "");
			const buttonElement =
				`<div class="btnWrapper">
					<button id="map-button2" class="btn btn-primary">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="map-satellite" class="bi bi-image-fill" viewBox="0 0 16 16">
							<path d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2V3zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12zm5-6.5a1.5 1.5 0 1 0-3 0 1.5 1.5 0 0 0 3 0z"/>
						</svg>
						航空写真地図へ
					</button>
				</div>`;
			const buttonElement2 =
				`<div class="btnWrapper">
					<button id="map-button2" class="btn btn-primary">
						<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" id="map-road" class="bi bi-map" viewBox="0 0 16 16">
							<path fill-rule="evenodd" d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.502.502 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103zM10 1.91l-4-.8v12.98l4 .8V1.91zm1 12.98 4-.8V1.11l-4 .8v12.98zm-6-.8V1.11l-4 .8v12.98l4-.8z"/>
						</svg>
						　　標準地図へ
					</button>
				</div>`;
			this._div.innerHTML = (active === false) ? buttonElement : buttonElement2;
			this._div.addEventListener("click", () => {
				let nowHtml = this._div.innerHTML;
				handleOnClick();
				if (nowHtml.includes("map-satellite")) {
					this._div.innerHTML = buttonElement2;
				}
				else{
					this._div.innerHTML = buttonElement;
				}
			});
			return this._div;
    };

    buttonControl.addTo(map);
		// map.remove(buttonControl);

    // return () => {
    //   map.remove(buttonControl);
    // };
  }, [map]);

  return null;
};

export default MapButton2;
