import MailTemplate from 'component/MailTemplate';
import WBGTSettei from 'component/WBGTSettei';
import Weekday from 'component/Weekday';
import 'flatpickr/dist/themes/material_blue.css';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import sendRequest, { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import {
  convUTCStringFormat,
  emailValidate,
  focusElement,
  nNU,
  showToast,
} from 'util/utils';
const days = ['全', '日', '月', '火', '水', '木', '金', '土'];
const defaultTimeOffset = [false, false, false, false, false];

const DeviceDetailThreshold = ({ deviceId }) => {
  const [isReload, setIsReload] = useState(false);
  const [thresholdValue, setThresholdValue] = useState([31, 28, 25, 21]);
  const [repeatTimeValue, setRepeatTimeValue] = useState([5, 5, 5, 5]);
  const [repeatValue, setRepeatValue] = useState([false, false, false, false]);
  const [enableCheck, setEnableCheck] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [utcTime, setUTCTime] = useState([]);
  const [timeOffset, setTimeOffset] = useState(defaultTimeOffset);
  const [localTime, setLocalTime] = useState([]);
  const [value, setValue] = useState([]);
  const [repeat, setRepeat] = useState([[], [], [], [], []]);
  const [mailList, setMailList] = useState([]);
  const [valueInputMail, setValueInputMail] = useState();
  const [enableList, setEnableList] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);
  const [repeatPeriod, setRepeatPeriod] = useState([]);
  const [dataMailTemplate, setDataMailTemplate] = useState([]);

  const countList = [0, 1, 2, 3, 4];
  useEffect(() => {
    if (!deviceId) {
      return;
    }
    sendRequest({
      url: `${Urls.notify.getNotifyConfiguration}/${deviceId}`,
    }).then((res) => {
      const { result } = res;
      // console.log(result);
      let newThresholdValue = [...thresholdValue];
      let isNotNull = result.thresholdSet.levelSettei.findIndex(
        (e) => e !== null
      );
      if (isNotNull !== -1) newThresholdValue = result.thresholdSet.levelSettei;
      else {
        newThresholdValue = [31, 28, 25, 21];
      }
      let newRepeatTimeValue = result?.thresholdSet?.alertRepeatInterval || [
        5, 5, 5, 5,
      ];
      let newRepeatValue = result?.thresholdSet?.alertRepeatEnable || [
        false,
        false,
        false,
        false,
      ];

      let index = 0;
      let newTimeOffset = [...timeOffset];
      let newLocalTime = [...localTime];
      let newUTCTime = [...utcTime];
      let newCheck = [];
      let newRepeat = [];

      for (const key in result.timeReports) {
        if (result.timeReports[key].time) {
          newCheck.push(result.timeReports[key].enable);
          const timeLocale = new Date(
            convUTCStringFormat(result.timeReports[key].time)
          );
          if (result.timeReports[key].repeat) {
            result.timeReports[key].repeat.indexOf('0') > -1
              ? newRepeat.push(`${index}-0`)
              : timeLocale.getHours() -
                  result.timeReports[key].time.substring(0, 2) >
                0
              ? result.timeReports[key].repeat.forEach((item) => {
                  newRepeat.push(`${index}-${item}`);
                })
              : result.timeReports[key].repeat.forEach((item) => {
                  let item1 = parseInt(item, 10);
                  if (item > 0) {
                    item1 = item1 + 1;
                    if (item1 === 8) {
                      item1 = 7;
                    }
                  }
                  newRepeat.push(`${index}-${item1}`);
                });
          }
          timeLocale.getHours() - result.timeReports[key].time.substring(0, 2) >
          0
            ? (newTimeOffset[index] = false)
            : (newTimeOffset[index] = true);
          newLocalTime[
            index
          ] = `${timeLocale.getHours()}:${timeLocale.getMinutes()}`;
          newUTCTime[index] = result.timeReports[key].time;
        } else {
          newLocalTime[index] = [];
          newTimeOffset[index] = false;
          newCheck[index] = false;
        }
        index = index + 1;
      }
      let newEmailAddresses = [...mailList];
      if (nNU(result.emailAddresses)) newEmailAddresses = result.emailAddresses;
      setEnableList(newCheck);
      setUTCTime(newUTCTime);
      setLocalTime(newLocalTime);
      // setTimeOffset(newTimeOffset);
      setValue(newRepeat);
      setThresholdValue(newThresholdValue);
      setRepeatTimeValue(newRepeatTimeValue);
      setRepeatValue(newRepeatValue);
      setMailList(newEmailAddresses);
      setRepeatPeriod(result.thresholdSet.repeatPeriod);
      setEnableCheck(result.thresholdSet.enableSettei);
      setDataMailTemplate(result.emailTemplate);
    });
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [deviceId, isReload]);

  const handleChangeTimely = (index, e) => {
    let newUtcTime = [...utcTime];
    let newTimeOffset = [...timeOffset];
    let localTimeHour = e[0].getHours();
    let dmy = e[0].toUTCString();
    let utcTimeHour = e[0].getUTCHours();
    newUtcTime[index] = dmy.slice(17, 22);
    localTimeHour - utcTimeHour > 0
      ? (newTimeOffset[index] = false)
      : (newTimeOffset[index] = true);
    setUTCTime(newUtcTime);
    // setTimeOffset(newTimeOffset);
  };
  const handleChangeOptionButton = (val) => {
    let newItemValue = [[], [], [], [], []];
    let newValue = [];

    val.forEach((item) => {
      let ind = item.substring(0, 1);
      newItemValue[ind].push(item);
    });
    for (let i = 0; i < newItemValue.length; i++) {
      let isAllWeekly = false;
      newItemValue[i].forEach((item) => {
        if (item === `${i}-0`) {
          isAllWeekly = true;
        }
      });
      if (newItemValue[i].length > 0)
        isAllWeekly
          ? newValue.push(`${i}-0`)
          : newItemValue[i].forEach((item) => {
              newValue.push(item);
            });
    }
    setValue(newValue);
  };
  const defaultValue = [];
  useEffect(() => {
    let rep = repeat;
    for (let i = 0; i < 5; i++) {
      rep[i].length = 0;
    }
    // console.log(value);
    value.forEach((item) => {
      let index = item.slice(0, 1);
      let val = item.slice(2, 3);
      if (timeOffset[index]) {
        if (val > 0) {
          val = val - 1;
          if (val === 0) {
            val = 7;
          }
        }
      }
      rep[index].push(val.toString());
    });
    // console.log(rep);
    setRepeat(rep);
  }, [value, timeOffset]);

  const handleSetMail = () => {
    focusElement('.input');
    setValueInputMail('');
    let isMatchingMail = false;
    if (mailList.length > 50) {
      showToast(string.MAIL_LENGTH_ERROR, 'warning');
      return;
    }
    mailList.forEach((mail) => {
      if (valueInputMail === mail) {
        isMatchingMail = true;
        return;
      }
    });
    if (isMatchingMail) return;
    if (!valueInputMail) return;
    if (!emailValidate(valueInputMail)) return;
    setMailList([...mailList, valueInputMail]);
  };
  const handleRemoveMailList = (mailItem) => {
    let newMailList = [...mailList];
    newMailList.splice(mailItem, 1);
    setMailList(newMailList);
  };
  const handleChangeCheckEnable = (index, e) => {
    let newList = [...enableList];
    newList[index] = e.target.checked;
    setEnableList(newList);
  };
  const handleSetFromData = () => {
    const wbgtData = {
      deviceId,
      emailAddresses: mailList,
      timeReports: [
        { time: utcTime[0], enable: enableList[0], repeat: repeat[0] },
        { time: utcTime[1], enable: enableList[1], repeat: repeat[1] },
        { time: utcTime[2], enable: enableList[2], repeat: repeat[2] },
        { time: utcTime[3], enable: enableList[3], repeat: repeat[3] },
        { time: utcTime[4], enable: enableList[4], repeat: repeat[4] },
      ],
      thresholdSet: {
        enableSettei: enableCheck,
        levelSettei: thresholdValue,
        repeatPeriod: repeatPeriod,
        alertRepeatEnable: repeatValue,
        alertRepeatInterval: repeatTimeValue,
      },
      emailTemplate: dataMailTemplate,
    };
    sendPost({ url: Urls.notify.setNotifyConfiguration, body: wbgtData }).then(
      (res) => {}
    );
  };

  const handleSetThreshold = (value, index) => {
    let newThresholdValue = [...thresholdValue];
    newThresholdValue[index] = value;
    let higherLevelValue = newThresholdValue[0];
    let dmy = false;
    for (let i = 0; i < newThresholdValue.length; i++) {
      if (dmy) {
        newThresholdValue[i] = 20;
      } else {
        if (higherLevelValue < newThresholdValue[i]) {
          dmy = true;
          newThresholdValue[i] = 20;
          showToast(string.SETTING_VALUE_ERROR, 'warning');
        } else {
          higherLevelValue = newThresholdValue[i];
        }
      }
    }
    setThresholdValue(newThresholdValue);
  };

  const handleSetRepeatTime = (value, index) => {
    let newRepeatTimeValue = [...repeatTimeValue];
    newRepeatTimeValue[index] = value;

    setRepeatTimeValue(newRepeatTimeValue);
  };

  return (
    <>
      <Container className="device-detail-thresh" fluid>
        <Card>
          <Card.Body>
            <Row xs={1} sm={4}>
              <Col sm={8} id="label-title">
                <span style={{ fontSize: 24 }}>{`①　`}</span>
                <span>{`WBGT設定値`}</span>
              </Col>
            </Row>
            <WBGTSettei
              thresholdValue={thresholdValue}
              setThresholdValue={handleSetThreshold}
              repeatTimeValue={repeatTimeValue}
              setRepeatTimeValue={handleSetRepeatTime}
              enableCheck={enableCheck}
              setEnableCheck={(check, index) => {
                let newThresholdValue = [...enableCheck];
                newThresholdValue[index] = check;
                setEnableCheck(newThresholdValue);
                let newEnableValue = [...repeatValue];
                if (index === 0)
                {
                  newEnableValue[0] = false;
                }
                setRepeatValue(newEnableValue);
                }}
              repeatPeriod={repeatPeriod}
              setRepeatPeriod={(value) => {
                setRepeatPeriod(value);
              }}
              repeatValue={repeatValue}
              setRepeatValue={(check, index) => {
                let newEnableValue = [...repeatValue];
                newEnableValue[index] = check;
                setRepeatValue(newEnableValue);
              }}
            />
            <Row xs={1} sm={4}>
              <Col sm={8} id="label-title">
                <span style={{ fontSize: 24 }}>{`②　`}</span>
                <span>{`レポート時間`}</span>
              </Col>
            </Row>
            <Row xs={1} sm={4}>
              <Col sm={1}></Col>

              <Col sm={1} className="value-set">{`時間`}</Col>
              <Col
                sm={4}
                xs={4}
                className="enable-check  title-width2"
              >{`有効`}</Col>
              <Col xs={2}>
                {days.map((day) => (
                  <div key={day} id="no-responsive" className="title ">
                    {day}
                  </div>
                ))}
              </Col>
            </Row>
            {countList.map((index) => (
              <React.Fragment key={index}>
                <Row xs={1} sm={4} className="border-responsive">
                  <Col sm={1}></Col>
                  <Col sm={1} className="value-set time-set">
                    {localTime.length > 0 && (
                      <Flatpickr
                        className="time-picker "
                        options={{
                          enableTime: true,
                          noCalendar: true,
                          dateFormat: 'H:i',
                          time_24hr: true,
                          defaultDate: localTime[index],
                        }}
                        onChange={(e) => {
                          handleChangeTimely(index, e);
                        }}
                      />
                    )}
                  </Col>
                  <Col xs={4} className="enable-check  title-width2">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      //label="有効"
                      checked={enableList[index]}
                      onChange={(e) => handleChangeCheckEnable(index, e)}
                    ></Form.Check>
                  </Col>
                  <Col xs={4}>
                    <Weekday
                      id={`${index}`}
                      value={value}
                      defaultValue={defaultValue[index]}
                      callback={handleChangeOptionButton}
                    ></Weekday>
                  </Col>
                </Row>
              </React.Fragment>
            ))}
            <Row>{` `}</Row>
            <Row xs={1} sm={4}>
              <Col sm={2} id="label-title">
                <span style={{ fontSize: 24 }}>{`③　`}</span>
                <span>{`${string.ACCOUNT_MAIL}`}</span>
              </Col>
              <Col sm={6}>
                <div className="input-group">
                  <input
                    placeholder="メールアドレスを入力してください"
                    type="text"
                    className="input form-control"
                    value={valueInputMail}
                    onChange={(e) => setValueInputMail(e.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleSetMail();
                      }
                    }}
                  />
                  <Button size="sm" onClick={handleSetMail}>
                    {string.SAVE}
                  </Button>
                </div>
              </Col>
            </Row>
            <div className="mail-list">
              <Row xs={1} sm={4}>
                {mailList.map((mail, index) => (
                  <Col key={index}>
                    <span>{mail}</span>
                    <Button
                      size="sm"
                      variant="outline-danger"
                      onClick={() => handleRemoveMailList(index)}
                    >
                      x
                    </Button>
                  </Col>
                ))}
              </Row>
            </div>
            <Row>{` `}</Row>
            <Row xs={1} sm={4}>
              <Col sm={12} id="label-title">
                <span style={{ fontSize: 24 }}>{`④　`}</span>
                <span>{`${string.MAIL_TEMPLATE}`}</span>
              </Col>
            </Row>
            <MailTemplate
              thresholdValue={thresholdValue}
              setThresholdValue={handleSetThreshold}
              dataMailTemplate={dataMailTemplate}
              setDataMailTemplate={(template) => {
                setDataMailTemplate(template);
              }}
            />
            <div className="button-set">
              {/* <Button onClick={() => setIsReload(!isReload)}>Reload</Button> */}
              <Button onClick={handleSetFromData}>{'保存'}</Button>
            </div>
          </Card.Body>
        </Card>
      </Container>
    </>
  );
};

export default DeviceDetailThreshold;
