import FormControls from 'component/FormControls';
import Modal from 'component/Modal';
import Page from 'component/Page';
import Table, { TablePaggingOption } from 'component/Table';
import DeviceSelect from 'page/device/DeviceSelect';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import {
  ACCOUNT_ROLE_ADMIN,
  ACCOUNT_ROLE_CHILD,
  ACCOUNT_ROLE_PARENT,
} from 'util/permission';
import sendRequest, { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import {
  focusElement,
  getFormData,
  getString,
  showConfirm,
  showToast,
} from 'util/utils';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { RiEyeCloseLine } from 'react-icons/ri';
import { ImEye } from 'react-icons/im';

// const LOGIN_ID_LIMIT_LENGTH = 16;
const LOGIN_ID_LIMIT_LENGTH = 50;

const accountAddRoleNameMap = {
  0: string.ACCOUNT_ROLE_ADMIN,
  1: string.ACCOUNT_ROLE_PARENT,
};

const UserMaintenance = ({
  data = { accountId: null, valid: true },
  onUpdated,
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const currentRole = useMemo(
    () => +currentUser.accountRole,
    [currentUser.accountRole]
  );
  const editMode = useMemo(() => !!data?.accountId, [data?.accountId]);
  const [showPassword, setShowPassword] = useState(false);
  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    let formData = getFormData(e.target, {
      rawCol: {
        accountName: true,
        loginId: true,
        password: true,
        confirmPassword: true,
        email: true,
        valid: true,
      },
    });

		if (
      formData.parentAccountId !== null &&
      currentRole !== ACCOUNT_ROLE_PARENT &&
      (currentRole !== ACCOUNT_ROLE_ADMIN || editMode === false)
    ) {
      delete formData.parentAccountId;
    }
    if (!formData?.accountName) {
      showToast(string.ACCOUNT_NAME_EMPTY_ERR);
      focusElement('#accountName');
      return;
    }
    if (!formData?.loginId) {
      showToast(string.LOGIN_ID_EMPTY_ERR);
      focusElement('#loginId');
      return;
    }
    if (!formData?.password) {
      showToast(string.PASSWORD_EMPTY_ERR);
      focusElement('#password');
      return;
    }
    if (!formData?.confirmPassword) {
      showToast(string.PASSWORD_EMPTY_ERR);
      focusElement('#confirmPassword');
      return;
    }
    if (formData?.password !== formData?.confirmPassword) {
      showToast(string.PASSWORD_NOT_MATCHED_ERR);
      focusElement('#password');
      return;
    }
    if (!formData?.email) {
      showToast(string.MAIL_ADDRESS_EMPTY_ERR);
      focusElement('#email');
      return;
    }
    if (formData?.loginId.length > LOGIN_ID_LIMIT_LENGTH) {
      showToast(string.LOGIN_ID_VERY_LONG_ERR, 'warning');
      focusElement('#loginId');
      return;
    }
    showConfirm({
      body: string.ACCOUNT_UPDATE_CONFIRM,
      defaultAction: () => {
        sendRequest({
          url: Urls.user.update + `${editMode ? `/${data?.accountId}` : ''}`,
          method: editMode ? 'put' : 'post',
          body: { ...formData, alertReport: true, passwordResetFlag: true },
        }).then((res) => {
          showToast(string.ACCOUNT_UPDATE_SUCCESS, 'success');
          onUpdated(res);
        });
      },
    });
  };

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [modalShow, setModalShow] = useState(false);

  const headers = [
    string.DEVICE_NAME,
    string.DEVICE_MODEL,
    string.DEVICE_SERIALNO,
  ];
  const [listData, setListData] = useState([]);
  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key,
        data: [raw.assetName, raw.model, raw.serialNumber],
        raw,
      })),
    []
  );

  const doSearch = useCallback(
    async (page, pageSize, getAll = false) => {
      if (!data?.accountId) {
        return;
      }
      let response = await sendPost({
        url: `${Urls.device.listOfAccount}/${data.accountId}`,
        body: {
          offset: (page - 1) * pageSize,
          size: pageSize,
        },
      });
      const { total, result } = response;
      if (!getAll) {
        setListData(result);
        setTotal(total);
      }
      return result;
    },
    [data]
  );

  useEffect(() => {
    doSearch(page, pageSize);
  }, [page, pageSize, doSearch, data.accountId]);

  const [userDevices, setUserDevices] = useState([]);

  const mainActions = [
    {
      icon: 'add',
      title: string.ADD_DEVICE,
      onClick: async () => {
        let result = await doSearch(1, total, true);
        setUserDevices(result.map((e) => e.deviceId));
        setModalShow(true);
      },
    },
  ];

  const actions = [
    {
      render: 'unassign',
      onClick: ({ raw }) => {
        showConfirm({
          body: getString(string.ACCOUNT_UNASSIGN_DEVICE_CONFIRM, [
            data?.accountName,
            raw?.assetName,
          ]),
          defaultAction: () => {
            sendPost({
              url: `${Urls.device.listOfAccount}/${data.accountId}`,
              body: {
                offset: 0,
                size: total,
              },
            }).then((response) => {
              const { result } = response;
              sendPost({
                url: Urls.device.unassignToAccount,
                body: {
                  accountId: data.accountId,
                  assignDevices: result.filter(
                    (e) => e.deviceId === raw.deviceId
                  ),
                },
              }).then(() => {
                showToast(
                  getString(string.ACCOUNT_UNASSIGN_DEVICE_SUCCESS, [
                    data?.accountName,
                  ]),
                  'success'
                );
                doSearch(page, pageSize);
              });
            });
          },
        });
      },
    },
  ];

  const onDeviceSelect = (items) => {
    showConfirm({
      body: getString(string.ACCOUNT_ASSIGN_DEVICE_CONFIRM, [
        data?.accountName,
      ]),
      defaultAction: () => {
        sendPost({
          url: `${Urls.device.listOfAccount}/${data.accountId}`,
          body: {
            offset: 0,
            size: total,
          },
        }).then((response) => {
          // const { result } = response;
          sendPost({
            url: Urls.device.assignToAccount,
            body: {
              accountId: data.accountId,
              assignDevices: items,
            },
          }).then(() => {
            showToast(string.ACCOUNT_ASSIGN_DEVICE_SUCCESS, 'success');
            setModalShow(false);
            doSearch(1, pageSize);
          });
        });
      },
    });
  };

  const modalBody = (
    <>
      <DeviceSelect
        onItemSelected={onDeviceSelect}
        multiple={true}
        selectedDevices={userDevices}
        isChild={true}
        accountChildID={data.accountId}
      />
    </>
  );
  return (
    <>
      <Page
        title={string.ACCOUNT_MAINTENANCE}
        body={
          <>
            <Form onSubmit={onSubmit}>
              <FormControls
                data={data}
                controls={[
                  {
                    id: 'accountName',
                    title: string.ACCOUNT_NAME,
                    readOnly:
                      data?.accountId &&
                      data?.accountId !==
                        '00000000-0000-0000-0000-000000000000',
                  },
                  {
                    id: 'loginId',
                    title: string.LOGIN_ID,
                    readOnly:
                      data?.accountId &&
                      data?.accountId !==
                        '00000000-0000-0000-0000-000000000000',
                  },
                  {
                    id: 'password',
                    title: string.PASSWORD,
                    type: showPassword ? 'text' : 'password',
                    defaultValue: '',
                    footer: showPassword ? (
                      <>
                        <ImEye
                          onClick={() => {
                            setShowPassword(false);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <RiEyeCloseLine
                          onClick={() => {
                            setShowPassword(true);
                          }}
                        />
                      </>
                    ),
                  },
                  {
                    id: 'confirmPassword',
                    title: string.CONFIRM_PASSWORD,
                    defaultValue: '',
                    type: showPassword ? 'text' : 'password',
                    footer: showPassword ? (
                      <>
                        <ImEye
                          onClick={() => {
                            setShowPassword(false);
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <RiEyeCloseLine
                          onClick={() => {
                            setShowPassword(true);
                          }}
                        />
                      </>
                    ),
                  },
                  {
                    id: 'passwordResetFlag',
                    title: ' ',
                    titleClass: 'invisible',
                    type: 'hidden',
                    options: [
                      { label: string.REQUIRE_RESET_PASSWORD, value: true },
                    ],
                  },
                  {
                    id: 'parentAccountId',
                    type: 'hidden',
                    defaultValue: editMode
                      ? data.parentAccountId
                      : currentRole === ACCOUNT_ROLE_PARENT
                      ? currentUser.accountId
                      : '',
                  },
                  { id: 'email', title: string.EMAIL_ADDRESS },
                  {
                    id: 'accountRole',
                    title: string.ACCOUNT_ROLE,
                    type:
                      currentRole === ACCOUNT_ROLE_ADMIN && !editMode
                        ? 'radio'
                        : 'hidden',
                    defaultValue: editMode
                      ? data?.['accountRole']
                      : currentRole === ACCOUNT_ROLE_ADMIN
                      ? ACCOUNT_ROLE_PARENT
                      : ACCOUNT_ROLE_CHILD,
                    options: Object.keys(accountAddRoleNameMap).map((value) => {
                      return {
                        label: accountAddRoleNameMap[value],
                        value,
                      };
                    }),
                  },
                  {
                    id: 'alertReport',
                    title: string.ALERT_REPORT,
                    type: 'hidden',
                    options: [{ label: string.RECEIVE, value: true }],
                  },
                  {
                    id: 'valid',
                    title: string.ACCOUNT_STATUS,
                    type: 'hidden',
                    options: [{ label: string.ACTIVE, value: 'true' }],
                  },
                ]}
                footer={<></>}
              />
              <Row className="form-button-row">
                <Button type="submit">{string.SAVE}</Button>
              </Row>
            </Form>
            {editMode && currentRole === ACCOUNT_ROLE_PARENT && (
              <>
                <h3>&nbsp;</h3>
                <h5>{string.MANAGING_DEVICES}</h5>
                <Table
                  className="nowrap"
                  mainActions={mainActions}
                  page={page}
                  pageSize={pageSize}
                  onPageChanged={setPage}
                  onPageSizeChanged={setPageSize}
                  total={total}
                  headers={headers}
                  data={dataTable(listData)}
                  actions={actions}
                  pagging={TablePaggingOption.TOP}
                />
                <Modal
                  show={modalShow}
                  onHide={() => setModalShow(false)}
                  body={modalShow && modalBody}
                />
              </>
            )}
          </>
        }
      />
    </>
  );
};

export default UserMaintenance;
