import * as React from "react"

const DkSf03wt2FloodFull = (props) => {
  return (
    <svg
      id="\u30EC\u30A4\u30E4\u30FC_211"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      {...props}
    >
      <style>
        {".st211{fill:none;stroke:#000;stroke-width:1.4173;stroke-miterlimit:10}"}
      </style>
      <g id="\u901A\u5E38\u6D78\u6C34\u80CC\u666F_21">
        <path
          d="M45.01 50.09h-40c-2.76 0-5-2.24-5-5v-40c0-2.76 2.24-5 5-5h40c2.76 0 5 2.24 5 5v40c0 2.76-2.24 5-5 5z"
          fill="#01a0c6"
        />
        <path d="M45.01 2.92c1.19 0 2.17.97 2.17 2.17v40c0 1.19-.97 2.17-2.17 2.17h-40c-1.19 0-2.17-.97-2.17-2.17v-40c0-1.19.97-2.17 2.17-2.17h40m0-2.83h-40c-2.76 0-5 2.24-5 5v40c0 2.76 2.24 5 5 5h40c2.76 0 5-2.24 5-5v-40c0-2.76-2.24-5-5-5z" />
      </g>
      <g id="\u901A\u5E38\u4E8C\u53E3_21">
        <g id="LINE_21_00000016763261895863963070000005104790132133934472_">
          <path
            d="M8.55 15.44a.425.425 0 10-.85 0v5.14c0 .24.19.42.43.42.23 0 .42-.19.42-.43v-5.13zm8.25 2.98a.425.425 0 000-.85c-.23 0-.42.19-.42.43s.19.42.42.42zm1.58-3.57V30.1H6.54V14.85c0-.59.4-1.1.96-1.25l1.75-.47h1.6V9.02l.58-7.06h2.06l.59 7.06v4.11h1.6l1.75.47c.56.16.95.67.95 1.25z"
            id="XMLID_21_00000123416120862017429810000001006585774977919380_"
          />
        </g>
        <g id="LINE_00000039115784625065600390000002187852024674256530_">
          <path
            d="M32.82 28.07a.425.425 0 10-.85 0v5.15a.425.425 0 00.85 0v-5.15zm7.13-2.29l1.75.47c.56.15.95.66.95 1.24v15.25H30.81V27.49c0-.58.39-1.09.96-1.24l1.74-.47h1.61v-4.12l.58-7.05h2.06l.59 7.05v4.12h1.6zm1.12 5.29a.425.425 0 10-.01-.85.425.425 0 00.01.85z"
            id="XMLID_21_00000158741975959943413870000006154806592794291902_"
          />
        </g>
        <path
          className="st211"
          d="M18.15.29v5.06c0 1.1.9 2 2 2h14.58c1.1 0 2 .9 2 2v5.25"
        />
      </g>
      <g id="\u50BE\u659C\u4E8C\u53E3_21">
        <g id="LINE_21_00000075135062577891469680000000960248461345069455_">
          <path
            d="M8.13 15.01c-.24 0-.43.19-.43.43v5.14c0 .24.19.42.43.42.23 0 .42-.19.42-.43v-5.14c0-.24-.19-.42-.42-.42zM6.54 30.1V14.85c0-.59.4-1.1.96-1.25l1.75-.47h1.6V9.02L11.26 4h2.41l.41 5.02v4.11h1.6l1.75.47c.56.15.95.66.95 1.25V30.1H6.54zM16.8 17.58a.425.425 0 000 .85c.23 0 .42-.19.42-.42 0-.25-.19-.43-.42-.43z"
            id="XMLID_21_00000038380629026748013400000007808212985581502094_"
          />
        </g>
        <g id="LINE_00000120520203022829816840000002137709568111438009_">
          <path
            d="M32.82 28.07a.425.425 0 10-.85 0v5.15a.425.425 0 00.85 0v-5.15zm7.13-2.29l1.75.47c.56.15.95.66.95 1.24v15.25H30.81V27.49c0-.58.39-1.09.96-1.24l1.74-.47h1.61v-4.12l.58-7.05h2.06l.59 7.05v4.12h1.6zm1.12 5.29a.425.425 0 10-.01-.85.425.425 0 00.01.85z"
            id="XMLID_21_00000066484372780800085570000003181514813875946897_"
          />
        </g>
        <path
          className="st211"
          d="M18.15 2.27v3.08c0 1.1.9 2 2 2h14.58c1.1 0 2 .9 2 2v5.25"
        />
      </g>
    </svg>
  )
}

export default DkSf03wt2FloodFull;
