import React, { useState, useEffect, useRef } from 'react';
import Modal from 'component/Modal';
import 'flatpickr/dist/themes/material_blue.css';
import { Button, Card, Col, Container, Form, Row } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import sendRequest, { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import { FaAlignJustify, FaFileDownload, FaFileUpload } from 'react-icons/fa';
import { mailTemplate, dksf03wt2MailTemplate } from 'util/mailTemplate';
import {
  calcDurationMinutes,
  calcNumberOfMinute,
  convUTCStringFormat,
  formatTime,
  emailValidate,
  focusElement,
  showToast,
  nNU,
} from 'util/utils';
import Weekday from 'component/Weekday2';
import 'component/Table.scss';

const mailTitle = [
  '浸水検出　水位上昇（A浸水/B浸水or渇水）',
  '渇水検出　水位低下（A渇水/B浸水or渇水）',
  '浸水検出　水位上昇（A浸水or渇水/B浸水）',
  '渇水検出　水位低下（A浸水or渇水/B渇水）',
  '傾斜検出',
  '傾斜正常',
  'PUSHボタン',
  '定時送信',
  '設定完了',
  '起動完了　定時連絡オフ/オン',
  // '起動完了　定時連絡オフ',
  '起動エラー',
  '電源確認',
  '定時連絡未送信',
];
const mailTitleIndex = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  // '8',
  // '9',
  // '10',
  // '11',
  // '12',
];
const mailTemplateIndex = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];
const mailUserAddIndex = [
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];
const repeatTime = [5, 10, 15, 30, 60];
//const days = ['全', '日', '月', '火', '水', '木', '金', '土'];
const mailTemplateHeaderSample = [
  '3.3V',
  '12時30分',
  '9月5日 12時30分',
];

// UTC Offset
let UTCOffset = false;
let UTCOffset2 = false;

const DEFAULT_START_TIME = "00:00"; // Japan time = 09:00
const DEFAULT_RANGE = "08:30";		// Japan time = 17:00
const DEFAULT_END_TIME = "08:30";		// Japan time = 17:00

const DeviceDkSf03Wt2MailSetting = ({ deviceId }) => {

	// States for management settings
	const [mailEnableAdmin, setMailEnableAdmin] = useState([]);
	const [allMailEnableAdmin, setAllMailEnableAdmin] = useState(false);
	const [mailRepeatEnableAdmin, setMailRepeatEnableAdmin] = useState([]);
	const [mailRepeatIntervalAdmin, setMailRepeatIntervalAdmin] = useState([]);
	const [repeatPeriodAdmin, setRepeatPeriodAdmin] = useState([]);
	const [startTimeAdmin, setStartTimeAdmin] = useState();
	const [endTimeAdmin, setEndTimeAdmin] = useState();
	const [endUTCTimeAdmin, setEndUTCTimeAdmin] = useState();
	const [startUTCTimeAdmin, setStartUTCTimeAdmin] = useState();
	const [weekdayAdmin, setWeekdayAdmin] = useState([]);
	const [weekdayEnableAdmin, setWeekdayEnableAdmin] = useState([]);
	const [mailAddrAdmin, setMailAddrAdmin] = useState([]);
	const [mailAddrListAdmin, setMailAddrListAdmin] = useState();

	// States for normal user settings
	const [mailEnableUser, setMailEnableUser] = useState([]);
	const [allMailEnableUser, setAllMailEnableUser] = useState(false);
	const [mailRepeatEnableUser, setMailRepeatEnableUser] = useState([]);
	const [mailRepeatIntervalUser, setMailRepeatIntervalUser] = useState([]);
	const [repeatPeriodUser, setRepeatPeriodUser] = useState([]);
	const [startTimeUser, setStartTimeUser] = useState();
	const [endTimeUser, setEndTimeUser] = useState();
	const [endUTCTimeUser, setEndUTCTimeUser] = useState();
	const [startUTCTimeUser, setStartUTCTimeUser] = useState();
	const [weekdayUser, setWeekdayUser] = useState([]);
	const [weekdayEnableUser, setWeekdayEnableUser] = useState([]);
	const [mailAddrUser, setMailAddrUser] = useState([]);
	const [mailAddrListUser, setMailAddrListUser] = useState();

	// States for mail template
	const [userMailTemplate, setUserMailTemplate] = useState([]);

	// States for show mail template
  const [mailShow, setMailShow] = useState(false);
	const [templateIndex, setTemplateIndex] = useState(0);

	// States for loading device information
	const [loadForm, setLoadForm] = useState(false);
  const [deviceData, setDeviceData] = useState({});

	// Refs for upload / download template
	const mailInput0 = useRef(null);
	const mailInput1 = useRef(null);
	const mailInput2 = useRef(null);
	const mailInput3 = useRef(null);
	const mailInput4 = useRef(null);
	const mailInput5 = useRef(null);
	const mailInput6 = useRef(null);

	// Save mail setting button click state
	const [isSaveClicked, setIsSaveClicked] = useState(false);

	// Get device's mail setting
  useEffect(()=>{
    if (!deviceId) {
      return;
    }
    sendRequest({
      url: `${Urls.sf03wt2.getDeviceNotifyConfiguration}/${deviceId}`,
      }).then((res) => {
				const { result } = res;

				// console.log('Result: ', result);

				// Get settings of management
				if(nNU(result.alertEnable)){
					let newMailEnableAdmin = result.alertEnable;
					setMailEnableAdmin(newMailEnableAdmin);
					let enableAll = true;
					for(let i = 0; i < newMailEnableAdmin.length - 1; i++){
						if(newMailEnableAdmin[i] === false){
							enableAll = false;
							break;
						}
					}
					setAllMailEnableAdmin(enableAll);
				}

				if(nNU(result.alertRepeatEnable)){
					let newMailRepeatEnableAdmin = result.alertRepeatEnable;
					setMailRepeatEnableAdmin(newMailRepeatEnableAdmin);
				}

				if(nNU(result.alertRepeat)){
					let newMailRepeatIntervalUser = result.alertRepeat;
					setMailRepeatIntervalAdmin(newMailRepeatIntervalUser);
				}

				if(nNU(result.repeatPeriod)){
					let newRepeatPeriodAdmin = result.repeatPeriod;
					setRepeatPeriodAdmin(newRepeatPeriodAdmin);
					// let newStartUTCTimeAdmin = result.repeatPeriod[0].startTime;
					// setStartUTCTimeAdmin(newStartUTCTimeAdmin);
				}

				if(nNU(result.emailAddresses)){
					let newMailAddrAdmin = result.emailAddresses;
					setMailAddrAdmin(newMailAddrAdmin);
				}

				// Get settings of User
				if(nNU(result.alertEnableNormal)){
					let newMailEnableUser = result.alertEnableNormal;
					for(let i = 7;i < newMailEnableUser.length - 1;i++){
						newMailEnableUser[i] = false;
					}
					setMailEnableUser(newMailEnableUser);
					let enableAll = true;
					for(let i = 0; i < 7; i++){
						if(newMailEnableUser[i] === false){
							enableAll = false;
							break;
						}
					}
					setAllMailEnableUser(enableAll);
				}

				if(nNU(result.alertRepeatEnableNormal)){
					let newMailRepeatEnableUser = result.alertRepeatEnableNormal;
					setMailRepeatEnableUser(newMailRepeatEnableUser);
				}

				if(nNU(result.alertRepeatNormal)){
					let newMailRepeatIntervalUser = result.alertRepeatNormal;
					setMailRepeatIntervalUser(newMailRepeatIntervalUser);
				}

				if(nNU(result.repeatPeriodNormal)){
					let newRepeatPeriodUser = result.repeatPeriodNormal;
					setRepeatPeriodUser(newRepeatPeriodUser);
				}

				if(nNU(result.emailAddressesNormal)){
					let newMailAddrUser = result.emailAddressesNormal;
					setMailAddrUser(newMailAddrUser);
				}

				// Get mail template
				if(nNU(result.emailTemplateContent)){
					let newUserMailTemplate = result.emailTemplateContent;
					setUserMailTemplate(newUserMailTemplate);
				}
      });
  },[deviceId]);

	// Update start time, end time and weekday setting when admin mailing time setting changed
	useEffect(()=>{
		if(repeatPeriodAdmin.length < 8){
			return;
		}

		let newStartUTCTime = '';
		if(repeatPeriodAdmin[0].startTime.length > 0){
			newStartUTCTime = repeatPeriodAdmin[0].startTime;
		}
		else{
			newStartUTCTime = DEFAULT_START_TIME;
		}
		let localStartTime = new Date(
			convUTCStringFormat(newStartUTCTime)
		);
		setStartUTCTimeAdmin(newStartUTCTime);
		setStartTimeAdmin(
      `${formatTime(localStartTime.getHours())}:${formatTime(localStartTime.getMinutes())}`
		);

    let duration = '';
		if((repeatPeriodAdmin[0].startTime.length > 0) && (repeatPeriodAdmin[0].rangeTime.length > 0)){
			duration = calcNumberOfMinute(repeatPeriodAdmin[0].rangeTime);
		}
		else{
			duration = calcNumberOfMinute(DEFAULT_RANGE);
		}
		let localEndTime = new Date(
			convUTCStringFormat(newStartUTCTime)
		);
    // let localEndTime = localStartTime;
    localEndTime.setMinutes(localEndTime.getMinutes() + duration);
    setEndTimeAdmin(
      `${formatTime(localEndTime.getHours())}:${formatTime(localEndTime.getMinutes())}`
    );
		setEndUTCTimeAdmin(
			`${formatTime(localEndTime.getUTCHours())}:${formatTime(localEndTime.getUTCMinutes())}`
		);

    let repeat = [];
    repeatPeriodAdmin.forEach((item) => {
      repeat.push(item.enable);
    });
    UTCOffset =
      localStartTime.getHours() - newStartUTCTime.substring(0, 2) < 0
        ? true
        : false;
    if (UTCOffset) {
      let lastRepeat = repeat[repeat.length - 1];
      for (let i = repeat.length - 2; i > 0; i--) {
        repeat[i + 1] = repeat[i];
      }
      repeat[1] = lastRepeat;
    }
    let weekly = [];
    if (repeat[0]) {
      weekly.push(`10-0`);
    } else {
      repeat.forEach((item, index) => {
        if (item) {
          weekly.push(`10-${index}`);
        }
      });
    }
		setWeekdayAdmin(weekly);
		// console.log('weekly = ', weekly);
	},[repeatPeriodAdmin]);

	// Update admin mailing time setting when start time or end time changed
	useEffect(()=>{
		if(repeatPeriodAdmin.length < 8){
			return;
		}
		let newRepeatPeriodAdmin = [...repeatPeriodAdmin];

		let startUTCTime = "";
		if(nNU(startUTCTimeAdmin)){
      newRepeatPeriodAdmin.forEach((item) => {
        item.startTime = startUTCTimeAdmin;
      });
			startUTCTime = startUTCTimeAdmin;
		}
		else{
      newRepeatPeriodAdmin.forEach((item) => {
        item.startTime = DEFAULT_START_TIME;
      });
			startUTCTime = DEFAULT_START_TIME;
		}

		let endUTCTime = "";
		if(nNU(endUTCTimeAdmin)){
			endUTCTime = endUTCTimeAdmin;
		}
		else{
			endUTCTime = DEFAULT_END_TIME;
		}

		let durationMinutes = calcDurationMinutes(startUTCTime, endUTCTime);
		if(durationMinutes < 1){
			durationMinutes = 24 * 60;
		}
		let duration = `${formatTime(Math.floor(durationMinutes / 60))}:${formatTime(durationMinutes % 60)}`;
		newRepeatPeriodAdmin.forEach((item) => {
			item.rangeTime = duration;
		});
		setRepeatPeriodAdmin(newRepeatPeriodAdmin);

		// console.log('duration = ', duration);

		/* eslint-disable react-hooks/exhaustive-deps */
	},[startUTCTimeAdmin, endUTCTimeAdmin]);

	// Update admin mailing time setting when weekday changed
	useEffect(()=>{
		if(repeatPeriodAdmin.length < 8){
			return;
		}
		let newRepeatPeriodAdmin = [...repeatPeriodAdmin];

		let arrEnable = [false, false, false, false, false, false, false, false];
		weekdayEnableAdmin.forEach((item) => {
			let dmy = parseInt(item.substring(3, 4), 10);
			arrEnable[dmy] = true;
		});
		if (UTCOffset) {
			let firstEnable = arrEnable[1];
			for (let i = 1; i < arrEnable.length - 1; i++) {
				arrEnable[i] = arrEnable[i + 1];
			}
			arrEnable[arrEnable.length - 1] = firstEnable;
		}
		newRepeatPeriodAdmin.forEach((item, index) => {
			item.enable = arrEnable[index];
		});

    setRepeatPeriodAdmin(newRepeatPeriodAdmin);

		// console.log('weekdayEnableAdmin = ', weekdayEnableAdmin);
		// console.log('Enable = ', arrEnable);

    /* eslint-disable react-hooks/exhaustive-deps */
	},[weekdayEnableAdmin]);

	// Update user mailing time setting when start time or end time changed

	// Update user mailing time setting when end time changed

	// Update user mailing time setting when weekday changed

	// Update start time, end time and weekday setting when admin mailing time setting changed
	useEffect(()=>{

		if(repeatPeriodUser.length < 8){
			return;
		}

		let newStartUTCTime = '';
		if(repeatPeriodUser[0].startTime.length > 0){
			newStartUTCTime = repeatPeriodUser[0].startTime;
		}
		else{
			newStartUTCTime = DEFAULT_START_TIME;
		}
    let localStartTime = new Date(
			convUTCStringFormat(newStartUTCTime)
		);
		setStartUTCTimeUser(newStartUTCTime);
		setStartTimeUser(
      `${formatTime(localStartTime.getHours())}:${formatTime(localStartTime.getMinutes())}`
		);

    let duration = '';
		if((repeatPeriodUser[0].startTime.length > 0) && (repeatPeriodUser[0].rangeTime.length > 0)){
			duration = calcNumberOfMinute(repeatPeriodUser[0].rangeTime);
		}
		else{
			duration = calcNumberOfMinute(DEFAULT_RANGE);
		}
    let localEndTime = new Date(
			convUTCStringFormat(newStartUTCTime)
		);
    localEndTime.setMinutes(localEndTime.getMinutes() + duration);
    setEndTimeUser(
      `${formatTime(localEndTime.getHours())}:${formatTime(localEndTime.getMinutes())}`
    );
		setEndUTCTimeUser(
			`${formatTime(localEndTime.getUTCHours())}:${formatTime(localEndTime.getUTCMinutes())}`
		);

    let repeat = [];
    repeatPeriodUser.forEach((item) => {
      repeat.push(item.enable);
    });
    UTCOffset2 =
      localStartTime.getHours() - newStartUTCTime.substring(0, 2) < 0
        ? true
        : false;
    if (UTCOffset2) {
      let lastRepeat = repeat[repeat.length - 1];
      for (let i = repeat.length - 2; i > 0; i--) {
        repeat[i + 1] = repeat[i];
      }
      repeat[1] = lastRepeat;
    }
    let weekly = [];
    if (repeat[0]) {
      weekly.push(`10-0`);
    } else {
      repeat.forEach((item, index) => {
        if (item) {
          weekly.push(`10-${index}`);
        }
      });
    }
		setWeekdayUser(weekly);

		/* eslint-disable react-hooks/exhaustive-deps */
	},[repeatPeriodUser]);

	// Update repeatPeriodUser setting when start time and end time changed
	useEffect(()=>{
		if(repeatPeriodUser.length < 8){
			return;
		}
		let newRepeatPeriodUser = [...repeatPeriodUser];

		let startUTCTime = "";
		if(nNU(startUTCTimeUser)){
      newRepeatPeriodUser.forEach((item) => {
        item.startTime = startUTCTimeUser;
      });
			startUTCTime = startUTCTimeUser;
		}
		else{
      newRepeatPeriodUser.forEach((item) => {
        item.startTime = DEFAULT_START_TIME;
      });
			startUTCTime = DEFAULT_START_TIME;
		}

		let endUTCTime = "";
		if(nNU(endUTCTimeUser)){
			endUTCTime = endUTCTimeUser;
		}
		else{
			endUTCTime = DEFAULT_END_TIME;
		}

		let durationMinutes = calcDurationMinutes(startUTCTime, endUTCTime);
		if(durationMinutes < 1){
			durationMinutes = 24 * 60;
		}
		let duration = `${formatTime(Math.floor(durationMinutes / 60))}:${formatTime(durationMinutes % 60)}`;
		newRepeatPeriodUser.forEach((item) => {
			item.rangeTime = duration;
		});
		setRepeatPeriodUser(newRepeatPeriodUser);

		// console.log('duration = ', duration);

		/* eslint-disable react-hooks/exhaustive-deps */
	},[startUTCTimeUser, endUTCTimeUser]);

	// Weekday setting User
	useEffect(()=>{
		if(repeatPeriodUser.length < 8){
			return;
		}
		let newRepeatPeriodUser = [...repeatPeriodUser];

		let arrEnable = [false, false, false, false, false, false, false, false];
		weekdayEnableUser.forEach((item) => {
			let dmy = parseInt(item.substring(3, 4), 10);
			arrEnable[dmy] = true;
		});
		if (UTCOffset2) {
			let firstEnable = arrEnable[1];
			for (let i = 1; i < arrEnable.length - 1; i++) {
				arrEnable[i] = arrEnable[i + 1];
			}
			arrEnable[arrEnable.length - 1] = firstEnable;
		}
		newRepeatPeriodUser.forEach((item, index) => {
			item.enable = arrEnable[index];
		});

    setRepeatPeriodUser(newRepeatPeriodUser);
    /* eslint-disable react-hooks/exhaustive-deps */
	},[weekdayEnableUser]);

	// Check / uncheck mail receive enable of management
	const handleMailEnableAdminCheckChanged = (index, e) => {
		let newMailEnableAdmin = [...mailEnableAdmin];
		newMailEnableAdmin[index] = e.target.checked;

		setMailEnableAdmin(newMailEnableAdmin);
		if((index === '0') || (index === '2') || (index === '4')){
			let newMailRepeatEnableAdmin = [...mailRepeatEnableAdmin];
			if(newMailEnableAdmin[index] === false){
				newMailRepeatEnableAdmin[index / 2] = false;
				setMailRepeatEnableAdmin(newMailRepeatEnableAdmin);
			}
		}
		let enableAll = true;
		// for(let i = 0; i < newMailEnableAdmin.length; i++){
		// 	if(newMailEnableAdmin[i] === false){
		// 		enableAll = false;
		// 		break;
		// 	}
		// }
		for(let i = 0; i < 8; i++){
			if(newMailEnableAdmin[i] === false){
				enableAll = false;
				break;
			}
		}
		setAllMailEnableAdmin(enableAll);
	};
	const handleAllMailEnableAdminCheckChanged = () => {
		let enableAll = !allMailEnableAdmin;
		setAllMailEnableAdmin(enableAll);
		let newMailEnableAdmin = [...mailEnableAdmin];
		if(enableAll === true){
			for(let i = 0; i < newMailEnableAdmin.length; i++){
				newMailEnableAdmin[i] = true;
			}
			// for(let i = 0; i < 8; i++){
			// 	newMailEnableAdmin[i] = true;
			// }
			setMailEnableAdmin(newMailEnableAdmin);
		}
		else{
			let allChecked = true;
			// for(let i = 0; i < newMailEnableAdmin.length; i++){
			// 	if(newMailEnableAdmin[i] === false){
			// 		allChecked = false;
			// 		break;
			// 	}
			// }
			for(let i = 0; i < 8; i++){
				if(newMailEnableAdmin[i] === false){
					allChecked = false;
					break;
				}
			}
			if(allChecked === true){
				// for(let i = 0; i < newMailEnableAdmin.length; i++){
				// 	newMailEnableAdmin[i] = false;
				// }
				for(let i = 0; i < 8; i++){
					newMailEnableAdmin[i] = false;
				}
				setMailEnableAdmin(newMailEnableAdmin);
				let newMailRepeatEnableAdmin = [...mailRepeatEnableAdmin];
				for(let i = 0; i < newMailRepeatEnableAdmin.length; i++){
					newMailRepeatEnableAdmin[i] = false;
				}
				setMailRepeatEnableAdmin(newMailRepeatEnableAdmin);
			}
		}
	};

	// Check / uncheck mail receive enable of user
	const handleMailEnableUserCheckChanged = (index, e) => {
		let newMailEnableUser = [...mailEnableUser];
		newMailEnableUser[index] = e.target.checked;
		setMailEnableUser(newMailEnableUser);
		if((index === '0') || (index === '2') || (index === '4')){
			let newMailRepeatEnableUser = [...mailRepeatEnableUser];
			if(newMailEnableUser[index] === false){
				newMailRepeatEnableUser[index / 2] = false;
				setMailRepeatEnableUser(newMailRepeatEnableUser);
			}
		}
		let enableAll = true;
		for(let i = 0; i < 7; i++){
			if(newMailEnableUser[i] === false){
				enableAll = false;
				break;
			}
		}
		setAllMailEnableUser(enableAll);
	};
	const handleAllMailEnableUserCheckChanged = () => {
		let enableAll = !allMailEnableUser;
		setAllMailEnableUser(enableAll);
		let newMailEnableUser = [...mailEnableUser];
		if(enableAll === true){
			for(let i = 0; i < 7; i++){
				newMailEnableUser[i] = true;
			}
			setMailEnableUser(newMailEnableUser);
		}
		else{
			let allChecked = true;
			for(let i = 0; i < 7; i++){
				if(newMailEnableUser[i] === false){
					allChecked = false;
					break;
				}
			}
			if(allChecked === true){
				for(let i = 0; i < 7; i++){
					newMailEnableUser[i] = false;
				}
				setMailEnableUser(newMailEnableUser);
				let newMailRepeatEnableUser = [...mailRepeatEnableUser];
				for(let i = 0; i < newMailRepeatEnableUser.length; i++){
					newMailRepeatEnableUser[i] = false;
				}
				setMailRepeatEnableUser(newMailRepeatEnableUser);
			}
		}
	};

	// Update mail repeat interval
	const handleUpdateMailRepeatIntervalAdmin = (index, e) => {
		let newMailRepeatIntervalAdmin = [...mailRepeatIntervalAdmin];
		newMailRepeatIntervalAdmin[index / 2] = +e.target.value;
		setMailRepeatIntervalAdmin(newMailRepeatIntervalAdmin);
	};
	const handleUpdateAllMailRepeatIntervalAdmin = (e) => {
		let newMailRepeatIntervalAdmin = [];
		for(let i = 0; i< 3; i++){
			newMailRepeatIntervalAdmin.push(+e.target.value);
		}
		setMailRepeatIntervalAdmin(newMailRepeatIntervalAdmin);
	};

	// Check / uncheck mail repeat enable of management
	const handleMailRepeatEnableAdminCheckChanged = (index, e) => {
		let newMailRepeatEnableAdmin = [...mailRepeatEnableAdmin];
		// if(mailEnableAdmin[index] === false){
		// 	newMailRepeatEnableAdmin[index / 2] = false;
		// }
		// else{
		// 	newMailRepeatEnableAdmin[index / 2] = e.target.checked;
		// }
		newMailRepeatEnableAdmin[index / 2] = e.target.checked;
		setMailRepeatEnableAdmin(newMailRepeatEnableAdmin);
	};

	// Check / uncheck mail repeat enable of user
	const handleMailRepeatEnableUserCheckChanged = (index, e) => {
		let newMailRepeatEnableUser = [...mailRepeatEnableUser];
		// if(mailEnableUser[index] === false){
		// 	newMailRepeatEnableUser[index / 2] = false;
		// }
		// else{
		// 	newMailRepeatEnableUser[index / 2] = e.target.checked;
		// }
		newMailRepeatEnableUser[index / 2] = e.target.checked;
		setMailRepeatEnableUser(newMailRepeatEnableUser);
	};

	// Update mail repeat interval
	const handleUpdateMailRepeatIntervalUser = (index, e) => {
		let newMailRepeatIntervalUser = [...mailRepeatIntervalUser];
		newMailRepeatIntervalUser[index / 2] = +e.target.value;
		setMailRepeatIntervalUser(newMailRepeatIntervalUser);
	};
	const handleUpdateAllMailRepeatIntervalUser = (e) => {
		let newMailRepeatIntervalUser = [];
		for(let i = 0; i < 3; i++){
			newMailRepeatIntervalUser.push(+e.target.value);
		}
		setMailRepeatIntervalUser(newMailRepeatIntervalUser);
	};

	// Add / remove mail address of management
	const handleAddMailAddrAdmin = () => {
		focusElement('.input');
		setMailAddrListAdmin('');
		let isMatchingMail = false;
		if(mailAddrAdmin.length > 50){
			showToast(string.MAIL_LENGTH_ERROR, 'warning');
			return;
		}
		mailAddrAdmin.forEach((mail) => {
			if(mailAddrListAdmin === mail){
				isMatchingMail = true;
				return;
			}
		});
		if(isMatchingMail){
			return;
		}
		if(!mailAddrListAdmin){
			return;
		}
		if(!emailValidate(mailAddrListAdmin)){
			return;
		}
		let newMailAddrAdmin = [];
		if(mailAddrAdmin.length < 1){
			newMailAddrAdmin = [mailAddrListAdmin];
		}
		else if(mailAddrAdmin.length < 2){
			if(!emailValidate(mailAddrAdmin[0])){
				newMailAddrAdmin = [mailAddrListAdmin];
			}
			else{
				newMailAddrAdmin = [...mailAddrAdmin, mailAddrListAdmin];
			}
		}
		else{
			newMailAddrAdmin = [...mailAddrAdmin, mailAddrListAdmin];
		}
		setMailAddrAdmin(newMailAddrAdmin);
		// console.log(newMailAddrAdmin);
	};
	const handleRemoveMailAddrAdmin = (mailItem) => {
		let newMailAddrAdmin = [...mailAddrAdmin];
		newMailAddrAdmin.splice(mailItem, 1);
		setMailAddrAdmin(newMailAddrAdmin);
		// console.log(newMailAddrAdmin);
	};

	// Add / remove mail address of user
	const handleAddMailAddrUser = () => {
		focusElement('.input');
		setMailAddrListUser('');
		let isMatchingMail = false;
		if(mailAddrUser.length > 50){
			showToast(string.MAIL_LENGTH_ERROR, 'warning');
			return;
		}
		mailAddrUser.forEach((mail) => {
			if(mailAddrListUser === mail){
				isMatchingMail = true;
				return;
			}
		});
		if(isMatchingMail){
			return;
		}
		if(!mailAddrListUser){
			return;
		}
		if(!emailValidate(mailAddrListUser)){
			return;
		}
		let newMailAddrUser = [];
		if(mailAddrUser.length < 1){
			newMailAddrUser = [mailAddrListUser];
		}
		else if(mailAddrUser.length < 2){
			if(!emailValidate(mailAddrUser[0])){
				newMailAddrUser = [mailAddrListUser];
			}
			else{
				newMailAddrUser = [...mailAddrUser, mailAddrListUser];
			}
		}
		else{
			newMailAddrUser = [...mailAddrUser, mailAddrListUser];
		}
		setMailAddrUser(newMailAddrUser);
		// console.log(newMailAddrUser);
	};
	const handleRemoveMailAddrUser = (mailItem) => {
		let newMailAddrUser = [...mailAddrUser];
		newMailAddrUser.splice(mailItem, 1);
		setMailAddrUser(newMailAddrUser);
	};

	// Save mail setting
	useEffect(() => {

		if(isSaveClicked === true){
			handleSaveMailSetting();
			setTimeout(() => setIsSaveClicked(false),0);
		}

	}, [isSaveClicked]);
	const handleSaveMailSetting = () => {
		let alertEnable = [...mailEnableAdmin];
		for(let i = 8; i < alertEnable.length; i++){
			alertEnable[i] = true;
		}
		let alertRepeat = [];
		for(let i = 0; i < 3; i++){
			alertRepeat.push(mailRepeatIntervalAdmin[0]);
		}
		let alertEnableNormal = [...mailEnableUser];
		for(let i = 7; i < alertEnableNormal.length; i++){
			alertEnableNormal[i] = false;
		}
		let alertRepeatNormal = [];
		for(let i = 0; i < 3; i++){
			alertRepeatNormal.push(mailRepeatIntervalUser[0]);
		}

		const dkSf03wt2MailSettingData = {
      deviceId,
			alertEnable: alertEnable,
			alertRepeatEnable: mailRepeatEnableAdmin,
			alertRepeat: alertRepeat,
			emailAddresses: mailAddrAdmin,
			repeatPeriod: repeatPeriodAdmin,
			emailTemplateContent: userMailTemplate,
			alertEnableNormal: alertEnableNormal,
			alertRepeatEnableNormal: mailRepeatEnableUser,
			alertRepeatNormal: alertRepeatNormal,
			emailAddressesNormal: mailAddrUser,
			repeatPeriodNormal: repeatPeriodUser,
		};
		// wait(1000);
		// console.log('Mail setting: ', dkSf03wt2MailSettingData);
    sendPost({ url: Urls.sf03wt2.storeDeviceNotifyConfiguration, body: dkSf03wt2MailSettingData }).then(
      (res) => {}
    );
	};
	const handleSaveMailSettingClick = () => {
		let isClicked = isSaveClicked;
		if(isClicked === false){
			setIsSaveClicked(true);
		}
	};

	// Make e-mail template
	const showMailTemplate = (index) => {
		let mail = mailTemplate;
		let userAddIndex = mailUserAddIndex[index];
		mail.title = dksf03wt2MailTemplate[index].title;
		mail.header = dksf03wt2MailTemplate[index].header;
		mail.content = dksf03wt2MailTemplate[index].content.replace("x.xxV", mailTemplateHeaderSample[0]);
		mail.content = mail.content.replace("xx月xx日　xx時xx分", mailTemplateHeaderSample[2]);
		mail.content = mail.content.replace("xx時xx分", mailTemplateHeaderSample[1]);
		if(nNU(userMailTemplate) && (userAddIndex < userMailTemplate.length)){
			mail.content = mail.content.replace("USERADDCONTENT", userMailTemplate[userAddIndex]);
		}
		else{
			mail.content = mail.content.replace("USERADDCONTENT", "");
		}
    return(
      <>
        <h3>{mail.title}</h3>
        <p>
          {mail.header.split(/AssetName (Sigfox ID)/g).map((key, e) => (
            <span key={e + 1}>
							{`${mail.header.split(/A/g)[e]} ${
								deviceData.model
							} ${' ('} ${
								deviceData.sigfoxId
							} ${')'}`}
							<br />
            </span>
          ))}
        </p>
        <p>
          {mail.content.split(/(?:\r\n|\r|\n)/g).map((key, e) => (
            <span key={e + 20}>
            {mail.content.split(/(?:\r\n|\r|\n)/g)[e]}{' '}
            {mail.content.split(/(?:\r\n|\r|\n)/g)[e].length > 0 ? (
              <br></br>
            ) : (
              ''
            )}
            </span>
          ))}
          </p>
          <p>
          {mail.footer.split(/(?:\r\n|\r|\n)/g).map((key, e) => (
            <span key={e + 100}>
            {mail.footer.split(/(?:\r\n|\r|\n)/g)[e]} <br />
            </span>
          ))}
        </p>
      </>
    );
	};

	// Get device data
  const getDeviceData = () => {
    setTimeout(() => {
      setLoadForm(false);
    }, 500);
    sendRequest({
      url: `${Urls.device.info}/${deviceId}`,
      background: true,
    }).then((response) => {
      const { result } = response;
      let deviceLocation = result?.deviceLocation;
      try {
        if (!!deviceLocation) {
          deviceLocation = JSON.parse(deviceLocation);
        }
      } catch (e) {}
      setDeviceData({ ...result, deviceLocation });
      setTimeout(() => {
        setLoadForm(true);
      }, 500);
    });
  };

	// Show mail template
	const handleShowMailTemplate = (index) => {
		getDeviceData();
		setTemplateIndex(+mailTemplateIndex[index]);
		setMailShow(true);
	}

	// Upload mail template
  const handleUpload = (index) => {
    switch (+index) {
      case 0:
        mailInput0.current?.click();
        break;
      case 1:
        mailInput1.current?.click();
        break;
      case 2:
        mailInput2.current?.click();
        break;
      case 3:
        mailInput3.current?.click();
        break;
      case 4:
        mailInput4.current?.click();
        break;
      case 5:
        mailInput5.current?.click();
        break;
      case 6:
        mailInput6.current?.click();
        break;
      default:
        mailInput0.current?.click();
        break;
    }
  };

	// Display upload file dialog
	const handleDisplayFileDetails = (index) => {
		// console.log(index);
		let templateIndex = 0;
    const reader = new FileReader();
    reader.onload = (e) => {
			// console.log('Get file');
      //const title = reader.result.split(/(?:\r\n|\r|\n)/g)[1] + '\n';
      let content = '';
      for (let i = 0; i < reader.result.split(/(?:\r\n|\r|\n)/g).length; i++) {
        content = content + reader.result.split(/(?:\r\n|\r|\n)/g)[i];
				if(i < reader.result.split(/(?:\r\n|\r|\n)/g) - 1){
					content = content + '\n';
				}
      }
			content = content.replace('本文：\n','');
			content = content.replace('本文：','');
			let newUserMailTemplate = [...userMailTemplate];
			newUserMailTemplate[templateIndex] = content;
			// console.log('Template: ', newUserMailTemplate[templateIndex]);
			setUserMailTemplate(newUserMailTemplate);
    };
		// console.log('Read file');
    switch (index) {
      case 0:
        reader.readAsText(mailInput0.current.files[0]);
				templateIndex = 0;
        break;
      case 1:
        reader.readAsText(mailInput1.current.files[0]);
				templateIndex = 1;
        break;
      case 2:
        reader.readAsText(mailInput2.current.files[0]);
				templateIndex = 2;
        break;
      case 3:
        reader.readAsText(mailInput3.current.files[0]);
				templateIndex = 3;
        break;
      case 4:
        reader.readAsText(mailInput4.current.files[0]);
				templateIndex = 4;
        break;
      case 5:
        reader.readAsText(mailInput5.current.files[0]);
				templateIndex = 5;
        break;
      case 6:
        reader.readAsText(mailInput6.current.files[0]);
				templateIndex = 6;
        break;
      default:
        reader.readAsText(mailInput0.current.files[0]);
				templateIndex = 0;
        break;
    }
	};

	// Download mail template
  const downloadTxtFile = (index) => {
    const element = document.createElement('a');
    const dataTemplate = userMailTemplate[index];

    const file = new Blob(
      ['本文：', dataTemplate],
      {
        type: 'text/plain;charset=utf-8',
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = `MailTemplate${index}.txt`;
    document.body.appendChild(element);
    element.click();
  };

	// Handle start time changed for Admin
	const handleSetStartUTCTimeAdmin = (e) => {
    UTCOffset = false;
    UTCOffset = e[0].getHours() - e[0].getUTCHours() < 0 ? true : false;
		// console.log('UTCOffset = ', UTCOffset);
		setStartUTCTimeAdmin(
			`${formatTime(e[0].getUTCHours())}:${formatTime(e[0].getUTCMinutes())}`
		);
	};

	// Handle end time changed for Admin
	const handleSetEndUTCTimeAdmin = (e) => {
		setEndUTCTimeAdmin(
			`${formatTime(e[0].getUTCHours())}:${formatTime(e[0].getUTCMinutes())}`
		);
	};

	// Handle start time changed for User
	const handleSetStartUTCTimeUser = (e) => {
    UTCOffset2 = false;
    UTCOffset2 = e[0].getHours() - e[0].getUTCHours() < 0 ? true : false;
		setStartUTCTimeUser(
			`${formatTime(e[0].getUTCHours())}:${formatTime(e[0].getUTCMinutes())}`
		);
	};

	// Handle end time changed for User
	const handleSetEndUTCTimeUser = (e) => {
		setEndUTCTimeUser(
			`${formatTime(e[0].getUTCHours())}:${formatTime(e[0].getUTCMinutes())}`
		);
	};


	// UI return
  return(
    <>
      <Container className="sf03wt2-setting" fluid>
        <Card>
          <Card.Body>
            <Row
              // className='border-responsive'
              id='no-responsive'
            >
              <Col
                className='enable-check title-width'
								sm={4}
								xs={2}
              >
                {''}
              </Col>
              <Col
                className='enable-check'
								sm={3}
								xs={2}
								id="label-title"
              >
                {'管理者の設定'}
              </Col>
              <Col
                className='enable-check'
								sm={3}
								xs={2}
								id="label-title"
              >
                {'一般の設定'}
              </Col>
              <Col
                className='enable-check'
								sm={2}
								xs={1}
								id="label-title"
              >
                {'テンプレート'}
              </Col>
            </Row>
            <Row
              // className='border-responsive'
              id='no-responsive'
            >
              <Col
                className='enable-check title-width'
								xs={3}
								sm={4}
								id="label-title"
              >
                <span style={{ fontSize: 24 }}>{`①　`}</span>
                <span>{`${'送信内容'}`}</span>
              </Col>
              <Col
                className='enable-check'
								sm={3}
								xs={2}
              >
                <Row
                >
                  <Col
										sm={3}
										xs={2}
									>{'有効'}</Col>
                  <Col
										sm={4}
										xs={3}
									>{'繰り返し'}</Col>
                  <Col
										//sm={2}
									>{'送信間隔'}</Col>
                </Row>
              </Col>
              <Col
                className='enable-check'
								sm={3}
								xs={2}
              >
                <Row
                >
                  <Col
										sm={3}
										xs={2}
									>{'有効'}</Col>
                  <Col
										sm={4}
										xs={3}
									>{'繰り返し'}</Col>
                  <Col
										//sm={2}
									>{'送信間隔'}</Col>
                </Row>
              </Col>
              <Col
                className='enable-check'
								sm={2}
								xs={1}
              >
                {''}
              </Col>
            </Row>
						<Row
							className='border-responsive'
						>
							<Col
								className='enable-check title-width'
								id='no-responsive'
								sm={4}
								xs={3}
							>
								<span>{`　　　`}</span>
								<span>{`全て`}</span>
							</Col>
							<Col
								className='enable-check'
								sm={3}
								xs={2}
							>
								<Row
									className='border-responsive'
								>
									<Col
										sm={3}
										xs={2}
									>
										{<Form.Check
											type='switch'
											checked={allMailEnableAdmin}
											onChange={() => handleAllMailEnableAdminCheckChanged()}
										></Form.Check>}
									</Col>
									<Col
										sm={4}
										xs={3}
									></Col>
									<Col
										//sm={2}
									>
										<Form.Select
											size='sm'
											value={nNU(mailRepeatIntervalAdmin[0]) ? mailRepeatIntervalAdmin[0]:'5'}
											onChange={(e) => {handleUpdateAllMailRepeatIntervalAdmin(e)}}
										>
											{repeatTime.map((item, inx) => (
												<option
													key={inx}
													value={item}
												>
													{`${item}分 `}
												</option>
											))}
										</Form.Select>
									</Col>
								</Row>
							</Col>
							<Col
								className='enable-check'
								sm={3}
								xs={2}
							>
								<Row
									className='border-responsive'
								>
									<Col
										sm={3}
										xs={2}
									>
										{<Form.Check
											type='switch'
											checked={allMailEnableUser}
											onChange={() => handleAllMailEnableUserCheckChanged()}
										></Form.Check>}
									</Col>
									<Col
										sm={4}
										xs={3}
									></Col>
									<Col
										//sm={2}
									>
										<Form.Select
											size='sm'
											value={nNU(mailRepeatIntervalUser[0]) ? mailRepeatIntervalUser[0]:'5'}
											onChange={(e) => {handleUpdateAllMailRepeatIntervalUser(e)}}
										>
											{repeatTime.map((item, inx) => (
												<option
													key={inx}
													value={item}
												>
													{`${item}分 `}
												</option>
											))}
										</Form.Select>
									</Col>
								</Row>
							</Col>
							<Col
								className='enable-check'
								sm={2}
								xs={1}
								// key={index}
							>
								<Row
								></Row>
							</Col>
						</Row>
            {mailTitleIndex.map((index) => (
              <React.Fragment key={parseInt(index, 10)}>
                <Row
                  className='border-responsive'
                >
                  <Col
                    className='enable-check title-width'
                    id='no-responsive'
										sm={4}
										xs={3}
									>
										<span>{`　　　`}</span>
										<span>{`${mailTitle[index]}`}</span>
                  </Col>
                  <Col
                    className='enable-check'
										sm={3}
										xs={2}
                  >
										<Row
											className='border-responsive'
										>
											<Col
												sm={3}
												xs={2}
											>
												{(parseInt(index,10) < 8) && (<Form.Check
													type='switch'
													checked={nNU(mailEnableAdmin[index])?mailEnableAdmin[index]:false}
													onChange={(e) => handleMailEnableAdminCheckChanged(index,e)}
												></Form.Check>)}
											</Col>
											<Col
												sm={4}
												xs={3}
											>
												{((index === '0') || (index === '2') || (index === '4')) && <Form.Check
													type='switch'
													checked={nNU(mailRepeatEnableAdmin[index / 2])?mailRepeatEnableAdmin[index / 2]:false}
													onChange={(e) => handleMailRepeatEnableAdminCheckChanged(index,e)}
												></Form.Check>}
											</Col>
											<Col
												//sm={2}
											>
												{/* {((index === '0') || (index === '2') || (index === '4')) && <Form.Select
													size='sm'
													value={nNU(mailRepeatIntervalAdmin[index/2])?mailRepeatIntervalAdmin[index/2]:'5'}
													onChange={(e) => {handleUpdateMailRepeatIntervalAdmin(index,e)}}
												>
													{repeatTime.map((item, inx) => (
														<option
															key={inx}
															value={item}
														>
															{`${item}分 `}
														</option>
													))}
												</Form.Select>} */}
											</Col>
										</Row>
                  </Col>
                  <Col
                    className='enable-check'
										sm={3}
										xs={2}
                  >
										<Row
											className='border-responsive'
										>
											<Col
												sm={3}
												xs={2}
											>
												{(parseInt(index,10) < 7) && (<Form.Check
													type='switch'
													checked={nNU(mailEnableUser[index])?mailEnableUser[index]:false}
													onChange={(e) => handleMailEnableUserCheckChanged(index,e)}
												></Form.Check>)}
											</Col>
											<Col
												sm={4}
												xs={3}
											>
												{((index === '0') || (index === '2') || (index === '4')) && <Form.Check
													type='switch'
													checked={nNU(mailRepeatEnableUser[index / 2])?mailRepeatEnableUser[index / 2]:false}
													onChange={(e) => handleMailRepeatEnableUserCheckChanged(index,e)}
												></Form.Check>}
											</Col>
											<Col
												//sm={2}
											>
												{/* {((index === '0') || (index === '2') || (index === '4')) && <Form.Select
													size='sm'
													value={nNU(mailRepeatIntervalUser[index/2])?mailRepeatIntervalUser[index/2]:'5'}
													onChange={(e) => {handleUpdateMailRepeatIntervalUser(index,e)}}
												>
													{repeatTime.map((item, inx) => (
														<option
															key={inx}
															value={item}
														>
															{`${item}分 `}
														</option>
													))}
												</Form.Select>} */}
											</Col>
										</Row>
                  </Col>
                  <Col
                    className='enable-check'
										sm={2}
										xs={1}
										key={index}
                  >
										<Row
										>
											<div
												className="button-up-download"
												key={index}
											>
												<div
													className="sm"
												>
													<input
														ref={
															index === '6'
																? mailInput6
																: index === '5'
																? mailInput5
																: index === '4'
																? mailInput4
																: index === '3'
																? mailInput3
																: index === '2'
																? mailInput2
																: index === '1'
																? mailInput1
																: mailInput0
														}
														onChange={() => handleDisplayFileDetails(+index)}
														className="d-none"
														type="file"
													/>
												</div>
												<Button
													size="sm"
													style={{
														margin:'2px',
													}}
													onClick={() => handleShowMailTemplate(+index)}
												>
													<FaAlignJustify />
												</Button>
												{(parseInt(index,10) < 7) && (<Button
													size="sm"
													style={{
														margin:'2px',
													}}
													onClick={() => handleUpload(+index)}
												>
													<FaFileUpload />
												</Button>)}
												{(parseInt(index,10) < 7) && (<Button
													size="sm"
													style={{
														margin:'2px',
													}}
													onClick={() => downloadTxtFile(+index)}
												>
													<FaFileDownload />
												</Button>)}
											</div>
										</Row>
                  </Col>
                </Row>
              </React.Fragment>
            ))}
						<Row
              className='border-responsive'
						>
              <Col
                className='enable-check title-width'
								sm={4}
								xs={2}
								id="label-title"
              >
                <span style={{ fontSize: 24 }}>{`②　`}</span>
                <span>{`${'適応時間'}`}</span>
              </Col>
              <Col
                className='enable-check'
								sm={3}
								xs={2}
              >
								<div className="time-selector ">
									{startTimeAdmin && (
										<Flatpickr
											className="time-picker"
											options={{
												enableTime: true,
												noCalendar: true,
												dateFormat: 'H:i',
												time_24hr: true,
												defaultDate: startTimeAdmin,
												minTime: '00:00',
												maxTime: '23:59',
												minuteIncrement: 1,
											}}
											style={{
												maxWidth:'3.5rem',
											}}
											onChange={handleSetStartUTCTimeAdmin}
										/>
									)}
									<span>~</span>
									{endTimeAdmin && (
										<Flatpickr
											className="time-picker "
											options={{
												enableTime: true,
												noCalendar: true,
												dateFormat: 'H:i',
												time_24hr: true,
												defaultDate: endTimeAdmin,
												minTime: '00:00',
												maxTime: '23:59',
												minuteIncrement: 1,
											}}
											style={{
												maxWidth:'3.5rem',
											}}
											onChange={handleSetEndUTCTimeAdmin}
										/>
									)}
								</div>
              </Col>
              <Col
                className='enable-check'
								sm={3}
								xs={2}
              >
								<div className="time-selector ">
									{startTimeUser && (
										<Flatpickr
											className="time-picker"
											options={{
												enableTime: true,
												noCalendar: true,
												dateFormat: 'H:i',
												time_24hr: true,
												defaultDate: startTimeUser,
												minTime: '00:00',
												maxTime: '23:59',
												minuteIncrement: 1,
											}}
											style={{
												maxWidth:'3.5rem',
											}}
											onChange={handleSetStartUTCTimeUser}
										/>
									)}
									<span>~</span>
									{endTimeUser && (
										<Flatpickr
											className="time-picker "
											options={{
												enableTime: true,
												noCalendar: true,
												dateFormat: 'H:i',
												time_24hr: true,
												defaultDate: endTimeUser,
												minTime: '00:00',
												maxTime: '23:59',
												minuteIncrement: 1,
											}}
											style={{
												maxWidth:'3.5rem',
											}}
											onChange={handleSetEndUTCTimeUser}
										/>
									)}
								</div>
              </Col>
              <Col
                className='enable-check'
								sm={2}
								xs={1}
              >
                {''}
              </Col>
						</Row>
						<Row
              className='border-responsive'
						>
              <Col
                className='enable-check title-width'
								sm={4}
								xs={2}
								id="label-title"
              >
                <span style={{ fontSize: 24 }}>{`③　`}</span>
                <span>{`${'適応曜日'}`}</span>
              </Col>
              <Col
                className='enable-check'
								sm={3}
								xs={2}
              >
                <Weekday
                  className="browser-mode"
                  id2={`${1}`}
                  id={`${10}`}
                  value={weekdayAdmin}
                  callback={(val) => {
                    setWeekdayEnableAdmin(val);
                  }}
									title={true}
                ></Weekday>
              </Col>
              <Col
                className='enable-check'
								sm={3}
								xs={2}
              >
                <Weekday
                  className="browser-mode"
                  id2={`${2}`}
                  id={`${10}`}
                  value={weekdayUser}
                  callback={(val) => {
                    setWeekdayEnableUser(val);
                  }}
									title={true}
                ></Weekday>
              </Col>
              <Col
                className='enable-check'
								sm={2}
								xs={1}
              >
                {'　'}
              </Col>
						</Row>
						<Row
              className='border-responsive'
						>
              <Col
								xs={2}
								sm={4}
								id="label-title"
							>
                <span style={{ fontSize: 24 }}>{`④　`}</span>
                <span>{`${string.ACCOUNT_MAIL}`}</span>
              </Col>
              <Col
                className='enable-check'
								sm={3}
								xs={2}
              >
                <div
									className="input-group"
								>
                  <input
                    placeholder="メールアドレスを入力してください"
                    type="text"
                    className="input form-control"
                    value={mailAddrListAdmin}
                    onChange={(e) => setMailAddrListAdmin(e.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleAddMailAddrAdmin();
                      }
                    }}
                  />
                  <Button
										size="sm"
										onClick={handleAddMailAddrAdmin}
									>
                    {string.ADD}
                  </Button>
                </div>
								<div
									className="mail-list"
								>
									<Row
									>
										{mailAddrAdmin.map((mail, index) => (!!emailValidate(mail) && (
											<Col
												sm={10}
												xs={4}
												key={index}
											>
												<span>{mail}</span>
												<Button
													size="sm"
													variant="outline-danger"
													onClick={() => handleRemoveMailAddrAdmin(index)}
												>
													x
												</Button>
											</Col>)
										))}
									</Row>
								</div>
              </Col>
              <Col
                className='enable-check'
								sm={3}
								xs={2}
              >
                <div
									className="input-group"
								>
                  <input
                    placeholder="メールアドレスを入力してください"
                    type="text"
                    className="input form-control"
                    value={mailAddrListUser}
                    onChange={(e) => setMailAddrListUser(e.target.value)}
                    onKeyPress={(event) => {
                      if (event.key === 'Enter') {
                        handleAddMailAddrUser();
                      }
                    }}
                  />
                  <Button
										size="sm"
										onClick={handleAddMailAddrUser}
									>
                    {string.ADD}
                  </Button>
                </div>
								<div
									className="mail-list"
								>
									<Row
									>
										{mailAddrUser.map((mail, index) => (!!emailValidate(mail) && (
											<Col
												sm={10}
												xs={4}
												key={index}
											>
												<span>{mail}</span>
												<Button
													size="sm"
													variant="outline-danger"
													onClick={() => handleRemoveMailAddrUser(index)}
												>
													x
												</Button>
											</Col>)
										))}
									</Row>
								</div>
              </Col>
              <Col
                className='enable-check'
								sm={2}
								xs={1}
              >
                {'　'}
              </Col>
						</Row>
						<Row
							className='border-responsive'
						>
							<div className="button-set">
								<Button
									onClick={handleSaveMailSettingClick}
								>{'保存'}</Button>
							</div>
						</Row>
          </Card.Body>
        </Card>
      </Container>
			<Modal
				show={mailShow}
				onHide={() => setMailShow(false)}
				body={showMailTemplate(templateIndex)}
			/>
		</>
  );
};

export default DeviceDkSf03Wt2MailSetting;