import axios from 'axios';
import { store } from 'redux/store';
// import jwt_decode from 'jwt-decode';
import Urls from 'util/urls';
import { formatDate, showLoader, showToast, signOut } from './utils';

/* eslint-disable */
Date.prototype.toJSON = function () {
  return formatDate(this, 'YYYY-MM-DDTHH:mm:ss.SSS');
};
/* eslint-enable */

export const RESPONSE_STATUS_SUCCESS = 'SUCCESS';
export const RESPONSE_STATUS_ERROR = 'ERROR';
// const RESPONSE_STATUS_EXCEPTION = 'EXCEPTION';

// instance
const instance = axios.create({
	baseURL: Urls.baseUrl,
	withCredentials: false,
	headers: {
		'Access-Control-Allow-Origin' : '*',
		'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	}
});
let currentRequestCount = 0;

const startRequest = (dontCountFlag) => {
  if (dontCountFlag) return;
  currentRequestCount++;
  showLoader();
};

const endRequest = (dontCountFlag) => {
  if (dontCountFlag) return;
  currentRequestCount--;
  if (currentRequestCount < 1) {
    currentRequestCount = 0;
    showLoader(false);
  }
};

export const sendPost = async (props) => {
  return await sendRequest({ ...props, method: 'POST' });
};

export const sendPut = async (props) => {
  return await sendRequest({ ...props, method: 'PUT' });
};

const sendRequest = async ({
  method = 'GET',
  body,
  url,
  headers,
  params,
  background = false,
  withToken = true,
  isRaw = false,
}) => {
  // decamelizeKeys
  // body = decamelizeKeys(body);
  // params = decamelizeKeys(params);

  let token;
  let requestHeader = { ...headers };

  if (withToken) {
    // check user login
    let state = store.getState();
    if (!state?.user?.currentUser?.token) {
      // need login
      signOut();
      return Promise.reject();
    }
    token = state.user.currentUser.token;
    // check token expired time
    // let { exp } = jwt_decode(token);

    requestHeader.Authorization = `Bearer ${token}`;
  }

  startRequest(background);
  let response;
  try {
    response = await instance({
      method: method.toLowerCase(),
      url,
      data: body,
      headers: requestHeader,
      params,
    });
    endRequest(background);

    if (isRaw) {
      return response.data;
    }
    let { status, message } = response.data;
    if (status === RESPONSE_STATUS_SUCCESS) {
      return response.data;
    } else if (status === RESPONSE_STATUS_ERROR && message) {
      showToast(message);
      return Promise.reject(message);
    }
    // showToast(message || 'Unknown exception');
    showToast('Unknown exception');
    // return Promise.reject(message || 'Unknown exception');
    return Promise.reject('Unknown exception');
  } catch (ex) {
    endRequest(background);
    response = ex.response;
    let { status, message } = response?.data || {};
    if (
      withToken &&
      response?.status === 401 &&
      response?.data?.message === 'Unauthorized'
    ) {
      signOut();
      return Promise.reject(ex);
    }
    if (status === RESPONSE_STATUS_ERROR && !!message) {
      showToast(message);
    } else {
      showToast(message || 'Unknown exception');
    }

    return Promise.reject(ex);
  }
};

export default sendRequest;
