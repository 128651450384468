import 'flatpickr/dist/themes/material_blue.css';
import React, { useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import string from 'util/string';
import {
  calcDurationMinutes,
  calcNumberOfMinute,
  convUTCStringFormat,
  formatTime,
  nNU,
} from 'util/utils';
import Arrow from './Arrow';
import Weekday from './Weekday';
const thresholdTbl = [
  20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
  39, 40,
];
const repeatTime = [5, 10, 15, 30, 60];
const repeat = [0, 1, 2, 3, 4, 5];
const labelText = ['レベル４', 'レベル３', 'レベル２', ' レベル１'];
const days = ['全', '日', '月', '火', '水', '木', '金', '土'];
let UTCOffset = false;

const WBGTSettei = ({
  thresholdValue = [],
  setThresholdValue,
  enableCheck = [],
  setEnableCheck,
  repeatPeriod = [],
  setRepeatPeriod,
  repeatValue = [],
  setRepeatValue,
  repeatTimeValue = [],
  setRepeatTimeValue,
}) => {
  const [valueWeekly, setValueWeekly] = useState([]);
  const [enableWeekly, setEnableWeekly] = useState([]);
  const [startTime, setStartTime] = useState();
  const [startUTCTime, setStartUTCTime] = useState();
  const [endTime, setEndTime] = useState();

  useEffect(() => {
    if (repeatPeriod.length < 8) return;
    const localStartTime = new Date(
      convUTCStringFormat(repeatPeriod[0].startTime)
    );
    setStartTime(
      `${formatTime(localStartTime.getHours())}:${formatTime(
        localStartTime.getMinutes()
      )}`
    );
    const localEndTime = new Date(
      convUTCStringFormat(repeatPeriod[0].startTime)
    );
    let duration = calcNumberOfMinute(repeatPeriod[0].rangeTime);
    localEndTime.setMinutes(localEndTime.getMinutes() + duration);
    setEndTime(
      `${formatTime(localEndTime.getHours())}:${formatTime(
        localEndTime.getMinutes()
      )}`
    );
    let repeat = [];

    repeatPeriod.forEach((item) => {
      repeat.push(item.enable);
    });
    // console.log(repeatPeriod);
    UTCOffset =
      localStartTime.getHours() - repeatPeriod[0].startTime.substring(0, 2) < 0
        ? true
        : false;
    if (UTCOffset) {
      let lastRepeat = repeat[repeat.length - 1];
      for (let i = repeat.length - 2; i > 0; i--) {
        repeat[i + 1] = repeat[i];
      }
      repeat[1] = lastRepeat;
    }
    let weekly = [];
    if (repeat[0]) {
      weekly.push(`10-0`);
    } else {
      repeat.forEach((item, index) => {
        if (item) {
          weekly.push(`10-${index}`);
        }
      });
    }
    setValueWeekly(weekly);
  }, [repeatPeriod]);

  useEffect(() => {
    if (repeatPeriod.length < 8) return;
    let newRepeatPeriod = [...repeatPeriod];

    if (nNU(startUTCTime)) {
      newRepeatPeriod.forEach((item) => {
        item.startTime = startUTCTime;
      });
    }

    if (nNU(endTime)) {
      let durationMinutes = calcDurationMinutes(startTime, endTime);
      let duration = `${formatTime(
        Math.floor(durationMinutes / 60)
      )}:${formatTime(durationMinutes % 60)}`;

      newRepeatPeriod.forEach((item) => {
        item.rangeTime = duration;
      });
    }

    setRepeatPeriod(newRepeatPeriod);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [startUTCTime, endTime]);

  useEffect(() => {
    if (repeatPeriod.length < 8) return;
    let newRepeatPeriod = [...repeatPeriod];

    // if (enableWeekly.length > 0) {
    if (1) {
      let arrEnable = [false, false, false, false, false, false, false, false];
      enableWeekly.forEach((item) => {
        let dmy = parseInt(item.substring(3, 4), 10);
        arrEnable[dmy] = true;
      });
      if (UTCOffset) {
        let firstEnable = arrEnable[1];
        for (let i = 1; i < arrEnable.length - 1; i++) {
          arrEnable[i] = arrEnable[i + 1];
        }
        arrEnable[arrEnable.length - 1] = firstEnable;
      }
      newRepeatPeriod.forEach((item, index) => {
        item.enable = arrEnable[index];
      });
    }

    setRepeatPeriod(newRepeatPeriod);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [enableWeekly]);
  const handleSetStartTime = (e) => {
    UTCOffset = false;
    UTCOffset = e[0].getHours() - e[0].getUTCHours() < 0 ? true : false;
    setStartUTCTime(
      `${formatTime(e[0].getUTCHours())}:${formatTime(e[0].getUTCMinutes())}`
    );
  };
  // console.log(valueWeekly);
  return (
    <>
      <Row xs={1} sm={4} className="border-responsive">
        <Col sm={1} className="label-set" style={{ padding: 0 }}>
          {/* <Col></Col> */}
          <Col>{string.DEVICE_WBGT}</Col>
        </Col>
        <Col sm={1} className="value-set">
          <Col id="no-responsive">{`設定値`}</Col>
        </Col>
        <Col sm={4} xs={4} className="enable-check title-width2">
          <Col id="no-responsive">{`設定値を上回った時`}</Col>
          <Col id="no-responsive">{`設定値を下回った時`}</Col>
        </Col>
        <Col xs={2} id="no-responsive">{`適応時間`}</Col>
      </Row>
      <Row
        xs={1}
        sm={4}
        className="border-responsive"
        style={{
          padding: '0px',
          maxHeight: '20px',
          textAlign: 'center',
          //margin: '0px',
        }}
        id="no-responsive"
      >
        <Col sm={1} className="label-set" style={{ padding: 0 }}></Col>
        <Col sm={1} className="value-set"></Col>
        <Col sm={4} xs={4} className="enable-check title-width2">
          <Col id="no-responsive">
            <Col>{`有効`}</Col>
            <Col>{`繰り返し`}</Col>
            <Col>{`送信間隔`}</Col>
          </Col>
          <Col id="no-responsive">{`有効`}</Col>
        </Col>
        <Col xs={2} id="no-responsive"></Col>
      </Row>
      {['0', '1', '2', '3'].map((index) => (
        <React.Fragment key={parseInt(index, 10)}>
          <Row xs={1} sm={4} className="border-responsive">
            <Col sm={1} className="label-set">
              <Col> {labelText[index]}</Col>
              {index === '0' && <Arrow />}
            </Col>
            <Col sm={1} className="value-set">
              <Form.Select
                onChange={(e) => {
                  setThresholdValue(+e.target.value, index);
                }}
                value={thresholdValue[index]}
                size="sm"
              >
                {thresholdTbl.map((th, inx) => (
                  <option key={inx} value={th}>
                    {th === '' ? `` : `${th}\u00B0C `}
                  </option>
                ))}
              </Form.Select>
            </Col>
            <Col sm={4} xs={4} className="enable-check title-width2">
              <Col>
                <Col>
                  <Form.Check
                    type="switch"
                    checked={enableCheck[2 * index]}
                    // label="有効"
                    onChange={(e) =>
                      setEnableCheck(e.target.checked, 2 * index)
                    }
                  ></Form.Check>
                </Col>
                <Col>
                  <Form.Check
                    type="switch"
                    checked={enableCheck[2 * index] && repeatValue[index]}
                    //checked={repeatValue[index]}
                    //label="有効"
                    onChange={(e) => setRepeatValue(e.target.checked, index)}
                    className={
                      //index === '0' ? repeatValue[index] === false ? repeatTimeValue[index] = repeatTime[0] : '' : 'invisible'
                      index === '0' ?  '' : 'invisible'
                    }
                  ></Form.Check>
                </Col>
                <Col>
                  <Form.Select
                    onChange={(e) => {
                      setRepeatTimeValue(+e.target.value, index);
                    }}
                    value={repeatTimeValue[index]}
                    size="sm"
                    className={
                      index === '0' ? '' : 'invisible'
                    }
                  >
                    {repeatTime.map((item, inx) => (
                      <option key={inx} value={item}>
                        {`${item}分 `}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Col>
              <Col className="center-responsive">
                <Form.Check
                  type="switch"
                  checked={enableCheck[2 * index + 1]}
                  // label="有効"
                  onChange={(e) =>
                    setEnableCheck(e.target.checked, 2 * index + 1)
                  }
                ></Form.Check>
              </Col>
            </Col>
            <Col xs={2}>
              {index === '0' && (
                <div className="time-selector browser-mode">
                  {startTime && (
                    <Flatpickr
                      className="time-picker"
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                        defaultDate: startTime,
                        minTime: '00:00',
                        maxTime: '23:59',
                        minuteIncrement: 1,
                      }}
                      onChange={handleSetStartTime}
                    />
                  )}

                  <span>~</span>
                  {endTime && (
                    <Flatpickr
                      className="time-picker "
                      options={{
                        enableTime: true,
                        noCalendar: true,
                        dateFormat: 'H:i',
                        time_24hr: true,
                        defaultDate: endTime,
                        minTime: '00:00',
                        maxTime: '23:59',
                        minuteIncrement: 1,
                      }}
                      onChange={(e) => {
                        setEndTime(
                          `${formatTime(e[0].getHours())}:${formatTime(
                            e[0].getMinutes()
                          )}`
                        );
                      }}
                    />
                  )}
                </div>
              )}
              {index === '1' && <span id="no-responsive">{`適応曜日`}</span>}
              {index === '2' && (
                <>
                  {days.map((day) => (
                    <div id="no-responsive" className="title " key={day}>
                      {day}
                    </div>
                  ))}
                </>
              )}
              {index === '3' && (
                <Weekday
                  className="browser-mode"
                  id={`${10}`}
                  value={valueWeekly}
                  callback={(val) => {
                    setEnableWeekly(val);
                  }}
                ></Weekday>
              )}
            </Col>
          </Row>
        </React.Fragment>
      ))}
      <Row id="mobile-mode" className="border-responsive">
        <Col>
          <div className="time-selector ">
            {startTime && (
              <Flatpickr
                className="time-picker"
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  time_24hr: true,
                  defaultDate: startTime,
                  minTime: '00:00',
                  maxTime: '23:59',
                  minuteIncrement: 1,
                }}
                onChange={handleSetStartTime}
              />
            )}

            <span>~</span>
            {endTime && (
              <Flatpickr
                className="time-picker "
                options={{
                  enableTime: true,
                  noCalendar: true,
                  dateFormat: 'H:i',
                  time_24hr: true,
                  defaultDate: endTime,
                  minTime: '00:00',
                  maxTime: '23:59',
                  minuteIncrement: 1,
                }}
                onChange={(e) => {
                  setEndTime(
                    `${formatTime(e[0].getHours())}:${formatTime(
                      e[0].getMinutes()
                    )}`
                  );
                }}
              />
            )}
          </div>
        </Col>
        <Col>
          <Weekday
            id={`${10}`}
            value={valueWeekly}
            callback={(val) => {
              setEnableWeekly(val);
            }}
          ></Weekday>
        </Col>
      </Row>
    </>
  );
};

export default WBGTSettei;
