import './Battery.scss';

const dataTable = (data) =>
  data > 3.0
    ? 'b-100'
    : data > 2.9
    ? 'b-80'
    : data > 2.8
    ? 'b-60'
    : data > 2.7
    ? 'b-50'
    : data > 2.6
    ? 'b-30'
    : 'b-10';

const Battery = ({ data }) => {
  return <i className={`battery ${dataTable(data)}`} />;
};

export default Battery;
