import { takeLatest, put, call } from 'redux-saga/effects';
import sendRequest from 'util/request';
import Urls from 'util/urls';
import { setSearchRangeDate } from 'util/utils';
import { signInFailure, signInSuccess } from './user.action';
import UserAcitonTypes from './user.types';

export function* signIn({ payload }) {
  try {
    // yield put(signInSuccess({ name: 'Test', token: 'zzz' }));
    // if (!!1) return;
    const { result } = yield call(sendRequest, {
      url: Urls.user.login,
      body: payload,
      method: 'post',
      withToken: false,
    });
    setSearchRangeDate();

    yield put(
      signInSuccess({
        ...result,
        accountId: result?.accountId || result?.userId,
      })
    );
  } catch (ex) {
    yield put(signInFailure(ex));
  }
}

export function* userSagas() {
  yield takeLatest(UserAcitonTypes.SIGN_IN_START, signIn);
}
