import * as React from "react"

const DkSf03wtTiltNormal = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      {...props}
    >
      <path
        fill="#fff"
        d="M32.36 4.16L38.47 7.34 39.54 7.9 40.68 7.47 43.78 6.31 42.62 9.41 42.19 10.54 42.75 11.62 45.94 17.72 42.75 23.82 42.1 25.08 42.75 26.34 45.95 32.46 42.75 38.73 42.2 39.81 42.63 40.94 43.75 43.85 40.69 42.7 39.55 42.27 38.47 42.83 32.37 46.02 26.27 42.83 25.01 42.18 23.75 42.83 17.65 46.02 11.55 42.83 10.47 42.27 9.33 42.7 6.23 43.87 7.39 40.77 7.82 39.63 7.26 38.55 4.08 32.45 7.26 26.35 7.92 25.09 7.26 23.83 4.08 17.73 7.26 11.63 7.82 10.55 7.39 9.41 6.23 6.31 9.33 7.47 10.46 7.9 11.54 7.34 17.64 4.16 23.74 7.34 25 8 26.26 7.34z"
      />
      <path d="M37.53 24.64a2.78 2.78 0 010 5.56h-1.67v12.9c0 .5-.41.9-.9.9h-19.9c-.5 0-.9-.41-.9-.9V30.2h-1.67c-1.54 0-2.78-1.24-2.78-2.78s1.24-2.78 2.78-2.78h1.67v-2.35c0-.5.4-.9.9-.9h7.46V16.4L23.19 6h3.62l.68 10.4v4.98h7.46c.5 0 .9.41.9.9v2.35h1.68v.01zm.68 2.78c0-.38-.3-.68-.68-.68s-.68.3-.68.68c0 .38.3.68.68.68s.68-.3.68-.68zm-25.06 0c0-.38-.3-.68-.68-.68s-.68.3-.68.68c0 .38.3.68.68.68.38 0 .68-.3.68-.68z" />
      <path
        d="M32.36 4.16l6.1 3.18 1.08.56 1.14-.43 3.1-1.17-1.16 3.1-.43 1.14.56 1.08 3.18 6.1-3.18 6.1-.66 1.26.66 1.26 3.19 6.11-3.2 6.27-.55 1.08.43 1.13 1.11 2.91-3.06-1.15-1.14-.43-1.08.56-6.1 3.18-6.1-3.18-1.26-.66-1.26.66-6.1 3.18-6.1-3.18-1.08-.56-1.12.44-3.1 1.17 1.16-3.1.43-1.14-.56-1.08-3.18-6.1 3.18-6.1.66-1.26-.66-1.26-3.18-6.1 3.18-6.1.56-1.08-.43-1.14-1.17-3.1 3.1 1.16 1.14.43 1.08-.56 6.1-3.18 6.1 3.18L25 8l1.26-.66 6.1-3.18zm0-3.07L25 4.93l-7.36-3.84-7.36 3.84-8.71-3.28 3.28 8.72-3.84 7.36 3.84 7.36-3.84 7.36 3.84 7.36-3.28 8.71 8.72-3.28 7.36 3.84 7.36-3.84 7.36 3.84 7.36-3.84 8.71 3.28-3.27-8.56L49 32.44l-3.84-7.36L49 17.72l-3.84-7.36 3.28-8.71-8.72 3.28-7.36-3.84h0z"
        fill="#f1000b"
        stroke="#000"
        strokeMiterlimit={10}
      />
    </svg>
  )
}

export default DkSf03wtTiltNormal;
