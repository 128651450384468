import { FaInfoCircle } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import Paths from 'util/paths';
import { formatDate, nNU, round } from 'util/utils';
import Battery from './Battery';
import Sensor from './Sensor';
import Signal from './Signal';
import State from './State';
import string from 'util/string';

const DeviceMarker2 = ({ data, assetName, sigfoxId, isJourney = false }) => {
  return (
    <>
      {/* <h6>{nNU(data.assetName) ? data.assetName:"Dummy"}</h6> */}
			{nNU(data.assetName) && (<h6>{data.assetName}</h6>)}
			{!nNU(data.assetName) && nNU(assetName) && (<h6>{assetName}</h6>)}
      <div>
				{nNU(data.sigfoxId) && (<div>
					{data.sigfoxId}
				</div>)}
				{!nNU(data.sigfoxId) && nNU(sigfoxId) && (<div>{sigfoxId}</div>)}
        {nNU(data?.logTime || data?.dataLogTime) && (
          <div>
            {formatDate(
              data?.logTime || data?.dataLogTime,
              'YYYY-MM-DD HH:mm:ss'
            )}
          </div>
        )}
        <div>
          {false && nNU(data?.temperature) && (
            <>Temperature: {data?.temperature}℃</>
          )}
        </div>

        {isJourney ? (
          <div style={{ display: 'flex' }}>
            {nNU(data?.lqi) && <Signal data={data.lqi} />}
          </div>
        ) : (
          <div style={{ display: 'flex' }}>
            {(data.model === string.DEVICE_WBGT) && nNU(data?.notifyType) && (
              <Sensor data={data.notifyType} model={data.model} />
            )}
            {/*nNU(data?.temperature) && (
              <span
                style={{ marginRight: '0.7rem', marginTop: '5px' }}
              >{`${data.temperature}℃`}</span>
            )*/}
            {(data.model === string.DEVICE_WBGT) && nNU(data?.wbgt) && (
              <span
                style={{ marginRight: '0.7rem', marginTop: '5px' }}
              >{`${data.wbgt}℃`}</span>
            )}{' '}
            {/* {nNU(data?.WBGTData) && <State data={data?.battery} />} */}
            {nNU(data?.batteryVoltage) && (
              <Battery data={data?.batteryVoltage} />
            )}
            {nNU(data?.lqi) && <Signal data={data.lqi} />}
          </div>
        )}

        {nNU(data?.location) && data?.location?.length > 1 && (
          <div>{data.location.map((e, i) => round(e, 6 - i)).join(' ')}</div>
        )}
      </div>
    </>
  );
};

export default DeviceMarker2;
