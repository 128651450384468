import Page from 'component/Page';
import string from 'util/string';
import Table from 'component/Table';
import { useState, useCallback } from 'react';
import { sendPost } from 'util/request';
import Urls from 'util/urls';
import { useEffect } from 'react';
import { Button, Container, Row } from 'react-bootstrap';
import { selectCurrentUser } from 'redux/user/user.selector';
import { useSelector } from 'react-redux';
import { ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT } from 'util/permission';
import { getString } from 'util/utils';

const DeviceSelect = ({
  selectedDevices = [],
  onItemSelected = () => {},
  multiple = false,
  isChild = false,
  accountChildID,
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [selectedItem, setSelectedItem] = useState([]);
  const [searchId, setSearchId] = useState('');

  // console.log(selectedItem);

  const mainActions = multiple
    ? [
        () => (
          <span>
            {getString(string.ROW_SELECTED_COUNT, selectedItem.length)}
          </span>
        ),
      ]
    : [];

  const title = string.DEVICE_SELECT;
  const headers = [
    string.DEVICE_NAME,
    string.DEVICE_SIGFOX_ID,
    string.DEVICE_MODEL,
    string.DEVICE_SERIALNO,
  ];
  const [data, setData] = useState([]);
  const dataTable = useCallback(
    (responseData) =>
      responseData.map((raw, key) => ({
        key,
        data: [
          raw.assetName,
          raw.sigfoxId,
          raw.model,
          raw.serialNumber || raw.serialNumbber,
        ],
        raw: { ...raw, serialNumber: raw.serialNumber || raw.serialNumbber },
      })),
    []
  );
  const onSelect = (e) => {
    if (!multiple) {
      onItemSelected(e);
      return;
    }
    let { raw } = e;
    let arr;
    let idx = -1;
    selectedItem?.forEach((item, index) => {
      if (item.deviceId === raw.deviceId) {
        idx = index;
        return;
      }
    });
    if (idx > -1) {
      let { [idx]: itm, ...rest } = selectedItem;
      arr = Object.values(rest);
    } else {
      // arr = [...selectedItem, raw.deviceId];
      arr = [...selectedItem, raw];
    }
    setSelectedItem(arr);
  };

  const actions = [
    ({ raw }) => {
      let idx = -1;
      selectedItem?.forEach((item, index) => {
        if (item.deviceId === raw.deviceId) {
          idx = index;
          return;
        }
      });
      return selectedDevices.indexOf(raw.deviceId) > -1
        ? null
        : {
            render: idx > -1 ? 'selected' : 'select',
            // selectedItem?.indexOf(raw) > -1 ? 'selected' : 'select',
            onClick: onSelect,
          };
    },
  ];

  const doSearch = useCallback(
    (page, pageSize, searchId) =>
      sendPost({
        url: isChild
          ? `${Urls.device.listOrphansOfContractByAccount}/${accountChildID}`
          : currentUser.accountRole === ACCOUNT_ROLE_ADMIN
          ? Urls.device.listOrphans
          : `${
              currentUser.accountRole === ACCOUNT_ROLE_PARENT
                ? Urls.device.listOfAccountContract
                : Urls.device.listOfAccount
            }/${currentUser.accountId}`,
        body: {
          offset: (page - 1) * pageSize,
          size: pageSize,
          keyword: searchId,
        },
      }).then((response) => {
        const { total, result } = response;

        setData(result);
        setTotal(total);
      }),
    [currentUser]
  );

  useEffect(() => {
    doSearch(page, pageSize, searchId);
  }, [page, pageSize, doSearch, searchId]);

  return (
    <>
      <Page
        title={title}
        body={
          <>
            <Container>
              <Table
                className="nowrap"
                mainActions={mainActions}
                page={page}
                pageSize={pageSize}
                total={total}
                onPageChanged={setPage}
                onPageSizeChanged={setPageSize}
                headers={headers}
                data={dataTable(data)}
                onRowClick={onSelect}
                actions={actions}
                search={true}
                onSearch={(value) => setSearchId(value)}
              />
              {multiple && (
                <Row className="form-button-row">
                  <Button
                    disabled={!selectedItem.length}
                    onClick={() => onItemSelected(selectedItem)}
                  >
                    {getString(string.N_ITEM_SELECT, selectedItem.length)}
                  </Button>
                </Row>
              )}
            </Container>
          </>
        }
      />
    </>
  );
};

export default DeviceSelect;
