import React from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { Row } from 'react-bootstrap';

const Arrow = () => {
  return (
    <>
      <ArcherContainer strokeColor="red" className="arrow arrow-no-responsive">
        <Row>
          <ArcherElement id="large">
            <div style={{ color: 'black' }}>{'高'}</div>
          </ArcherElement>
        </Row>
        <Row>{``}</Row>
        <Row>{``}</Row>
        <Row>{``}</Row>
        <Row>{``}</Row>
        <Row>{``}</Row>
        <Row>{``}</Row>
        <Row>{``}</Row>
        <Row>{``}</Row>
        <Row>{``}</Row>
        <Row>{``}</Row>
        <Row>
          <ArcherElement
            id="small"
            relations={[
              {
                targetId: 'large',
                targetAnchor: 'bottom',
                sourceAnchor: 'top',
                // label: 'Arrow 3',
                style: { strokeColor: 'black', strokeWidth: 1 },
              },
            ]}
          >
            <div style={{ color: 'black' }}>{'低'}</div>
          </ArcherElement>
        </Row>
      </ArcherContainer>
    </>
  );
};

export default Arrow;
