import AppAcitonTypes from './app.types';

export const appShowToast = (payload) => ({
  type: AppAcitonTypes.SHOW_TOAST,
  payload,
});

export const appHideToast = (payload) => ({
  type: AppAcitonTypes.HIDE_TOAST,
  payload,
});

export const appShowConfirm = (payload) => ({
  type: AppAcitonTypes.SHOW_CONFIRM,
  payload,
});

export const appHideConfirm = (payload) => ({
  type: AppAcitonTypes.HIDE_CONFIRM,
  payload,
});

export const setSearchRangeDate = (payload) => ({
  type: AppAcitonTypes.SET_SEARCH_RANGE_DATE,
  payload,
});
