const Paths = {
  root: '/',
  user: {
    login: '/login',
    info: '/account/info',
    list: '/account/list',
    update: '/account/update',
    log: '/account/log',
    forgetPassword: '/forget-password',
  },
  contract: {
    list: '/contract/list',
  },
  device: {
    list: '/device/list',
    status: '/device/status',
    statusSF07: '/device/status/sf07',
    statusWBGT: '/device/status/wbgt',
    statusGPS: '/device/status/gps',
    statusSF03WT: '/device/status/sf03wt',
    statusSF03WT2: '/device/status/sf03wt2',
    statusXE1151: '/device/status/xe1151',
    status2: '/device/status2',
    activity: '/device/activity',
    detail: '/device/detail',
    activeAlert: '/alert/active',
    alertHistory: '/alert/history',
  },
  activity: '/activity',
};
export default Paths;
