import React, { useCallback, useEffect, useState } from 'react';
import {
  FaArrowAltCircleLeft,
  FaArrowAltCircleRight,
  FaTrashAlt,
} from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import './Slideshow.scss';
import { showConfirm, showToast } from 'util/utils';
import sendRequest from 'util/request';
import Urls from 'util/urls';

const Slideshow = ({ data = [], deleteImage }) => {
  const [current, setCurrent] = useState(0);
  const length = data.length;
  const [x, setX] = useState(0);
  const [y, setY] = useState(0);

  /*const randomMovement = () => {
    this.setState((prevState) => ({
      left: prevState.left + 30 * Math.random(),
      top: prevState.top + 30 * Math.random(),
    }));*/


  const printCoordinates = (e) => {
    //const x = e.pageX - e.currentTarget.offsetLeft;
    //const y = e.pageY - e.currentTarget.offsetTop;
    //setX(x);
    //setY(y);
    //const { width, height } = e.target.getBoundingClientRect();
    //const { offsetX, offsetY } = e.nativeEvent;
    // calculate percentage, subtract border/endzone percentage(10), map 80% to 100%, calculate yardage
    //setX(Math.round(((((offsetX / width) * 100) - 10) / 80) * 100));
    // calculate percentage, subtract top-border percentage(5), map 90% to 100%, calculate yardage (53.3 yards total width)
    //setY(Math.round(((((offsetY / height) * 100) - 5) / 90) * 53.3));

    const { width, height } = e.target.getBoundingClientRect();
    const { offsetX, offsetY } = e.nativeEvent;
    setX(Math.round((offsetX / width) * 100));
    setY(Math.round((offsetY / height) * 100));

    //console.log('x:', Math.round((offsetX / width) * 100),'y:',Math.round((offsetY / height) * 100));
    const element = document.getElementById('image-id');
    if (element !== null) {
      //element.remove();
      //element.style.top = offsetX;
      //element.style.left = offsetY;
      console.log('x:',Math.round((offsetX / width) * 100).toString() + "%",'y:',Math.round((offsetY / height) * 100).toString() + "%");
      //element.style.top = (100 - Math.round((offsetX / width) * 100)).toString() + "%";
      //element.style.left = (100 - Math.round((offsetY / height) * 100)).toString() + "%";
      element.style.left = Math.round((offsetX / width) * 100).toString() + "%";
      element.style.top = Math.round((offsetY / height) * 100).toString() + "%";
    }
    //console.log('x:', x,'y:',y);
  };
  //console.log('x:', x,'y:',y);
  //console.log(y);

  const nextSlide = () => {
    setCurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevSlide = () => {
    setCurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    const length = data.length;
    if (current > length) {
      setCurrent(0);
    }
  }, [data]);

  if (!Array.isArray(data) || data.length <= 0) {
    return null;
  }

  return (
    <div className="slider">
      <section className="slide-section">
        {length > 1 && (
          <FaArrowAltCircleLeft className="left-arrow" onClick={prevSlide} />
        )}
        {length > 1 && (
          <FaArrowAltCircleRight className="right-arrow" onClick={nextSlide} />
        )}
        {data.map((item, index) => {
          return (
            <div
              className={index === current ? 'slide-active' : 'slide'}
              key={index}

            >
              {index === current && (
                <div>
                  <img
                    src={item.imageUrl}
                    className="image"
                    alt="layout"
                    width="800"
                    height="360"
                    //onDoubleClick={(e) => printCoordinates(e)}
                  />
                  {/*<img
                    id = 'image-id'
                    src = "/logo192.png"
                    className="image2"
                    alt="device"
                    width="10%"
              />*/}
                </div>
              )}
            </div>
          );
        })}
        {/*(<Button
          style={{ padding: '0rem 0.7rem', height: '26px' }}
          className="button"
          variant="danger"
          onClick={() => deleteImage(current)}
        >
          <FaTrashAlt />
        </Button>)*/}
      </section>
    </div>
  );
};

export default Slideshow;
