import * as React from "react"

const DkSf03wtTiltFlood = (props) => {
  return (
    <svg
      id="\u30EC\u30A4\u30E4\u30FC_15_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      {...props}
    >
      <style>{".st30{fill:#01a0c6}"}</style>
      <g id="\u30EC\u30A4\u30E4\u30FC_15_7">
        <g id="XMLID_15_00000131331053253050953640000016326621755499558590_">
          <path
            className="st30"
            d="M9.33 7.48L10.47 7.9 11.54 7.34 17.64 4.16 23.74 7.34 25 8 26.26 7.34 32.37 4.16 38.46 7.34 39.55 7.9 40.68 7.48 43.79 6.31 42.62 9.41 42.2 10.54 42.75 11.63 45.94 17.72 42.75 23.83 42.1 25.09 42.75 26.34 45.95 32.46 42.75 38.73 42.2 39.81 42.63 40.93 43.74 43.85 40.69 42.7 39.55 42.27 38.47 42.84 32.38 46.01 26.27 42.84 25.01 42.17 23.75 42.84 17.66 46.02 11.55 42.84 10.47 42.27 9.34 42.7 6.22 43.86 7.4 40.77 7.82 39.63 7.26 38.55 4.07 32.45 7.26 26.35 7.91 25.09 7.26 23.84 4.07 17.73 7.26 11.63 7.82 10.55 7.4 9.41 6.22 6.31z"
          />
          <path
            className="st30"
            d="M1.57 1.65l8.72 3.27 7.35-3.84L25 4.93l7.35-3.84 7.36 3.84 8.73-3.27-3.27 8.71 3.84 7.36-3.84 7.36 3.84 7.35-3.84 7.53 3.27 8.56-8.72-3.28-7.35 3.84-7.36-3.84-7.35 3.84-7.36-3.84-8.73 3.28 3.28-8.72-3.84-7.36 3.84-7.35-3.84-7.36 3.84-7.36-3.28-8.73zm5.69 24.7l-3.19 6.1 3.19 6.11.56 1.08-.42 1.13-1.17 3.09 3.11-1.16 1.13-.43 1.08.57 6.11 3.19 6.1-3.19 1.26-.66 1.26.66 6.11 3.18 6.1-3.18 1.08-.57 1.14.43 3.05 1.15-1.11-2.92-.43-1.12.56-1.08 3.2-6.27-3.2-6.12-.65-1.26.65-1.26 3.19-6.11-3.19-6.1-.56-1.08.42-1.13 1.17-3.1-3.11 1.17-1.15.43-1.08-.56-6.1-3.19-6.11 3.19L25 8l-1.26-.65-6.11-3.19-6.1 3.19-1.06.55-1.14-.42-3.1-1.17 1.17 3.1.42 1.14-.56 1.08-3.19 6.11 3.19 6.11.65 1.26-.65 1.24z"
          />
        </g>
      </g>
      <g id="\u4E00\u53E3\u30BB\u30F3\u30B5_15">
        <g id="LINE_15_00000108280879333122650190000003660985830898353817_">
          <path
            d="M37.53 24.64a2.78 2.78 0 010 5.56h-1.67v12.9c0 .5-.41.9-.9.9h-19.9c-.5 0-.9-.41-.9-.9V30.2h-1.67c-1.54 0-2.78-1.24-2.78-2.78s1.24-2.78 2.78-2.78h1.67v-2.35c0-.5.4-.9.9-.9h7.46V16.4L23.19 6h3.62l.68 10.4v4.98h7.46c.5 0 .9.41.9.9v2.35h1.68v.01zm.68 2.78c0-.38-.3-.68-.68-.68s-.68.3-.68.68c0 .38.3.68.68.68s.68-.3.68-.68zm-25.06 0c0-.38-.3-.68-.68-.68s-.68.3-.68.68c0 .38.3.68.68.68.38 0 .68-.3.68-.68z"
            id="XMLID_15_00000112628607985987567670000011263570796403053705_"
          />
        </g>
      </g>
      <path
        d="M32.36 4.16l6.1 3.18 1.08.56 1.14-.43 3.1-1.17-1.16 3.1-.43 1.14.56 1.08 3.18 6.1-3.18 6.1-.66 1.26.66 1.26 3.19 6.11-3.2 6.27-.55 1.08.43 1.13 1.11 2.91-3.06-1.15-1.14-.43-1.08.56-6.1 3.18-6.1-3.18-1.26-.66-1.26.66-6.1 3.18-6.1-3.18-1.08-.56-1.12.44-3.1 1.17 1.16-3.1.43-1.14-.56-1.08-3.18-6.1 3.18-6.1.66-1.26-.66-1.26-3.18-6.1 3.18-6.1.56-1.08-.43-1.14-1.17-3.1 3.1 1.16 1.14.43 1.08-.56 6.1-3.18 6.1 3.18L25 8l1.26-.66 6.1-3.18zm0-3.07L25 4.93l-7.36-3.84-7.36 3.84-8.71-3.28 3.28 8.72-3.84 7.36 3.84 7.36-3.84 7.36 3.84 7.36-3.28 8.71 8.72-3.28 7.36 3.84 7.36-3.84 7.36 3.84 7.36-3.84 8.71 3.28-3.27-8.56L49 32.44l-3.84-7.36L49 17.72l-3.84-7.36 3.28-8.71-8.72 3.28-7.36-3.84h0z"
        fill="#f1000b"
        stroke="#000"
        strokeMiterlimit={10}
        id="\u30EC\u30A4\u30E4\u30FC_15_4"
      />
    </svg>
  )
}

export default DkSf03wtTiltFlood;
