import React, { useRef, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import string from 'util/string';
import './PopOver.scss';

const notifyContent = (data) => {
  let notifyContent = [];
  if (data === 'danger') {
    notifyContent[0] = `高齢者においては安静状態でも発生する危険性が大きい。外出はなるべく避け、涼しい室内に移動する。`;
    notifyContent[1] = `特別の場合以外は運動を中止する。特に子どもの場合には中止すべき。`;
  } else if (data === 'caution') {
    notifyContent[0] = `外出時は炎天下を避け、室内では室温の上昇に注視する。`;
    notifyContent[1] = `熱中症の危険性が高いので、激しい運動や持久走など体温が上昇しやすい運動は避ける。10～20分おきに休憩をとり水分・塩分の補給を行う。暑さに弱い人（体力の低い人、肥満の人や暑さに慣れていない人）などは運動を軽減または中止。`;
  } else if (data === 'vigilance') {
    notifyContent[0] = `運動や激しい作業をする際は定期的に充分に休息を取り入れる。`;
    notifyContent[1] = `熱中症の危険が増すので、積極的に休憩をとり適宜、水分・塩分を補給する。激しい運動では、30分おきくらいに休憩をとる`;
  } else if (data === 'warning') {
    notifyContent[0] = `一般に危険性は少ないが激しい運動や重労働時には発生する危険性がある。`;
    notifyContent[1] = `熱中症による死亡事故が発生する可能性がある。熱中症の兆候に注意するとともに、運動の合間に積極的に水分・塩分を補給する。`;
  } else {
    notifyContent[0] = `一般に危険性は少ないが激しい運動や重労働時には発生する危険性がある。`;
    notifyContent[1] = `通常は熱中症の危険は小さいが、適宜水分・塩分の補給は必要である。市民マラソンなどではこの条件でも熱中症が発生するので注意。`;
  }
  return notifyContent;
};

const notifyTypeMap = (data) => {
  let notifyType;
  if (data === 'danger') notifyType = string.STATE_DANGER;
  else if (data === 'caution') notifyType = string.STATE_CAUTION;
  else if (data === 'vigilance') notifyType = string.STATE_VIGILANCE;
  else if (data === 'warning') notifyType = string.STATE_WARNING;
  else notifyType = string.STATE_NORMAL;
  return notifyType;
};
const notifyThreshold = (data) => {
  let notifyThresold;
  if (data === 'danger') notifyThresold = '31℃以上';
  else if (data === 'caution') notifyThresold = '28℃-31℃';
  else if (data === 'vigilance') notifyThresold = '25℃-28℃';
  else if (data === 'warning') notifyThresold = '21℃-25℃';
  else notifyThresold = '21℃未満';
  return notifyThresold;
};

export const notifyState = (data, threshold = [31, 28, 25, 21]) => {
  let notifyState;
  if (data > threshold[0]) notifyState = 'danger';
  else if (data > threshold[1]) notifyState = 'caution';
  else if (data > threshold[2]) notifyState = 'vigilance';
  else if (data > threshold[3]) notifyState = 'warning';
  else notifyState = 'normal';
  return notifyState;
};

const PopOver = ({ title, content, className = 'normal' }) => {
  const [show, setShow] = useState(false);
  const ref = useRef(null);

  const showOverlay = (event) => {
    setShow(true);
  };
  const hideOverlay = () => {
    setShow(false);
  };
  return (
    <>
      <OverlayTrigger
        // show={show}
        show={show}
        placement="right"
        // trigger={['focus', 'hover']}
        overlay={
          <Popover
            id="popover-contained"
            onMouseEnter={showOverlay}
            onMouseLeave={hideOverlay}
            ref={ref}
            style={{ maxWidth: '340px' }}
          >
            <Popover.Header
              className={`title-overlay-${className || 'normal'}`}
            >
              <div className={`title-overlay-center`}>{content?.title}</div>
            </Popover.Header>
            <Popover.Body>
              {content?.content.split(/(?:\r\n|\r|\n)/g).map((key, e) => (
                <div key={e + 1}>
                  {e === 3 ? (
                    <a
                      target="_blank"
                      href={`${content?.content.split(/(?:\r\n|\r|\n)/g)[e]}`}
                    >{`${content?.content.split(/(?:\r\n|\r|\n)/g)[e]}`}</a>
                  ) : (
                    `${content?.content.split(/(?:\r\n|\r|\n)/g)[e]}`
                  )}
                  <br />
                </div>
              ))}
            </Popover.Body>
          </Popover>
        }
      >
        <div ref={ref} onMouseEnter={showOverlay} onMouseLeave={hideOverlay}>
          {title}
        </div>
      </OverlayTrigger>
    </>
  );
};

export default PopOver;
