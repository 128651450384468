import Page from 'component/Page';
import { useEffect } from 'react';
import { useState } from 'react';
import sendRequest, { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import Table from 'component/Table';
import Modal from 'component/Modal';
import UserMaintenance from './UserMaintenance';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import { ACCOUNT_ROLE_ADMIN, ACCOUNT_ROLE_PARENT } from 'util/permission';
import { useCallback } from 'react';
import { getString, showConfirm, showToast } from 'util/utils';

export const accountRoleNameMap = {
  0: string.ACCOUNT_ROLE_ADMIN,
  1: string.ACCOUNT_ROLE_PARENT,
  2: string.ACCOUNT_ROLE_CHILD,
};

export const accountStatusNameMap = {
  true: string.ACTIVE,
  false: string.INACTIVE,
};

const UserList = () => {
  const currentUser = useSelector(selectCurrentUser);
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const [selectedUser, setSelectedUser] = useState(null);

  const doSearch = useCallback(
    async (page, pageSize, getAll = false) => {
      let data = await sendPost({
        url:
          currentUser.accountRole === ACCOUNT_ROLE_ADMIN
            ? Urls.user.list
            : `${Urls.user.childList}/${currentUser.accountId}`,
        body: {
          offset: (page - 1) * pageSize,
          size: pageSize,
        },
      });
      const { total, result } = data;
      if (!getAll) {
        setUsers(result);
        setTotal(total);
      }
      return result;
    },
    [currentUser]
  );

  useEffect(() => {
    doSearch(page, pageSize);
  }, [page, pageSize, doSearch]);

  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key: raw.accountId,
        data: currentUser.accountRole === ACCOUNT_ROLE_ADMIN ? [
          raw.loginId,
          raw.accountName,
          accountRoleNameMap[raw.accountRole] || '',
          raw.email,
          // accountStatusNameMap[raw.valid] || '',
        ] :
				[
          raw.loginId,
          raw.accountName,
          // accountRoleNameMap[raw.accountRole] || '',
          raw.email,
          // accountStatusNameMap[raw.valid] || '',
				],
        raw,
      })),
    []
  );

  return (
    <>
      <Page
        title={string.ACCOUNT_MANAGEMENT}
        body={
          <>
            <Table
              className="nowrap"
              mainActions={[
                {
                  icon: 'add',
                  title: string.NEW_ACCOUNT,
                  onClick: () => setSelectedUser({ valid: true }),
                },
              ]}
              page={page}
              pageSize={pageSize}
              total={total}
              onPageChanged={setPage}
              onPageSizeChanged={setPageSize}
              headers={currentUser.accountRole === ACCOUNT_ROLE_ADMIN ? [
									string.LOGIN_ID,
									string.ACCOUNT_NAME,
									string.ACCOUNT_ROLE,
									string.ACCOUNT_MAIL,
									// string.ACCOUNT_STATUS,
              	]:[
									string.LOGIN_ID,
									string.ACCOUNT_NAME,
									// string.ACCOUNT_ROLE,
									string.ACCOUNT_MAIL,
									// string.ACCOUNT_STATUS,
							]}
              data={dataTable(users)}
              getCSVData={async () => {
                return dataTable(await doSearch(1, total, true));
              }}
              csvFileName="AccountList"
              actions={[
                { render: 'edit', onClick: ({ raw }) => setSelectedUser(raw) },
                ...(currentUser.accountRole === ACCOUNT_ROLE_ADMIN ||
                currentUser.accountRole === ACCOUNT_ROLE_PARENT
                  ? [
                      {
                        render: 'minus',
                        className: 'color-danger',
                        onClick: ({ raw }) => {
                          showConfirm({
                            body: getString(string.ACCOUNT_DELETE_CONFIRM, [
                              raw.accountName,
                            ]),
                            defaultAction: () => {
                              sendRequest({
                                url: `${Urls.user.update}/${raw.accountId}`,
                                method: 'delete',
                              }).then(() => {
                                showToast(
                                  string.ACCOUNT_DELETED_ALERT,
                                  'success'
                                );
                                doSearch(page, pageSize);
                              });
                            },
                          });
                        },
                      },
                    ]
                  : []),
              ]}
            />
          </>
        }
      />
      <Modal
        show={!!selectedUser}
        onHide={() => setSelectedUser(null)}
        body={
          <>
            {!!selectedUser && (
              <UserMaintenance
                onUpdated={() => {
                  setSelectedUser(null);
                  doSearch(page, pageSize);
                }}
                data={selectedUser}
              />
            )}
          </>
        }
      />
    </>
  );
};

export default UserList;
