import { Col, Form, InputGroup, Row } from 'react-bootstrap';
import { formatDate } from 'util/utils';
import 'flatpickr/dist/themes/material_blue.css';
import Flatpickr from 'react-flatpickr';
import './FormControls.scss';
import { Japanese } from 'flatpickr/dist/l10n/ja.js';
import { useEffect, useState } from 'react';

const formControlMap = {
  radio: (props) =>
    props.options.map((option, index) => {
      return (
        <Form.Check
          key={index}
          inline
          name={props.name}
          id={`${props.name}-${option.id || option.value}`}
          type={props.type}
          label={option?.label || ' '}
          {...option}
          defaultChecked={`${props.defaultValue}` === `${option.value}`}
        />
      );
    }),
  selection: (props) => {
    return (
      <>
        <Form.Select
          id={`${props.name}`}
          name={props.name}
          type={props.type}
          defaultValue={props.defaultValue}
        >
          {props.options.map((option, index) => {
            return (
              <option value={option.select} key={index}>
                {option.select}
              </option>
            );
          })}
        </Form.Select>
      </>
    );
  },

  default: (props) => (
    <Form.Control {...props} style={{ fontSize: props?.fontSize || '1rem' }} />
  ),
  dateSelect: (props) => (
    <Flatpickr
      {...props}
      className={`date ${props.className || ''}`}
      options={{ mode: 'single', locale: Japanese, ...(props?.options || {}) }}
      onChange={(e) => {
        if (props.onChange) {
          // console.log(e);
          props.onChange(e);
        }
      }}
    />
  ),
  rangeDate: (props) => (
    <Flatpickr
      {...props}
      className={`range-time ${props.className || ''}`}
      options={{ mode: 'range', locale: Japanese, ...(props?.options || {}) }}
      onChange={(e) => {
        if (props.onChange) {
          // console.log(e);
          props.onChange(e);
        }
      }}
    />
  ),
};
formControlMap['checkbox'] = formControlMap.radio;

const FormControls = ({ controls, data }) => {
  const [value, setValue] = useState(data);
  useEffect(() => {
    setValue(data);
  }, [data]);
  return (
    <Row>
      {controls.map(({ title, titleClass, footer, ...controlProps }, index) => {
        if (!controlProps?.name && !!controlProps?.id) {
          controlProps.name = controlProps.id;
        }
        if (
          !controlProps?.defaultValue &&
          data?.[controlProps?.id || controlProps?.name] !== undefined
        ) {
          controlProps.defaultValue =
            data[controlProps?.id || controlProps?.name];
        }

        if (!!controlProps?.defaultValue && controlProps.type === 'date') {
          controlProps.defaultValue = formatDate(
            controlProps.defaultValue,
            'YYYY-MM-DD'
          );
        }
        return (
          <Col
            key={index}
            xl={
              controlProps?.xl ||
              controlProps?.lg ||
              controlProps?.md ||
              controlProps?.sm ||
              controlProps?.xs ||
              12
            }
            lg={
              controlProps?.lg ||
              controlProps?.md ||
              controlProps?.sm ||
              controlProps?.xs ||
              12
            }
            md={controlProps?.md || controlProps?.sm || controlProps?.xs || 12}
            sm={controlProps?.sm || controlProps?.xs || 12}
            xs={controlProps?.xs || 12}
          >
            <InputGroup
              key={index}
              className={controlProps.type === 'hidden' ? 'hidden' : ''}
            >
              {!!title && (
                <InputGroup.Text
                  {...(!!titleClass ? { className: titleClass } : {})}
                  style={{
                    minWidth: controlProps?.minWidth || '',
                  }}
                  placeholder={controlProps?.placeHolder}
                >
                  {title}
                </InputGroup.Text>
              )}
              {(formControlMap[controlProps.type] || formControlMap['default'])(
                controlProps
              )}

              {!!footer && (
                <InputGroup.Text className="footer">{footer}</InputGroup.Text>
              )}
            </InputGroup>
          </Col>
        );
      })}
    </Row>
  );
};

export default FormControls;
