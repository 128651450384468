import { cloneDeep } from 'util/utils';
import { v4 } from 'uuid';
import AppAcitonTypes from './app.types';

const INITIAL_STATE = { toast: {}, confirm: null, searchRangeDate: [] };
const appReducer = (state = cloneDeep(INITIAL_STATE), { type, payload }) => {
  switch (type) {
    case AppAcitonTypes.SHOW_TOAST:
      let curTime = new Date().getTime();
      return {
        ...state,
        toast: {
          [v4()]: { ...payload, createdAt: curTime },
          ...Object.keys(state.toast).reduce((prev, id) => {
            let e = state.toast[id];
            if (curTime < e.createdAt + e.delay) {
              prev[id] = e;
            }
            return prev;
          }, {}),
        },
      };
    case AppAcitonTypes.HIDE_TOAST:
      if (!payload?.id) {
        return { ...state, toast: cloneDeep(INITIAL_STATE).toast };
      }
      return {
        ...state,
        toast: {
          ...Object.keys(state.toast).reduce((prev, id) => {
            if (id !== payload.id) {
              prev[id] = state.toast[id];
            }
            return prev;
          }, {}),
        },
      };

    case AppAcitonTypes.SHOW_CONFIRM:
      return { ...state, confirm: payload };
    case AppAcitonTypes.HIDE_CONFIRM:
      return { ...state, confirm: null };

    case AppAcitonTypes.SET_SEARCH_RANGE_DATE:
      return { ...state, searchRangeDate: payload };

    default:
      return state;
  }
};

export default appReducer;
