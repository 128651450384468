import React from "react";
import string from "util/string";
import { nNU } from "util/utils";

const DkSf03wt2FloodState = [
    string.DKSF03WT2_FLOOD_OK,
    string.DKSF03WT2_FLOOD_NG,
];

const DkSf03wt2FloodState2 = [
    string.DKSF03WT2_FLOOD_AB_OK,
    string.DKSF03WT2_FLOOD_A_NG,
    string.DKSF03WT2_FLOOD_B_NG,
    string.DKSF03WT2_FLOOD_AB_NG,
];

const DkSf03wt2TiltState = [
    string.DKSF03WT2_TILT_OK,
    string.DKSF03WT2_TILT_NG,
];

const DkSf03wt2BatteryState = [
    string.DKSF03WT2_BATTERY_OK,
    string.DKSF03WT2_BATTERY_NG,
];

const DkSf03wt2Timeout = [
    string.DKSF03WT2_TIMEOUT,
];

const GetDkSf03Wt2State = ({timeout=0, singleSensor=0, flood=0, tilt=0, battery=0}) => {
    if(nNU(timeout) && (timeout === 1)){
        return (
            <div
                className={'dksf03wt2state'}
            >
                {DkSf03wt2Timeout[0]}
            </div>
        )
    }
    else{
        if(nNU(singleSensor) && (singleSensor === 1)){
            return (
                <div
                    className={'dksf03wt2state'}
                >
                    <div>
                        {DkSf03wt2FloodState[flood]}
                        {' '}
                        {DkSf03wt2TiltState[tilt]}
                        {' '}
                        {DkSf03wt2BatteryState[battery]}
                    </div>
                    {/*<div>
                        {DkSf03wt2FloodState[flood]}
                    </div>
                    <div>
                        {DkSf03wt2TiltState[tilt]}
                    </div>
                    <div>
                        {DkSf03wt2BatteryState[battery]}
                    </div>*/}

                </div>
            )
        }
        else{
            return (
                <div
                    className={'dksf03wt2state'}
                >
                    <div>
                        {DkSf03wt2FloodState2[flood]}
                        {' '}
                        {DkSf03wt2TiltState[tilt]}
                        {' '}
                        {DkSf03wt2BatteryState[battery]}
                    </div>
                    {/* <div>
                        {DkSf03wt2FloodState2[flood]}
                    </div>
                    <div>
                        {DkSf03wt2TiltState[tilt]}
                    </div>
                    <div>
                        {DkSf03wt2BatteryState[battery]}
                    </div> */}
                </div>
            )
        }
    }
}

export default GetDkSf03Wt2State;
