export const mailTemplate = {
  title: '高い暑さ指数検出\n',
  header: `\n装置名称：
暑さ指数：
WBGT指針:
電池電圧：
〇からレベル１へ上がりました。`,

  content: `\n高い暑さ指数を検出しました。
熱中症にご注意ください。`,
  footer: `\nこのメールは送信専用アドレスから送られています。
ご返信いただいても確認することができません。
------------------------------------------------------------
[お問い合わせ先]
アルインコ株式会社　電子事業部
アルインコ電子サービスセンター

フリーダイヤル：0120-464-007
受付時間／10:00～17:00　月曜～金曜
（祝祭日及び12:00～13:00は除きます）

URL：https://www.alinco.co.jp/`,
};

export const dataTemplate = [
  {
    title: '暑さ指数検出通知\n',
    header: `\n装置名称：
暑さ指数：
WBGT指針:
電池電圧：
WBGTがレベル４の設定値へ上がりました。`,
    content: `\n熱中症レベルが上がりましたので、厳重警戒して下さい。\n`,
  },
  {
    title: '暑さ指数検出通知\n',
    header: `\n装置名称：
暑さ指数：
WBGT指針:
電池電圧：
WBGTがレベル４の設定値から下がりました。`,
    content: `\n熱中症レベルは下がりましたが、厳重警戒して下さい。\n`,
  },
  {
    title: '暑さ指数検出通知\n',
    header: `\n装置名称：
暑さ指数：
WBGT指針:
電池電圧：
WBGTがレベル３の設定値へ上がりました。`,
    content: `\n熱中症レベルが上がりましたので、厳重警戒して下さい。\n`,
  },
  {
    title: '暑さ指数検出通知\n',
    header: `\n装置名称：
暑さ指数：
WBGT指針:
電池電圧：
WBGTがレベル３の設定値から下がりました。`,
    content: `\n熱中症レベルは下がりましたが、厳重警戒して下さい。\n`,
  },
  {
    title: '暑さ指数検出通知\n',
    header: `\n装置名称：
暑さ指数：
WBGT指針:
電池電圧：
WBGTがレベル２の設定値へ上がりました。`,
    content: `\n熱中症レベルが上がりましたので、厳重警戒して下さい。\n`,
  },
  {
    title: '暑さ指数検出通知\n',
    header: `\n装置名称：
暑さ指数：
WBGT指針:
電池電圧：
WBGTがレベル２の設定値から下がりました。`,
    content: `\n熱中症レベルは下がりましたが、厳重警戒して下さい。\n`,
  },
  {
    title: '暑さ指数検出通知\n',
    header: `\n装置名称：
暑さ指数：
WBGT指針:
電池電圧：
WBGTがレベル１の設定値へ上がりました。`,
    content: `\n熱中症レベルが上がりましたので、厳重警戒して下さい。\n`,
  },
  {
    title: '暑さ指数検出通知\n',
    header: `\n装置名称：
暑さ指数：
WBGT指針:
電池電圧：
WBGTがレベル１の設定値から下がりました。`,
    content: `\n熱中症レベルは下がりましたが、厳重警戒して下さい。\n`,
  },
  {
    title: '暑さ指数定期測定の結果\n',
    header: `\n装置名称：
暑さ指数：
WBGT指針:
電池電圧：`,
    content: `\n暑さ指数の測定結果のお知らせです。\n`,
  },
];

export const gpsMailTemplate = [
  {
    title: '進入検出通知\n',
    header: `\n装置名称：
    電池電圧：
    〇に進入しました。`,
    content: `\n進入検出の通知です。\n`,
  },
  {
    title: '退出検出通知\n',
    header: `\n装置名称：
    電池電圧：
    〇から退出しました。`,
    content: `\n退出検出の通知です。\n`,
  },
  {
    title: '長時間滞在通知\n',
    header: `\n装置名称：
    電池電圧：
    〇に長い時間(〇〇以上)滞在しました。`,
    content: `\n長時間滞在の通知です。\n`,
  },
  {
    title: '位置のお知らせ\n',
    header: `\n装置名称：
    電池電圧：
		xx月xx日　xx時xx分（現在時刻）の位置をお知らせします。`,
    content: `\n\n`,
  },
];

export const dksf03wt2MailTemplate = [
  {
    title:'水位センサー　浸水検出メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\nxx月xx日　xx時xx分の状態をお知らせします。
		水位が上昇しました。
		USERADDCONTENT
    水位情報A：浸水状態
    水位情報B：浸水状態/渇水状態
    電池電圧：x.xxV\n`,
  },
  {
    title:'水位センサー　渇水検出メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\nxx月xx日　xx時xx分の状態をお知らせします。
		水位が下がりました。
		USERADDCONTENT
    水位情報A：渇水状態
    水位情報B：浸水状態/渇水状態
    電池電圧：x.xxV\n`,
  },
  {
    title:'水位センサー　浸水検出メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\nxx月xx日　xx時xx分の状態をお知らせします。
		水位が上昇しました。
		USERADDCONTENT
    水位情報A：浸水状態/渇水状態
    水位情報B：浸水状態
    電池電圧：x.xxV\n`,
  },
  {
    title:'水位センサー　渇水検出メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\nxx月xx日　xx時xx分の状態をお知らせします。
		水位が下がりました。
		USERADDCONTENT
    水位情報A：浸水状態/渇水状態
    水位情報B：渇水状態
    電池電圧：x.xxV\n`,
  },
  {
    title:'水位センサー　傾斜検出メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\nxx月xx日　xx時xx分の状態をお知らせします。
		傾斜を検出しました。
		USERADDCONTENT
    電池電圧：x.xxV\n`,
  },
  {
    title:'水位センサー　傾斜正常メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\nxx月xx日　xx時xx分の状態をお知らせします。
		傾斜が正常に戻りました。
  	USERADDCONTENT
    電池電圧：x.xxV\n`,
  },
  {
    title:'水位センサー　PUSHボタンメール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\nxx月xx日　xx時xx分の状態をお知らせします。
		PUSHボタンが押されました。
		USERADDCONTENT
    電池電圧：x.xxV
    電波状況：強い/良い/弱い/非常に弱い\n`,
  },
  {
    title:'水位センサー　定時メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\nxx時xx分の状態をお知らせします。
    水位情報A：浸水状態/渇水状態
    水位情報B：浸水状態/渇水状態
    設定情報
    SET送信：オン/オフ
    PUSH送信：オン/オフ
    傾斜検出：オン/オフ
    定時連絡：オン/オフ
    連動浸水センサー：1本/2本
    センサー応答時間：3～15秒
    電池電圧：x.xxV
    電波状況：強い/良い/弱い/非常に弱い\n`,
  },
  {
    title:'水位センサー　設定完了メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\n設定が完了しました。
    設定情報
    SET送信：オン/オフ
    PUSH送信：オン/オフ
    傾斜検出：オン/オフ
    定時連絡：オン/オフ
    連動浸水センサー：1本/2本
    センサー応答時間：3～15秒
    電池電圧：x.xxV
    電波状況：強い/良い/弱い/非常に弱い\n`,
  },
  {
    title:'起動完了　定時連絡オン/オフ\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\n水位センサーの起動が完了して、時刻データを取得しました。
    水位センサーの起動が完了しました。
    電池電圧：x.xxV
    電波状況：強い/良い/弱い/非常に弱い\n`,
  },
  // {
  //   title:'起動完了　定時連絡オフ\n',
  //   header:`\n装置名称:AssetName (SigfoxID)\n`,
  //   content:`\n水位センサーの起動が完了して、時刻データを取得しました。
  //   水位センサーの起動が完了しました。
  //   電池電圧：x.xxV
  //   電波状況：強い/良い/弱い/非常に弱い\n`,
  // },
  {
    title:'水位センサー　起動エラーメール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\n水位センサーが起動しましたが、時刻データの取得に失敗しました。
    電池電圧：x.xxV
    電波状況：強い/良い/弱い/非常に弱い\n`,
  },
  {
    title:'水位センサー　電源確認メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\n装置の電源がオンになりました。
    水位センサーが検知を開始するには時間を要します。
    電波環境等の理由により最長15分程度お待ちいただくことがあります。
    電池電圧：x.xxV
    電波状況：強い/良い/弱い/非常に弱い\n`,
  },
  {
    title:'水位センサー　定時連絡未送信メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\n前回のsigfox送信から48時間が経過しました。
    装置もしくは電波状況の異常が考えられます。\n`,
  },
];

export const dksf07MailTemplate = [
  {
    title:'傾斜センサー　傾斜検出メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\nxx月xx日　xx時xx分の状態をお知らせします。
		傾斜を検出しました。
		USERADDCONTENT
    電池電圧：x.xxV\n`,
  },
  {
    title:'傾斜センサー　傾斜正常メール\n',
    header:`\n装置名称:AssetName (SigfoxID)\n`,
    content:`\nxx月xx日　xx時xx分の状態をお知らせします。
		傾斜が正常に戻りました。
  	USERADDCONTENT
    電池電圧：x.xxV\n`,
  },
];
