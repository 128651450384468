import Page from 'component/Page';
import string from 'util/string';
import FormControls from 'component/FormControls';
import Table from 'component/Table';
import { useState } from 'react';
import { useCallback } from 'react';
import { sendPost } from 'util/request';
import Urls from 'util/urls';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Paths from 'util/paths';
import { formatDate } from 'util/utils';

const DeviceActiveAlert = () => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const title = string.DEVICE_ACTIVE_ALERT;

  const headers = [
    string.DEVICE_SIGFOX_ID,
    string.DEVICE_NAME,
    string.DEVICE_ALERT_DETAIL,
    string.DEVICE_ALERT_DETECTION_TIME,
    string.DEVICE_ALERT_DETECTION_PERIOD,
  ];
  // eslint-disable-next-line no-unused-vars
  const [searchData, setSearchData] = useState({});
  const formControls = [];

  const history = useHistory();
  const infoAction = ({ raw }) =>
    history.push(`${Paths.device.detail}/?id=${raw.deviceId}`);
  const actions = [{ render: 'info', onClick: infoAction }];

  const [data, setData] = useState([]);
  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key,
        data: [
          raw.sigfoxId,
          raw.assetName,
          raw.detail,
          formatDate(raw.detectionTime, 'YYYY-MM-DD HH:mm:ss'),
          raw.detectionPeriod,
        ],
        raw,
      })),
    []
  );

  const doSearch = useCallback(
    async (page, pageSize, getAll = false) => {
      let body = {
        keyword: '',
        // detectionTime: new Date(),
        offset: (page - 1) * pageSize,
        size: pageSize,
      };
      let data = await sendPost({
        url: Urls.device.activeAlert,
        body,
      });
      const { total, result } = data;
      if (!getAll) {
        setData(result);
        setTotal(total);
      }
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchData]
  );

  useEffect(() => {
    doSearch(page, pageSize);
  }, [page, pageSize, doSearch]);

  return (
    <>
      <Page
        title={<>{title}</>}
        body={
          <>
            <FormControls controls={formControls} />
            <Table
              className="nowrap"
              page={page}
              actions={actions}
              pageSize={pageSize}
              total={total}
              onPageChanged={setPage}
              onPageSizeChanged={setPageSize}
              headers={headers}
              data={dataTable(data)}
              getCSVData={async () => {
                return dataTable(await doSearch(1, total, true));
              }}
              csvFileName="ActiveAlert"
            />
          </>
        }
      />
    </>
  );
};

export default DeviceActiveAlert;
