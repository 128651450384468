import React from "react";
import { useState, useEffect } from "react";
import DkSf03wtDataTimeout from "dksf03wticon/DkSf03wtDataTimeout";
import DkSf03wtFlood from "dksf03wticon/DkSf03wtFlood";
import DkSf03wtFloodLowBattery from "dksf03wticon/DkSf03wtFloodLowBattery";
import DkSf03wtLowBattery from "dksf03wticon/DkSf03wtLowBattery";
import DkSf03wtNormal from "dksf03wticon/DkSf03wtNormal";
import DkSf03wtTiltNormal from "dksf03wticon/DkSf03wtTiltNormal";
import DkSf03wtTiltFlood from "dksf03wticon/DkSf03wtTiltFlood";
import DkSf03wtTiltFloodLowBattery from "dksf03wticon/DkSf03wtTiltFloodLowBattery";

const IconList = [
    <DkSf03wtNormal/>,                  // 0
    // <DkSf03wtFlood/>,                   // 1
    // <DkSf03wtTiltNormal/>,              // 2
    // <DkSf03wtTiltFlood/>,               // 3
    // <DkSf03wtLowBattery/>,              // 4
    // <DkSf03wtFloodLowBattery/>,         // 5
    // <DkSf03wtTiltFloodLowBattery/>,     // 6
    // <DkSf03wtDataTimeout/>,             // 7

    <DkSf03wtFlood/>,                   // 1
    <DkSf03wtTiltNormal/>,              // 2
    <DkSf03wtTiltFlood/>,               // 3
    <DkSf03wtLowBattery/>,              // 4
    <DkSf03wtFloodLowBattery/>,         // 5
    <DkSf03wtTiltFloodLowBattery/>,     // 6
    <DkSf03wtDataTimeout/>,             // 7
];

const DkSf03wtIcon = ({
	timeout=0,
	flood=0,
	tilt=0,
	batteryLow=0,
	blinkInterval=0,
	blinkOff=false,
}) => {
	const [countdown, setCountdown] = useState(blinkInterval);

	useEffect(() => {
		const timerId = setTimeout(() => {
			if(countdown > 0){
				setCountdown(countdown - 1);
			}
		},500);
		// console.log('countdown: ', countdown);
		return () => clearTimeout(timerId);
	},[countdown]);

	let iconNo = (timeout === 1) ? 7:0;
    if(timeout === 0){
        if(batteryLow === 1){
            iconNo = iconNo + 4
        }
        if(tilt === 1){
            iconNo = iconNo + 2
        }
        if(flood > 0){
            iconNo = iconNo + 1
        }
    }
    return (
			((blinkOff === false) && (countdown % 2) === 0) ?
				(<React.Fragment>
					{IconList[iconNo]}
				</React.Fragment>)
				:(<></>)
    )
}

export default DkSf03wtIcon;