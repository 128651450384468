import * as React from "react"

const DkSf03wtNormal = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
      <defs>
        <style>{".cls-1{fill:#fff}"}</style>
      </defs>
      <g id="\u30EC\u30A4\u30E4\u30FC_14_2" data-name="\u30EC\u30A4\u30E4\u30FC 2">
        <rect
          className="cls-1"
          x={1.42}
          y={1.42}
          width={47.17}
          height={47.17}
          rx={3.58}
        />
        <path
          d="M45 2.92a2.17 2.17 0 012.16 2.17v40A2.16 2.16 0 0145 47.25H5a2.17 2.17 0 01-2.17-2.16v-40A2.17 2.17 0 015 2.92h40m0-2.83H5a5 5 0 00-5 5v40a5 5 0 005 5h40a5 5 0 005-5v-40a5 5 0 00-5-5z"
          transform="translate(-.01 -.09)"
        />
        <rect
          className="cls-1"
          x={1.42}
          y={1.42}
          width={47.17}
          height={47.17}
          rx={3.58}
        />
        <path
          d="M45 2.92a2.17 2.17 0 012.16 2.17v40A2.16 2.16 0 0145 47.25H5a2.17 2.17 0 01-2.17-2.16v-40A2.17 2.17 0 015 2.92h40m0-2.83H5a5 5 0 00-5 5v40a5 5 0 005 5h40a5 5 0 005-5v-40a5 5 0 00-5-5z"
          transform="translate(-.01 -.09)"
        />
      </g>
      <g id="\u4E00\u53E3\u30BB\u30F3\u30B5_14">
        <path
          d="M37.53 24.64a2.78 2.78 0 010 5.56h-1.67v12.9a.91.91 0 01-.86.9H15.05a.91.91 0 01-.91-.9V30.2h-1.67a2.78 2.78 0 010-5.56h1.67v-2.35a.91.91 0 01.91-.91h7.46v-5L23.19 6h3.62l.68 10.4v5H35a.91.91 0 01.91.91v2.35zm.68 2.78a.68.68 0 10-.68.68.67.67 0 00.68-.68zm-25.06 0a.68.68 0 10-.68.68.67.67 0 00.68-.68z"
          transform="translate(-.01 -.09)"
          id="LINE_14"
        />
      </g>
      <path
        fill="none"
        stroke="#000"
        strokeMiterlimit={10}
        strokeWidth="2.13px"
        id="\u4E00\u53E3\u30B1\u30FC\u30D6\u30EB_14"
        d="M25 5.91L25 1.45"
      />
    </svg>
  )
}

export default DkSf03wtNormal;
