import { createSelector } from 'reselect';

const selectApp = (state) => state.app;

export const selectToast = createSelector([selectApp], (app) => app.toast);
export const selectConfirm = createSelector([selectApp], (app) => app.confirm);

export const selectSearchRangeDate = createSelector(
  [selectApp],
  (app) => app.searchRangeDate
);
