import { createStore, applyMiddleware } from 'redux';
import { persistStore } from 'redux-persist';
import createSagaMiddleware from 'redux-saga';

import { composeWithDevTools } from 'redux-devtools-extension';

import rootReducer from './root-reducer';
import rootSaga from './root-saga';

const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];

let configMiddlewares = applyMiddleware(...middlewares);

if (process.env.NODE_ENV === 'development') {
  configMiddlewares = composeWithDevTools(configMiddlewares);
}

const store = createStore(rootReducer, configMiddlewares);

sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);

export { store, persistor };
