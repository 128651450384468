import FormControls from 'component/FormControls';
import Modal from 'component/Modal';
import Page from 'component/Page';
import Table from 'component/Table';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { selectSearchRangeDate } from 'redux/app/app.selector';
import { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import { adjustDate, formatDate, truncTime, setHours, getLat, getLong, nNU } from 'util/utils';
import MailAlertSelect from './MailAlertSelect';
import PhoneAlertSelect from './PhoneAlertSelect';

const DeviceAlertHistory = () => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const searchRangeDate = useSelector(selectSearchRangeDate);
  const [searchData, setSearchData] = useState({
    fromDate: searchRangeDate?.[0] || truncTime(adjustDate(new Date(), -7)),
    toDate: searchRangeDate?.[1] || truncTime(),
  });
  const formControls = [
    {
      type: 'rangeDate',
      title: string.LOG_TIME,
      onChange: (e) =>
        setSearchData({
          ...searchData,
          fromDate: e[0],
          toDate: e[1],
        }),
      options: {
        defaultDate: [searchData.fromDate, searchData.toDate],
      },
    },
  ];

  const title = string.DEVICE_ALERT_HISTORY;

  const headers = [
    string.DEVICE_SIGFOX_ID,
    string.DEVICE_NAME,
    string.DEVICE_ALERT_DETAIL,
    string.DEVICE_ALERT_DETECTION_TIME,
    // string.DEVICE_ALERT_RESOLUTION_TIME,
    // string.DEVICE_ALERT_DETECTION_PERIOD,
  ];

  const csvHeaders = [
    string.DEVICE_SIGFOX_ID,
    string.DEVICE_NAME,
    string.DEVICE_ALERT_DETAIL,
    string.DEVICE_ALERT_DETECTION_TIME,
    string.DEVICE_ALERT_DETECTION_LAT,
    string.DEVICE_ALERT_DETECTION_LONG,
    string.DEVICE_ALERT_DETECTION_DEVICE_URL,
    string.DEVICE_ALERT_DETECTION_MAP_URL,
    // string.DEVICE_ALERT_RESOLUTION_TIME,
    // string.DEVICE_ALERT_DETECTION_PERIOD,
  ];


  // eslint-disable-next-line no-unused-vars
  // const [searchData, setSearchData] = useState({});
  const [mailAlertData, setMailAlertData] = useState(null);
  const [phoneAlertData, setPhoneAlertData] = useState(null);

  const history = useHistory();
  const infoAction = ({ raw }) => setMailAlertData(raw);
  const infoPhoneAction = ({ raw }) => setPhoneAlertData(raw);
  const [searchId, setSearchId] = useState('');

  const modalMailBody = (
    <>
      <Page
        title={string.MAIL_DETAIL}
        body={
          <>
            <MailAlertSelect selectedData={mailAlertData} />
          </>
        }
      />
    </>
  );
  const modalPhoneBody = (
    <>
      <Page
        title={string.PHONE_DETAIL}
        body={
          <>
            <PhoneAlertSelect selectedData={phoneAlertData} />
          </>
        }
      />
    </>
  );

  const [data, setData] = useState([]);
  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key,
        data: [
          raw.sigfoxId,
          raw.assetName,
          raw.detail,
          formatDate(raw.detectionTime, 'YYYY-MM-DD HH:mm:ss'),
          // formatDate(raw.resolutionTime, 'YYYY-MM-DD HH:mm:ss'),
          // raw.detectionPeriod,
					// raw.latLong,
					// raw.deviceUrl,
					// raw.mapUrl,
        ],
				csvData: [
          raw.sigfoxId,
          raw.assetName,
          raw.detail,
          formatDate(raw.detectionTime, 'YYYY-MM-DD HH:mm:ss'),
          // formatDate(raw.resolutionTime, 'YYYY-MM-DD HH:mm:ss'),
          // raw.detectionPeriod,
					!!nNU(raw.latLong) ? getLat(raw.latLong):"",
					!!nNU(raw.latLong) ? getLong(raw.latLong):"",
					raw.deviceUrl,
					raw.mapUrl,
				],
        raw,
      })),
    []
  );
  // console.log(data);
  const actions = [
    // { render: 'info', onClick: infoAction },
    ({ raw }) => {
      if (raw.toMail) {
        return { render: 'mail', onClick: infoAction };
      }
    },
    ({ raw }) => {
      if (raw.toApp) {
        return { render: 'phone', onClick: infoPhoneAction };
      }
    },
  ];
  const doSearch = useCallback(
    async (page, pageSize, searchId, getAll = false) => {
      setHours(searchData.fromDate);
      setHours(searchData.toDate, 23, 59, 59, 999);

      let body = {
        ...(!searchData?.fromDate
          ? null
          : {
              fromDate: searchData.fromDate.toISOString(),
            }),
        ...(!searchData?.toDate
          ? null
          : {
              toDate: searchData.toDate.toISOString(),
            }),
        keyword: searchId,
        // detectionTime: new Date(),
        offset: (page - 1) * pageSize,
        size: pageSize,
      };
      if (!body?.fromDate || !body?.toDate) {
        return;
      }
      let data = await sendPost({
        url: Urls.device.alertHistory,
        body,
      });
      const { total, result } = data;
      if (!getAll) {
        setData(result);
        setTotal(total);
      }
      return result;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchData, searchId]
  );

  useEffect(() => {
    doSearch(page, pageSize, searchId);
  }, [page, pageSize, searchId, doSearch]);

  return (
    <>
      <Page
        title={<>{title}</>}
        body={
          <>
            <FormControls controls={formControls} />
            <Table
              className="nowrap"
              page={page}
              actions={actions}
              pageSize={pageSize}
              total={total}
              onPageChanged={setPage}
              onPageSizeChanged={setPageSize}
              headers={headers}
							csvHeaders={csvHeaders}
              data={dataTable(data)}
              getCSVData={async () => {
                return dataTable(await doSearch(1, total, '', true));
              }}
              csvFileName="AlertHistory"
              search={true}
              onSearch={(value) => setSearchId(value)}
            />
          </>
        }
      />
      <Modal
        show={!!mailAlertData}
        onHide={() => setMailAlertData(null)}
        body={modalMailBody}
      />
      <Modal
        show={!!phoneAlertData}
        onHide={() => setPhoneAlertData(null)}
        body={modalPhoneBody}
      />
    </>
  );
};

export default DeviceAlertHistory;
