import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { mailTemplate } from 'util/mailTemplate';
import string from 'util/string';
import { formatDate, formatDayOfTheWeekJa } from 'util/utils';
import './PhoneAlertSelect.scss';
import { BsFillPhoneVibrateFill } from 'react-icons/bs';
var kanjidate = require('kanjidate');

const PhoneAlertSelect = ({ selectedData = [] }) => {
  //console.log(selectedData);
  return (
    <div className="alert-select">
      <h5 style={{ marginBottom: '20px' }}>{selectedData?.detail}</h5>
      <Row style={{ display: 'flex', marginBottom: '7px' }}>
        <Col xs="auto" xl={1}>
          <BsFillPhoneVibrateFill size={25} />
          {` :`}
        </Col>
        <Col style={{ color: '#1c63b7', cursor: 'pointer', hover: '' }}>
          {selectedData?.sendToApp.map((item, index) => (
            <React.Fragment key={index}>
              <span className="link">
                {item.deviceInfo ? item.deviceInfo : 'SmartPhoneX'}
              </span>
              {index === selectedData?.sendToApp.length - 1 ? '' : '、'}
            </React.Fragment>
          ))}
        </Col>
      </Row>
      {/* <Row xs={1} sm={4} style={{ marginBottom: '10px' }}>
        {selectedData.sendToApp.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <Col
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  border: '1px solid #ddd',
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <BsFillPhoneVibrateFill size={50} />
                  <div>{item.deviceInfo ? item.deviceInfo : 'Phone'}</div>
                </div>
              </Col>
            </React.Fragment>
          );
        })}
      </Row> */}
      <Row style={{ display: 'flex', marginBottom: '20px' }}>
        <Col xs="auto" xl={1}>
          {string.MAIL_DATE}
        </Col>
        <Col>
          {formatDayOfTheWeekJa(
            formatDate(
              selectedData?.detectionTime,
              'YYYY年MM月DD日 (ddd) HH:mm'
            )
          )}
        </Col>
      </Row>

      {selectedData?.header?.split('\n').map((line) => (
        <Row>
          <Col>{line}</Col>
        </Row>
      ))}
      {selectedData?.content?.split('\n').map((line) => (
        <Row>
          <Col>{line}</Col>
        </Row>
      ))}
    </div>
  );
};

export default PhoneAlertSelect;
