import Page from 'component/Page';
import string from 'util/string';
import FormControls from 'component/FormControls';
import Table from 'component/Table';
import { useState } from 'react';
import {
  adjustDate,
  formatDate,
  setHours,
  setSearchRangeDate,
  truncTime,
} from 'util/utils';
import { useCallback } from 'react';
import { sendPost } from 'util/request';
import Urls from 'util/urls';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Paths from 'util/paths';
import { useSelector } from 'react-redux';
import { selectSearchRangeDate } from 'redux/app/app.selector';

const DeviceActivity = () => {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchId, setSearchId] = useState('');

  const title = string.DEVICE_ACTIVITY;

  const headers = [
    string.DEVICE_SIGFOX_ID,
    string.DEVICE_NAME,
    string.DEVICE_LAST_TRACKED_TIME,
    string.DEVICE_TRACKING_COUNT,
  ];

  const searchRangeDate = useSelector(selectSearchRangeDate);
  const [searchData, setSearchData] = useState({
    fromDate: searchRangeDate?.[0] || truncTime(adjustDate(new Date(), -7)),
    toDate: searchRangeDate?.[1] || truncTime(),
  });
  const formControls = [
    {
      type: 'rangeDate',
      title: string.LOG_TIME,
      onChange: (e) =>
        setSearchData({
          ...searchData,
          fromDate: e[0],
          toDate: e[1],
        }),
      options: {
        defaultDate: [searchData.fromDate, searchData.toDate],
      },
    },
  ];

  const history = useHistory();
  const infoAction = ({ raw }) =>
    history.push(`${Paths.device.detail}/?id=${raw.deviceId}`);
  const actions = [{ render: 'info', onClick: infoAction }];

  const [data, setData] = useState([]);
  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key,
        data: [
          raw.sigfoxId,
          raw.assetName,
          formatDate(raw.latestSeen, 'YYYY-MM-DD HH:mm:ss'),
          raw.totalMessages,
        ],
        raw,
      })),
    []
  );

  const doSearch = useCallback(
    async (page, pageSize, searchId, getAll = false) => {
      setHours(searchData.fromDate);
      setHours(searchData.toDate, 23, 59, 59, 999);
      let body = {
        ...(!searchData?.fromDate
          ? null
          : {
              fromDate: searchData.fromDate.toISOString(),
            }),
        ...(!searchData?.toDate
          ? null
          : {
              toDate: searchData.toDate.toISOString(),
            }),
        offset: (page - 1) * pageSize,
        size: pageSize,
        keyword: searchId,
      };
      if (!body?.fromDate || !body?.toDate) {
        return;
      }
      setSearchRangeDate([searchData.fromDate, searchData.toDate]);
      let response = await sendPost({
        url: Urls.device.activities,
        body,
      });
      const { total, result } = response;
      if (!getAll) {
        setData(result);
        setTotal(total);
      }
      return result;
    },
    [searchData]
  );

  useEffect(() => {
    // console.log(page);
    doSearch(page, pageSize, searchId);
  }, [page, pageSize, doSearch, searchId]);
  // console.log(searchId);
  return (
    <>
      <Page
        title={<>{title}</>}
        body={
          <>
            <FormControls controls={formControls} />
            <Table
              className="nowrap"
              page={page}
              actions={actions}
              pageSize={pageSize}
              total={total}
              onPageChanged={setPage}
              onPageSizeChanged={setPageSize}
              headers={headers}
              data={dataTable(data)}
              getCSVData={async () => {
                return dataTable(await doSearch(1, total, '', true));
              }}
              csvFileName="Activity"
              search={true}
              onSearch={(value) => setSearchId(value)}
            />
          </>
        }
      />
    </>
  );
};

export default DeviceActivity;
