import React from 'react';
import { Col } from 'react-bootstrap';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
// import { Button } from 'react-bootstrap';
import './Weekday.scss';

const Weekday = ({ className, id, id2, value, callback, title, defaultValue }) => {
  const days = [
    { title: '全', active: false },
    { title: '日', active: false },
    { title: '月', active: false },
    { title: '火', active: false },
    { title: '水', active: false },
    { title: '木', active: false },
    { title: '金', active: false },
    { title: '土', active: false },
  ];
  return (
    <ToggleButtonGroup
      type="checkbox"
      value={value}
      onChange={callback}
      className={className}
    >
      {days.map((day, index) => {
        return (
          <ToggleButton
            key={index}
            value={`${id}-${index}`}
            id={`tbg-btn-${id2}-${id}-${index}`}
            type="checkbox"
            variant="outline-primary"
            className="weekday2-button"
          >
            {/* <Col>{title ? day.title : ''}</Col> */}
						{<div>{title ? day.title : ''}</div>}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default Weekday;
