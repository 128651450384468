import { getString } from './utils';

const ejMap = {
  contract: '契約',
  device: '装置',
  account: 'アカウント',
};

const actStrEn = {
  NEW_CONFIRM: 'Create new $1?',
  UPDATE_CONFIRM: 'Update $1?',
  DELETE_CONFIRM: 'Delete $1?',
  UNASSIGN_CONFIRM: 'Unassign $1?',
  NEW_SUCCESS: '$1 created.',
  UPDATE_SUCCESS: '$1 updated.',
  DELETE_SUCCESS: '$1 deleted.',
  UNASSIGN_SUCCESS: '$1 unassigned.',
};

const stringEn = {
  BLANK: '',
  TITLE: 'ALINCO Sensor FOX',
  MY_ACCOUNT: 'My Account',
  ACCOUNT_MANAGEMENT: 'Account Management',
  ACCOUNT_MAINTENANCE: 'Account Maintenance',
  LOG_IN: 'Log in',
  LOG_OUT: 'Log out',
  LOGIN_ID: 'Login ID',
  ACCOUNT_NAME: 'Account Name',
  ACCOUNT_ROLE: 'Role',
  ACCOUNT_MAIL: 'Mail Address',
  ACCOUNT_STATUS: 'Status',
  ACCOUNT_SELECT: 'Account Select',
  NEW_ACCOUNT: 'New Account',
  EMAIL_ADDRESS: 'Email Address',
  CHANGE_PASSWORD: 'Change Password',
  CURRENT_PASSWORD: 'Current Password',
  NEW_PASSWORD: 'New Password',
  CONFIRM_PASSWORD: 'Confirm New Password',
  PASSWORD: 'Password',
  UPDATE: 'Update',
  ACCOUNT_ROLE_ADMIN: 'System Manager',
  ACCOUNT_ROLE_PARENT: 'Service Manager',
  ACCOUNT_ROLE_CHILD: 'Normal',
  ALERT_REPORT: 'Alert Report',
  ACCOUNT_DELETED_ALERT: 'Account deleted',
  ACTIVE: 'Active',
  INACTIVE: 'Inactive',
  RECEIVE: 'Receive',
  NO_RECEIVE: "Don't Receive",
  SAVE: 'Save',
  CONTRACT_MANAGEMENT: 'Contract Management',
  NEW_CONTRACT: 'New Contract',
  ADD_CONTRACT: 'Add Contract',
  CONTRACT_NUMBER: 'Contract Number',
  CONTRACT_INFO: 'Contract Info',
  CONTRACT_EXPIRATION_DATE: 'Expiration Date',
  CONTRACT_ACTIVATION_DATE: 'Validation Date',
  DEVICE_LAST_TRACKED_TIME: 'Last Tracked Time',
  DEVICE_TRACKING_COUNT: 'Tracking Count',
  DEVICE_ACTIVITY: 'Device Activity',
  DEVICE_MANAGEMENT: 'Device Management',
  DEVICE_MAINTENANCE: 'Device Maintenance',
  DEVICE_NAME: 'Name',
  DEVICE_MODEL: 'Model',
  DEVICE_SERIALNO: 'Serial Number',
  DEVICE_STATUS: 'Device Status',
  DEVICE_SIGNAL_STRENGTH: 'Signal Strength',
  DEVICE_ID: 'Device ID',
  DEVICE_SIGFOX_ID: 'Sigfox ID',
  DEVICE_SELECT: 'Device Select',
  DEVICE_TEMPERATURE: 'Temperature',
  DEVICE_BATTERY: 'Battery',
  DEVICE_SENSOR: 'Sensor',
  DEVICE_DETAIL: 'Device Detail',
  DEVICE_DETAIL_STATUS: 'Status',
  DEVICE_DETAIL_JOURNEY: 'Journey',
  DEVICE_DETAIL_PAYLOAD: 'Payload',
  DEVICE_LOCATION: 'Location',
  DEVICE_LOCATION_UPDATE: 'Update Location',
  DEVICE_LOCATION_UPDATE_SUCCESS: 'Location Updated',
  LOCATION_LATITUDE: 'Latitude',
  LOCATION_LONGITUDE: 'Longitude',
  DEVICE_LOG_TIME: 'Log time',
  DEVICE_LOG_DATA: 'Data',
  DEVICE_LOG_SEQUENCENO: 'Sequence Number',
  DEVICE_INFO: 'Device Info',
  DEVICE_ACTIVE_ALERT: 'Active Alert',
  DEVICE_ALERT_DETAIL: 'Alert Detail',
  DEVICE_ALERT_DETECTION_TIME: 'Detection Time',
  DEVICE_ALERT_RESOLUTION_TIME: 'Resolution Time',
  DEVICE_ALERT_DETECTION_PERIOD: 'Detection Period',
  DEVICE_ALERT_HISTORY: 'Alert History',
  NEW_DEVICE: 'New Device',
  REQUIRE_RESET_PASSWORD: 'Require reset password on first login',
  ADD_DEVICE: 'Add Device',
  REMOVE_DEVICE: 'Remove Device',
  NOTI_STARTUP_REPORT: 'Startup report',
  NOTI_PERIODIC_REPORT: 'Periodic report',
  NOTI_EXTERNAL_INPUT_MONITOR: 'External input monitor',
  NOTI_TEMPERATURE_SENSOR: 'Temperature sensor',
  NOTI_FLOODING_SENSOR: 'Flooding sensor',
  NOTI_DROUGHT_SENSOR: 'Drought sensor',
  NOTI_SOUND_SENSOR: 'Sound sensor',
  NOTI_VIBRATION_SENSOR: 'Vibration sensor',
  NOTI_TILT_SENSOR: 'Tilt sensor',
  NOTI_ALERT_BUTTON: 'Alert button',
  NOTI_HEALTH_CHECK_CONFIRMED: 'Health check confirmed',
  NOTI_HEALTH_CHECK_UNCONFIRMED: 'Health check unconfirmed',
  NOTI_HEALTH_CHECK_START: 'Health check start',
  NOTI_DIP_SWITCH_CONFIRMATION: 'DIP switch confirmation',
  NOTI_STARTUP_COMPLETE: 'Startup complete',
  NOTI_TIME_DATA_REQUEST_STARTUP: 'Time data request on startup',
  NOTI_PERIODIC_TIME_DATA_REQUEST: 'Periodic time data request',
  NOTI_REQUEST_FOR_DOWNLINK_DATA: 'Request for downlink data',
  PAGINATION_TEXT: 'Showing $1 to $2, $3 items in total',
  PAGINATION_PERPAGE_1: 'Show',
  PAGINATION_PERPAGE_2: 'Items',
  AUTHENTICATION_LOG: 'Authentication log',
  PROCESSED_TIME: 'Processed Time',
  PROCESSED_RESULT: 'Processed Result',
  IP_ADDRESS: 'IP Address',
  CONFIRM: 'Confirm',
  ARE_YOU_SURE: 'Are you sure?',
  ROWS: '$1 Rows',
  DONE: 'DONE',
  FINISH: 'FINISH',
  OK: 'OK',
  ROW_SELECTED_COUNT: '$1 Row Selected',
  N_ITEM_SELECT: 'Select $1 Items',
  CSV_DOWNLOAD: 'CSV Download',
  CHANGE_PASSWORD_CONFIRM: 'Do you want to change the password?',
  CHANGE_PASSWORD_SUCCESS: 'Password Changed',
  PASSWORD_EMPTY_ERR: 'Password Empty.',
  NEW_PASSWORD_NOT_MATCHED_ERR: 'New Password Not Matched.',
  UNKNOWN_EXCEPTION: 'Unknown Exception',
  ACCOUNT_UNASSIGN_CONTRACT_CONFIRM: 'Unassign contract $1 from account $2?',
  LOCATION_UPDATE_CONFIRM: getString(actStrEn.UPDATE_CONFIRM, ['location']),
  LOCATION_UPDATE_SUCCESS: getString(actStrEn.UPDATE_SUCCESS, ['Location']),
  ...['contract', 'account', 'device'].reduce((prev, val) => {
    let valU = val.toUpperCase();
    let valN = val[0].toUpperCase() + val.substr(1);
    prev[`${valU}_NEW_CONFIRM`] = getString(actStrEn.NEW_CONFIRM, [val]);
    prev[`${valU}_UPDATE_CONFIRM`] = getString(actStrEn.UPDATE_CONFIRM, [val]);
    prev[`${valU}_DELETE_CONFIRM`] = getString(actStrEn.DELETE_CONFIRM, [val]);
    prev[`${valU}_NEW_SUCCESS`] = getString(actStrEn.NEW_SUCCESS, [valN]);
    prev[`${valU}_UPDATE_SUCCESS`] = getString(actStrEn.UPDATE_SUCCESS, [valN]);
    prev[`${valU}_DELETE_SUCCESS`] = getString(actStrEn.DELETE_SUCCESS, [valN]);
    return prev;
  }, {}),
  DEVICE_SF07NAME: 'DK-SF07',
  DEVICE_SF07: 'DK-SF07',
  DEVICE_SFGPS: 'DK-SFGPS',
  DEVICE_SF04: 'DK-SF04',
  DEVICE_SF03: 'DK-SF03',
  DEVICE_WBGT: 'WBGT',
  DEVICE_SF03WT: 'DK-SF03WT',
  DEVICE_SF03WT2: 'DK-SF03WT2',
  DEVICE_XE1151: 'XE1151',
  MAIL_TEMPLATE_TITLE: 'TITLE',
  MAIL_TEMPLATE_HEADER: 'HEADER',
  MAIL_TEMPLATE_CONTENT: 'CONTENT',
  MAIL_TEMPLATE_FOOTER: 'FOOTER',
};

const actStrJP = {
  NEW_CONFIRM: '$1を新規登録してもよろしいですか？',
  UPDATE_CONFIRM: '$1の情報を更新してもよろしいですか？',
  DELETE_CONFIRM: '$1を消去してもよろしいですか？',
  UNASSIGN_CONFIRM: '$1を解除してもよろしいですか？',
  NEW_SUCCESS: '$1を新規登録しました。',
  UPDATE_SUCCESS: '$1の情報を更新しました。',
  DELETE_SUCCESS: '$1を消去しました。',
  UNASSIGN_SUCCESS: '$1を解除しました。',
};

// console.log(Object.keys(stringEn).filter((e) => !stringJp[e]));

const string = {
  ...stringEn,
  ...{
    DEVICE_STATUS: '状態表示',
    DEVICE_STATUS_DETAIL: '装置状態',
    DEVICE_MANAGEMENT: '装置設定',
    CONTRACT_MANAGEMENT: '契約情報管理',
    CONTRACT_NUMBER: '契約番号',
    CONTRACT_CODE: '契約コード',
    ACCOUNT_MANAGEMENT: 'アカウント管理',
    ACCOUNT_MAINTENANCE: 'アカウント登録',
    ACCOUNT_MAIL: 'メールアドレス',
    ACCOUNT_ROLE: 'ロール',
    ACCOUNT_STATUS: 'ステータス',
    ACCOUNT_SELECT: 'アカウント選択',
    ALERT_REPORT: 'アラートレポート',
    RECEIVE: '受信する',
    NO_RECEIVE: '受信しない',
    ACTIVE: '有効',
    INACTIVE: '無効',
    ACCOUNT_ROLE_ADMIN: 'システム管理者',
    ACCOUNT_ROLE_PARENT: 'サービス管理者',
    ACCOUNT_ROLE_CHILD: '普通',
    REQUIRE_RESET_PASSWORD: '初回ログイン時にパスワードを変更する',
    PAGINATION_TEXT: '$3件中$1から$2まで表示',
    PAGINATION_PERPAGE_1: '表示数',
    PAGINATION_PERPAGE_2: '',
    DEVICE_MAINTENANCE: '装置設定',
    DEVICE_NAME: '装置名称',
    DEVICE_MODEL: '装置種別',
    DEVICE_STATE: '状態',
    DEVICE_SERIALNO: 'シリアル番号',
    DEVICE_SENSOR: '状態表示',
    //DEVICE_SENSOR: '状態表示',
    //DEVICE_STATUS: '状態表示',
    DEVICE_BATTERY: '電源情報',
    DEVICE_TEMPERATURE: '温度',
    DEVICE_WBGT_DATA: 'WBGTデータ',
    DEVICE_WBGT_DATA_TITLE: 'WBGT暑さ指数',
    DEVICE_SIGNAL_STRENGTH: '通信品質',
    DEVICE_DETAIL: '装置詳細',
    DEVICE_DETAIL_STATUS: 'ステータス',
    DEVICE_DETAIL_JOURNEY: '移動履歴',
    DEVICE_DETAIL_PAYLOAD: 'ペイロード',
    DEVICE_DETAIL_THRESH: 'メール送信設定',
    GPS_MAIL_SETTING: 'メール送信設定',
    DEVICE_ACTIVITY: 'アクティビティ',
    DEVICE_LAST_TRACKED_TIME: '最終検知時間',
    DEVICE_TRACKING_COUNT: '検知件数',
    DEVICE_LOCATION: 'ロケーション',
    DEVICE_LOCATION_UPDATE: 'ロケーション更新',
    DEVICE_LOCATION_UPDATE_SUCCESS: '位置の情報を更新しました。',
    LOCATION_LATITUDE: '緯度',
    LOCATION_LONGITUDE: '経度',

    DEVICE_INFO: '装置情報',
    DEVICE_ID: '装置ID',
    DEVICE_LOG_TIME: '受信日時',
    DEVICE_LOG_DATA: 'データ',
    DEVICE_LOG_SEQUENCENO: 'シーケンス番号',
    DEVICE_ACTIVE_ALERT: 'アクティブアラート',
    DEVICE_ALERT_DETAIL: '詳細情報',
    DEVICE_ALERT_TITLE: '装置詳細',
    // DEVICE_ALERT_DETECTION_TIME: '検出日時',
    DEVICE_ALERT_DETECTION_TIME: '通知時間',
    DEVICE_ALERT_RESOLUTION_TIME: '解除日時',
    DEVICE_ALERT_DETECTION_PERIOD: '検出時間',
    DEVICE_ALERT_DETECTION_POSITION: '位置情報',
    DEVICE_ALERT_DETECTION_LAT: '緯度',
    DEVICE_ALERT_DETECTION_LONG: '軽度',
    DEVICE_ALERT_DETECTION_DEVICE_URL: 'WebアプリへのURL',
		DEVICE_ALERT_DETECTION_MAP_URL: 'グーグルマップへのURL',
    // DEVICE_ALERT_HISTORY: 'アラート履歴',
    DEVICE_ALERT_HISTORY: '通知履歴',
    ADD_DEVICE: '装置追加',
    REMOVE_DEVICE: '装置削除',
    DEVICE_SELECT: '装置選択',
    SERVICE_ACCOUNT_SELECT: 'サービス管理者アカウント選択',
    CONTRACT_SELECT: 'アカウント選択',
    ADD_CONTRACT: '契約割当',
    CONTRACT_INFO: '契約詳細',
    CONTRACT_EDIT: '契約情報編集',
    CONTRACT_ACTIVATION_DATE: '開始日',
    CONTRACT_EXPIRATION_DATE: '終了日',
    LOG_TIME: '履歴期間表示',
    PROCESSED_TIME: '処理時間',
    PROCESSED_RESULT: '処理結果',
    LOGIN_ID: 'ログインID',
    LOG_IN: 'ログイン',
    NEXT_STEP: '次へ',
    LOG_OUT: 'ログアウト',
    ACCOUNT_NAME: 'アカウント名',
    EMAIL_ADDRESS: 'メールアドレス',
    CHANGE_PASSWORD: 'パスワード変更',
    CURRENT_PASSWORD: '現在パスワード',
    NEW_PASSWORD: '新しいパスワード',
    UPDATE: '更新',
    COMFIRM: '確認',
    CONFIRM_NEW_PASSWORD: '新しいパスワード（再入力）',
    CONFIRM_PASSWORD: 'パスワード（再入力）',
    MY_ACCOUNT: 'マイアカウント',
    PASSWORD: 'パスワード',
    PASSWORD_REPEAT: 'パスワード（再入力）',
    ACTIVATE_CODE: 'アクティベーションコード',
    IP_ADDRESS: 'IPアドレス',
    NOTI_FLOODING_SENSOR: '浸水検出',
    NOTI_HIGH_FLOOD_LEVEL: '浸水レベル高',
    NOTI_LOW_FLOOD_LEVEL: '浸水レベル低',
    NOTI_TILT_SENSOR: '傾斜異常',
    NOTI_DROUGHT_SENSOR: '正常',
    NOTI_NORMAL: '正常',
    SAVE: '保存',
    ADD: '追加',
    CONFIRM: '確認',
    ARE_YOU_SURE: '進んでもよろしいですか？',
    NEW_ACCOUNT: 'アカウント登録',
    ROWS: '$1件',
    CSV_DOWNLOAD: 'CSVダウンロード',
    NEW_DEVICE: '装置登録',
    NEW_CONTRACT: '契約登録',
    ACCOUNT_DELETED_ALERT: 'アカウントが削除されました。',
    DONE: '完了',
    FINISH: '完了',
    ROW_SELECTED_COUNT: '選択件数：$1件',
    N_ITEM_SELECT: '$1件を選択する',
    ITEM_SELECT: '選択する',
    CHANGE_PASSWORD_CONFIRM: 'パスワードを変更してもよろしいですか？',
    CHANGE_PASSWORD_SUCCESS: 'パスワードを変更しました。',
    PASSWORD_EMPTY_ERR: 'パスワードは未入力です。',
    ACCOUNT_NAME_EMPTY_ERR: 'アカウント名は未入力です。',
    LOGIN_ID_EMPTY_ERR: 'ログインIDは未入力です。',
    MAIL_ADDRESS_EMPTY_ERR: 'メールアドレスは未入力です。',
    ACTIVE_CODE_EMPTY_ERR: 'アクティベーションコードは未入力です。',
    CONTRACT_NUMBER_EMPTY_ERR: '契約番号は未入力です。',
    LOGIN_ID_VERY_LONG_ERR: 'ログインIDの長さは長すぎる',
    NEW_PASSWORD_NOT_MATCHED_ERR: '新しいパスワードが一致しません。',
    PASSWORD_NOT_MATCHED_ERR: 'パスワードが一致しません。',
    UNKNOWN_EXCEPTION: '予期せぬエラーが発生しています。',
    CONTRACT_ASSIGN_DEVICE_CONFIRM:
      '$1の契約に装置を追加してもよろしいですか？',
    CONTRACT_ASSIGN_DEVICE_SUCCESS: '装置を追加ました。',
    CONTRACT_ASSIGN_ACCOUNT_CONFIRM:
      '$1アカウントに$2の契約を追加してもよろしいですか？',
    CONTRACT_ASSIGN_ACCOUNT_SUCCESS: '契約を追加しました。',
    CONTRACT_UNASSIGN_DEVICE_CONFIRM: getString(actStrJP.UNASSIGN_CONFIRM, [
      '$1の契約から装置',
    ]),
    CONTRACT_UNASSIGN_DEVICE_SUCCESS: getString(actStrJP.UNASSIGN_SUCCESS, [
      '装置',
    ]),
    ACCOUNT_ASSIGN_DEVICE_CONFIRM:
      '$1のアカウントに装置を割り当ててもよろしいですか？',
    ACCOUNT_ASSIGN_DEVICE_SUCCESS: '装置を割り当てました。',
    ACCOUNT_UNASSIGN_CONTRACT_CONFIRM: getString(actStrJP.UNASSIGN_CONFIRM, [
      '$1のアカウントから$2の契約の割り当て',
    ]),
    ACCOUNT_UNASSIGN_CONTRACT_SUCCESS: getString(actStrJP.UNASSIGN_SUCCESS, [
      '契約',
    ]),
    ACCOUNT_UNASSIGN_DEVICE_CONFIRM: getString(actStrJP.UNASSIGN_CONFIRM, [
      '$1のアカウントから$2の装置の割り当て',
    ]),
    ACCOUNT_UNASSIGN_DEVICE_SUCCESS: getString(actStrJP.UNASSIGN_SUCCESS, [
      '装置',
    ]),
    ACCOUNT_DEVICE_ALREADY_ASSIGNED: '$1の装置が既に$2に割り当てされています。',
    LOCATION_UPDATE_CONFIRM: getString(actStrJP.UPDATE_CONFIRM, [
      'ロケーション',
    ]),
    LOCATION_UPDATE_SUCCESS: getString(actStrJP.UPDATE_SUCCESS, [
      'ロケーション',
    ]),
    AUTHENTICATION_LOG: '認証ログ',
    ...['contract', 'account', 'device'].reduce((prev, val) => {
      let valU = val.toUpperCase();
      let valJ = ejMap[val];
      prev[`${valU}_NEW_CONFIRM`] = getString(actStrJP.NEW_CONFIRM, [valJ]);
      prev[`${valU}_UPDATE_CONFIRM`] = getString(actStrJP.UPDATE_CONFIRM, [
        valJ,
      ]);
      prev[`${valU}_DELETE_CONFIRM`] = getString(actStrJP.DELETE_CONFIRM, [
        `$1の${valJ}`,
      ]);
      prev[`${valU}_NEW_SUCCESS`] = getString(actStrJP.NEW_SUCCESS, [valJ]);
      prev[`${valU}_UPDATE_SUCCESS`] = getString(actStrJP.UPDATE_SUCCESS, [
        valJ,
      ]);
      prev[`${valU}_DELETE_SUCCESS`] = getString(actStrJP.DELETE_SUCCESS, [
        valJ,
      ]);
      return prev;
    }, {}),
    DEVICE_NEW_CONFIRM: getString(actStrJP.NEW_CONFIRM, ['装置']),
    DEVICE_UPDATE_CONFIRM: getString(actStrJP.UPDATE_CONFIRM, ['装置名称']),
    DEVICE_UPDATE_SUCCESS: getString(actStrJP.UPDATE_SUCCESS, ['装置名称']),
    GEO_NEW_CONFIRM: getString(actStrJP.NEW_CONFIRM, ['ジオフェンス']),
    GEO_UPDATE_CONFIRM: getString(actStrJP.UPDATE_CONFIRM, ['ジオフェンス']),
    GEO_UPDATE_SUCCESS: getString(actStrJP.UPDATE_SUCCESS, ['ジオフェンス']),
    TEMPERATURE: '気温',
    LIGHT_TEMPERATURE: '黒球温度',
    WBGT_TEMPERATURE: 'WBGT',
    HUMIDITY: '湿度',
    MAIL_TEMPLATE: 'メールテンプレート',
    TEMPLATE_1: '高い暑さ指数検出のメール',
    TEMPLATE_2: '安全な暑さ指数検出のメール',
    TEMPLATE_3: '暑さ指数定期測定の結果のメール',
    DISPLAY_HOVER: '表示',
    SETTING_VALUE_ERROR: `無効な設定値になっています。\n※設定値について次の条件をご確認してください。\n① Lev.1-4で設定値が重複しないこと。\n② Lev.4（WBGT高）→ Lev.1（WBGT低）とする。\n③ 下限値が上限値より低い設定値であること。`,
    STATE_DANGER: '危険',
    STATE_CAUTION: '厳重警戒 ',
    STATE_VIGILANCE: '警戒 ',
    STATE_WARNING: '注意',
    STATE_NORMAL: 'ほぼ安全 ',
    MANAGING_DEVICES: '',
    TIME_GRAPH: 'グラフ表示期間指定',
    TIME_PAYLOAD: 'ペイロード表示期間指定',
    RANGE_MEASURE: '縦軸の範囲設定',
    GRAPH_TEXT: '$1から$2までのデータがありません',
    ADD_CONTRACT_ERROR: '$1の契約が不正解',
    STATUS_CODE_200: 'ログイン成功',
    STATUS_CODE_401: 'ログインID又はパスワードが一致しません',
    MAIL_LENGTH_ERROR: '',
    MAIL_FORM: '発信者：',
    MAIL_FORM_USER: 'no-reply@alinco.vn',
    MAIL_TO: '宛先：',
    MAIL_DATE: '日付：',
    FORGET_PASSWORD: 'パスワードを忘れた方はこちら',
    FORGET_PASSWORD_STEP_LOGINID: '',
    FORGET_PASSWORD_STEP_EMAIL:
      '登録時に設定したメールアドレスを入力してください',
    FORGET_PASSWORD_STEP_ACTIVE_CODE:
      'メールに送信したアクティブなコードを入力してください',
    FORGET_PASSWORD_STEP_NEW_PASSWORD: '新しいパスワードを入力してください',
    LOGINID_NOT_EXIST: 'ログインIDが存在しません',
    MAIL_ADDRESS_HINT: 'ヒント',
    LOCATION_IS_NOT_FORMAT:
      '受け付けできない値になっています。ロケーションを「度（Degree Decimal）」というフォーマットで入力してください。',
    CURRENT_LOCATION: '現在位置',
    GET_CURRENT_LOCATION_CONFIRM:
      'Webアプリへログイン中のパソコン・スマホの位置情報をロケーション設定に使用しますか？（使用する場合、アプリが位置情報にアクセスできるように、パソコン・スマホの位置情報アクセス設定をONにしてください。）',
    CURRENT_LOCATION_DISABLE: 'ﾊﾟｿｺﾝ･ｽﾏﾎの位置情報ｱｸｾｽ設定がｵﾌになっています',
    SHOW_PICTURE: 'レイアウト図表示へ',
    HIDE_PICTURE: '　　地図表示へ　　',
    UPLOAD_IMAGE: 'レイアウト図アップロード',
    UPLOAD_IMAGE_SUCCESS: 'レイアウト図アップロードが完了しました。',
    UPLOAD_IMAGE_CONFIRM: 'レイアウト図をアップロードしてもよろしいですか？',
    DELETE_DATA: '装置のデータを消去します。',
    DELETE_DATA_CONFIRM: '装置のデータを消去してもよろしいですか？',
    MAIL_DETAIL: 'メール詳細',
    PHONE_DETAIL: 'アラート詳細',
    ADD_GPS_SETTING: 'ジオフェンス登録',
    UPDATE_GPS_SETTING: 'ジオフェンス更新',
    GEO_RADIUS: '半径(m)',
    GEO_REGION_NAME: '地名',
    GEO_CHECK_IN: '進入通知',
    GEO_CHECK_OUT: '退出通知',
    GEO_HOLD_TIME: '長期滞在判定閾値(分)',
    GEO_HOLD_TIME_REPEAT: '繰り返し送信間隔(分)',
    GEO_LIST_TITLE: 'ジオフェンス一覧',
    GEO_EDIT: '編集',
    GEO_DELETE: 'ジオフェンス($1)を消去してもよろしいですか？',
    GEO_DELETED: 'ジオフェンス($1)を消去しました。',
    GEO_NAME_EMPTY_ERR: '地名は未入力です。',

    DKSF03WT2_FLOOD_OK: '水位情報 渇水状態',
    DKSF03WT2_FLOOD_NG: '水位情報 浸水状態',

    DKSF03WT2_FLOOD_AB_OK: '水位情報A、水位情報Bともに渇水状態',
    DKSF03WT2_FLOOD_A_NG: '水位情報A 浸水状態、水位情報B 渇水状態',
    DKSF03WT2_FLOOD_B_NG: '水位情報A 渇水状態、水位情報B 浸水状態',
    DKSF03WT2_FLOOD_AB_NG: '水位情報A、水位情報Bともに浸水状態',

    DKSF03WT2_TILT_OK: '傾斜無し',
    DKSF03WT2_TILT_NG: '傾斜異常',

    DKSF03WT2_BATTERY_OK: '電池正常',
    DKSF03WT2_BATTERY_NG: '減電池',

    DKSF03WT2_TIMEOUT: '定時連絡未送信',
    DKSF03WT2_MAIL_SETTING: 'メール送信設定',

    DKSF07_TILT_OK: '傾斜無し',
    DKSF07_TILT_NG: '傾斜異常',
    DKSF07_MAIL_SETTING: 'メール送信設定',

  },
};

export default string;
