import { Modal as BSModal } from 'react-bootstrap';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import { RouteMap } from 'util/routes';
import './Modal.scss';

const Modal = ({
  size = 'xl',
  header,
  closeButton = true,
  title,
  body = <></>,
  footer,
  show,
  onHide,
  className,
  route,
  props = {},
}) => {
  const currentUser = useSelector(selectCurrentUser);
  const currentRoute = RouteMap[route];
  let currentRoutePermission = currentRoute?.permission || [];
  if (!Array.isArray(currentRoutePermission)) {
    currentRoutePermission = [currentRoutePermission];
  }

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        let modals = window.document.querySelectorAll('.modal.show');
        let modalBackdrops =
          window.document.querySelectorAll('.modal-backdrop');
        let defaultIndex = 800;
        for (let i = 0; i < modals.length; i++) {
          modals[i].style.zIndex = defaultIndex + i * 20 + 5;
          modalBackdrops[i].style.zIndex = defaultIndex + i * 20;
        }
      }, 600);
    }
  }, [show]);

  if (
    !!currentRoutePermission.length &&
    show &&
    currentRoutePermission.indexOf(currentUser.accountRole) < 0
  ) {
    return <></>;
  }

  return (
    <BSModal
      show={show}
      onHide={onHide}
      className={`_modal ${!title && !header ? 'no-header' : ''} ${
        className || ''
      }`}
      size={size}
      backdrop="static"
    >
      <BSModal.Header closeButton={closeButton}>
        {!!header && header}
        {!!title && <BSModal.Title>{title}</BSModal.Title>}
      </BSModal.Header>
      <BSModal.Body>
        {body}
        {!!route}
      </BSModal.Body>
      {!!footer && <BSModal.Footer>{footer}</BSModal.Footer>}
    </BSModal>
  );
};

export default Modal;
