import Page from 'component/Page';
import { useCallback, useMemo } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import Table from 'component/Table';
import { sendPost } from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import { adjustDate, formatDate, setHours, truncTime } from 'util/utils';
import FormControls from 'component/FormControls';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import { ACCOUNT_ROLE_ADMIN } from 'util/permission';

const AuthLog = () => {
  const currentUser = useSelector(selectCurrentUser);
  const isAdmin = useMemo(
    () => currentUser.accountRole === ACCOUNT_ROLE_ADMIN,
    [currentUser]
  );
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  const title = string.AUTHENTICATION_LOG;
  const headers = isAdmin
    ? [
        string.PROCESSED_TIME,
        string.LOGIN_ID,
        string.PROCESSED_RESULT,
        string.IP_ADDRESS,
      ]
    : [string.PROCESSED_TIME, string.LOGIN_ID, string.PROCESSED_RESULT];
  const [searchData, setSearchData] = useState({
    startProcessedDate: truncTime(adjustDate(new Date(), -7)),
    endProcessedDate: truncTime(),
  });
  const formControls = [
    {
      type: 'rangeDate',
      title: string.PROCESSED_TIME,
      onChange: (e) =>
        setSearchData({
          ...searchData,
          startProcessedDate: e[0],
          endProcessedDate: e[1],
        }),
      options: {
        defaultDate: [
          searchData.startProcessedDate,
          searchData.endProcessedDate,
        ],
      },
    },
  ];

  const getErrorCode = (errCode) => {
    if (errCode === 200) return string.STATUS_CODE_200;
    else if (errCode === 401) return string.STATUS_CODE_401;
    else return errCode;
  };

  const [data, setData] = useState([]);
  const dataTable = useCallback(
    (data) =>
      data.map((raw, key) => ({
        key,
        data: isAdmin
          ? [
              formatDate(raw.processedDate, 'YYYY-MM-DD HH:mm:ss'),
              raw.loginId,
              getErrorCode(raw.processedResult),
              raw.ipAddress,
            ]
          : [
              formatDate(raw.processedDate, 'YYYY-MM-DD HH:mm:ss'),
              raw.loginId,
              getErrorCode(raw.processedResult),
            ],
        raw,
      })),
    []
  );

  const doSearch = useCallback(
    async (page, pageSize, getAll = false) => {
      setHours(searchData.startProcessedDate);
      setHours(searchData.endProcessedDate, 23, 59, 59, 999);
      let body = {
        ...(!searchData?.startProcessedDate
          ? null
          : {
              startProcessedDate: searchData.startProcessedDate.toISOString(),
            }),
        ...(!searchData?.endProcessedDate
          ? null
          : {
              endProcessedDate: searchData.endProcessedDate.toISOString(),
            }),
        offset: (page - 1) * pageSize,
        size: pageSize,
      };
      if (!body?.startProcessedDate || !body?.endProcessedDate) {
        return;
      }

      let data = await sendPost({
        url: Urls.user.authLog,
        body,
      });
      const { total, result } = data;
      if (!getAll) {
        setData(result);
        setTotal(total);
      }
      return result;
    },
    [searchData]
  );

  useEffect(() => {
    doSearch(page, pageSize);
  }, [page, pageSize, doSearch]);

  return (
    <>
      <Page
        title={title}
        body={
          <>
            <FormControls controls={formControls} />
            <Table
              className="nowrap"
              page={page}
              pageSize={pageSize}
              total={total}
              onPageChanged={setPage}
              onPageSizeChanged={setPageSize}
              headers={headers}
              data={dataTable(data)}
              getCSVData={async () => {
                return dataTable(await doSearch(1, total, true));
              }}
              csvFileName="AuthenticationLog"
            />
          </>
        }
      />
    </>
  );
};

export default AuthLog;
