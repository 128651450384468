import { useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import Paths from 'util/paths';
import sendRequest, {
  RESPONSE_STATUS_ERROR,
  RESPONSE_STATUS_SUCCESS,
  sendPost,
} from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import { focusElement, getFormData, showToast } from 'util/utils';
import './ForgetPassword.scss';

const STEP_SIZE = 4;
const STEP_LOGINID = 0;
const STEP_EMAIL = 1;
const STEP_ACTIVE_CODE = 2;
const STEP_NEW_PASSWORD = 3;

const InputText = ({ type = 'text', id, placeholder, Icon }) => {
  const props = { id, name: id, type };
  if (placeholder) {
    props['placeholder'] = placeholder;
  }

  return (
    <InputGroup>
      {!!Icon && (
        <InputGroup.Text>
          <Icon />
        </InputGroup.Text>
      )}
      <Form.Control {...props} />
    </InputGroup>
  );
};

const ForgetPassword = () => {
  const history = useHistory();
  const [resetPasswordStep, setResetPasswordStep] = useState(STEP_LOGINID);
  const [mailHint, setMailHint] = useState('');
  const [loginID, setLoginID] = useState('');
  const [activeCode, setActiveCode] = useState('');

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    let formData = getFormData(e.target);
    if (!formData?.username && resetPasswordStep === STEP_LOGINID) {
      showToast(string.LOGIN_ID_EMPTY_ERR);
      focusElement('#username');
      return;
    }
    if (!formData?.mailAddress && resetPasswordStep === STEP_EMAIL) {
      showToast(string.MAIL_ADDRESS_EMPTY_ERR);
      focusElement('#mailAddress');
      return;
    }
    if (!formData?.activeCode && resetPasswordStep === STEP_ACTIVE_CODE) {
      showToast(string.ACTIVE_CODE_EMPTY_ERR);
      focusElement('#activeCode');
      return;
    }
    if (!formData?.newPassword && resetPasswordStep === STEP_NEW_PASSWORD) {
      showToast(string.PASSWORD_EMPTY_ERR);
      focusElement('#newPassword');
      return;
    }
    if (
      !formData?.repeatNewPassword &&
      resetPasswordStep === STEP_NEW_PASSWORD
    ) {
      showToast(string.PASSWORD_EMPTY_ERR);
      focusElement('#repeatNewPassword');
      return;
    }
    if (resetPasswordStep === STEP_NEW_PASSWORD) {
      if (formData?.newPassword !== formData?.repeatNewPassword) {
        showToast(string.NEW_PASSWORD_NOT_MATCHED_ERR);
        focusElement('#newPassword');
        return;
      }
    }
    if (resetPasswordStep === STEP_LOGINID) {
      sendRequest({
        method: 'GET',
        url: `${Urls.user.getMailFromLoginID}/${formData?.username}`,
        background: true,
        withToken: false,
      }).then((res) => {
        const { status, email, username } = res;
        if (status === RESPONSE_STATUS_SUCCESS) {
          setMailHint(email);
          setLoginID(formData?.username);
          setResetPasswordStep(
            (resetPasswordStep) => (resetPasswordStep + 1) % STEP_SIZE
          );
        }
      });
    } else if (resetPasswordStep === STEP_EMAIL) {
      sendPost({
        url: Urls.user.checkEmailAndLoginID,
        body: { loginId: loginID, email: formData?.mailAddress },
        withToken: false,
      }).then((res) => {
        setResetPasswordStep(
          (resetPasswordStep) => (resetPasswordStep + 1) % STEP_SIZE
        );
      });
    } else if (resetPasswordStep === STEP_ACTIVE_CODE) {
      sendPost({
        url: Urls.user.confirmActiveCode,
        body: { loginId: loginID, activateCode: formData?.activeCode },
        withToken: false,
      }).then((res) => {
        setActiveCode(formData?.activeCode);
        setResetPasswordStep(
          (resetPasswordStep) => (resetPasswordStep + 1) % STEP_SIZE
        );
      });
    } else if (resetPasswordStep === STEP_NEW_PASSWORD) {
      sendPost({
        url: Urls.user.confirmActiveCodeAndSetNewPassword,
        body: {
          loginId: loginID,
          activateCode: activeCode,
          newPassword: formData?.newPassword,
        },
        withToken: false,
      }).then((res) => {
        history.push(Paths.user.login);
      });
    } else {
    }
  };

  return (
    <>
      <div className="screen-forget-password">
        <div className="wrapper fadeInDown">
          <div id="formContent">
            <h2 className="active">{string.TITLE}</h2>
            <div className="card-body">
              <Form onSubmit={onSubmit}>
                {resetPasswordStep === STEP_LOGINID && (
                  <>
                    <h6
                      style={{
                        textAlign: 'left',
                        paddingLeft: '10px',
                        marginBottom: '5px',
                      }}
                    >
                      {string.FORGET_PASSWORD_STEP_LOGINID}
                    </h6>
                    <div className="input">
                      <InputText
                        id="username"
                        // Icon={MdFace}
                        placeholder={string.LOGIN_ID}
                      />
                    </div>
                  </>
                )}
                {resetPasswordStep === STEP_EMAIL && (
                  <>
                    <h6
                      style={{
                        textAlign: 'left',
                        paddingLeft: '10px',
                        marginBottom: '15px',
                      }}
                    >
                      {string.FORGET_PASSWORD_STEP_EMAIL}
                    </h6>
                    <h6
                      style={{
                        textAlign: 'left',
                        paddingLeft: '10px',
                        color: '#777',
                      }}
                    >{`${string.MAIL_ADDRESS_HINT}: ${mailHint}`}</h6>
                    <div className="input">
                      <InputText
                        id="mailAddress"
                        // Icon={MdFace}
                        placeholder={string.EMAIL_ADDRESS}
                      />
                    </div>
                  </>
                )}
                {resetPasswordStep === STEP_ACTIVE_CODE && (
                  <>
                    <h6
                      style={{
                        textAlign: 'left',
                        paddingLeft: '10px',
                        marginBottom: '15px',
                      }}
                    >
                      {string.FORGET_PASSWORD_STEP_ACTIVE_CODE}
                    </h6>
                    <div className="input">
                      <InputText
                        id="activeCode"
                        // Icon={MdFace}
                        placeholder={string.ACTIVATE_CODE}
                      />
                    </div>
                  </>
                )}
                {resetPasswordStep === STEP_NEW_PASSWORD && (
                  <>
                    <h6
                      style={{
                        textAlign: 'left',
                        paddingLeft: '10px',
                        marginBottom: '15px',
                      }}
                    >
                      {string.FORGET_PASSWORD_STEP_NEW_PASSWORD}
                    </h6>
                    <div className="input">
                      <InputText
                        id="newPassword"
                        // Icon={MdFace}
                        type="password"
                        placeholder={string.PASSWORD}
                      />
                      <InputText
                        id="repeatNewPassword"
                        // Icon={MdFace}
                        type="password"
                        placeholder={string.PASSWORD_REPEAT}
                      />
                    </div>
                  </>
                )}
                <div>
                  <Button type="submit" className="button-submit">
                    {resetPasswordStep === STEP_NEW_PASSWORD
                      ? string.CONFIRM
                      : string.NEXT_STEP}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgetPassword;
