import Modal from 'component/Modal';
import 'flatpickr/dist/themes/material_blue.css';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { FaAlignJustify, FaFileDownload, FaFileUpload } from 'react-icons/fa';
import { dataTemplate, mailTemplate } from 'util/mailTemplate';
import string from 'util/string';
import { nNU } from 'util/utils';
import Arrow from './Arrow';

const mailTemplateHeaderSample = [
  '',
  'WBGT（Sigfox ID）',
  '(WBGT測定値℃)',
  '(測定値に合わせる指針)',
  '3.3V',
  '',
];
const thresholdTbl = [
  20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38,
  39, 40,
];

const labelText = ['レベル４', 'レベル３', 'レベル２', ' レベル１'];
const MailTemplate = ({
  thresholdValue = [],
  setThresholdValue,
  dataMailTemplate = [],
  setDataMailTemplate,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [showTemplate, setShowTemplate] = useState(0);
  const inputRef0 = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const inputRef7 = useRef(null);
  const inputRef8 = useRef(null);
  const modalBody = (index) => {
    let mailShow = mailTemplate;
    mailShow.title =
      dataMailTemplate[index]?.title || dataTemplate[index].title;
    mailShow.content =
      dataMailTemplate[index]?.content || dataTemplate[index].content;
    mailShow.header = dataTemplate[index].header;
    return (
      <>
        <h3>{mailShow.title}</h3>
        <p>
          {mailShow.header.split(/(?:\r\n|\r|\n)/g).map((key, e) => (
            <span key={e + 1}>
              {`${mailShow.header.split(/(?:\r\n|\r|\n)/g)[e]} ${
                mailTemplateHeaderSample[e]
              }`}
              <br />
            </span>
          ))}
        </p>
        <p>
          {mailShow.content.split(/(?:\r\n|\r|\n)/g).map((key, e) => (
            <span key={e + 20}>
              {mailShow.content.split(/(?:\r\n|\r|\n)/g)[e]}{' '}
              {mailShow.content.split(/(?:\r\n|\r|\n)/g)[e].length > 0 ? (
                <br></br>
              ) : (
                ''
              )}
            </span>
          ))}
        </p>
        <p>
          {mailShow.footer.split(/(?:\r\n|\r|\n)/g).map((key, e) => (
            <span key={e + 100}>
              {mailShow.footer.split(/(?:\r\n|\r|\n)/g)[e]} <br />
            </span>
          ))}
        </p>
      </>
    );
  };

  useEffect(() => {
    let newTemplate = [...dataMailTemplate];
    dataMailTemplate.forEach((item, index) => {
      if (!nNU(item.title) || !nNU(item.content)) {
        newTemplate[index].title = dataTemplate[index].title;
        newTemplate[index].content = dataTemplate[index].content;
      }
    });
    setDataMailTemplate(dataMailTemplate);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [dataMailTemplate]);

  const handleUpload = (index) => {
    switch (index) {
      case 0:
        inputRef0.current?.click();
        break;
      case 1:
        inputRef1.current?.click();
        break;
      case 2:
        inputRef2.current?.click();
        break;
      case 3:
        inputRef3.current?.click();
        break;
      case 4:
        inputRef4.current?.click();
        break;
      case 5:
        inputRef5.current?.click();
        break;
      case 6:
        inputRef6.current?.click();
        break;
      case 7:
        inputRef7.current?.click();
        break;
      case 8:
        inputRef8.current?.click();
        break;
      default:
        inputRef0.current?.click();
        break;
    }
  };
  const downloadTxtFile = (inx) => {
    const element = document.createElement('a');
    const dataTemplate = dataMailTemplate[inx];

    const file = new Blob(
      ['件名：\n', dataTemplate.title, '\n本文：', dataTemplate.content],
      {
        type: 'text/plain;charset=utf-8',
      }
    );
    element.href = URL.createObjectURL(file);
    element.download = `MailTemplate${inx}.txt`;
    document.body.appendChild(element);
    element.click();
  };
  const handleDisplayFileDetails = (inx) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const title = reader.result.split(/(?:\r\n|\r|\n)/g)[1] + '\n';
      let content = '\n';
      for (let i = 4; i < reader.result.split(/(?:\r\n|\r|\n)/g).length; i++) {
        content = content + reader.result.split(/(?:\r\n|\r|\n)/g)[i];
        content = content + '\n';
      }
      let newMailTemplate = [...dataMailTemplate];
      newMailTemplate[inx].title = title;
      newMailTemplate[inx].content = content;
      setDataMailTemplate(newMailTemplate);
    };
    switch (inx) {
      case 0:
        reader.readAsText(inputRef0.current.files[0]);
        break;
      case 1:
        reader.readAsText(inputRef1.current.files[0]);
        break;
      case 2:
        reader.readAsText(inputRef2.current.files[0]);
        break;
      case 3:
        reader.readAsText(inputRef3.current.files[0]);
        break;
      case 4:
        reader.readAsText(inputRef4.current.files[0]);
        break;
      case 5:
        reader.readAsText(inputRef5.current.files[0]);
        break;
      case 6:
        reader.readAsText(inputRef6.current.files[0]);
        break;
      case 7:
        reader.readAsText(inputRef7.current.files[0]);
        break;
      case 8:
        reader.readAsText(inputRef8.current.files[0]);
        break;
      default:
        reader.readAsText(inputRef0.current.files[0]);
        break;
    }
  };
  const handleShowMailTemplate = (inx) => {
    setShowTemplate(inx);
    setModalShow(true);
  };
  return (
    <>
      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        body={modalBody(showTemplate)}
      />
      <Row>
        <Col md={12} xxl={10} className="set-mail-template" id="no-responsive">
          <Col sm={2} className="label-set" style={{ padding: '0px' }}>
            <Col></Col>
            <Col>{string.DEVICE_WBGT}</Col>
          </Col>
          <Col xs={4} className="value-set">
            <Col>{`設定値`}</Col>
          </Col>
          <Col xs={4} className="enable-check">
            <div>{`設定値を上回った時`}</div>
            <div>{`設定値を下回った時`}</div>
          </Col>
        </Col>
      </Row>
      {['0', '1', '2', '3'].map((index) => (
        <React.Fragment key={index + 10}>
          <Row className="border-responsive">
            <Col md={12} xxl={10} className="set-mail-template">
              <Col sm={2} className="label-set">
                <Col>{labelText[index]}</Col>
                {index === '0' && <Arrow />}
              </Col>
              <Col
                xs={4}
                className="value-set"
                style={{
                  paddingTop: '5px',
                }}
              >
                <Form.Label
                  size="sm"
                  style={{
                    border: '1px solid #999',
                    padding: '0px 20px',
                    borderRadius: '3px',
                  }}
                >{`${thresholdValue[index]}℃`}</Form.Label>
              </Col>
              <Col xs={4} className="enable-check" key={2 * index}>
                <div className="button-up-download">
                  <div className="sm">
                    <input
                      ref={
                        index === '3'
                          ? inputRef6
                          : index === '2'
                          ? inputRef4
                          : index === '1'
                          ? inputRef2
                          : inputRef0
                      }
                      onChange={() => handleDisplayFileDetails(2 * index)}
                      className="d-none"
                      type="file"
                    />
                  </div>
                  <Button
                    size="xs"
                    onClick={() => handleUpload(2 * index)}
                    //className={`btn btn-primary`}
                  >
                    <FaFileUpload />
                  </Button>
                  <Button size="xs" onClick={() => downloadTxtFile(2 * index)}>
                    <FaFileDownload />
                  </Button>
                  <Button
                    size="xs"
                    onClick={() => handleShowMailTemplate(2 * index)}
                  >
                    <FaAlignJustify />
                  </Button>
                </div>
                <div className="button-up-download" key={2 * index + 1}>
                  <div className="sm">
                    <input
                      ref={
                        index === '3'
                          ? inputRef7
                          : index === '2'
                          ? inputRef5
                          : index === '1'
                          ? inputRef3
                          : inputRef1
                      }
                      onChange={() => handleDisplayFileDetails(2 * index + 1)}
                      className="d-none"
                      type="file"
                    />
                  </div>
                  <Button
                    size="xs"
                      onClick={() => handleUpload(2 * index + 1)}
                      //className={`btn btn-primary`}
                  >
                    <FaFileUpload />
                  </Button>
                  <Button
                    size="xs"
                    onClick={() => downloadTxtFile(2 * index + 1)}
                  >
                    <FaFileDownload />
                  </Button>
                  <Button
                    size="xs"
                    onClick={() => handleShowMailTemplate(2 * index + 1)}
                  >
                    <FaAlignJustify />
                  </Button>
                </div>
              </Col>
            </Col>
          </Row>
        </React.Fragment>
      ))}
      <Row></Row>
      <Row className="border-responsive">
        <Col md={12} xxl={10} className="set-mail-template">
          <Col sm={2}>{`定期通知`}</Col>
          <Col xs={4} className="title-label">
            {`暑さ指数定期測定の結果のメール`}
          </Col>
          <Col xs={4} className="enable-check">
            <div>
              <div className="button-up-download">
                <div className="sm">
                  <input
                    ref={inputRef8}
                    onChange={() => handleDisplayFileDetails(8)}
                    className="d-none"
                    type="file"
                  />
                </div>
                <Button
                  style={{marginLeft:'-10px'}}
                  size="xs"
                  onClick={() => handleUpload(8)}
                  className={`btn btn-primary`}
                >
                  <FaFileUpload />
                </Button>
                <Button size="xs" onClick={() => downloadTxtFile(8)}>
                  <FaFileDownload />
                </Button>
                <Button size="xs" onClick={() => handleShowMailTemplate(8)}>
                  <FaAlignJustify />
                </Button>
              </div>
            </div>
            <div className="visible">
              <div className="button-up-download">
                <div className="sm">
                  <input className="d-none" type="file" />
                  <button className={`btn btn-primary`}>
                    <FaFileUpload />
                  </button>
                </div>
                <Button size="sm">
                  <FaFileDownload />
                </Button>
                <Button size="sm">
                  <FaAlignJustify />
                </Button>
              </div>
            </div>
          </Col>
        </Col>
      </Row>
    </>
  );
};

export default MailTemplate;
