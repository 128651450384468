import React from "react";
import string from "util/string";
// import { nNU } from "util/utils";

const DkSf07TiltState = [
	string.DKSF07_TILT_OK,
	string.DKSF07_TILT_NG,
];

const GetDkSF07State = ({tilt=0}) => {
	return (
		<div
			className={'dksf07state'}
		>
			<div>
				{DkSf07TiltState[tilt]}
			</div>
		</div>
	)
};

export default GetDkSF07State;