import _ from 'lodash';
import moment from 'moment';
import string from 'util/string';
import { CSVDownload } from 'react-csv';

export const cloneDeep = _.cloneDeep;

export const formatDayOfTheWeekJa = (string) => {
  //2022年05月16日 (Mon) 13:08 => Mon => /2022年05月16日 (月) 13:08
  if (!nNU(string)) return string;
  let ymd = string.substring(0, 13);
  let day = string.substring(13, 16);
  let hm = string.substring(16, string.length);
  let dayJp = '';
  if (day === 'Sun') dayJp = '日';
  else if (day === 'Mon') dayJp = '月';
  else if (day === 'Tue') dayJp = '火';
  else if (day === 'Wed') dayJp = '水';
  else if (day === 'Thu') dayJp = '木';
  else if (day === 'Fri') dayJp = '金';
  else if (day === 'Sat') dayJp = '土';
  return `${ymd}${dayJp}${hm}`;
};

export const formatDate = (inputDate, formatString = 'DD/MM/YYYY') => {
  return inputDate && moment.utc(inputDate).local().format(formatString);
};
// export const durationTime = (fromDate, endDate) => {
//   return fromDate && endDate && moment.duration(endDate, fromDate);
// };
export const durationFromNow = (endDate) => {
	let curDate = new Date();
	// let enDate = new Date(endDate);
	return endDate && moment(curDate).diff(endDate,'days');
};

export const getLocalTime = () =>{
	let date = new Date();
	let exportTime = `${date.getFullYear()}`;
	if(date.getMonth() < 9){
		exportTime = `${exportTime}0${date.getMonth()+1}`;
	}
	else{
		exportTime = `${exportTime}${date.getMonth()+1}`;
	}
	exportTime = `${exportTime}${date.getDate()}`;
	exportTime = `${exportTime}_${date.getHours()}`;
	exportTime = `${exportTime}${date.getMinutes()}`;
	exportTime = `${exportTime}${date.getSeconds()}`;

	return exportTime;
};

export const adjustDate = (adjDate = new Date(), number = 0) => {
  const isDate = adjDate instanceof Date;
  const adjustedDate = isDate ? new Date(adjDate) : new Date();
  adjustedDate.setDate(adjustedDate.getDate() + (isDate ? number : adjDate));
  return adjustedDate;
};

export const hexToDec = (hexString) => {
  return parseInt(hexString, 16);
};

export const calcDurationMinutes = (start, end) => {
  let timeStart = new Date();
  let timeEnd = new Date();
  timeStart.setHours(start.substring(0, 2), start.substring(3, 5));
  timeEnd.setHours(end.substring(0, 2), end.substring(3, 5));
  if (timeStart > timeEnd) {
    timeEnd.setDate(timeEnd.getDate() + 1);
  }
  return (timeEnd - timeStart) / 1000 / 60; // miliSecond: 1min = 60s; 1s = 1000ms
};

export const formatTime = (time) => {
  return `${time < 10 ? '0' : ''}${time}`;
};

export const setHours = (
  date = new Date(),
  hour = 0,
  minute = 0,
  second = 0,
  miliSecond = 0
) => {
  try {
    if (typeof date === 'string') {
      date = new Date(date);
    }
    date.setHours(hour, minute, second, miliSecond);
    return date;
  } catch (e) {}
  return null;
};

export const truncTime = (date = new Date()) => {
  return setHours(date);
};

export const camelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => camelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [_.camelCase(key)]: camelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

export const camelize = _.camelCase;

export const nNU = (e) => e !== null && e !== undefined;

export const decamelizeKeys = (obj) => {
  if (Array.isArray(obj)) {
    return obj.map((v) => decamelizeKeys(v));
  } else if (obj != null && obj.constructor === Object) {
    return Object.keys(obj).reduce(
      (result, key) => ({
        ...result,
        [decamelize(key)]: decamelizeKeys(obj[key]),
      }),
      {}
    );
  }
  return obj;
};

const decamelize = (str, separator = '_') => {
  return str
    .replace(/([a-z\d])([A-Z])/g, '$1' + separator + '$2')
    .replace(/([A-Z]+)([A-Z][a-z\d]+)/g, '$1' + separator + '$2')
    .toLowerCase();
};

export const averageArray = (arr) =>
  arr.reduce((a, b) => a + b, 0) / arr.length;
export const subArray = (arr, aver) =>
  arr.map((item) => {
    return Math.round((item - aver) * 100) / 100;
  });

export const arrayMin = (arr) => {
  var len = arr.length,
    min = Infinity;
  while (len--) {
    if (Number(arr[len]) < min) {
      min = Number(arr[len]);
    }
  }
  return min;
};

export const arrayMax = (arr) => {
  var len = arr.length,
    max = -Infinity;
  while (len--) {
    if (Number(arr[len]) > max) {
      max = Number(arr[len]);
    }
  }
  return max;
};

export const getFormData = (formObj, options = { rawCol: {} }) =>
  // Object.fromEntries(new FormData(formObj).entries());
  {
    options.rawCol = options.rawCol || {};
    if (!formObj?.elements?.length) {
      return null;
    }
    const processBoolValueMap = {
      true: true,
      false: false,
    };
    const processValue = (val, name) => {
      // console.log(typeof processBoolValueMap[val] == 'boolean');
      if (typeof processBoolValueMap[val] == 'boolean') {
        return processBoolValueMap[val];
      }
      if (options.rawCol[name]) {
        return val;
      }

      if (!isNaN(val)) {
        return parseFloat(val);
      }
      return val;
    };
    let result = {};
    let element = null;
    let elementName = null;
    for (let i = 0; i < formObj.elements.length; i++) {
      element = formObj.elements[i];
      elementName = element.id || element.name;
      if (['button', 'submit'].indexOf(element.type) > -1) {
        continue;
      } else if (element.type === 'radio') {
        if (!!element?.checked) {
          result[element.name] = processValue(element.value, elementName);
        }
        continue;
      } else if (element.type === 'checkbox') {
        if (!result[element.name]?.length) {
          if (formObj.elements[element.name]?.length) {
            result[element.name] = [];
            for (let j = 0; j < formObj.elements[element.name].length; j++) {
              let checkbox = formObj.elements[element.name][j];
              if (checkbox?.checked) {
                result[element.name].push(
                  processValue(checkbox.value, elementName)
                );
              }
            }
            result[element.name] = result[element.name].join(',');
          } else if (!!element?.checked) {
            result[element.name] = processValue(element.value, elementName);
          } else if (`${element?.value}` === 'true') {
            result[element.name] = !!element.checked;
          }
        }
        continue;
      } else if (element.type === 'select-one') {
        result[element.name] = processValue(element.value, elementName);
        continue;
      }
      result[element.name] = processValue(element.value, elementName);
    }

    return result;
  };

export const setMapValue = (map) =>
  Object.keys(map).forEach((key) => {
    map[key] = key;
  });

export const showLoader = (status = true) => {
  const loaderContainer = document.getElementsByClassName('_loading')[0];
  if (loaderContainer) {
    if (status) {
      loaderContainer.classList.add('show');
      document.body.classList.add('_noselect');
    } else {
      loaderContainer.classList.remove('show');
      document.body.classList.remove('_noselect');
    }
  }
};

const SPACE_EXPRESSION = /\s+/;
export const LATITUDE_EXPRESSION =
  /^(\d{1,2})°(?:\s*(\d{1,2})[′'])?\s*(N|S|N\/S)$/; // 0- 90° 0-59′ N/S
export const LONGITUDE_EXPRESSION =
  /^(\d{1,3})°(?:\s*(\d{1,2})[′'])?\s*(E|W|E\/W)$/; // 0-180° 0-59′ E/W

export const DD_LOCATION_FORMAT_REGEX =
  /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

const parseCordinate = (expression, limit, surfaces, text) => {
  expression.lastIndex = 0;
  const match = expression.exec(text);
  if (match) {
    const degrees = parseInt(match[1]); // 0-90° or 0-180°
    if (degrees > limit) {
      throw new Error(
        'Incorrect degrees value (should be in range from 0 to ' + limit + ').'
      );
    }
    const minutes = parseInt(match[2] || '0'); // 0-59′
    if (minutes > 59) {
      throw new Error(
        'Incorrect minutes value (should be in range from 0 to 59).'
      );
    }
    if (degrees === 0 && minutes === 0) {
      return 0;
    }
    const surface = match[3]; // N/S or E/W
    if (surface === surfaces[0]) {
      return +(degrees + minutes / 60);
    }
    if (surface === surfaces[1]) {
      return -(degrees + minutes / 60);
    }
    throw new Error(
      'Incorrect surface value (should be ' +
        surfaces[0] +
        ' or ' +
        surfaces[1] +
        ').'
    );
  }
  throw new Error('Incorrect cordinate format.');
};

const parseLatitude = (latitude) => {
  return parseCordinate(LATITUDE_EXPRESSION, 90, 'NS', latitude); // N/S
};

const parseLongitude = (longitude) => {
  return parseCordinate(LONGITUDE_EXPRESSION, 180, 'EW', longitude); // E/W
};

export const showToast = (content, type = 'error', delay = 6 * 1000) => {
  require('redux/store').store.dispatch(
    require('redux/app/app.action').appShowToast({ content, type, delay })
  );
};

export const hideToast = (props) => {
  require('redux/store').store.dispatch(
    require('redux/app/app.action').appHideToast(props)
  );
};

const DEVICE_DATA_BIDIR = 'DATA_BIDIR';
const DEVICE_DATA_UPLINK = 'DATA_UPLINK';
const DEVICE_DATA_ADVANCED = 'SERVICE_DATA_ADVANCED';

export const getDeviceLog = (log, model) => {
  let { deviceId, type: logType, dataLogTime } = log;
  let result = { deviceId, logTime: moment.utc(dataLogTime) };

  switch (logType) {
    case DEVICE_DATA_BIDIR:
      break;
    case DEVICE_DATA_UPLINK:
      let { uplinkData } = log;
      /* eslint no-unused-vars: 0 */
      switch (model) {
        case string.DEVICE_SFGPS:
          let [
            _gpsData,
            gpsNotifyMode,
            operatingType,
            latitudeData,
            longitudeData,
          ] = /([a-f0-9]{2})?([a-f0-9]{4})?([a-f0-9]{8})?([a-f0-9]{10})?/.exec(
            uplinkData.toLowerCase()
          );
          result['notifyMode'] = gpsNotifyMode;
          if (gpsNotifyMode === '10') {
            result['battery'] = parseInt(operatingType, 16) / 100;
          }
          result['operatingType'] = operatingType;
          result['latitudeData'] = latitudeData;
          result['longitudeData'] = longitudeData;
          break;
        case string.DEVICE_WBGT:
          let [
            _wbgtData,
            humidityData,
            temperatureData,
            dTemperatureData,
            WBGTData,
            batteryData,
            reservedData,
          ] = /([a-f0-9]{4})?([a-f0-9]{4})?([a-f0-9]{4})?([a-f0-9]{4})?([a-f0-9]{2})?([a-f0-9]{6})?/.exec(
            uplinkData.toLowerCase()
          );
          result['humidityData'] =
            Math.round(((parseInt(humidityData, 16) * 100) / 65535) * 100) /
            100;
          result['temperatureData'] =
            Math.round(
              ((parseInt(temperatureData, 16) * 175) / 65535 - 45) * 100
            ) / 100;
          result['dTemperatureData'] =
            Math.round(
              ((parseInt(dTemperatureData, 16) * 175) / 65535 - 45) * 100
            ) / 100;
          result['WBGTData'] =
            Math.round(((parseInt(WBGTData, 16) * 175) / 65535 - 45) * 100) /
            100;
          result['battery'] =
            Math.round((parseInt(batteryData, 16) / 255) * 3.6 * 100) / 100;
          result['reservedData'] = reservedData;
          break;

        default:
          let [
            _sf04Data,
            notifyMode,
            notifyType,
            notifyData,
            dipSwitch,
            temperature,
            battery,
          ] = /([a-f0-9]{2})?([a-f0-9]{2})?([a-f0-9]{8})?([a-f0-9]{4})?([a-f0-9]{4})?([a-f0-9]{4})?/.exec(
            uplinkData.toLowerCase()
          );
          result['notifyMode'] =
            +notifyMode === 0
              ? 'Direct mode'
              : `Sub-${parseInt(notifyMode, 16)}`;
          result['notifyType'] = notifyType;
          result['notifyData'] = notifyData;
          result['dipSwitch'] = dipSwitch;
          if (model === string.DEVICE_SF04) {
            if (!!temperature) {
              result['temperature'] =
                parseInt('8000', 16) & parseInt(temperature, 16)
                  ? `-${round(
                      (parseInt(`10000`, 16) - parseInt(`${temperature}`, 16)) *
                        0.1,
                      1
                    )}`
                  : round(parseInt(temperature, 16) * 0.1, 1);
            }
          }

          if (!!battery) {
            result['battery'] = round(parseInt(battery, 16) * 0.01, 2);
          }
          break;
      }
      break;
    case DEVICE_DATA_ADVANCED:
      let { computedLocation, lqi } = log;
      result['lqi'] = lqi.toUpperCase();
      try {
        computedLocation = JSON.parse(computedLocation);
      } catch (e) {}

      if (nNU(computedLocation?.Lat) && nNU(computedLocation?.Lng)) {
        result['location'] = [computedLocation.Lat, computedLocation.Lng];
      }
      break;
    default:
  }
  return result;
};

export const showConfirm = (props) =>
  require('redux/store').store.dispatch(
    require('redux/app/app.action').appShowConfirm(props)
  );
export const hideConfirm = () =>
  require('redux/store').store.dispatch(
    require('redux/app/app.action').appHideConfirm()
  );

export const dataURIToBlob = (dataURI) => {
  const splitDataURI = dataURI.split(',');
  const byteString =
    splitDataURI[0].indexOf('base64') >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);
  return new Blob([ia], { type: mimeString });
};

export const convUTCStringFormat = (string) => {
  const utcStr = new Date().toUTCString();
  const strDay = utcStr.substring(0, 17);
  const strLast = utcStr.substring(22);
  return `${strDay}${string}${strLast}`;
};

export const calcNumberOfMinute = (string) => {
  let minutes;
  let dmy = string.split(':');
  minutes = parseInt(dmy[0], 10) * 60 + parseInt(dmy[1], 10);
  return minutes;
};

export const emailValidate = (email) => {
  return email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const round = _.round;

export const capitalize = (str, lower = false) =>
  (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) =>
    match.toUpperCase()
  );

export const getString = (str, params = []) => {
  return (nNU(params) && !Array.isArray(params) ? [params] : params).reduce(
    (pre, cur, idx) => {
      return pre.replace(new RegExp(`\\$${idx + 1}`, 'g'), cur);
    },
    str
  );
};

// params.reduce((pre, cur, idx) => pre.replace(`$${idx + 1}`, cur), str);

export const getParams = () =>
  document.location.search.split(/[?&]/g).reduce((prev, val) => {
    let [k, v] = val.split('=');
    if (!k) {
      return prev;
    }
    prev[k] = v || '';
    return prev;
  }, {});

export const downloadFile = ({
  data,
  fileName,
  dataType = 'text/csv;charset=ISO-8859-1',
  fileExt = 'csv',
}) => {
  // console.log(data);
  if (!data || !fileName) {
    return;
  }
  let elm;
  if (!document.getElementById('file-download-link')) {
    elm = document.createElement('a');
    elm.id = 'file-download-link';
    document.body.appendChild(elm);
  } else {
    elm = document.getElementById('file-download-link');
  }
  var BOM = new Uint8Array([0xef, 0xbb, 0xbf]);
  const file = new Blob([BOM, data], {
    type: 'text/plain;charset=UTF-8',
    encoding: 'UTF-8',
  });
  elm.href = URL.createObjectURL(file);
  elm.download = `${fileName}_${formatDate(
    new Date(),
    'YYYYMMDD_HHmm'
  )}.${fileExt}`;
  elm.click();
};

export const GUIDChecker = (string) => {
  const regexExp =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
  return regexExp.test(string);
};

export const focusElement = (selector, isSelect = false) => {
  let elm = document.querySelector(selector);
  elm.focus();
  if (isSelect) {
    elm.select();
  }
};

export const setSearchRangeDate = (rangeDate = []) => {
  require('redux/store').store.dispatch(
    require('redux/app/app.action').setSearchRangeDate(rangeDate)
  );
};

export const signOut = () => {
  setSearchRangeDate();
  require('redux/store').store.dispatch(
    require('redux/user/user.action').signOut()
  );
};

export const reSignIn = (payload) => {
  setSearchRangeDate();
  require('redux/store').store.dispatch(
    require('redux/user/user.action').signInSuccess(payload)
  );
};

export const wait = (interval) => {
	if(interval < 1){
		return;
	}
	let start = new Date().getTime();
	let end = start;
	while(end < start + interval){
		end = new Date().getTime();
	}
};

export const getLat = (latLong) => {
	if(latLong.length > 0 && latLong.includes("-") === true){
		const latLongArray = latLong.split("-");
		if (latLongArray.length > 1){
			return latLongArray[0].substring(0,9);
		}
		else{
			return null;
		}
	}
	else{
		return null;
	}
};

export const getLong = (latLong) => {
	if(latLong.length > 0 && latLong.includes("-") === true){
		const latLongArray = latLong.split("-");
		if (latLongArray.length > 1){
			return latLongArray[1].substring(0, 10);
		}
		else{
			return null;
		}
	}
	else{
		return null;
	}
};
