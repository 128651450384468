import React from "react";
import { useState, useEffect } from "react";
import DkSf03wt2DataTimeout from "dksf03wt2icon/DkSf03wt2DataTimeout";
import DkSf03wt2FloodFull from "dksf03wt2icon/DkSf03wt2FloodFull";
import DkSf03wt2FloodHalf from "dksf03wt2icon/DkSf03wt2FloodHalf";
import DkSf03wt2LowBattery from "dksf03wt2icon/DkSf03wt2LowBattery";
import DkSf03wt2Normal from "dksf03wt2icon/DkSf03wt2Normal";
import DkSf03wt2TiltFloodFull from "dksf03wt2icon/DkSf03wt2TiltFloodFull";
import DkSf03wt2TiltFloodFullLowBattery from "dksf03wt2icon/DkSf03wt2TiltFloodFullLowBattery";
import DkSf03wt2TiltFloodHalf from "dksf03wt2icon/DkSf03wt2TiltFloodHalf";
import DkSf03wt2TiltFloodHalfLowBattery from "dksf03wt2icon/DkSf03wt2TiltFloodHalfLowBattery";
import DkSf03wt2TiltNormal from "dksf03wt2icon/DkSf03wt2TiltNormal";
import DkSf03wt2TiltNormalLowBattery from "dksf03wt2icon/DkSf03wt2TiltNormalLowBattery";
import DkSf03wt2FloodHalfLowBattery from "dksf03wt2icon/DkSf03wt2FloodHalfLowBattery";
import DkSf03wt2FloodFullLowBattery from "dksf03wt2icon/DkSf03wt2FloodFullLowBattery";

const IconList = [
    <DkSf03wt2Normal/>,                 // 0
    //<DkSf03wt2FloodHalf/>,              // 1
    //<DkSf03wt2FloodFull/>,              // 2
    //<DkSf03wt2TiltNormal/>,             // 3
    //<DkSf03wt2TiltFloodHalf/>,          // 4
    //<DkSf03wt2TiltFloodFull/>,          // 5
    //<DkSf03wt2LowBattery/>,             // 6
    //<DkSf03wt2FloodHalfLowBattery/>,    // 7
    //<DkSf03wt2FloodFullLowBattery/>,    // 8
    //<DkSf03wt2TiltNormalLowBattery/>,   // 9
    //<DkSf03wt2TiltFloodHalfLowBattery/>,// 10
    //<DkSf03wt2TiltFloodFullLowBattery/>,// 11
    //<DkSf03wt2DataTimeout/>,            // 12

    <DkSf03wt2FloodHalf/>,              // 1
    <DkSf03wt2FloodFull/>,              // 2
    <DkSf03wt2TiltNormal/>,             // 3
    <DkSf03wt2TiltFloodHalf/>,          // 4
    <DkSf03wt2TiltFloodFull/>,          // 5
    <DkSf03wt2LowBattery/>,             // 6
    <DkSf03wt2FloodHalfLowBattery/>,    // 7
    <DkSf03wt2FloodFullLowBattery/>,    // 8
    <DkSf03wt2TiltNormalLowBattery/>,   // 9
    <DkSf03wt2TiltFloodHalfLowBattery/>,// 10
    <DkSf03wt2TiltFloodFullLowBattery/>,// 11
    <DkSf03wt2DataTimeout/>,            // 12
];

const DkSf03wt2Icon = ({
	timeout=0,
	flood=0,
	tilt=0,
	batteryLow=0,
	blinkInterval=0,
	blinkOff=false,
}) => {
		const [countdown, setCountdown] = useState(0);

		useEffect(() => {
			if(blinkInterval > 0){
				setCountdown(blinkInterval);
			}
		},[blinkInterval]);

		useEffect(() => {
			if(countdown > 0){
				const timerId = setTimeout(() => {
					setCountdown(countdown - 1);
				},500);
				// console.log('Set countdown');
				// console.log('countdown: ', countdown);
				return () => clearTimeout(timerId);
			}
		},[countdown]);

    let iconNo = (timeout === 1) ? 12:0;
    if(timeout === 0){
        if(batteryLow === 1){
            iconNo = iconNo + 6
        }
        if(tilt === 1){
            iconNo = iconNo + 3
        }
        if(flood > 2){
            iconNo = iconNo + 2
        }
        else if(flood > 0){
            iconNo = iconNo + 1
        }
    }
    return (
			((blinkOff === false) && (countdown % 2) === 0) ?
				(<React.Fragment>
					{IconList[iconNo]}
				</React.Fragment>)
				:(<></>)
    )
}

export default DkSf03wt2Icon;