import { Nav } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { selectCurrentUser } from 'redux/user/user.selector';
import Paths from 'util/paths';
import {
  ACCOUNT_ROLE_ADMIN,
  ACCOUNT_ROLE_CHILD,
  ACCOUNT_ROLE_PARENT,
} from 'util/permission';
import { RouteMap } from 'util/routes';
import string from 'util/string';
import { BsChevronRight, BsChevronDown } from 'react-icons/bs';
import './Menu.scss';
import { useState } from 'react';

const Menu = () => {
  const currentUser = useSelector(selectCurrentUser);
  const location = useLocation();
  const [subMenu, setSubMenu] = useState(true);
  const menu = [
    // { path: Paths.device.status, name: string.DEVICE_INFO },
    // { path: Paths.device.status2, name: string.DEVICE_INFO },
    { path: Paths.device.list, name: string.DEVICE_MANAGEMENT },
    { path: Paths.contract.list, name: string.CONTRACT_MANAGEMENT },
    { path: Paths.user.list, name: string.ACCOUNT_MANAGEMENT },
    { path: Paths.device.activity, name: string.DEVICE_ACTIVITY },
    // { path: Paths.device.activeAlert, name: string.DEVICE_ACTIVE_ALERT },
    { path: Paths.device.alertHistory, name: string.DEVICE_ALERT_HISTORY },
    { path: Paths.user.log, name: string.AUTHENTICATION_LOG },
  ];
  //console.log(location);
  return (
    <>
      <Nav className="me-auto">
        {currentUser.accountRole === ACCOUNT_ROLE_ADMIN ? (
          <>
            <div
              className="device-group nav-link"
              onClick={() => {
                setSubMenu(!subMenu);
              }}
            >
              <span>{string.DEVICE_INFO}</span>
              {subMenu ? (
                <>
                  <span className="icons">
                    <BsChevronDown />
                  </span>
                </>
              ) : (
                <>
                  <span className="icons">
                    <BsChevronRight />
                  </span>
                </>
              )}
            </div>
            {subMenu && (
              <div className="sub-menu">
                <Link
                  className={`sub-nav-link ${
                    location.pathname === Paths.device.statusWBGT
                      ? '_current'
                      : ''
                  }`}
                  to={Paths.device.statusWBGT}
                >
                  {string.DEVICE_WBGT}
                </Link>
                {/*<Link
                  className={`sub-nav-link ${
                    location.pathname === Paths.device.statusSF03WT
                      ? '_current'
                      : ''
                  }`}
                  to={Paths.device.statusSF03WT}
                >
                  {string.DEVICE_SF03WT}
                </Link>*/}
                <Link
                  className={`sub-nav-link ${
                    location.pathname === Paths.device.statusSF03WT2
                      ? '_current'
                      : ''
                  }`}
                  to={Paths.device.statusSF03WT2}
                >
                  {string.DEVICE_SF03WT2}
                </Link>
                <Link
                  className={`sub-nav-link ${
                    location.pathname === Paths.device.statusXE1151
                      ? '_current'
                      : ''
                  }`}
                  to={Paths.device.statusXE1151}
                >
                  {string.DEVICE_XE1151}
                </Link>
                <Link
                  className={`sub-nav-link ${
                    location.pathname === Paths.device.statusGPS
                      ? '_current'
                      : ''
                  }`}
                  to={Paths.device.statusGPS}
                >
                  {string.DEVICE_SFGPS}
                </Link>
                <Link
                  className={`sub-nav-link ${
                    location.pathname === Paths.device.statusSF07
                      ? '_current'
                      : ''
                  }`}
                  to={Paths.device.statusSF07}
                >
                  {string.DEVICE_SF07NAME}
                </Link>
              </div>
            )}
          </>
        ) : (
          <>
            <Link
              className={`nav-link ${
                location.pathname === Paths.device.status ? '_current' : ''
              }`}
              to={Paths.device.status}
            >
              {string.DEVICE_INFO}
            </Link>
          </>
        )}
        {menu
          .filter((e) => {
            let permissions = RouteMap[e.path]?.permissions || [
              ACCOUNT_ROLE_ADMIN,
              ACCOUNT_ROLE_PARENT,
              ACCOUNT_ROLE_CHILD,
            ];
            return permissions.indexOf(currentUser.accountRole) !== -1;
          })
          .map((item, index) => (
            <>
              <Link
                className={`nav-link ${
                  location.pathname === item.path ? '_current' : ''
                }`}
                key={index}
                to={item.path}
              >
                {item.name}
              </Link>
            </>
          ))}
      </Nav>
    </>
  );
};

export default Menu;
