import FormControls from 'component/FormControls';
import Page from 'component/Page';
import { Button, Form, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/user/user.selector';
import sendRequest from 'util/request';
import string from 'util/string';
import Urls from 'util/urls';
import { getFormData, showConfirm, showToast } from 'util/utils';

const deviceNamesMap = [
  //string.DEVICE_SF03,
  //string.DEVICE_SF03WT,
  string.DEVICE_WBGT,
  string.DEVICE_SF03WT2,
  string.DEVICE_XE1151,
  string.DEVICE_SFGPS,
  string.DEVICE_SF07,
];

const DeviceMaintenance = ({ data, onUpdated }) => {
  const currentUser = useSelector(selectCurrentUser);
  const title = string.NEW_DEVICE;
  const editMode = !!data?.deviceId;

  const controls = [
    {
      id: 'assetName',
      title: string.DEVICE_NAME,
      md: 6,
    },
    {
      id: 'model',
      title: string.DEVICE_MODEL,
      md: 6,
      type: 'selection',
      options: deviceNamesMap.map((value) => ({ select: value })),
    },
    {
      id: 'serialNumber',
      title: string.DEVICE_SERIALNO,
      md: 6,
    },
    {
      id: 'sigfoxId',
      title: string.DEVICE_SIGFOX_ID,
      md: 6,
    },
  ];

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    showConfirm({
      body: editMode ? string.DEVICE_UPDATE_CONFIRM : string.DEVICE_NEW_CONFIRM,
      defaultAction: () => {
        let formData = getFormData(e.target, {
          rawCol: {
            assetName: true,
            model: true,
            serialNumber: true,
            sigfoxId: true,
          },
        });
        sendRequest({
          url: `${Urls.device.update}${editMode ? `/${data.deviceId}` : ''}`,
          method: editMode ? 'put' : 'post',
          body: {
            ...formData,
            serialNumbber: formData.serialNumbber || formData.serialNumber,
            deviceLocation: data.deviceLocation,
            ...(!editMode ? { foreignAccountId: currentUser.accountId } : {}),
          },
        }).then((res) => {
          // console.log(res);
          showToast(string.DEVICE_UPDATE_SUCCESS, 'success');
          onUpdated(res);
        });
      },
    });
  };

  return (
    <>
      <Page
        title={title}
        body={
          <>
            <Form onSubmit={onSubmit}>
              <FormControls data={data} controls={controls} />
              <Row className="form-button-row">
                <Button type="submit">{string.SAVE}</Button>
              </Row>
            </Form>
          </>
        }
      />
    </>
  );
};
export default DeviceMaintenance;
