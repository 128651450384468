import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import {
  Button,
  Form,
  OverlayTrigger,
  Pagination,
  Table as BSTable,
  Tooltip,
} from 'react-bootstrap';
import {
  FaCheckCircle,
  FaEdit,
  FaFileDownload,
  FaHandPointUp,
  FaInfoCircle,
  FaMapMarkerAlt,
  FaUserMinus,
  FaUserPlus,
} from 'react-icons/fa';
import { GoMail, GoHistory } from 'react-icons/go';
import {
  RiFileAddLine,
  RiFileReduceLine,
  RiAddCircleFill,
} from 'react-icons/ri';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import string from 'util/string';
import { nNU } from 'util/utils';
import { camelize, downloadFile, getString, setMapValue } from 'util/utils';
import Search from './Search';
import './Table.scss';
import WBGTThreshold from './WBGTThreshold';
import PopOver from './PopOver';
import { popoverContent } from 'util/popupContent';
import { GiSmartphone } from 'react-icons/gi';
// import DkSf03wt2Icon from './DkSf03wt2Icon';
// import DkSf03wtIcon from './DkSf03wtIcon';

const Pagging = ({
  page = 1,
  total = 0,
  count = 0,
  pageSize = 10,
  pageSizes = [10, 50, 100],
  onPageSizeChanged = (pageSize) => {
    let a = pageSize;
    pageSize = a;
  },
  onPageChanged = (page) => {
    let a = page;
    page = a;
  },
  search,
  onSearch = (value) => {
    let a = value;
    value = a;
  },
  placeHolder,
}) => {
  const [paggingItems, setPaggingItems] = useState([]);
  const lastPage = Math.ceil(total / pageSize);

  useEffect(() => {
    let arr = [];
    let firstItem = Math.max(page - 2, 1);
    let lastItem = Math.min(firstItem + 4, Math.max(lastPage, 1));
    for (let i = firstItem; i < lastItem + 1; i++) {
      arr.push(
        <Pagination.Item
          key={i}
          onClick={() => onPageChanged(i)}
          active={page === i}
        >
          {i}
        </Pagination.Item>
      );
    }
    setPaggingItems(arr);
  }, [page, total, pageSize, lastPage, onPageChanged]);
  const handleSearch = (value) => {
    onSearch(value);
  };
  return (
    <div className="_pagging">
      <div className="_text">
        <span>
          {getString(string.PAGINATION_TEXT, [
            total > 0 ? (page - 1) * pageSize + 1 : 0,
            Math.min((page - 1) * pageSize + count, total),
            total,
          ])}
        </span>
        {search && (
          <Search handleSearch={handleSearch} placeholder={placeHolder} />
        )}
      </div>
      <div>
        <span className="_text">{string.PAGINATION_PERPAGE_1}</span>
        <Form.Select
          onChange={(e) => {
            // console.log(+e.target.value);
            onPageSizeChanged(+e.target.value);
            onPageChanged(1);
          }}
          value={pageSize}
          size="sm"
        >
          {pageSizes.map((size, index) => (
            <option key={index} value={size}>
              {size}
            </option>
          ))}
        </Form.Select>
        <span className="_text">{string.PAGINATION_PERPAGE_2}</span>
        <Pagination>
          <Pagination.Item onClick={() => onPageChanged(1)} disabled={page < 2}>
            &lt;&lt;
          </Pagination.Item>
          <Pagination.Item
            onClick={() => onPageChanged(page - 1)}
            disabled={page < 2}
          >
            &lt;
          </Pagination.Item>
          {paggingItems}
          <Pagination.Item
            onClick={() => onPageChanged(page + 1)}
            disabled={page > lastPage - 1}
          >
            &gt;
          </Pagination.Item>
          <Pagination.Item
            onClick={() => onPageChanged(lastPage)}
            disabled={page > lastPage - 1}
          >
            &gt;&gt;
          </Pagination.Item>
        </Pagination>
      </div>
    </div>
  );
};

const TablePaggingOption = {
  TOP: '',
  BOTTOM: '',
  ALL: '',
  NONE: '',
};

setMapValue(TablePaggingOption);
export { TablePaggingOption };

const defaultActionIcon = {
  add: RiFileAddLine,
  edit: FaEdit,
  map: FaMapMarkerAlt,
  minus: RiFileReduceLine,
  assignminus: RiFileReduceLine,
  select: GrCheckbox,
  selected: GrCheckboxSelected,
  info: FaInfoCircle,
  assign: FaUserPlus,
  unassign: FaUserMinus,
  download: FaFileDownload,
  userSelect: RiAddCircleFill,
  mail: GoMail,
  phone: GiSmartphone,
  history: GoHistory,
};

const actionText = (text) => {
  if (text === 'Edit') return '編集';
  else if (text === 'Add') return '追加';
  else if (text === 'Map') return 'マップ';
  else if (text === 'Minus') return '消去';
  else if (text === 'Assignminus') return '割当解除';
  else if (text === 'Select') return '加入';
  else if (text === 'Selected') return '加入済';
  else if (text === 'Info') return '詳細';
  else if (text === 'Assign') return '割当';
  else if (text === 'Unassign') return '割当解除';
  else if (text === 'Download') return 'ダウンロード';
  else if (text === 'Userselect') return '加入';
  else if (text === 'Mail') return '詳細';
  else if (text === 'Phone') return '詳細';
  else if (text === 'History') return '移動履歴';
  else return text;
};

// const actionText = (text) => {
//   if (text === 'Edit') return '編集';
//   else if (text === 'Add') return '追加';
//   else if (text === 'Map') return 'マップ';
//   else if (text === 'Minus') return '消去';
//   else if (text === 'Select') return '加入';
//   else if (text === 'Selected') return '加入済';
//   else if (text === 'Info') return '詳細';
//   else if (text === 'Assign') return 'Assign';
//   else if (text === 'Unassign') return 'Unassign';
//   else if (text === 'Download') return 'ダウンロード';
//   else return text;
// };

const Table = ({
  className,
  mainActions = [],
  getCSVData,
  csvFileName,
  headers,
  csvHeaders,
  data = [],
  page,
  total,
  pageSize,
  pageSizes,
  onPageSizeChanged,
  onPageChanged,
  actions = [],
  onRowClick,
  defaultAction,
  pagging = TablePaggingOption.ALL,
  search = false,
  onSearch,
  placeHolder = '装置名称、Sigfox ID',
  isPagging = true,
}) => {
  // console.log('Re-render');

  if (!defaultAction && !!actions?.length && !!actions?.[0]?.onClick) {
    defaultAction = actions[0].onClick;
  }

  if (
    !!getCSVData &&
    !mainActions.find((e) => e.title === string.CSV_DOWNLOAD)
  ) {
    mainActions = [
      {
        icon: 'download',
        title: string.CSV_DOWNLOAD,
        onClick: async () => {
          let csvData = await getCSVData();
          //console.log(csvData);
          csvData = [
            (csvHeaders || headers).join(','),
            ...csvData.map((e) => {
              let newData = [];
              e.data.forEach((item) => {
                let newItem = !!nNU(item) ? item.replace('\n', '').replace(',', ' ') : '';
                newData.push(newItem);
              });
              return newData.join(',');
            }),
          ].join('\n');
          // console.log(csvData);
          downloadFile({
            data: csvData,
            fileName: csvFileName || 'CSVDownload',
            fileType: 'csv',
          });
        },
      },
      ...mainActions,
    ];
  }

  return (
    <div className={`_table ${className || ''}`}>
      {!!mainActions?.length && (
        <div className="function-button-area">
          {mainActions.map((action, actionIndex) => {
            if (typeof action === 'function') {
              const ActionElm = action;
              return <ActionElm />;
            }
            const ActionIcon = defaultActionIcon[action?.icon] || action?.icon;
            return (
              <Button
                key={actionIndex}
                size="sm"
                onClick={action.onClick}
                className={action?.className || ''}
              >
                {!!action?.icon && <ActionIcon />}
                {/*!!action?.icon && <DkSf03wt2Icon index={0}/>*/}
                {/*<DkSf03wt2Icon index={0}/>*/}
                {action.title}
              </Button>
            );
          })}
        </div>
      )}
      {[TablePaggingOption.TOP, TablePaggingOption.ALL].indexOf(pagging) > -1 &&
        isPagging === true && (
          <Pagging
            {...{
              page,
              count: data.length,
              total,
              pageSize,
              pageSizes,
              onPageSizeChanged,
              onPageChanged,
              search,
              onSearch,
              placeHolder,
            }}
          />
        )}
      <div className="_table-container">
        <BSTable striped bordered hover>
          {!!headers && !!headers?.length && (
            <thead>
              <tr>
                {headers.map((header, index) => {
                  return (
                    <th key={index} className={`data-${camelize(header)}`}>
                      {header === string.DEVICE_STATE ? (
                        <WBGTThreshold />
                      ) : header === string.DEVICE_WBGT_DATA_TITLE ? (
                        <PopOver
                          title={header}
                          content={popoverContent.wbgtData}
                          className="redPink"
                        />
                      ) : (
                        header
                      )}
                    </th>
                  );
                })}
                {!!actions?.length && <th className="mapMarker"></th>}
              </tr>
            </thead>
          )}
          <tbody>
            {data.map((row, rowIndex) => (
              <tr
                rowno={rowIndex}
                key={row?.key || rowIndex}
                onClick={() => {
                  if (!!onRowClick) {
                    onRowClick({
                      rowIndex,
                      id: row.id || row.key,
                      data: row.data,
                      raw: row.raw,
                    });
                  }
                }}
                style={{ lineHeight: '30px' }}
                {...(!!actions?.length && !onRowClick && !!defaultAction
                  ? {
                      onDoubleClick: () =>
                        defaultAction({
                          rowIndex,
                          id: row.id || row.key,
                          data: row.data,
                          raw: row.raw,
                        }),
                    }
                  : {})}
              >
                {(row?.data || row).map((cell, cellIndex) => (
                  <td
                    key={cellIndex}
                    className={`data-${camelize(headers[cellIndex])}`}
                  >
                    {cell}
                  </td>
                ))}
                {!!actions?.length && (
                  <td className="data-actions">
                    {actions.map((action, actionIndex) => {
                      if (typeof action === 'function') {
                        action = action({
                          rowIndex,
                          id: row.id || row.key,
                          data: row.data,
                          raw: row.raw,
                        });
                      }
                      if (!action) {
                        return null;
                      }
                      const ActionIcon =
                        defaultActionIcon[action.render] || action.render;
                      let altText =
                        action?.alt || !!defaultActionIcon[action.render]
                          ? capitalize(action.render)
                          : null;

                      // console.log(altText);
                      altText = actionText(altText);
                      return (
                        <OverlayTrigger
                          key={actionIndex}
                          placement="top"
                          overlay={
                            altText && (
                              <Tooltip id={`tooltip-`}>{altText}</Tooltip>
                            )
                          }
                        >
                          <span>
                            <ActionIcon
                              className={action?.className || ''}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                action.onClick({
                                  id: row.id || row.key,
                                  data: row.data,
                                  raw: row.raw,
                                  rowIndex,
                                });
                              }}
                            />
                          </span>
                        </OverlayTrigger>
                      );
                    })}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </BSTable>
      </div>
      {/* {!!data.length &&
        [TablePaggingOption.BOTTOM, TablePaggingOption.ALL].indexOf(pagging) >
          -1 && (
          <Pagging
            {...{
              page,
              total,
              count: data.length,
              pageSize,
              pageSizes,
              onPageSizeChanged,
              onPageChanged,
            }}
          />
        )} */}
    </div>
  );
};

export default Table;
