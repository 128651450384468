import * as React from "react"

const DkSf03wt2TiltNormalLowBattery = (props) => {
  return (
    <svg
      id="\u30EC\u30A4\u30E4\u30FC_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 50 50"
      xmlSpace="preserve"
      {...props}
    >
      <style />
      <path
        fill="#fff"
        id="\u50BE\u659C\u767D\u80CC\u666F_2B"
        d="M32.36 4.16L38.47 7.34 39.54 7.9 40.68 7.47 43.78 6.31 42.62 9.41 42.19 10.54 42.75 11.62 45.94 17.72 42.75 23.82 42.1 25.08 42.75 26.34 45.95 32.46 42.75 38.73 42.2 39.81 42.63 40.94 43.75 43.85 40.69 42.7 39.55 42.27 38.47 42.83 32.37 46.02 26.27 42.83 25.01 42.18 23.75 42.83 17.65 46.02 11.55 42.83 10.47 42.27 9.33 42.7 6.23 43.87 7.39 40.77 7.82 39.63 7.26 38.55 4.08 32.45 7.26 26.35 7.92 25.09 7.26 23.83 4.08 17.73 7.26 11.63 7.82 10.55 7.39 9.41 6.23 6.31 9.33 7.47 10.46 7.9 11.54 7.34 17.64 4.16 23.74 7.34 25 8 26.26 7.34z"
      />
      <path
        d="M32.36 4.16l6.1 3.18 1.08.56 1.14-.43 3.1-1.17-1.16 3.1-.43 1.14.56 1.08 3.18 6.1-3.18 6.1-.66 1.26.66 1.26 3.19 6.11-3.2 6.27-.55 1.08.43 1.13 1.11 2.91-3.06-1.15-1.14-.43-1.08.56-6.1 3.18-6.1-3.18-1.26-.66-1.26.66-6.1 3.18-6.1-3.18-1.08-.56-1.12.44-3.1 1.17 1.16-3.1.43-1.14-.56-1.08-3.18-6.1 3.18-6.1.66-1.26-.66-1.26-3.18-6.1 3.18-6.1.56-1.08-.43-1.14-1.17-3.1 3.1 1.16 1.14.43 1.08-.56 6.1-3.18 6.1 3.18L25 8l1.26-.66 6.1-3.18zm0-3.07L25 4.93l-7.36-3.84-7.36 3.84-8.71-3.28 3.28 8.72-3.84 7.36 3.84 7.36-3.84 7.36 3.84 7.36-3.28 8.71 8.72-3.28 7.36 3.84 7.36-3.84 7.36 3.84 7.36-3.84 8.71 3.28-3.27-8.56L49 32.44l-3.84-7.36L49 17.72l-3.84-7.36 3.28-8.71-8.72 3.28-7.36-3.84h0z"
        fill="#f1000b"
        stroke="#000"
        strokeMiterlimit={10}
        id="\u50BE\u659C\u67A0_2B"
      />
      <g id="\u30EC\u30A4\u30E4\u30FC_2B_12">
        <path d="M36.11 35.17h2.69c.66 0 1.2-.54 1.2-1.2v-2.4c0-.66-.54-1.2-1.2-1.2h-2.69c-.66 0-1.2.54-1.2 1.2v2.4c0 .66.54 1.2 1.2 1.2z" />
        <path
          d="M34.68 39.64h-22.2c-.99 0-1.8-.81-1.8-1.8V27.69c0-.99.81-1.8 1.8-1.8h22.2c.99 0 1.8.81 1.8 1.8v10.14c0 1-.81 1.81-1.8 1.81z"
          fill="#fff"
          stroke="#000"
          strokeWidth={1.2756}
          strokeMiterlimit={10}
        />
        <defs>
          <path id="SVGID_2B_1_" d="M10 23.57H15.57V41.86H10z" />
        </defs>
        <clipPath id="SVGID_2B_00000180368654452137588090000002404371047642332562_">
          <use xlinkHref="#SVGID_2B_1_" overflow="visible" />
        </clipPath>
        <path
          d="M34.68 39.64h-22.2c-.99 0-1.8-.81-1.8-1.8V27.69c0-.99.81-1.8 1.8-1.8h22.2c.99 0 1.8.81 1.8 1.8v10.14c0 1-.81 1.81-1.8 1.81z"
          clipPath="url(#SVGID_2B_00000180368654452137588090000002404371047642332562_)"
          fill="#fd1a16"
          stroke="#000"
          strokeWidth={1.2756}
          strokeMiterlimit={10}
        />
        <path
          d="M25.13 19.82l-7.96 13.79c-.23.4.06.9.52.9h15.92c.46 0 .75-.5.52-.9l-7.96-13.79a.6.6 0 00-1.04 0z"
          fill="#ffff01"
          stroke="#000"
          strokeWidth={0.8534}
          strokeMiterlimit={10}
        />
        <g>
          <path d="M24.78 32.72c0-.55.37-.94.87-.94.52 0 .86.4.86.94 0 .52-.34.91-.87.91-.55.01-.86-.42-.86-.91zm1.34-2.39h-.97l-.21-8.1h1.39l-.21 8.1z" />
        </g>
      </g>
    </svg>
  )
}

export default DkSf03wt2TiltNormalLowBattery;
