import React, { useCallback, useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { popoverContent } from 'util/popupContent';
import string from 'util/string';
import './Chart.scss';
import MultiRangeSlider from './MultiRangeSlider';
import PopOver from './PopOver';

const DefaultRange = [
  [0, 50],
  [0, 100],
  [0, 80],
];

const Chart = ({
  title,
  color = '#000',
  data,
  dataCenter,
  xAxis,
  temp = true,
  min = -5,
  max = 5,
  tickAmount = 10,
  dateStartSearch = new Date(),
  showPopOver = false,
}) => {
  const [minimum, setMin] = useState();
  const [maximum, setMax] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [minVal, setMinVal] = useState(min);
  const [maxVal, setMaxVal] = useState(max);
  const [isGraphText, setGraphText] = useState(false);
  const [chartData, setChartData] = useState({
    series: [],
    title: {
      text: '',
      align: 'center',
      style: {
        fontSize: '20px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238',
      },
    },
    xaxis: {
      type: 'category',
      categories: xAxis,
      label: {
        formatter: (value) => {
          return temp ? value : value;
        },
      },

      labels: {
        show: false,
        rotate: 0,
      },
      axisBorder: {
        show: true,
        width: '200%',
      },
    },
    chart: {
      height: 500,
      type: 'area',
      toolbar: {
        tools: {
          download: true,
          selection: true,
          zoom: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          reset: false,
          customIcons: [],
        },
        export: {
          csv: {
            filename: `${title}_${xAxis[0]}_${xAxis[xAxis.length - 1]}`,
            columnDelimiter: ',',
            headerCategory: '時間',
            headerValue: '価値',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString();
            },
          },
          svg: {
            filename: `${title}_${xAxis[0]}_${xAxis[xAxis.length - 1]}`,
          },
          png: {
            filename: `${title}_${xAxis[0]}_${xAxis[xAxis.length - 1]}`,
          },
        },
      },
      autoSelected: 'zoom',
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    plotOptions: {
      area: {},
    },
    fill: {
      opacity: 0.9,
      type: 'gradient',
      fillTo: -20,
    },
    // redrawOnWindowResize: true,
    // redrawOnParentResize: true,
  });

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(title));
    setMin(data.min);
    setMax(data.max);
  }, [title]);

  useEffect(() => {
    let data = { min: minimum, max: maximum };
    localStorage.setItem(title, JSON.stringify(data));
  }, [minimum, maximum]);

  useEffect(() => {
    setChartData({
      ...chartData,

      series: [
        {
          name: title,
          data: data,
        },
      ],

      colors: [color],
      yaxis: {
        labels: {
          formatter: (value) => {
            return temp ? `${value}\u00B0C` : `${value}%`;
          },
        },
        min:
          tickAmount > 20
            ? Math.floor(minimum / 5) * 5
            : tickAmount > 10
            ? minimum % 2
              ? minimum + 1
              : minimum
            : minimum,
        max:
          tickAmount > 20
            ? Math.floor(maximum / 5) * 5
            : tickAmount > 10
            ? maximum % 2
              ? maximum + 1
              : maximum
            : maximum,
        tickAmount:
          tickAmount > 20
            ? Math.floor(maximum / 5) - Math.floor(minimum / 5)
            : tickAmount > 10
            ? tickAmount % 2
              ? maximum % 2
                ? (tickAmount + 1) / 2
                : (tickAmount - 1) / 2
              : tickAmount / 2
            : tickAmount,
        title: {
          show: false,
          text: '',
          style: {
            fontSize: '16px',
          },
        },
      },
      chart: {
        height: 500,
        type: 'area',
        toolbar: {
          tools: {
            download: true,
            selection: true,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
          export: {
            csv: {
              filename: `${title}_${xAxis[0]}_${xAxis[xAxis.length - 1]}`,
              columnDelimiter: ',',
              headerCategory: '時間',
              headerValue: '価値',
              dateFormatter(timestamp) {
                return new Date(timestamp).toDateString();
              },
            },
            svg: {
              filename: `${title}_${xAxis[0]}_${xAxis[xAxis.length - 1]}`,
            },
            png: {
              filename: `${title}_${xAxis[0]}_${xAxis[xAxis.length - 1]}`,
            },
          },
        },
        autoSelected: 'zoom',
      },
      xaxis: {
        type: 'category',
        categories: xAxis,
        label: {
          formatter: (value) => {
            return temp ? value : value;
          },
        },

        labels: {
          show: false,
          rotate: 0,
        },
        axisBorder: {
          show: true,
          width: '200%',
        },
      },
    });
    setStartDate(xAxis[0]);
    setEndDate(xAxis[xAxis.length - 1]);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data, xAxis, minimum, maximum, dateStartSearch]);

  // console.log(data);
  const handleChangeRange = useCallback(
    ({ min, max }) => {
      // setMinVal(min);
      // setMaxVal(max);
      setMin(min);
      setMax(max);
    },
    [maximum, minimum]
  );
  return (
    <>
      {maximum && (
        <div className="chart">
          <div className="chart-title">
            {showPopOver ? (
              <PopOver
                title={title}
                content={
                  title === string.DEVICE_WBGT
                    ? popoverContent.wbgtData
                    : popoverContent.globeTemperature
                }
                className={
                  title === string.DEVICE_WBGT ? 'redPink' : 'yellowOrange'
                }
              />
            ) : (
              title
            )}
          </div>
          <div className="chart-y-setting">
            <div className="title">{string.RANGE_MEASURE}</div>
            <MultiRangeSlider
              min={temp ? DefaultRange[0][0] : DefaultRange[1][0]}
              defaultMin={minimum}
              defaultMax={maximum}
              max={
                temp
                  ? title === string.LIGHT_TEMPERATURE
                    ? DefaultRange[2][1]
                    : DefaultRange[0][1]
                  : DefaultRange[1][1]
              }
              onChange={handleChangeRange}
              temp={temp}
            />
            {/* <button
              onClick={() => {
                setMin(minVal);
                setMax(maxVal);
              }}
            >
              {string.SAVE}
            </button> */}
          </div>

          <ReactApexChart
            options={chartData}
            series={chartData.series}
            type="area"
          />
          <div className="startDate">{startDate}</div>
          <div className="endDate">{endDate}</div>
          <div className="averageValue">
            {temp ? `平均値 ${dataCenter}\u00B0C ` : `平均値 ${dataCenter}%`}
          </div>

          <div style={{ visibility: 'hidden' }}>{'ALINCO-CHART'}</div>
        </div>
      )}
    </>
  );
};

export default Chart;
